import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { Router } from "@angular/router";
import { OverlayPanel } from "primeng/overlaypanel";
import { ModalSize } from "@core/enums/modalSize";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { ModalService } from "@core/services/modal.service";
import { ProductSaleModalComponent } from "@shared/modals/product-sale/product-sale.modal.component";
import { ToastService } from "@core/services/toast.service";
import { PatientService } from "@core/services/patient.service";
import { HttpParams } from "@angular/common/http";
import next from "ajv/dist/vocabularies/next";
import { formatDate } from "@angular/common";
import { BookingsModalComponent } from "@shared/modals/bookings/bookings.modal";

@Component({
  selector: 'app-single-patient-search',
  templateUrl: './single-patient-search.component.html'
})
export class SinglePatientSearchComponent implements OnInit, OnChanges {
  @Input() data!: IPatient;
  @Input() showActions: boolean = true;
  @Input() simpleView: boolean = false;
  @Output() clearSearch = new EventEmitter<void>();
  documentClickListener: (() => void) | null = null;
  futureBookings: any[] = [];
  futureBookingsCount: number = 0;
  isUpdatedLongTimeAgo: boolean = false;
  sixMonthsAgo: Date | null = null;

  isMenuOpen: boolean = false;

  actions = [
    {
      label: 'Koondvaade',
      command: () => this.sendOpenPatientMessage()
    },
    {
      label: 'Ava TSK'
    },
    {
      label: 'Pildipank',
    },
    {
      label: 'Alusta müüki',
      command: () => this.startSelling()
    }
  ];

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private authService: AuthService,
    private el: ElementRef,
    private toastService: ToastService,
    private modalService: ModalService,
    private patientService: PatientService,
  ) {
    const today = new Date();
    this.sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));
  }

  ngOnInit() {
    if(!this.showActions){
      let params = new HttpParams();
      params = params.append('patientId', this.data.patientId)
      params = params.append('dateFrom', this.formatDateISO(new Date()))
      this.patientService.getBookings(params).subscribe({
        next: (bookings) => {
          this.futureBookings = this.getFutureBookings(bookings);
          this.futureBookingsCount = this.futureBookings.length;
        },
        error: (err) => {
          console.error("Error fetching bookings", err);
        }
      })
    }
  }

  ngOnChanges() {
    if (this.data.modified && this.sixMonthsAgo) {
      this.isUpdatedLongTimeAgo = new Date(this.data.modified) < this.sixMonthsAgo;
    } else {
      this.isUpdatedLongTimeAgo = false;
    }
  }

  getFutureBookings(bookings: any[]){
    const futureBookings: any[] = []
    const today = new Date();
    const todayDate = today.toLocaleDateString();
    const todayCommonTime = today.toLocaleTimeString().split(" ");
    const todayTime = todayCommonTime[0];

    bookings.forEach(booking => {
      if(booking.date.toLocaleDateString() === todayDate){
        if(booking.timeFrom >= todayTime){
          futureBookings.push(booking);
        }
      } else if(booking.date.toLocaleDateString() > todayDate){
        futureBookings.push(booking);
      }
    })

    return futureBookings;
  }

  formatDateISO(date: Date): string {
    return formatDate(date, "yyyy-MM-dd", "et_EE");
  }

  onClearSearch() {
    this.clearSearch.emit();
  }

  copyIdCode(value: number) {
    navigator.clipboard.writeText(value.toString()).then(() => {
      this.toastService.secondary("Isikukood kopeeritud", "Info");
    }).catch(() => {
      this.toastService.error("Isikukoodi kopeerimine nurjus");
    });
  }

  openActions(event: any, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      this.documentClickListener = this.renderer.listen('document', 'click', (e) => {
        if (!this.el.nativeElement.contains(e.target) && !overlayPanel.el.nativeElement.contains(e.target)) {
          this.isMenuOpen = false;
          if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
          }
        }
      });
    }
  }

  showPatientBooking(patient: IPatient) {
    this.router.navigate(['/reservation'], {state: {patient}});
  }

  getStatusInfo(isInsured: boolean) {
    return isInsured
      ? {class: 'tag--green', name: 'KINDLUSTATUD'}
      : {class: 'tag--red', name: 'KINDLUSTAMATA'};
  }

  private sendOpenPatientMessage(): void {
    this.authService.getCurrentUser().subscribe({
      next: (response: ICurrentUser | null) => {
        if (response) {
          this.externalMedicalCaseService.open({
            patient: {
              id: this.data.patientId
            },
            doctor: {
              id: response.personId,
              departmentId: response.selectedDepartmentId
            }
          });
        }
      }
    });
  }

  private startSelling(): void {
    this.modalService.openModal({
      component: ProductSaleModalComponent,
      size: ModalSize.LARGE,
      data: { patient: this.data },
      header: 'Müük',
    });
  }

  showFutureReservations(){
    this.modalService.openModal({
      component: BookingsModalComponent,
      size: ModalSize.LARGE,
      customWidth: '50vw',
      data: { bookings: this.futureBookings },
      header: `${this.data.fullName} - tulevased broneeringud`,
    });
  }

  protected readonly parseInt = parseInt;
}
