<section class="products container container--full py-4 flex flex-column gap-4">
  <p-selectButton
    [options]="tableViews"
    [multiple]="false"
    [(ngModel)]="selectedView"
    optionValue="value"
    (onChange)="onTableViewChange($event.value)"
  />

  <div class="flex flex-row gap-4">
    <div class="flex flex-column w-12">
      <div class="form-section__col w-full">
        <p-table [columns]="cols" [value]="documents" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th>
                  {{ col }}
                </th>
              }
              <th>
                @if (documentType !== DocumentType.OPEN_MEDICAL_CASES) {
                  <div class="table__actions flex justify-content-end gap-3">
                    <p-button type="button"
                              label="Kinnita kõik"
                              severity="success"
                              styleClass="w-full font-bold py-2"
                              (click)="confirmAll()"
                    />
                  </div>
                }
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-document>
            <tr>
              @if (documentType === DocumentType.PRESCRIPTIONS) {
                <td>{{ document.patient.idCode }} {{ document.patient.firstName }} {{ document.patient.lastName }}</td>
                <td>
                  @if (document.date) {
                    {{ document.doctor.firstName }} {{ document.doctor.lastName }}
                  }
                </td>
                <td>
                  @if (document.date) {
                    {{ document.date | dateTimeDisplay: 'dd.MM.yyyy' }}
                  }
                </td>
                <td>{{ document.description }}</td>
                <td>{{ document.quantity }}</td>
                <td>{{ document.administration }}</td>
              } @else if (documentType === DocumentType.WORK_INCAPACITY_CERTIFICATES) {
                <td>{{ document.patient.idCode }} {{ document.patient.firstName}} {{ document.patient.lastName }}</td>
                <td>{{ document.periodStart | dateTimeDisplay: 'dd.MM.yyyy' }} - {{ document.periodEnd | dateTimeDisplay: 'dd.MM.yyyy' }}</td>
                <td>{{ document.doctor.firstName }} {{ document.doctor.lastName }} {{ document.doctor.doctorCode }}</td>
              } @else {
                <td>{{ document.caseId }}</td>
                <td>{{ document.createdDateUtc | dateTimeDisplay: 'dd.MM.yyyy' }}</td>
                <td>{{ document.patient.idCode }} {{ document.patient.firstName }} {{ document.patient.lastName }}</td>
                <td>{{ document.doctor.firstName }} {{ document.doctor.lastName }} {{ document.doctor.doctorCode }}</td>
              }
              <td>
                <div class="table__actions flex justify-content-end gap-3">
                  <p-button type="button"
                            label="Ava muutmiseks"
                            severity="secondary"
                            styleClass="w-full font-bold py-2"
                            [outlined]="true"
                            (click)="open(document)"
                  />
                  @if (document.status !== DocumentStatus.CONFIRMED && documentType !== DocumentType.OPEN_MEDICAL_CASES) {
                    <p-button type="button"
                              label="Salvesta"
                              styleClass="w-full font-bold py-2"
                              [loading]="loadings[document.id]"
                              (click)="confirm(document)"
                    />
                  }
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</section>

