import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'dateTimeDisplay',
  standalone: true
})
export class DateTimeDisplayPipe implements PipeTransform {

  constructor(private datePipe: DatePipe, private sanitizer: DomSanitizer) {
  }

  transform(input: string | Date | undefined, format: string = 'dd.MM.yyyy HH:mm', withWeakDay: boolean = false): SafeHtml {
    if (!input) return '';

    const inputDate = new Date(input);
    if (isNaN(inputDate.getTime())) return input as string;

    const formattedDate = this.datePipe.transform(inputDate, format);

    if (withWeakDay) {
      const weekdayLetter = new Intl.DateTimeFormat('et-EE', { weekday: 'long' })
        .format(inputDate)
        .charAt(0).toUpperCase();

      const result = `<strong>${weekdayLetter}</strong> ${formattedDate}`;

      return this.sanitizer.bypassSecurityTrustHtml(result);
    }

    return this.datePipe.transform(inputDate, format) ?? '';
  }

}
