import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IMapping } from "@core/interfaces/mapping.interface";
import { PersonService } from "@core/services/person.service";
import { finalize } from "rxjs";
import { IPerson } from "@core/interfaces/person.interface";
import { ToastService } from "@core/services/toast.service";
import { ModalService } from "@core/services/modal.service";
import { DoctorEditComponent } from "app/feature/doctors/doctors/doctor-edit/doctor-edit.component";
import { ModalSize } from "@core/enums/modalSize";
import { IDepartment } from "@core/interfaces/department.interface";
import { NgForm } from "@angular/forms";
import { ProviderSelectListComponent } from "@shared/components/provider-select-list/provider-select-list.component";

@Component({
  selector: 'medis-replace-doctor',
  templateUrl: './replace-provider.modal.component.html',
  styleUrl: './replace-provider.modal.component.scss'
})
export class ReplaceProviderModalComponent implements OnInit {
  @ViewChild("ngForm") ngForm!: NgForm;

  selectedProvider!: IPerson;
  selectedDepartment!: IDepartment;
  loading = false;
  personDepartments: IDepartment[] = [];

  @ViewChild('providerSelectRef') providerSelectRef!: ProviderSelectListComponent

  constructor(private ref: DynamicDialogRef,
              private personService: PersonService,
              private toastService: ToastService,
              private modalService: ModalService,) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.ref.close();
  }

  submit(): void {
    if (this.selectedProvider && this.selectedDepartment) {
      this.ref.close({person: this.selectedProvider, department: this.selectedDepartment});
    }
  }

  selectProvider(event: IMapping): void {
    let providerId = +event['providerId'];
    if (providerId) {
      this.loading = true;
      this.personService.get(providerId)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (person: IPerson) => {
            this.selectedProvider = person;
            if (person.personDepartments) {
              this.personDepartments = person.personDepartments.map(x => x.department);
            }
          },
          error: (err: Error) => this.toastService.error(err.message),
        })
    }
  }

  addNewProvider(): void {
    let providerModalRef = this.modalService.openModal({
      header: 'Lisa uus tellija',
      component: DoctorEditComponent,
      size: ModalSize.LARGE,
    });

    providerModalRef.onClose.subscribe({
      next: (provider: IPerson) => {
        this.preselectCreatedProvider(provider);
      },
      error: (err: Error) => this.toastService.error(err.message),
    })
  }

  selectDepartment($event: any) {
    let existingDepartment = this.personDepartments.find((dep: IDepartment) => $event.value === dep.departmentId);
    if (existingDepartment) {
      this.selectedDepartment = existingDepartment;
    }

  }

  private preselectCreatedProvider(provider: IPerson) {
    this.providerSelectRef.refreshAndSetProvider(provider);
  }
}
