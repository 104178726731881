
@if (!departmentProfessionPairs.length) {
  <p>Puuduvad osakonnad ja erialad valimiseks. Palun proovige uuesti.</p>
}

<div class="flex flex-column pb-4 gap-2">
  @for(pair of departmentProfessionPairs; track pair){
    <div class="auth-dept-menu__item dept-menu__item flex flex-column border-round gap-2 py-3 px-4 pb-4" (click)="selectDepartmentProfession(pair.department.departmentId, pair.profession?.personProfessionId)">
      @if (pair.profession){
        <div class="auth-dept-menu__item-profession dept-menu__item-proffesion text-base">{{ pair.profession?.professionName }} - {{ pair.profession?.doctorCode }}</div>
      }
      <small class="auth-dept-menu__item-department dept-menu__item-department text-xl font-semibold">{{ pair.department.department.name }} - {{ pair.department.department.companyName }}</small>
    </div>
  }
</div>
