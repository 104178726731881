<div [ngClass]="{
'cursor-move': draggable,
}">
  <pre *ngIf="objToPrint">
    {{ objToPrint | json }}
  </pre>

  <div class="resize-handle top-left" #resizeHandleTL></div>
  <div class="resize-handle top-right" #resizeHandleTR></div>
  <div class="resize-handle bottom-left" #resizeHandleBL></div>
  <div class="resize-handle bottom-right" #resizeHandleBR></div>
</div>
