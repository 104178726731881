import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullSex',
  standalone: true
})
@Injectable({
  providedIn: 'root' // This makes the pipe available application-wide
})
export class FullSexPipe implements PipeTransform {

  private sexes: { [code: string]: string} = {
    "m": "Mees",
    "f": "Naine"
  };

  transform(str: string): string {
    return this.sexes[str];
  }


}
