export enum InvoiceStatus {
  REQUIRED = "Required",
  PAYMENT_LINK_SENT = "PaymentLinkSent",
  CREATED = "Created",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  PREPAID = "Prepaid",
  REFUND = "Refund",
  PREPAID_FAILED = "PrepaidFailed",
  UNNEEDED = "Unneeded",
}
