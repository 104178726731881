import { Component, Input } from '@angular/core';
import { DynamicDialogConfig} from "primeng/dynamicdialog";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { IPatient } from "@core/interfaces/patient.interface";

@Component({
  selector: 'medis-patient-workplaces',
  templateUrl: './patient-workplaces.component.html',
  styleUrl: './patient-workplaces.component.scss'
})
export class PatientWorkplacesComponent {
  @Input() formArray!: FormArray;
  @Input() patient: IPatient = {} as IPatient;
  isModal = false;

  constructor(private formBuilder: FormBuilder,
              private dialogConfig: DynamicDialogConfig) {
    if (this.dialogConfig?.modal) {
      this.isModal = true;
    }
  }

  getPatientProfession(idx: number): FormGroup {
    return this.formArray?.at(idx) as FormGroup;
  }

  addWorkplace(): void {
    this.formArray?.push(this.formBuilder.group({
      patientWorkplaceId: [0],
      patientId: [this.patient?.patientId ?? 0],
      workplace: [null],
      workplaceRegisterNumber: [null],
      workplaceSubdivision: [null],
      profession: [null],
      delete: [false]
    }));
  }
}
