<section class="products-edit container--full flex justify-content-center">
  <div class="container flex flex-column gap-4 py-4">
    <medis-page-header [title]="pageHeader"/>
    @if(patient){
      <medis-order-patient [patient]="patient"/>
    }

      @if(orderDetails){
        <div class="bg-white modal__section border-round-md px-4 pb-2">
          <div class="flex flex-row justify-content-between">
            <h3 class="user-details__section-title text-color-secondary text-xl font-bold">Tellimuse info</h3>
          </div>
          <div class="user-details__section-info flex flex-row" [style]="{gap: '28px'}">
            <div class="flex flex-column w-6">
              @for (item of orderDetails.leftInfo; track item) {
                <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                  <div class="user-details__section-label">
                    <span><b>{{ item.label }}</b></span>
                  </div>
                  <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
                </div>
              }
            </div>
            <div class="flex flex-column w-6">
              @for (item of orderDetails.rightInfo; track item) {
                <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                  <div class="user-details__section-label">
                    <span><b>{{ item.label }}</b></span>
                  </div>
                  <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
                </div>
              }
            </div>
          </div>
        </div>
      }

      <div class="bg-white modal__section border-round-md px-4 pb-4">
        <div class="flex flex-row justify-content-between">
          <h3 class="user-details__section-title text-color-secondary text-xl font-bold">Vastuvõtuinfo</h3>
        </div>

        <div class="col-md-12 mt-2">
          <p-table [value]="serviceItems" [tableStyle]="{'min-width': '50rem'}"
                   styleClass="p-datatable-sm p-datatable-striped">
            <ng-template pTemplate="header">
              <tr>
                <th>Kuupäev</th>
                <th>Kellaaeg</th>
                <th>Osutaja</th>
                <th>Asukoht</th>
                <th>Kabinet</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>
                  {{ item.date | dateFormat }}
                </td>
                <td>{{ item.range }}</td>
                <td>{{ item.providerName }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.roomCode }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

    </div>
    <div class="form-buttons flex justify-content-between gap-3">
      <span>
        <p-button label="Tagasi" severity="primary" [outlined]="true" icon="pi pi-arrow-left" (click)="back()"/>
      </span>
      <span class="flex row gap-4">
        <p-button
          label="Saada kinnitus"
          (click)="sendConfirmation()"
          severity="warning"
        />
        <p-button label="Prindi" type="submit" severity="primary" icon="pi pi-print" (click)="print()" [iconPos]="right"/>
      </span>
    </div>


    @if(orderDetails){
      <div id="print-section">
        <div class="order-details">
          <h4>Lp. {{ patient?.fullName ?? 'Patsient' }}</h4>
          <p>Olete oodatud:</p>
          <br>
          <br>
          <div class="details">
            <div class="left-info">
              <div class="flex flex-row">
                TEENUS <b class="ml-4">{{ orderDetails.leftInfo[1].value }}</b>
              </div>
            </div>
            <br>
            <div class="right-info">
              <div>
                MAKSJA <b class="ml-4">{{ orderDetails.rightInfo[0].value }}</b>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="mt-2">
          <p-table [value]="serviceItems" [tableStyle]="{ width: '100%' }">
            <ng-template pTemplate="header">
              <!-- CSS from anywhere does not seem to be available, so we align text with inline styles -->
              <tr>
                <th style="text-align: start">Kuupäev</th>
                <th style="text-align: start">Kellaaeg</th>
                <th style="text-align: start">Osutaja</th>
                <th style="text-align: start">Asukoht</th>
                <th style="text-align: start">Kabinet</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <!-- td text was aligned from the start, but we set styles anyway just in case-->
                <td style="text-align: start">
                  {{ item.date | dateFormat }}
                </td>
                <td style="text-align: start">{{ item.range }}</td>
                <td style="text-align: start">{{ item.providerName }}</td>
                <td style="text-align: start">{{ item.address }}</td>
                <td style="text-align: start">{{ item.roomCode }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <br>
        <br>
        @if(brochureText){
          <span class="form-group mb-4">
          @for(bText of brochureText; track bText){
            <span>{{bText}}<br><br></span>
          }
        </span>
        }
        @if(orderService && orderService.financialSourceName === FinancialSource.PERSON){
          <span class="form-group mb-4">
            Palume tulla registratuuri 15 min enne Teile broneeritud vastuvõtuaega. Võtke kaasa isikut tõendav dokument.<br>
          </span>
        }
          Kui Te ei saa kokkulepitud ajal vastuvõtule tulla, palun teavitage sellest Medicumi vähemalt 24 tundi ette.
          <br/><br/>
        @if((orderService?.orderServiceTimes?.length && orderService?.orderServiceTimes?.[0]?.departmentPhone) ||
            (orderService?.orderServiceQueues?.length && orderService?.orderServiceQueues?.[0]?.departmentPhone)) {
            Registratuuri telefon:
            {{ orderService?.orderServiceTimes?.[0]?.departmentPhone ?? orderService?.orderServiceQueues?.[0]?.departmentPhone }}
        }
        <br/><br/>
        @if(orderService && orderService.financialSourceName !== FinancialSource.PERSON){
          <span class="form-group mb-4">
            <sup>*</sup> Ambulatoorse eriarstiabi visiiditasu Eesti Tervisekassa poolt kindlustatud vanemale kui 2aastasele isikule on 5 €.
          </span>
        }
        @if(orderService && orderService.financialSourceName === FinancialSource.PERSON){
          <span class="form-group mb-4">
            <sup>*</sup> Tasu vastavalt Medicumi hinnakirjale.
          </span>
        }
      </div>
    }
  </div>
</section>
