import { Component, Input } from '@angular/core';
import { MenuItem } from "primeng/api";
import { DateTimeDisplayPipe } from "@shared/pipes/date-time-display.pipe";

@Component({
  selector: 'medis-page-header',
  template: `
    @if (!isPatientEdit){
      <div class="page__header flex flex-column gap-3 pb-4 border-bottom-1">
        <h1 class="page__title m-0">{{ title }}</h1>
        <medis-breadcrumb [breadcrumbs]="breadcrumbs"></medis-breadcrumb>
      </div>
    } @else {
      <div class="flex flex-row justify-content-between">
        <div class="page__header flex flex-column gap-3 pb-4 border-bottom-1">
          <h1 class="page__title m-0">{{ title }}</h1>
          <medis-breadcrumb [breadcrumbs]="breadcrumbs"></medis-breadcrumb>
        </div>
        <div>
          <medis-patient-last-modified-label [lastModifiedDate]="lastModifiedDate"></medis-patient-last-modified-label>
        </div>
      </div>
    }
  `,
  styleUrl: './page-header.component.scss',
  providers: [DateTimeDisplayPipe]
})
export class PageHeaderComponent {
  @Input() breadcrumbs?: MenuItem[];
  @Input() title: string = '';
  @Input() isPatientEdit?: boolean = false;
  @Input() lastModifiedDate?: Date;
}
