<div class="contact-details flex flex-column gap-4 py-4" [formGroup]="formGroup">
  <div class="modal__row w-full">
    <medis-general-select-list [listId]="26"
                               formControlName="type"
                               labelName="Seos"
                               (selectedGeneralListItem)="setPatientType($event)"
    ></medis-general-select-list>
  </div>
  <div class="modal__row w-full">
    <p-floatLabel>
      <input pInputText
             id="idCode"
             formControlName="idCode"
             type="text"
             class="w-full"
      />
      <label for="idCode">Isikukood</label>
    </p-floatLabel>
    @if (idCode.invalid && idCode.dirty && idCode.errors) {
      <small class="p-error">Isikukood on kohustuslik</small>
    }
  </div>
  <div class="modal__row flex gap-4">
    <div class="modal__col w-full">
      <p-floatLabel>
        <input pInputText
               id="firstname"
               formControlName="firstname"
               type="text"
               class="w-full"
        />
        <label for="firstname">Eesnimi</label>
      </p-floatLabel>
      @if (firstname.invalid && firstname.dirty && firstname.errors) {
        <small class="p-error">Eesnimi on kohustuslik</small>
      }
    </div>
    <div class="modal__col w-full">
      <p-floatLabel>
        <input pInputText
               id="lastname"
               formControlName="lastname"
               type="text"
               class="w-full"
        />
        <label for="lastname">Perekonnanimi</label>
      </p-floatLabel>
      @if (lastname.invalid && lastname.dirty && lastname.errors) {
        <small class="p-error">Perekonnanimi on kohustuslik</small>
      }
    </div>
  </div>
  <div class="modal__row flex gap-4">
    <div class="modal__col w-full">
      <p-floatLabel>
        <input pInputText
               id="email"
               formControlName="email"
               type="text"
               class="w-full"
        />
        <label for="email">E-post</label>
      </p-floatLabel>
      @if (email.invalid && email.dirty && email.errors) {
        <small class="p-error">E-post on kohustuslik</small>
      }
    </div>
    <div class="modal__col w-full">
      <p-floatLabel>
        <input pInputText
               id="phone"
               formControlName="phone"
               type="text"
               class="w-full"
        />
        <label for="phone">Telefon</label>
      </p-floatLabel>
      @if (phone.invalid && phone.dirty && phone.errors) {
        <small class="p-error">Telefon on kohustuslik</small>
      }
    </div>
  </div>

  <div class="modal__col w-full flex gap-2 justify-content-end">
    <p-button label="Sulge"
              severity="secondary"
              [outlined]="true"
              (click)="closeModal()"
    ></p-button>
    <p-button label="Salvesta"
              (click)="submit()"
    ></p-button>
  </div>
</div>
