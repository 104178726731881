import { Component, Input, OnInit } from '@angular/core';
import { IFullOrder } from "@core/interfaces/full-order.interface";
import { getChannelName } from "@core/enums/channel-id.enum";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceService } from "@core/services/order-service.service";

@Component({
  selector: 'medis-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit {
  @Input() set loading (event: any) {
    this.loadingVal = event;
    if(!event){
      this.resetExpanded();
    }
  };
  loadingVal = false;
  @Input() data: IFullOrder[] = [];
  @Input() columns: OrderTableColumn[] = [
    {
      name: OrderColumnName.PATIENT,
      width: '14%'
    },
    {
      name: OrderColumnName.PROVIDER,
      width: '10.5%'
    },
    {
      name: OrderColumnName.FIN_SOURCE,
      width: '6%'
    },
    {
      name: OrderColumnName.SERVICE,
      width: '22.5%'
    },
    {
      name: OrderColumnName.STATUS,
      width: '10.5%'
    },
    {
      name: OrderColumnName.CHANNEL,
      width: '6.5%'
    },
    {
      name: OrderColumnName.REFERRAL_STATUS,
      width: '10.5%'
    },
    {
      name: OrderColumnName.REFERRAL,
      width: '8.5%'
    }
  ];
  expandedRows: { [key: string]: boolean } = {};
  serviceStatusIdx = 0;

  constructor(private orderServiceService: OrderServiceService) {
  }

  ngOnInit() {
    this.serviceStatusIdx = this.columns.findIndex(x => x.name == OrderColumnName.SERVICE);
    this.resetExpanded();
  }

  private resetExpanded(){
    this.data.forEach(order => {
      if (order.orderService.orderServiceTimes && order.orderService.orderServiceTimes.length > 0) {
        this.expandedRows[order.orderService.orderServiceId] = true;
      }
    });
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.orderService.orderServiceId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.orderService.orderServiceId];
  }

  getChannelName(channelId: number): string {
    return getChannelName(channelId);
  }

  openCommentsByOrder = (orderServiceTime: IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

  protected readonly OrderColumnName = OrderColumnName;
}


export interface OrderTableColumn {
  name: OrderColumnName;
  width?: string;
}

export enum OrderColumnName {
  PATIENT = 'patsient',
  PROVIDER = 'osutaja',
  SUBSCRIBER_PROVIDER = 'tellija',
  FIN_SOURCE = 'maksja',
  SERVICE = 'teenus',
  STATUS = 'staatus',
  CHANNEL = 'kanal',
  REFERRAL_STATUS = 'saatekiri',
  REFERRAL_NR = 'saatekiri nr',
  REFERRAL = 'suunaja',
  DESCRIPTION = 'kirjeldus',
  RESERVATION_CHANNEL = 'broneeringu kanal',
}
