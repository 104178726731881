import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function idCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return validate(control.value) ? null : { idCodeValidator: true };
  };
}

function getControlNumber(code: string) {

  const mul1: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
  const mul2: number[] = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
  let controlNum: number;
  let total: number = 0;

  for (let i = 0; i < 10; ++i) {
    total += Number(code.charAt(i)) * mul1[i];
  }
  controlNum = total % 11;

  total = 0;
  if (controlNum === 10) {
    for (let i = 0; i < 10; ++i) {
      total += Number(code.charAt(i)) * mul2[i];
    }
    controlNum = total % 11;
    if (10 === controlNum) {
      controlNum = 0;
    }
  }
  return controlNum;
}

/**
 * Validates the Estonian personal ID.
 */
function validate(code: string): boolean {
  if(!code) { return  false;}
  if (code.charAt(0) === '0') {
    return false;
  }
  if (code.length !== 11) {
    return false;
  }
  const control = getControlNumber(code);
  if (control !== Number(code.charAt(10))) {
    return false;
  }

  const year: number = Number(code.substring(1, 3));
  const month: number = Number(code.substring(3, 5));
  const day: number = Number(code.substring(5, 7));
  const birthDate: Date = getBirthday(code);
  return (
    year === birthDate.getFullYear() % 100 &&
    birthDate.getMonth() + 1 === month &&
    day === birthDate.getDate()
  );
}

function getBirthday(code: string): Date {
  let year: number = Number(code.substring(1, 3));
  const month: number =
    Number(code.substring(3, 5).replace(/^0/, '')) - 1;
  const day: number = Number(code.substring(5, 7).replace(/^0/, ''));
  const firstNumber: string = code.charAt(0);

  for (let i = 1, j = 1800; i <= 8; i += 2, j += 100) {
    if ([i, i + 1].map(String).includes(firstNumber)) {
      year += j;
    }
  }
  return new Date(year, month, day);
}

