<form [formGroup]="form" class="bg-white data-details__section border-round-md">
  <div class="flex flex-column gap-4 pt-4">
    <div class="form-section__row flex gap-4 align-items-center">
      <p-inputSwitch id="anotherPayer" formControlName="anotherPayer" (onChange)="anotherPayerToggle($event)"/>
      <label for="anotherPayer">Maksja on teine isik</label>
    </div>
    @if (anotherPayer.value) {
      <div class="modal__row flex gap-4">
        <div class="modal__col w-full">
          <p-floatLabel>
            <input pInputText
                   id="payerEmail"
                   formControlName="payerEmail"
                   type="text"
                   class="w-full"
                   [ngClass]="{'ng-invalid ng-dirty': sendLinkReady && payerEmail.errors}"
            />
            <label for="payerEmail">E-post</label>
          </p-floatLabel>
          @if (sendLinkReady && payerEmail.errors?.['required']) {
            <small class="p-error">E-post on kohustuslik</small>
          }
          @if (sendLinkReady && payerEmail.errors?.['email']) {
            <small class="p-error">E-post ei ole valiidne</small>
          }
        </div>
        <div class="modal__col w-full">
          <p-floatLabel>
            <input pInputText
                   id="payerPhone"
                   formControlName="payerPhone"
                   type="text"
                   class="w-full"
                   [ngClass]="{'ng-invalid ng-dirty': sendLinkReady && payerEmail.errors}"
            />
            <label for="payerPhone">Telefon</label>
          </p-floatLabel>
          @if (sendLinkReady && payerPhone.errors) {
            <small class="p-error">Telefon on kohustuslik</small>
          }
        </div>
      </div>
    }
  </div>
</form>

