<div class="w-full flex flex-column pb-4 gap-4">
  <form #ngForm="ngForm"
        (ngSubmit)="submit()"
        class="form flex flex-column gap-4">
    <div class="form__row">
      <medis-provider-select-list (onProviderChange)="selectProvider($event)"
                                  labelName="Tellija"
                                  [ngClass]="{ 'ng-invalid ng-dirty' : ngForm.submitted && !selectedProvider}"
                                  #providerSelectRef
      ></medis-provider-select-list>
      @if (selectedProvider && !personDepartments.length) {
        <small class="p-error pt-2">Tellijatel puuduvad osakonnad</small>
      }
      @if (ngForm.submitted && !selectedProvider) {
        <small class="p-error pt-2">Tellija on kohustuslik</small>
      }
    </div>
    <div class="form__row">
      <p-floatLabel>
        <p-dropdown id="department"
                    #dep
                    name="department"
                    required
                    [ngClass]="{ 'ng-invalid ng-dirty' : ngForm.submitted && !selectedDepartment && !dep.disabled}"
                    [options]="personDepartments"
                    styleClass="w-full "
                    [disabled]="!selectedProvider || !personDepartments.length"
                    optionLabel="name"
                    optionValue="departmentId"
                    (onChange)="selectDepartment($event)"
        ></p-dropdown>
        <label for="department">Osakond</label>
      </p-floatLabel>
      @if (ngForm.submitted && !selectedDepartment) {
        <small class="p-error pt-2">Osakond on kohustuslik</small>
      }
    </div>
    <div class="modal-footer flex justify-content-end gap-2">
      <p-button type="button"
                [outlined]="true"
                severity="secondary"
                label="Katkesta"
                (click)="close()"
      ></p-button>
      <p-button type="button"
                severity="secondary"
                label="Lisa uus tellija"
                (click)="addNewProvider()"
                [loading]="loading"
      ></p-button>
      <p-button type="submit"
                label="Salvesta"
                [loading]="loading"
      ></p-button>
    </div>
  </form>
</div>
