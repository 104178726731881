<div class="search-bar flex gap-2">
  <p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input
      #searchInput
      type="text"
      pInputText
      [(ngModel)]="searchQuery"
      (keyup.enter)="performSearch()"
      placeholder="Otsi patsiendi nime, isikukoodi jm. järgi"
      style="width: 25rem; height: 2.5rem"
    />
  </p-iconField>
  @if(hasButton){
    <p-button
      severity="secondary"
      [style]="{ height: '2.5rem' }"
      [outlined]="true"
      label="Otsi"
      (click)="performSearch()">
      </p-button>
  }
</div>
