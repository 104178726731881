<section class="doctors-edit container--full flex justify-content-center">
  <div class="flex flex-column gap-4"
       [ngClass]="isModal ? 'w-full pb-4' : 'container py-4'"
  >
    @if (!isModal) {
      <medis-page-header
        [title]="pageTitle"
        [breadcrumbs]="breadcrumbs"
      ></medis-page-header>
    }

    @if (doctorEditForm && this.departments.length && this.relatedPersons.length) {
      <form
        class="flex flex-column gap-4"
        role="form"
        [formGroup]="doctorEditForm"
        #ngForm="ngForm"
        (ngSubmit)="submit()"
      >
        <div class="form-section">
          <h3 class="form-section__title"></h3>
          <div class="form-section__row flex gap-4">
            <div class="form-section__col w-full">
              <p-floatLabel>
                <input
                  id="firstName"
                  type="text"
                  class="w-full"
                  [ngClass]="{
                    'ng-invalid ng-dirty': ngForm.submitted && firstName.errors
                  }"
                  pInputText
                  formControlName="firstName"
                />
                <label for="firstname">Eesnimi</label>
              </p-floatLabel>
              @if (ngForm.submitted && firstName.errors) {
                <small class="p-error"> Eesnimi on kohustuslik </small>
              }
            </div>

            <div class="form-section__col w-full">
              <p-floatLabel>
                <input
                  id="lastName"
                  type="text"
                  class="w-full"
                  [ngClass]="{
                    'ng-invalid ng-dirty': ngForm.submitted && lastName.errors
                  }"
                  pInputText
                  formControlName="lastName"
                />
                <label for="lastname">Perekonnanimi</label>
              </p-floatLabel>
              @if (ngForm.submitted && lastName.errors) {
                <small class="p-error"> Perekonnanimi on kohustuslik </small>
              }
            </div>

            <div class="form-section__col w-full">
              <p-floatLabel>
                <input
                  id="code"
                  type="text"
                  class="w-full"
                  [ngClass]="{
                    'ng-invalid ng-dirty': ngForm.submitted && code.errors
                  }"
                  pInputText
                  formControlName="code"
                />
                <label for="code">Isikukood</label>
              </p-floatLabel>
              @if (ngForm.submitted && code.hasError('required')) {
                <small class="p-error"> Isikukood on kohustuslik </small>
              } @else if (ngForm.submitted && code.hasError('idCodeValidator')) {
                <small class="p-error">Isikukood on vales formaadis!</small>
              }
            </div>
          </div>
          <div class="form-section__row flex gap-4">
            <div class="form-section__col w-full">
              <medis-general-select-list
                formControlName="professionTypeId"
                [listId]="GeneralList.PROVIDER_GROUP"
                labelName="Kutse"
              >
              </medis-general-select-list>
            </div>
          </div>
          <div class="form-section__row flex gap-4">
            <div class="form-section__col w-full">
              <p-floatLabel>
                <input
                  id="email"
                  type="text"
                  class="w-full"
                  pInputText
                  formControlName="email"
                />
                <label for="email">Email</label>
              </p-floatLabel>
            </div>
            <div class="form-section__col w-full">
              <p-floatLabel>
                <input
                  id="mobile"
                  type="text"
                  class="w-full"
                  pInputText
                  formControlName="mobile"
                />
                <label for="mobile">Telefon</label>
              </p-floatLabel>
            </div>
          </div>
          @if (departments.length > 0) {
            <div class="form-section__row flex gap-4">
              <div class="form-section__col w-full">
                <p-floatLabel>
                  <p-multiSelect
                    id="personDepartments"
                    display="chip"
                    styleClass="w-full"
                    [options]="departments"
                    optionValue="departmentId"
                    optionLabel="name"
                    formControlName="DepartmentIds"
                    [maxSelectedLabels]="10"
                  />
                  <label for="personDepartments">Osakond</label>
                </p-floatLabel>
              </div>
            </div>
          }
          @if (departments.length > 0) {
            <div class="form-section__row flex gap-4">
              <div class="form-section__col w-full">
                <p-floatLabel>
                  <p-multiSelect
                    id="languages"
                    display="chip"
                    styleClass="w-full"
                    [options]="serviceLanguages"
                    optionValue="key"
                    optionLabel="value"
                    formControlName="languages"
                    [maxSelectedLabels]="10"
                  />
                  <label for="languages">Keel</label>
                </p-floatLabel>
              </div>
            </div>
          }

          <div class="form-section__row flex gap-4">

            <div class="form-section__col w-full">
              <medis-address-select-list
                [onlyWorkAddresses]="true"
                formControlName="workaddressId"
                labelName="Töö asukoht"
              ></medis-address-select-list>
            </div>
            <div class="form-section__col w-full">
              <medis-room-select-list
                labelName="Ruum"
                formControlName="roomId"
              ></medis-room-select-list>
            </div>
            <div class="form-section__col w-full">
              <p-floatLabel>
                <p-dropdown
                  id="relatedPersonId"
                  type="text"
                  class="w-full"
                  styleClass="w-full"
                  [showClear]="true"
                  formControlName="relatedPersonId"
                  optionValue="personId"
                  optionLabel="fullName"
                  [options]="relatedPersons"
                  [filter]="true"
                />
                <label for="relatedPersonId">Kolleeg</label>
              </p-floatLabel>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3 class="form-section__title">Erialad</h3>
          <ng-container formArrayName="personProfessions">
            @for (profession of personProfessions.controls; track $index) {
              <div
                class="form-section__row flex gap-4"
                [formGroup]="getPersonProfession($index)"
              >
                <div class="form-section__col w-full">
                  <p-floatLabel>
                    <medis-general-select-list
                      formControlName="professionId"
                      [listId]="GeneralList.SPECIALTY"
                      labelName="Eriala"
                    >
                    </medis-general-select-list>
                  </p-floatLabel>
                </div>

                <div class="form-section__col w-full">
                  <p-floatLabel>
                    <input
                      id="doctorCode{{$index}}"
                      type="text"
                      class="w-full"
                      pInputText
                      formControlName="professionCode"
                    />
                    <label for="doctorCode{{$index}}">Erialakood</label>
                  </p-floatLabel>
                </div>

                <div class="form-section__col w-full">
                  <p-floatLabel>
                    <input
                      id="tehikProfessionCode"
                      type="text"
                      class="w-full"
                      pInputText
                      formControlName="tehikProfessionCode"
                    />
                    <label for="tehikProfessionCode">TEHIKu erialakood</label>
                  </p-floatLabel>
                </div>

                <div class="form-section__col w-full">
                  <p-floatLabel>
                    <input
                      id="personProfessionDoctorCode"
                      type="text"
                      class="w-full"
                      pInputText
                      formControlName="doctorCode"
                    />
                    <label for="personProfessionDoctorCode">Medtöötaja kood
                    </label>
                  </p-floatLabel>
                </div>

                <div class="form-section__col flex align-items-center">
                  <p-button
                    [rounded]="true"
                    icon="pi pi-times"
                    [text]="true"
                    severity="secondary"
                    medisConfirmAction
                    (confirmedAction)="removePersonProfession($event, $index)"
                    confirmMessage="Kas olete kindel, et soovite kustutada selle eriala?"
                  />
                </div>
              </div>
            }
          </ng-container>

          <div class="form-section__row">
            <div class="form-section__col min-w-max">
              <p-button
                label="Lisa eriala"
                icon="pi pi-plus"
                [outlined]="true"
                (click)="addPersonProfession()"
              />
            </div>
          </div>
        </div>

        <div class="form-section__row">
          <div class="form-section__col flex justify-content-end gap-3"
               [class.justify-content-end]="isModal"
          >
            <p-button type="button"
                      label="Katkesta"
                      [outlined]="true"
                      severity="secondary"
                      (click)="cancel()"
            />
            <p-button label="Salvesta"
                      type="submit"
                      [severity]="isModal ? 'primary' : 'success'"
            />
          </div>
        </div>
      </form>
    }
  </div>
</section>
