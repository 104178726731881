import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-html-modal',
  templateUrl: './html-modal.component.html',
  styleUrl: './html-modal.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class HtmlModalComponent {
  html: any;
  @ViewChild('iframe') iframe?: HTMLIFrameElement;


  constructor(private dynamicDialogConfig: DynamicDialogConfig,
              private sanitized: DomSanitizer) {
  }

  ngOnInit() {
    this.html = this.sanitized.bypassSecurityTrustHtml(this.dynamicDialogConfig.data.html);

  }

}
