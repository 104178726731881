import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { IBookingDates } from "@core/interfaces/booking-dates.interface";
import { IBookingTime } from "@core/interfaces/booking-time.interface";

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/bookings`;
  }

  public saveBooking(booking: any): Observable<IOrder>{
    return this.http.post<IOrder>(`${this.BACKEND_URL}/`, booking);
  }

  public changeBooking(booking: any): Observable<IOrderService>{
    return this.http.post<IOrderService>(`${this.BACKEND_URL}/change`, booking);
  }

  public dates(params: HttpParams): Observable<IBookingDates>{
    return this.http.get<IBookingDates>(`${this.BACKEND_URL}/dates`, {params});
  }

  public calendarDates(params: HttpParams): Observable<IBookingTime[]>{
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/calenderdate`, {params});
  }

}
