import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { IPatient } from "@core/interfaces/patient.interface";
import { FormGroup } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { InvoiceService } from "@core/services/invoice.service";
import { DirectoPaymentModalComponent } from "@shared/modals/directo-payment/directo-payment.modal.component";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { IOrderPaymentSmsRequest } from "@core/interfaces/order-payment-sms-request.interface";
import { NotificationService } from "@core/services/notification.service";
import { ConfirmModalComponent } from "@shared/modals/confirm/confirm.modal.component";
import { OrderService } from "@core/services/order.service";

@Component({
  selector: 'medis-company-selected-times',
  templateUrl: './company-selected-times.component.html',
  styleUrl: './company-selected-times.component.scss'
})
export class CompanySelectedTimesComponent implements OnInit {
  @Input() unpaidServices: IOrderServicePayment[] = [];
  @Input() patient!: IPatient;
  @Input() summaryForm!: FormGroup;
  @Input() externalBooking: boolean = false;

  @Output() onServicePayment: EventEmitter<any> = new EventEmitter();
  @Output() onSendPaymentClick: EventEmitter<boolean> = new EventEmitter();
  @Output() onServiceCancel: EventEmitter<number> = new EventEmitter();

  totalSum: number = 0;
  nothingSelected = false;

  constructor(
    private toastService: ToastService,
    private invoiceService: InvoiceService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private orderService: OrderService,
  ) {
  }

  ngOnInit(): void {
    this.updateViewData(true);
  }

  updateViewData(onLoad = false) {
    this.totalSum = 0;

    this.unpaidServices.forEach(s => {
      if (onLoad) s.selected = true;
      if (s.selected) {
        this.totalSum += s.price;
      }
    });

    this.nothingSelected = this.unpaidServices.filter(s => s.selected).length === 0;
  }

  sendPaymentLink(): void {
    this.onSendPaymentClick.emit(true);

    if (this.summaryForm && this.summaryForm?.invalid) return;

    const query = this.getSendPaymentLinkQuery();

    this.notificationService.sendServicesPaymentLink(query).subscribe({
      next: (linkSent) => {
        if (linkSent) {
          this.toastService.success('Makselink edukalt saadetud');
          this.onServicePayment.emit();
        }
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  private getSendPaymentLinkQuery(): IOrderPaymentSmsRequest {
    let query: IOrderPaymentSmsRequest = {
      orderServiceIds: this.getUnpaidOrderServiceIds()
    }

    if (this.summaryForm.get('payerEmail')?.value) {
      query.email = this.summaryForm.get('payerEmail')?.value;
    }

    if (this.summaryForm.get('payerPhone')?.value) {
      query.phone = this.summaryForm.get('payerPhone')?.value;
    }

    return query;
  }

  pay(): void {
    const orderServiceIds = this.getUnpaidOrderServiceIds();
    if (orderServiceIds.length === 0) {
      return;
    }

    const query = { orderServiceIds };

    this.invoiceService.createOrderServicesInvoice(query)
      .subscribe({
        next: (response) => {
          response.invoiceNumbers.forEach(invoiceNumber => {
            this.invoiceService.getInvoiceLink(invoiceNumber)
              .subscribe({
                next: (response) => {
                  window.open(response.link, '_blank');
                  this.openDirectoModal(response.link);
                },
                error: err => {
                  this.toastService.error(err.message)
                }
              })
          });
        }
      })
  }

  openDirectoModal(link: string): void {
    const modalRef = this.modalService.openModal({
      component: DirectoPaymentModalComponent,
      size: ModalSize.LARGE,
      header: 'Maksmine Directos',
      data: {
        orderId: this.unpaidServices[0].orderId,
        patient: this.patient,
        unpaidServices: this.unpaidServices.filter(s => s.selected),
        showPrintButton: false,
        link
      }
    })

    modalRef.onClose.subscribe({
      next: () => {
        this.onServicePayment.emit();
      },
      error: (err: Error) => this.toastService.error(err.message),
    })
  }

  private getUnpaidOrderServiceIds(): number[] {
    return this.unpaidServices.filter(s => s.selected).map(us => us.orderServiceId);
  }

  cancelService(orderServiceId: number): void {
    const CONFIRMATION_MODAL_TITLE = 'Teenuse aja tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid tühistada?'
    const ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.MEDIUM,
      header: CONFIRMATION_MODAL_TITLE,
      data: {bodyText: CONFIRMATION_MODAL_BODY_TEXT}
    })

    ref.onClose.subscribe((result: boolean | string | number) => {
      if (result) {
        this.orderService.cancelService(orderServiceId).subscribe({
          next: () => {
            this.onServiceCancel.emit(orderServiceId);
          },
          error: err => {
            this.toastService.error(err.message)
          }
        })
      }
    })
  }

}
