export enum OrderReferralStatus {
  OPTIONAL = 'Optional',
  MISSING = 'Missing',
  CREATED = 'Created',
  CONFIRMED = 'Confirmed',
  ASSIGNED = 'Assigned',
  RESOLVED = 'Resolved',
  CANCELLED = 'Cancelled',
  NOT_ALLOWED = 'NotAllowed',
  EXPIRED = 'Expired'
}
