import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'DateTime',
  standalone: true
})
export class DateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(datetime: string | null): string {
    if (!datetime) return '';

    return this.datePipe.transform(datetime, 'dd.MM.YYYY HH:mm') ?? '';
  }
}
