@if (!loadingVal) {
  <p-table [value]="data" dataKey="orderService.orderServiceId"
           [expandedRowKeys]="expandedRows"
           (onRowExpand)="onRowExpand($event)"
           (onRowCollapse)="onRowCollapse($event)"
           [columns]="columns"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="first-col">Tellimus</th>
        @for (col of columns; track col) {
          <th [style.width]="col.width">{{ col.name }}</th>
        }
        <th class="last-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-expanded="expanded" let-columns="columns">
      <tr [class.expanded-background]="expanded">
        <td class="first-col">
          <div class="flex align-items-center">
            @if (order.orderService.orderServiceTimes?.length > 0) {
              <p-button
                type="button"
                [pRowToggler]="order"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></p-button>
            } @else {
              <div class="expand-button-placeholder"></div>
            }
            <div class="ml-2 gap-1">
              <p class="m-0">{{ order.orderService.orderId }}</p>
              <span class="text-base font-semibold">{{ order.orderService.created | dateFormat }}</span>
            </div>
          </div>
        </td>

        @for (col of columns; track col) {
          <td [style.width]="col.width ?? 'auto'">
            @if (loading) {
              <p-skeleton></p-skeleton>
            }
            @switch (col.name) {
              @case (OrderColumnName.PATIENT) {
                <div class="flex flex-column gap-1">
                  <a routerLink="/patients/view/{{order.order.patient.patientId}}"
                     class="text-primary patient-link">{{ order.order.patient.fullName }}
                  </a>
                  <span class="text-base font-semibold text-base font-semibold patient-codecursor-pointer">{{
                      order.order.patient.idCode
                    }}</span>
                </div>
              }
              @case (OrderColumnName.PROVIDER) {
                <p>
                  {{
                    order.orderService.orderServiceTimes[0]?.providerName
                      ? order.orderService.orderServiceTimes[0]?.providerName
                      : order.orderService.providerName
                        ? order.orderService.providerName
                        : order.orderService.orderServiceQueues[0]?.providerName
                  }}
                </p>
              }
              @case (OrderColumnName.SUBSCRIBER_PROVIDER) {
                {{ (order.order.provider.lastName ?? '') + ' ' + (order.order.provider.firstName ?? '') }}
              }
              @case (OrderColumnName.FIN_SOURCE) {
                @if (order.orderService.financialSourceName) {
                  <medis-financial-source-label
                    [financialSourceId]="order.orderService.financialSourceId ?? order.orderService.financialSourceName"></medis-financial-source-label>
                }
              }
              @case (OrderColumnName.SERVICE) {
                <div class="flex gap-2 align-items-center">
                  <medis-service-badge [badges]="order.service.serviceBadges"></medis-service-badge>
                  {{ order.service.name }}
                  @if (order.orderService.commentsCount && order.orderService.commentsCount > 0) {
                    <p-button [rounded]="true"
                              icon="pi pi-comment"
                              [text]="true"
                              severity="secondary"
                              (click)="openCommentsByOrder(order.orderService)"/>
                  }
                </div>
              }
              @case (OrderColumnName.DESCRIPTION) {
                <!-- here goes Order Description-->
              }
              @case (OrderColumnName.STATUS) {
                @if (order.orderService.orderServiceTimeStatus || order.orderService.orderServiceStatus) {
                  <medis-order-service-status-label
                    [status]="order.orderService.orderServiceTimeStatus ?? order.orderService.orderServiceStatus"
                    [invoiceStatus]="order.orderService.invoiceStatus">
                  </medis-order-service-status-label>
                }
              }
              @case (OrderColumnName.CHANNEL) {
                {{ getChannelName(order.order.channelId) }}
              }
              @case (OrderColumnName.REFERRAL_STATUS) {
                <div class="flex flex-column gap-1">
                  @if (order.orderService.referralStatus !== "NotAllowed") {
                    <medis-referral-status-label
                      [referralStatus]="order.orderService.referralStatus"></medis-referral-status-label>
                  }
                </div>
              }
              @case (OrderColumnName.REFERRAL_NR) {
                <span class="text-base font-semibold text-center">{{ order.orderService.referralNumber }}</span>
              }
              @case (OrderColumnName.REFERRAL) {
                <div class="flex flex-column">
                  <p class="m-0">{{ order.orderService.referralAuthorName }}</p>
                  <p class="m-0">{{ order.orderService.referralSourceCompanyName }}</p>
                </div>
              }
              @case (OrderColumnName.RESERVATION_CHANNEL) {
                <div class="flex flex-column">
                  <p class="m-0">{{ order.order.channelId | channelName }}</p>
                </div>
              }
            }
          </td>
        }

        <td class="last-col">
          <div class="flex justify-content-end">
            <medis-order-actions [orderService]="order.orderService">
            </medis-order-actions>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-order let-expanded="expanded">
      @for (orderServiceTime of order.orderService.orderServiceTimes; let index = $index; track orderServiceTime) {
        <tr class="border-0 expanded-background">
          <td class="first-col"></td>
          @for (col of columns; track col) {
            @switch (col.name) {
              @default {
                <td [style.width]="col.width"></td>
              }
              @case (OrderColumnName.SERVICE) {
                <td [style.width]="col.width">
                  Aeg {{ index + 1 }} - <span [innerHTML]="orderServiceTime.date | dateTimeDisplay: 'dd.MM.yyyy': true"></span> &nbsp;
                  {{ orderServiceTime?.timeFrom | timeFormat }} - {{ orderServiceTime?.timeUpto | timeFormat }}
                  &nbsp;
                  {{ orderServiceTime?.roomCode }} &nbsp;
                  {{ orderServiceTime?.departmentShort }}
                </td>
              }
              @case (OrderColumnName.STATUS) {
                <td [style.width]="col.width">
                  @if (orderServiceTime?.orderServiceTimeStatus || orderServiceTime?.orderServiceStatus) {
                    <medis-order-service-status-label
                      [status]="orderServiceTime?.orderServiceTimeStatus ?? orderServiceTime?.orderServiceStatus"
                      [invoiceStatus]="orderServiceTime?.invoiceStatus">
                    </medis-order-service-status-label>
                  }</td>
              }
            }
          }
          <td class="last-col">
            <div class="flex justify-content-end">
              <medis-order-actions [time]="orderServiceTime">
              </medis-order-actions>
            </div>
          </td>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length + 2"></td>
      </tr>
    </ng-template>
  </p-table>
} @else {
  <p-table [value]="data" [columns]="columns">
    <ng-template pTemplate="header">
      <tr>
        @for (col of columns; track col) {
          <th [style.width]="col.width">
            <p-skeleton width="100%" height="3rem"></p-skeleton>
          </th>
        }
      </tr>
    </ng-template>
  </p-table>
}
