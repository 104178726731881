import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(datetime: string | Date | null | undefined): string {
    if (!datetime) return '';
    let dateValue = datetime instanceof Date ? datetime : Date.parse(datetime);
    if(Number.isNaN(dateValue) && typeof datetime == 'string'){
      const [day, month, year] = datetime.split('.').map(Number);
      dateValue = new Date(year, month - 1, day);
    }

    return this.datePipe.transform(dateValue, 'dd.MM.yyyy') ?? '';
  }
}
