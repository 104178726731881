<p-pickList
  [source]="sourceServices"
  [target]="targetServices"
  sourceHeader="Saadaval"
  targetHeader="Valitud"
  [dragdrop]="true"
  [responsive]="true"
  [showSourceControls]="false"
  [showTargetControls]="false"
  [stripedRows]="true"
  (onMoveToTarget)="onTargetChange($event)"
  (onMoveToSource)="onTargetChange($event)"
  (onMoveAllToSource)="onTargetChange($event)"
  (onMoveAllToTarget)="onTargetChange($event)"
  filterBy="name"
  sourceFilterPlaceholder="Filter by name"
  targetFilterPlaceholder="Filter by name"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  breakpoint="1400px">
  <ng-template let-service pTemplate="item">
    <div class="p-picklist-item flex align-items-center gap-1" (click)="onItemClick(service)" [ngClass]="{'selected-picker-item': isSelected(service), 'unselected-picker-item': !isSelected(service)}">
      <medis-service-badge [badges]="service.serviceBadges"></medis-service-badge>
      <div>{{ service.name }}</div>
    </div>
  </ng-template>
</p-pickList>
@if (targetServices.length < 1 && formSubmitted && required) {
  <small class="p-error">
    Vähemalt üks teenus peab olema lisatud
  </small>
}
