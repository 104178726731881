<p-table [value]="unpaidServices" styleClass="pt-4">
  <ng-template pTemplate="header">
    <tr>
      @if (summaryForm) {
        <th class="checkbox-col"></th>
      }
      <th>Kuupäev</th>
      <th>Kellaaeg</th>
      <th>Teenus</th>
      <th>Arst</th>
      <th>Aadress</th>
      <th>Kabinet</th>
      <th>Kogus</th>
      <th>Hind</th>
      <th>Tasuda(€)</th>
      <th>Staatus</th>
      @if (!summaryForm) {
        <th></th>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-service>
    <tr>
      @if (summaryForm) {
        <td>
          <p-checkbox [(ngModel)]="service.selected"
                      [binary]="true"
                      (onChange)="updateViewData()"
          />
        </td>
      }
      <td>{{ service.date | dateTimeDisplay: 'dd.MM.yyyy' }}</td>
      <td>{{ service.time | time }}</td>
      <td>{{ service.serviceName }}</td>
      <td>{{ service.providerName }}</td>
      <td>{{ service.address | patientContact }}</td>
      <td>{{ service.room.codeName}}</td>
      <td>{{ service.quantity }}</td>
      <td>{{ service.unitPrice }}</td>
      <td>{{ service.price }}</td>
      <td>
        <medis-order-service-invoice-status [status]="service.invoice.invoiceStatus"/>
      </td>
      @if (!summaryForm) {
        <td>
          <p-button label="Tühista" [link]="true" (click)="cancelService(service.orderServiceId)"/>
        </td>
      }
    </tr>
  </ng-template>
</p-table>
@if (summaryForm) {
  <div class="pt-2">
    <p-button label="Saada makselink"
              class="pr-2"
              icon="pi pi-envelope"
              severity="secondary"
              [disabled]="nothingSelected || !totalSum"
              (click)="sendPaymentLink()"
    />
    @if (!externalBooking) {
      <p-button [disabled]="nothingSelected"
                (click)="pay()">
        Mine maksma ({{(totalSum | number:'1.0-2')}}€)
      </p-button>
    }
  </div>
}




