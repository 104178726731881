import { Component, Input } from '@angular/core';
import { IOrderService } from "@core/interfaces/order-service.interface.interface";

@Component({
  selector: 'medis-order-selected-products',
  templateUrl: './order-selected-products.component.html',
  styleUrl: './order-selected-products.component.scss'
})
export class OrderSelectedProductsComponent {
  @Input() products: IOrderService[] = [];
}
