<div class="patient-form container--full flex justify-content-center">
  <form class="flex flex-column gap-4 pb-4"
        role="form"
        [formGroup]="patientForm"
        (ngSubmit)="submit()"
        style="max-width: 1025px; width: 100%">
    @if (!isModal) {
      <medis-page-header title="Patsiendi andmete muutumine"
                         class="mt-4"
                         [breadcrumbs]="breadcrumbs"
                         [isPatientEdit]="true"
                         [lastModifiedDate]="patient.modified"
      />
    }

    @if (generalDataGroup) {
      <medis-patient-general-data [formGroup]="generalDataGroup"
                                  [patient]="patient"
      />
    }

    @if (additionalDataGroup && showAdditionalData) {
      <medis-patient-additional-data [formGroup]="additionalDataGroup"/>
    }

    <div class="form-section__row">
      <div class="form-section__col flex justify-content-end gap-3"
           [class.justify-content-end]="isModal"
      >
        <p-button type="button"
                  label="Katkesta"
                  severity="secondary"
                  [outlined]="true"
                  (click)="back()"
        />
        <p-button type="submit"
                  label="Salvesta"
                  [severity]="isModal ? 'primary' : 'success'"
        />
      </div>
    </div>
  </form>
</div>
