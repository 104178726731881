import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "medis-bookings-modal",
  templateUrl: "./bookings.modal.html",
})
export class BookingsModalComponent {
  bookings: any[] = [];

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
    this.bookings = this.config.data?.bookings || [];
  }

  closeModal(){
    this.ref.close();
  }
}
