import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { LocationService } from "@core/services/location.service";

@Component({
  templateUrl: './add-address.modal.html',
  styleUrl: './add-address.modal.scss'
})
export class AddAddressModal implements OnInit {
  formItems = [
    { formControlName: "department", label: "Asukoha nimi", required: true },
    { formControlName: "departmentShort", label: "Asukoha lühinimi", required: true },
    { formControlName: "city", label: "Linn", required: true },
    { formControlName: "street", label: "Tänav", required: true },
    { formControlName: "building", label: "Maja", required: true },
    { formControlName: "zip", label: "Indeks", required: false },
  ]
  addressForm: FormGroup;
  formSubmitted = false;

  constructor(
    private ref: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
  ) {
    this.addressForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.initAddressForm();
  }

  private initAddressForm() {
    this.formItems.forEach(item => {
      this.addressForm.addControl(
        item.formControlName,
        this.formBuilder.control(null, item.required ? Validators.required : [])
      );
    });
  }

  submit() {
    this.formSubmitted = true;

    if (this.addressForm.invalid) return;

    this.locationService.saveAddress(this.addressForm.value).subscribe({
      next: () => {
        this.ref.close(true);
      }
    })
  }

  closeModal(){
    this.ref.close();
  }
}
