@if (eConsultation) {
  <form [formGroup]="eConsultationForm" (ngSubmit)="resolveEConsultation()">
    <div class="flex flex-column gap-4 overflow-y-auto">

      <medis-referral-data [patient]="eConsultation.patient"
                           [referral]="eConsultation.referral"
                           [sentDate]="eConsultation.sentDate"
                           [provider]="eConsultation.provider"
      ></medis-referral-data>

      <div class="response__section dialog__section border-round-md">
        <h3 class="dialog__section-title text-color-secondary text-xl font-bold">Vastus |
          Vastaja: {{ eConsultation.providerName }}</h3>
        <div class="response__section-info flex flex-column">
          @if (!readonly && eConsultation.status === EConsultationStatus.ASSIGNED) {
            <div class="flex flex-column">
              <p-floatLabel>
                <p-dropdown
                  id="decisionCode"
                  styleClass="w-full"
                  [ngClass]="{'ng-invalid ng-dirty': formSubmitted && eConsultationForm.get('decisionCode')?.errors}"
                  appendTo="body"
                  [options]="decisionCodes"
                  optionLabel="label"
                  optionValue="value"
                  formControlName="decisionCode"
                ></p-dropdown>
                <label for="decisionCode" class="text-color-secondary text-sm">Liik</label>
              </p-floatLabel>
              @if (formSubmitted && eConsultationForm.get('decisionCode')?.errors) {
                <small class="p-error my-2"> Liik on kohustuslik </small>
              }
            </div>
          }
          @if (readonly || eConsultation.status === EConsultationStatus.RESOLVED) {
            <div class="dialog__section-field flex flex-row">
              <div class="dialog__section-label">
                <span><b>Liik</b></span>
              </div>
              <p
                class="dialog__section-value m-0 text-color-secondary">{{ eConsultation.decisionCode | decisionName }}</p>
            </div>
          }
          @if (!readonly && eConsultation.status === EConsultationStatus.ASSIGNED) {
            <div class="response__section-description flex flex-row">
              <div class="flex flex-column w-6">
                <p-floatLabel>
                  <textarea
                    id="decision"
                    rows="5"
                    pInputTextarea
                    class="w-full"
                    [ngClass]="{'ng-invalid ng-dirty': formSubmitted && eConsultationForm.get('decision')?.errors}"
                    [autoResize]="true"
                    formControlName="decision"
                  ></textarea>
                  <label for="decision" class="text-color-secondary text-sm">Kirjeldus</label>
                </p-floatLabel>
                @if (formSubmitted && eConsultationForm.get('decision')?.errors) {
                  <small class="p-error my-2"> Kirjeldus on kohustuslik </small>
                }
              </div>
              <div class="flex flex-column w-6">
                <p-floatLabel>
                  <textarea
                    id="comment"
                    rows="5"
                    pInputTextarea
                    class="w-full"
                    [autoResize]="true"
                    formControlName="comment"
                  ></textarea>
                  <label for="comment" class="text-color-secondary text-sm">Kommentaar õele/assistentidele</label>
                </p-floatLabel>
              </div>
            </div>
          }
          @if (readonly || eConsultation.status === EConsultationStatus.RESOLVED) {
            <div class="dialog__section-field flex flex-row">
              <div class="dialog__section-label">
                <span><b>Kirjeldus</b></span>
              </div>
              <p class="dialog__section-value m-0 text-color-secondary"
                 [innerHTML]="eConsultation.decision | nl2br"></p>
            </div>
            <div class="dialog__section-field flex flex-row last-element">
              <div class="dialog__section-label">
                <span><b>Kommentaar õele/assistendile</b></span>
              </div>
              <p class="dialog__section-value m-0 text-color-secondary" [innerHTML]="eConsultation.comment | nl2br"></p>
            </div>
          }
        </div>
      </div>

    </div>

    <div class="p-dialog-footer justify-content-end sticky-footer">
      <p-button
        label="Sulge"
        [outlined]="true"
        severity="secondary"
        (onClick)="closeModal()"
      />
      @if (!readonly && eConsultation.patientMisCode) {
        <p-button label="Ava MIS-is" severity="secondary" (click)="openPatientInMis()"/>
      }
      @if (!readonly && eConsultation.status === EConsultationStatus.RESOLVED) {
        <p-button label="Ava muutmiseks" (click)="reopen()"/>
        <p-button label="Kinnita" severity="success" (click)="confirmEConsultation()"/>
      }
      @if (!readonly && eConsultation.status === EConsultationStatus.ASSIGNED) {
        <p-button label="Salvesta" type="submit"/>
      }
    </div>
  </form>
}
