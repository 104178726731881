import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Prevent any input that is not number
 */
@Directive({
  selector: '[numbersOnly]',
  standalone: true
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight',
      'Delete', 'Enter', 'Escape'
    ];

    // Allow specific keys234324
    if (
      allowedKeys.includes(event.key) ||
      (event.ctrlKey || event.metaKey) && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())
    ) {
      return;
    }

    // Block keys that aren't numbers
    const isNumberKey = event.key >= '0' && event.key <= '9';
    const isNumpadKey = event.key.startsWith('Numpad') && event.key >= '0' && event.key <= '9';


    if (!isNumberKey && !isNumpadKey) {
      event.preventDefault();
    }
  }

  // Prevent copy-pasting strings
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filteredValue = inputElement.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (inputElement.value !== filteredValue) {
      inputElement.value = filteredValue; // Update the value
      inputElement.dispatchEvent(new Event('input')); // Trigger Angular's change detection
    }
  }
}
