import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CalendarOptions } from "@fullcalendar/core";
import { FullCalendarComponent } from "@fullcalendar/angular";

@Component({
  selector: 'medis-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements AfterViewInit {

  @ViewChild('calendar') calendar!: FullCalendarComponent;
  @Input() calendarOptions!: any;
  @Output() calendarObject = new EventEmitter();

  ngAfterViewInit() {
    setTimeout(() => {this.calendarObject.emit(this.calendar)}, 1000);
  }
}
