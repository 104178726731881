export enum GeneralList {
  ABSENCE_REASON = 24,
  BODY_AREA = 10,
  BODY_AREA_SPECIFICATION = 11,
  CONTACT_TYPE = 26,
  CONTRAST_SUBSTANCE = 21,
  DEVICE_MANUFACTURER = 14,
  DEVICE_MODEL = 15,
  FINANCIAL_SOURCE = 25,
  LATERALITY = 12,
  OCCUPATION_NAME = 22,
  ORGAN_SYSTEM = 23,
  PROCEDURE_KIND = 16,
  PROCEDURE_SUB_TYPE = 18,
  PROCEDURE_TYPE = 17,
  PROJECTION = 13,
  PROVIDER_GROUP = 19,
  RADIOLOGICAL_STUDY_TYPE = 8,
  SERVICE_NAME = 20,
  SPECIALTY = 1,
  SPECIALTY_SPECIFICATION = 3,
  STUDY_TYPE = 7,
  SUB_SPECIALTY = 2,
  VISIT_KIND = 6,
  VISIT_TYPE = 5,
  GP_TEAM_ROLE = 27,
  CLIENT_GROUP = 28,
  FIELD = 29,
  CHILD_AGE_GROUP = 30,
  WORK_PLACE_RISK_FACTOR = 31
}
