import { Component, OnInit } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'medis-web-frontend';
  sidebarOpen: boolean = false;
  authorized = false;

  constructor(
    private authService: AuthService,
    private activatedRoute : Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.events.subscribe(url =>{
      this.authorized = this.authService.hasAccessToken();
    });
  }

}
