import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from "@angular/common";
import { BadgeModule } from "primeng/badge";
import { TagModule } from "primeng/tag";
import { IMapping } from "@core/interfaces/mapping.interface";
import { BadgeType } from "@core/enums/badge-type.enum";
import { ReferralStatusEnum } from "@core/enums/referral-status.enum";
interface Source {
  full: string;
  style: string;
}
@Component({
  selector: 'medis-referral-status-label',
  standalone: true,
  imports: [
    NgClass,
    BadgeModule,
    TagModule,
  ],
  template: `
    <p-tag
      rounded="true"
      class="text-center"
      [value]="source.full"
      [styleClass]="source.style"
    ></p-tag>
  `
})

export class ReferralStatusLabelComponent implements OnInit {
  @Input() referralStatus!: ReferralStatusEnum;
  source!: Source;

  private readonly sourceStyleMap: IMapping = {
    [BadgeType.INFO]: 'tag tag--blue',
    [BadgeType.YELLOW]: 'tag tag--yellow',
    [BadgeType.DANGER]: 'tag tag--red',
    [BadgeType.SUCCESS]: 'tag tag--green',
  };

  private readonly labels = {
    [ReferralStatusEnum.CONFIRMED]: {full: 'Saatekiri seotud', style: this.sourceStyleMap[BadgeType.SUCCESS]},
    [ReferralStatusEnum.CREATED]: {full: 'Saatekiri salvestatud', style: this.sourceStyleMap[BadgeType.YELLOW]},
    [ReferralStatusEnum.EXPIRED]: {
      full: 'Saatekiri aegunud',
      style: this.sourceStyleMap[BadgeType.DANGER]
    },
    [ReferralStatusEnum.MISSING]: {
      full: 'Saatekiri sidumata',
      style: this.sourceStyleMap[BadgeType.DANGER]
    },
    [ReferralStatusEnum.OPTIONAL]: {
      full: 'Saatekiri pole vajalik',
      style: this.sourceStyleMap[BadgeType.INFO]
    },
  };

  ngOnInit() {
    this.source = this.labels[this.referralStatus]
      || {full: this.referralStatus, style: this.sourceStyleMap[BadgeType.INFO]};
  }
}
