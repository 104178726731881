import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { Router } from "@angular/router";

@Component({
  selector: 'app-multiple-patient-search',
  templateUrl: './multiple-patient-search.component.html'
})
export class MultiplePatientSearchComponent {
  @Input() patients: IPatient[] = [];
  @Output() selectPatient = new EventEmitter<IPatient>();

  constructor(private router: Router) {}

  choosePatient(patient: IPatient) {
    this.selectPatient.emit(patient);
  }

  getStatusInfo(isInsured: boolean) {
    return isInsured
      ? { class: 'tag--green', name: 'KINDLUSTATUD' }
      : { class: 'tag--red', name: 'KINDLUSTAMATA' };
  }

  openPatientView(patientId: number){
    this.router.navigate([`/patients/${patientId}/details`]);
  }

  protected readonly parseInt = parseInt;
}
