import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PresentModalComponent } from "@shared/modals/present/present.modal.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IService } from "@core/interfaces/service.interface";
import { OrderServiceService } from "@core/services/order-service.service";
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { OrderService } from "@core/services/order.service";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { OrderServiceQueueService } from "@core/services/order-service-queue.service";
import { OrderServiceTimeService } from "@core/services/order-service-time.service";

@Component({
  selector: 'medis-patients-table',
  providers: [DialogService],
  templateUrl: './patients-table.component.html',
  styleUrls: ['./patients-table.component.scss'],
})
export class PatientsTableComponent implements OnDestroy {
  @Input() data: IOrderServiceTime[] = [];
  @Input() bookingDate: Date = new Date();
  @Input() service!: IService;
  @Input() isFreeTimesLoaded: boolean = false;

  expandedRows: { [key: string]: boolean } = {};

  @Output() updateList = new EventEmitter<{time: IOrderServiceTime, status: OrderServiceStatus}>();
  @Output() refreshList = new EventEmitter();
  @Output() menuOpenForDailyPlan = new EventEmitter<boolean>();

  orderTypes: any = [];
  ref: DynamicDialogRef | undefined;

  constructor(
    private dialogService: DialogService,
    private orderServiceService: OrderServiceService,
    private router: Router,
    private orderService: OrderService,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private orderServiceQueueService: OrderServiceQueueService,
    private orderServiceTimeService: OrderServiceTimeService,
    ) {
  }

  onMenuOpenChange(isMenuOpen: boolean) {
    this.menuOpenForDailyPlan.emit(isMenuOpen);
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.freeTimeGroupId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.freeTimeGroupId];
  }

  openCommentsByOrder = (orderServiceTime: IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

  presentReady(time: IOrderServiceTime): boolean {
    const status = time.orderServiceTimeStatus ?? time.orderServiceStatus;
    return !!status && [OrderServiceStatus.CONFIRMED, OrderServiceStatus.IN_SERVICE_QUEUE, OrderServiceStatus.IN_SERVICE_QUEUE, OrderServiceStatus.IN_PROVIDER_QUEUE].includes(status);
  }

  openPatient(time: IOrderServiceTime){
    this.sendOpenPatientMessage(time);
  }

  openPatientView(patientId: number){
    this.router.navigate(['/patients/view', patientId]);
  }

  isToday(dateString: string): boolean {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    const formattedDate = dateString.split('T')[0];
    return formattedToday === formattedDate;
  }

  navigateToReservation(serviceId: number, personId: number, timeFrom:string, timeUpto: string, date: string){
    const freeTimeReservation = {
      serviceId: serviceId,
      personId: personId,
      timeFrom: timeFrom,
      timeUpto: timeUpto,
      date: date
    }
    this.router.navigate(['/reservation'], { state: { freeTimeReservation } });
  }

  setPresent(time: IOrderServiceTime): void {
      this.ref = this.dialogService.open(
        PresentModalComponent,
        {
          header: 'Kohaloleku märkimine',
          width: '60vw',
          data: { patientId: time.patientId, orderServiceTime: time }
        });

      this.ref.onClose.subscribe((response) => {
        if (response) {
          if (time && (time.orderServiceTimeId || time.orderServiceQueueId)) {
            const request = time.orderServiceQueueId
              ? (id: number) => this.orderServiceQueueService.setPresent(id)
              : (id: number) => this.orderServiceTimeService.setPresent(id);

            request(time.orderServiceQueueId ?? time.orderServiceTimeId)
              .subscribe({
              next: () => {
                this.updateList.emit({time, status: OrderServiceStatus.PRESENT})
              }
            });
          }
        }
      })
  }

  isFutureDateSelected = () => {
    const now = new Date();
    now.setHours(0,0,0,0)
    return now.getDate() < this.bookingDate.getDate();
  }

  onTimeChanged(timeData: {time: IOrderServiceTime, status: OrderServiceStatus}, time: IOrderServiceTime): void {
    time = timeData.time;
    this.updateList.emit(timeData);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  private sendOpenPatientMessage(time: IOrderServiceTime): void {
    let httpParams = new HttpParams()
    .set("orderServiceTimeId", time.orderServiceTimeId || 0)
    .set("orderServiceQueueId", time.orderServiceQueueId || 0);
    this.orderService
      .getInitExternalMedicalCaseData(httpParams)
      .subscribe({
        next: (response: any) => {
          this.externalMedicalCaseService.open(response);
        }
      });
  }

  protected readonly OrderServiceStatus = OrderServiceStatus;
}
