@if (!loading) {
  <div class="order-filter flex flex-column gap-4-5">
    <!--          Upper filter-->
    @if (config?.externalBookingTitle) {
      <h3 class="form-section__title pt-4 pb-2">{{ config?.externalBookingTitle }}</h3>
    }
    <div class="order-filter__section flex flex-row w-full gap-4-5">
      <div class="order-filter__order w-3">
        @if (implementationService && implementationService === selectedBookingOutput.service) {
          <div class="flex align-items-center gap-2">
            <medis-service-badge [badges]="implementationService.serviceBadges"/>
            <div>{{ implementationService.name }}</div>
          </div>
        } @else {
          <medis-service-select-list
            labelName="Teenus"
            [isDisabled]="config?.disableService"
            [selectedServiceId]="selectedBookingOutput.serviceId"
            (onServiceSelect)="onServiceChange($event)"
          />
        }
      </div>
      <div class="order-filter__doctor w-3">
        <p-floatLabel>
          <p-dropdown
            styleClass="w-full"
            id="doctor"
            [options]="providers"
            [showClear]="true"
            [filter]="true"
            filterBy="fullName"
            optionLabel="fullName"
            optionValue="personId"
            [(ngModel)]="selectedBookingOutput.providerId"
            (ngModelChange)="onProviderChange($event)"
          >
          </p-dropdown>
          <label for="doctor">Osutaja</label>
        </p-floatLabel>
      </div>
      <div class="order-filter__location w-3">
        <p-floatLabel>
          <p-dropdown
            styleClass="w-full"
            id="location"
            [options]="addresses"
            [showClear]="true"
            optionLabel="departmentShort"
            [(ngModel)]="selectedBookingOutput.department"
            (ngModelChange)="onLocationChange($event)"
            [disabled]="!selectedBookingOutput.service"
          >
          </p-dropdown>
          <label for="location">Asukoht</label>
        </p-floatLabel>
      </div>
      <div class="order-filter__location w-3">
        <p-floatLabel>
          <p-dropdown
            styleClass="w-full"
            id="company"
            [options]="companies"
            [showClear]="true"
            optionLabel="name"
            [(ngModel)]="selectedBookingOutput.companyFilter"
            (ngModelChange)="onCompanyChange($event)"
            [disabled]="config?.disableCompany ?? false"
          >
          </p-dropdown>
          <label for="company">Ettevõte</label>
        </p-floatLabel>
      </div>
    </div>

    <!--          Lower filter -->
    <div class="order-filter__section grid grid-nogutter gap-4-5">
      <!-- SelectButton Container -->
      <div class="order-filter__select-button col-fixed">
        <p-selectButton
          [options]="tableViews"
          (onChange)="changeReservationTab()"
          [(ngModel)]="selectedView"
          [disabled]="loading"
          [unselectable]="true"
          styleClass="flex white-space-nowrap"
        />
      </div>

      <!-- Time Range Calendars -->
      <div class="order-filter__time flex flex-row gap-2-5 col-fixed">
        <div class="order-filter__time-start cursor-pointer">
          <p-floatLabel>
            <p-calendar
              [(ngModel)]="startTime"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [timeOnly]="true"
              id="startTime"
            >
              <ng-template
                pTemplate="inputicon"
                let-clickCallBack="clickCallBack"
              >
                <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
              </ng-template>
            </p-calendar>
            <label for="startTime">Algusaeg</label>
          </p-floatLabel>
        </div>

        <div class="order-filter__time-end cursor-pointer">
          <p-floatLabel>
            <p-calendar
              [(ngModel)]="endTime"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [timeOnly]="true"
              id="endTime"
            >
              <ng-template
                pTemplate="inputicon"
                let-clickCallBack="clickCallBack"
              >
                <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
              </ng-template>
            </p-calendar>
            <label for="endTime">Lõppaeg</label>
          </p-floatLabel>
        </div>
      </div>

      <!-- Days Checkboxes -->
      <div class="order-filter__weekday col-fixed">
        <div class="flex align-items-center gap-2-5" [style.height.rem]="2.745">
          <span class="order-filter__weekday-item">
            <p-checkbox
              [(ngModel)]="weekdays.monday"
              (onChange)="onDaysOfWeekChange($event, 1)"
              value="1"
              id="monday"
            />
            <label for="monday">E</label>
          </span>
          <span class="order-filter__weekday-item">
            <p-checkbox
              [(ngModel)]="weekdays.tuesday"
              (onChange)="onDaysOfWeekChange($event, 2)"
              value="2"
              id="tuesday"
            />
            <label for="tuesday">T</label>
          </span>
          <span class="order-filter__weekday-item">
            <p-checkbox
              [(ngModel)]="weekdays.wednesday"
              (onChange)="onDaysOfWeekChange($event, 3)"
              value="3"
              id="wednesday"
            />
            <label for="wednesday">K</label>
          </span>
          <span class="order-filter__weekday-item">
            <p-checkbox
              [(ngModel)]="weekdays.thursday"
              (onChange)="onDaysOfWeekChange($event, 4)"
              value="4"
              id="thursday"
            />
            <label for="thursday">N</label>
          </span>
          <span class="order-filter__weekday-item">
            <p-checkbox
              [(ngModel)]="weekdays.friday"
              (onChange)="onDaysOfWeekChange($event, 5)"
              value="5"
              id="friday"
            />
            <label for="friday">R</label>
          </span></div>
      </div>

      <!-- Date Range Calendars -->
      <div class="order-filter__date flex gap-2-5 col-fixed">
        <div class="order-filter__date-start">
          <p-floatLabel>
            <p-calendar
              [iconDisplay]="'input'"
              [(ngModel)]="selectedStartDate"
              [showIcon]="true"
              id="startDate"
              dateFormat="dd.mm.yy"
              showButtonBar
              (onSelect)="onDateChange($event, 'start')"
            />
            <label for="startDate">Algus</label>
          </p-floatLabel>
        </div>

        <div class="order-filter__date-end">
          <p-floatLabel>
            <p-calendar
              [iconDisplay]="'input'"
              [showIcon]="true"
              [(ngModel)]="selectedEndDate"
              id="endDate"
              dateFormat="dd.mm.yy"
              showButtonBar
              (onSelect)="onDateChange($event, 'end')"
            />
            <label for="endDate">Lõpp</label>
          </p-floatLabel>
        </div>
      </div>

      <!-- Dropdown that should stretch to fill remaining space -->
        <div class="order-filter__payer col">
          <p-floatLabel>
            <p-dropdown
              styleClass="w-full"
              class="w-full"
              id="payer"
              [options]="financialSources"
              optionLabel="financialSourceName"
              optionValue="financialSourceId"
              [(ngModel)]="selectedFinancialSource"
              [disabled]="!selectedBookingOutput.service"
              [showClear]="true"
            />
            <label for="payer">Maksja</label>
          </p-floatLabel>
        </div>

        <!-- Dropdown that should stretch to fill remaining space -->
        <div class="order-filter__all-times col">
          <p-floatLabel>
            <p-dropdown
              styleClass="w-full"
              class="w-full"
              [options]="forPublicWebOptions"
              [(ngModel)]="selectedOption"
              id="allTimes"
              [showClear]="true"
            />
            <label for="allTimes">Kõik ajad</label>
          </p-floatLabel>
        </div>

        @if (multiple || bookingCount > 1) {
          <div class="flex col-fixed">
            <p-floatLabel>
              <input id="bookingCount"
                     pInputText
                     [(ngModel)]="bookingCount"
                     [disabled]="bookingCountDisabled"
                     class="max-w-5rem"/>
              <label for="allTimes">Kogus</label>
            </p-floatLabel>
          </div>
        }

        <div class="flex col-fixed">
          <p-button
            label="Otsi"
            icon="pi pi-search"
            (click)="onSomethingChanged()"
          />
        </div>
    </div>

    <!-- order-table.component.html -->

    @if (isViewSelected(TableViewsEnum.ALL_TIMES) ||
    isViewSelected(TableViewsEnum.ADD_MULTIPLE)) {
      <div class="order-table flex relative border-c">
        <!-- Left button -->
        <p-button
          *ngIf="bookingTimes.length > 0 && currentIndex != 0"
          class="order-reservation-arrow order-reservation-arrow--left"
          severity="secondary"
          [raised]="true"
          [text]="true"
          icon="pi pi-chevron-left"
          (click)="loadLeftData(bookingTimes)"
        >
        </p-button>

        <!-- Order tables -->
        <!-- Left table -->
        <div class="order-table__container flex gap-4 w-full relative">
          @if (leftTableData.length && !loading) {
            <!-- Left table -->
            <ng-container *ngTemplateOutlet="freeTimeTable; context:{data: leftTableData}"></ng-container>
          }
          @if (rightTableData.length && !loading) {
            <ng-container *ngTemplateOutlet="freeTimeTable; context:{data: rightTableData}"></ng-container>
          }
          @if (loading) {
            <div class="w-full flex justify-content-center pb-4">
              <p-progressSpinner
                styleClass="w-4rem h-4rem spinner"
                strokeWidth="4"
                animationDuration=".5s"
              />
            </div>
          }
          <!-- Right table -->
          @if (bookingCount > 1) {
            <div
              class="reservation-times w-2 flex flex-column border-round-md ml-auto"
            >
              <ng-container *ngTemplateOutlet="selectedTimes"></ng-container>
            </div>
          }
        </div>
        <!-- Right button -->
        <p-button
          *ngIf="bookingTimes.length > 20 && rightTableData.length === 10"
          class="order-reservation-arrow order-reservation-arrow--right"
          severity="secondary"
          [ngClass]="{ 'displaced-arrow': bookingCount > 1 }"
          [raised]="true"
          [text]="true"
          icon="pi pi-chevron-right"
          (click)="loadRightData(bookingTimes)"
        />
      </div>
    }
    @if (isBookingTimesEmpty) {
      <div class="pb-5">
        <p>Broneerimisaegu pole saadaval</p>
      </div>
    }
    @if ((isViewSelected(TableViewsEnum.CALENDAR) || isViewSelected(TableViewsEnum.ADD_NUMBER)) && selectedBookingOutput.serviceId) {
      <div class="calendar flex gap-4-5 flex-grow-1">
        <div class="w-6">
          <!-- TODO: Add spinner for preload calendar  -->
          <medis-calendar [calendarOptions]="calendarOptions"
                          (calendarObject)="calendar = $event; fetchCalendarEvents()">
            <ng-template #eventContent let-arg>
              @if (arg.event.extendedProps['data']) {
                <div class="flex flex-column w-full h-full p-2 gap-1">
                  <span class="flex row w-full justify-content-between gap-2">
                    <i class="flex align-items-center gap-2">
                      <p class="m-0">
                        {{ arg.event.title }} {{ getLocation(arg.event.extendedProps['data']) }}
                      </p>
                      @if (arg.event.extendedProps['data'].state === 'Queue') {
                        <order-service-queue-label></order-service-queue-label>
                      }
                    </i>
                    <medis-financial-source-label
                      [financialSourceId]="arg.event.extendedProps['data'].financialSourceId"></medis-financial-source-label>
                  </span>
                  <span>
                    <i class="flex gap-1 align-items-center">
                      {{ arg.event.extendedProps['data'].serviceName }}
                    </i>
                  </span>
                </div>
              }
            </ng-template>
          </medis-calendar>
        </div>
        <div class="w-6 flex gap-4-5">
          <div class="reservation-service w-8">
            <div class="reservation-service__header">
              <div class="reservation-service__title">
                <medis-service-badge
                  [badges]="selectedBookingOutput.service!.serviceBadges"
                />
                <div>{{ selectedBookingOutput.service!.name }}
                </div>
              </div>
            </div>
            <div class="reservation-service__content flex flex-column gap-4">
              @if (selectedCalendarDate) {
                <div class="reservation-service__date text-4xl font-semibold">
                  {{ selectedCalendarDate | dateFormat }}
                </div>
              }
              <div class="reservation-service__accordion flex flex-column">
                @if (calendarFreeTimes && calendarFreeTimes.length > 0 || !loading) {
                  <p-accordion [activeIndex]="0">
                    <p-accordionTab
                      [header]="providerFreeTimes.provider ? providerFreeTimes.provider.fullName : ''"
                      *ngFor="let providerFreeTimes of calendarFreeTimes"
                    >
                      <div class="accordion__section p-3-5">
                        <div class="booking-list">
                          <div
                            class="booking-item"
                            *ngFor="let item of providerFreeTimes.times"
                            [class.text-indigo-500]="item.state == BookingStatesEnum.REST"
                            [class.text-green-500]="item.state == BookingStatesEnum.QUEUE"
                          >
                            <div class="booking-item__checkbox">
                              <p-checkbox
                                [(ngModel)]="item.isChecked"
                                [binary]="true"
                                (ngModelChange)="onBookingSelect(item)"
                                [disabled]="isItemChecked(item) || disabledState(item)"
                              >
                              </p-checkbox>
                              <div class="booking-item__time">
                                {{ item.timeFrom | timeFormat }} -
                                {{ item.timeUpto | timeFormat }}
                              </div>
                            </div>

                            <div class="booking-item__source">
                              @if (item.state != BookingStatesEnum.REST) {
                                <medis-financial-source-label
                                  [financialSourceId]="
                              item.isChecked &&
                              selectedBookingOutput.financialSourceId
                                ? selectedBookingOutput.financialSourceId
                                : (item.financialSourceId ??
                                  item.financialSourceName ?? null)
                            "
                                  [small]="true"
                                ></medis-financial-source-label>
                              }
                            </div>

                            <div class="booking-item__location">
                              @if (item.state != BookingStatesEnum.REST) {
                                {{ item.departmentShort }}
                                {{ item.roomCode }}
                                @if (selectedBookingOutput.providerId && item.patientName && item.serviceName) {
                                  | {{ item.patientName }} | {{ item.serviceName }}
                                }
                              } @else {
                                {{ item.info }}
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                } @else {
                  @for (col of [1, 2]; track $index) {
                    <th class="w-full">
                      <p-skeleton width="100%" height="3rem"></p-skeleton>
                    </th>
                  }
                }
              </div>
            </div>
          </div>
          @if (bookingCount > 1) {
            <div
              class="reservation-times flex flex-column border-round-md w-4"
            >
              <ng-container *ngTemplateOutlet="selectedTimes"></ng-container>
            </div>
          }
        </div>


      </div>
    }

    @if (showReferral) {
      <medis-unused-referrals
        [data]="{
        patientId: selectedBookingOutput.patientId,
        service: selectedBookingOutput.service,
        hideBook: true,
        referralNr: config?.referral?.documentNr,
        type: 'unused'
      }"
        (referralOutput)="setReferral($event)"
      >
      </medis-unused-referrals>
    }

    <!--          Finalize order section-->
    @if (selectedBookingOutput.times.length && selectedBookingOutput.service && !loading && bookingTimes) {
      <div class="order-summary flex flex-row w-12 gap-4">
        <div
          [ngClass]="isCompanyForSelectShow() ? 'w-3': 'w-6'"
          class="order-summary__comment">
          <p-floatLabel>
            <input id="comment"
                   pInputText
                   class="w-full"
                   [(ngModel)]="comment"
            />
            <label for="comment">Kommentaar</label>
          </p-floatLabel>
        </div>

        <div
          [ngClass]="isCompanyForSelectShow() ? 'w-9': 'w-6'"
          class="order-summary__payer flex flex-row gap-4">
          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="payer-info"
                styleClass="w-full"
                [options]="financialSources"
                optionLabel="financialSourceName"
                optionValue="financialSourceId"
                [showClear]="true"
                [(ngModel)]="selectedBookingOutput.financialSourceId"
                (ngModelChange)="changeFinSource()"
              />
              <label for="payer-info">Maksjainfo muutmine</label>
            </p-floatLabel>
          </div>
          @if (isCompanyForSelectShow()) {
            <div class="w-full flex gap-1 flex-column">
              <p-floatLabel>
                <p-dropdown id="selectedCompany"
                            styleClass="w-full"
                            optionLabel="name"
                            [options]="companies"
                            [showClear]="true"
                            [required]="!selectedBookingOutput.companyFilter && selectedBookingOutput.financialSourceId == FinSourceId.AnotherJuridicalPerson"
                            [(ngModel)]="selectedBookingOutput.companySelected"
                />
                <label for="case">Ettevõtte</label>
              </p-floatLabel>
              @if (!selectedBookingOutput.companySelected) {
                <small class="p-error">
                  <span> Peab olema valitud! </span>
                </small>
              }
            </div>
          }
          <div class="order-summary__case w-full">
            <p-floatLabel>
              <p-dropdown id="case"
                          styleClass="w-full"
                          [options]="medicalCases"
                          placeholder="caseId"
                          optionLabel="displayName"
                          (ngModelChange)="setMedicalCase()"
                          [(ngModel)]="selectedBookingOutput.medicalCase"
              />
              <label for="case">Haigusjuht</label>
            </p-floatLabel>
          </div>
          <p-button
            severity="success"
            label="Kinnita"
            [disabled]="successBlock() || confirmClicked"
            [loading]="confirmClicked"
            (onClick)="output.emit(selectedBookingOutput); config?.submitHandler()"
          />
        </div>
      </div>
    }
  </div>
} @else {
  <div class="flex flex-column gap-4">
    <div class="flex gap-5 w-full mb-2">
      <p-skeleton class="w-full" height="2.745rem"/>
      <p-skeleton class="w-full" height="2.745rem"/>
      <p-skeleton class="w-full" height="2.785rem"/>
      <p-skeleton class="w-full" height="2.785rem"/>
    </div>
    <div class="flex gap-5 w-full mb-2">
      <p-skeleton class="w-4" height="2.745rem"/>
      <p-skeleton class="w-1" height="2.745rem"/>
      <p-skeleton class="w-1" height="2.745rem"/>
      <p-skeleton class="w-3" height="2.745rem"/>
      <p-skeleton class="w-3" height="2.745rem"/>
    </div>
    <p-skeleton width="100%" height="40rem"/>
  </div>
}

<p-overlayPanel styleClass="calendar-overlaypanel" #op>
  <div class="calendar-overlay">
    <div class="calendar-overlay__header">
      <div class="calendar-overlay__title">
        <medis-service-badge [badges]="overlayObj?.serviceBadges ?? []">

        </medis-service-badge>
        <span>{{ overlayObj?.serviceName }}</span>
      </div>
    </div>
    <div class="calendar-overlay__content">
      <div class="overlay-service">
        @if (overlayObj?.range) {
          <div class="overlay-service__item">
            <span class="overlay-service__label">Periood</span
            ><span class="overlay-service__value">{{ overlayObj?.range }}</span>
          </div>
        }
        <div class="overlay-service__item">
          <span class="overlay-service__label">Patsient</span
          ><span class="overlay-service__value">{{
            overlayObj?.patientName
          }}</span>
        </div>
        <div class="overlay-service__item">
          <span class="overlay-service__label">Osutaja</span
          ><span class="overlay-service__value">{{
            overlayObj?.providerName
          }}</span>
        </div>
        <div class="overlay-service__item">
          <span class="overlay-service__label">Maksja</span>
          <span class="overlay-service__value">
            <p-tag
              rounded="true"
              value="{{ overlayObj?.financialSourceName }}"
              styleClass="tag tag--small tag--yellow"
            >
            </p-tag>
          </span>
        </div>
        <div class="overlay-service__item">
          <span class="overlay-service__label">Asukoht</span
          ><span class="overlay-service__value">{{ overlayObj?.departmentShort }} {{ overlayObj?.roomCode }}</span>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>


<ng-template #freeTimeTable let-data="data">
  <p-table
    [value]="data"
    [class.w-5]="bookingCount > 1"
    [class.w-6]="bookingCount <= 1"
  >
    <ng-template pTemplate="body" let-item>
      <tr [class.selected-row]="item.isChecked">
        <td>
          <p-checkbox
            [(ngModel)]="item.isChecked"
            [binary]="true"
            (click)="onBookingSelect(item)"
            [disabled]="isItemChecked(item)"
          >
          </p-checkbox>
        </td>
        <td>
          <b>{{ item.dayOfWeek | dayOfWeek }}</b>
          {{ item.date | dateFormat }}
        </td>
        <td>
          {{ item.timeFrom | timeFormat }} -
          {{ item.timeUpto | timeFormat }}
        </td>
        <td>
          <medis-financial-source-label
            [financialSourceId]="
                  item.isChecked && selectedBookingOutput.financialSourceId
                    ? selectedBookingOutput.financialSourceId
                    : item.financialSourceId ?? item.financialSourceName
                "
          ></medis-financial-source-label>
        </td>
        @if (item.ageFrom && item.ageTo) {
          <td>
            <age-group-label
              [ageFrom]="item.ageFrom"
              [ageTo]="item.ageTo"
            ></age-group-label>
          </td>
        }
        @if (!item.ageFrom || !item.ageTo) {
          <td></td>
        }
        <td>
          <hidden-in-public-web-label
            [isHiddenInPublicWeb]="item.isHiddenInPublicWeb"
          ></hidden-in-public-web-label>
        </td>
        @if (item.price) {
          <td>
            <b>{{ item.price }}€</b>
          </td>
        }
        @if (!item.price) {
          <td></td>
        }
        <td>{{ item.departmentShort }} {{ item.roomCode }}</td>
        <td>
          {{ item.providerName }}
        </td>
        <td>
          <emergency-gp-team-label
            [isEmergencyGpTeam]="item.isEmergencyGpTeam"
          ></emergency-gp-team-label>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template #selectedTimes let-data="data">
  <div
    class="reservation-times__header flex row justify-content-between align-items-center p-3"
    [class.header-div--highlight]="bookingCount > 1"
  >
    <span class="text-lg">Valitud ajad</span>
    <span
    ><b
    >{{ selectedBookingOutput.times.length }}/{{ bookingCount }}</b
    ></span
    >
  </div>

  <div class="reservation-times__content bg-white">
    @for (item of selectedBookingOutput.times; track item) {
      <div class="flex row justify-content-between h-4rem px-4">
        <div class="flex align-items-center gap-2">
          <span [innerHTML]="item.date | dateTimeDisplay: 'dd.MM.yyyy': true"></span>
          <span>{{ item.timeFrom | timeFormat }} - {{ item.timeUpto | timeFormat }}</span>
          <span>
            <medis-financial-source-label
              [financialSourceId]="
                    selectedBookingOutput.financialSourceId ??
                    item.financialSourceId
                  "
            >
            </medis-financial-source-label>
          </span>
        </div>
        <div class="flex align-items-center">
          <span>
            @if (canRemoveTime(item)) {
              <p-button
                [rounded]="true"
                icon="pi pi-times"
                [text]="true"
                severity="secondary"
                (onClick)="onBookingSelect(item)"
              />
            }
          </span>
        </div>
      </div>
    }
  </div>
</ng-template>
