import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PrimeNGConfig } from "primeng/api";

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
})
export class I18nModule {
  constructor(translate: TranslateService,
              private primeConfig: PrimeNGConfig,
  ) {
    translate.addLangs(['et']);
    translate.use('et');

    // Adding translations for PrimeNG components
    translate.get('primeng').subscribe(res => this.primeConfig.setTranslation(res));
  }
}

export function translateLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
