import { Component, Input, OnInit } from '@angular/core';
import { BadgeModule } from "primeng/badge";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";
import { OrderStatusPipe } from "@shared/pipes/order-status.pipe";
import { TagModule } from "primeng/tag";

@Component({
  selector: 'medis-order-service-invoice-status',
  standalone: true,
  imports: [
    BadgeModule,
    OrderStatusPipe,
    TagModule
  ],
  template: `
    <p-tag
      rounded="true"
      class="text-center"
      [value]="statusLabel"
      [styleClass]="statusClass"
    />
  `,
})

export class OrderServiceInvoiceStatusComponent implements OnInit {
  @Input() status!: InvoiceStatus;

  private readonly statusMapping = {
    [InvoiceStatus.CANCELLED]: {label: 'Tühistatud', class: 'tag tag--blue'},
    [InvoiceStatus.REQUIRED]: {label: 'Ootab maksmist', class: 'tag tag--red'},
    [InvoiceStatus.CREATED]: {label: 'Suunatud kassasse', class: 'tag tag--cyan'},
    [InvoiceStatus.COMPLETED]: {label: 'Makstud', class: 'tag tag--green'},
    [InvoiceStatus.PAYMENT_LINK_SENT]: {label: 'Makselink saadetud', class: 'tag tag--blue'},
    [InvoiceStatus.PREPAID]: {label: 'Makstud', class: 'tag tag--green'},
    [InvoiceStatus.REFUND]: {label: 'Tagasimakse', class: 'tag tag--purple'},
    [InvoiceStatus.PREPAID_FAILED]: {label: 'Arve loomine ebaõnnestus', class: 'tag tag--orange'},
    [InvoiceStatus.UNNEEDED]: {label: 'Mittekohustuslik', class: 'tag tag--blue'},
  };

  statusLabel: string = '';
  statusClass: string = '';

  ngOnInit(): void {
    this.statusLabel = this.statusMapping[this.status].label;
    this.statusClass = this.statusMapping[this.status].class;
  }
}
