import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ModalSize } from "@core/enums/modalSize";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private dialogRef: DynamicDialogRef | undefined;
  constructor(private dialogService: DialogService) {
  }

  public openModal(settings: ModalSettings): DynamicDialogRef {
    let sizes = this.mapSizes(settings.size);

    let modalOptions: any = {
      width: settings.customWidth || sizes.width,
      header: settings.header,
      height: settings.customHeight || sizes.height,
      data: settings.data,
      modal: true,
      resizable: settings.resizable ?? false,
      breakpoints: {
        '960px': sizes.breakPoint960,
        '640px': sizes.breakPoint640
      },
      styleClass: settings.styleClass,
      contentStyle: settings.contentStyle
    };

    if (settings.disableClose) {
      modalOptions.closable = false;
      modalOptions.dismissableMask = false;
    }

    this.dialogRef = this.dialogService.open(settings.component, modalOptions);

    // Return the modal reference to allow for subscribing to onClose
    return this.dialogRef;
  }

  // public closeModal() {
  //   if (this.dialogRef) {
  //     this.dialogRef.close();
  //   }
  // }

  private mapSizes(size: ModalSize|undefined): ModalServiceSize {
    switch (size) {
      case ModalSize.EXTRA_LARGE:
        return {
          width: '90vw',
          height: '90vh',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      case ModalSize.LARGE:
        return {
          width: '75rem',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      case ModalSize.MEDIUM:
        return {
          width: '40rem',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      default:
      case ModalSize.SMALL:
        return {
          width: '50vw',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
    }
  }
}

interface ModalSettings {
  component: any;
  size?: ModalSize;
  data?: any;
  header?: any;
  disableClose?: boolean;
  styleClass?: string;
  customWidth?: string;
  customHeight?: string;
  contentStyle?: {[klass: string]: any } | null | undefined;
  resizable?: boolean;
}

interface ModalServiceSize {
  width: string;
  height?: string|undefined;
  breakPoint960: string;
  breakPoint640: string;
}

