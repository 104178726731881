import { Component, Input, OnInit } from '@angular/core';
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: 'age-group-label',
  standalone: true,
  imports: [
    TagModule,
    TooltipModule,
  ],
  template: `<p-tag
                rounded="true"
                [value]="ageRange"
                [pTooltip]="'Vanuse rühm'"
                styleClass="tag tag--default">
            </p-tag>`
})
export class AgeGroupLabelComponent implements OnInit {
  @Input() ageFrom!: number;
  @Input() ageTo!: number;
  ageRange: string = '';

  ngOnInit() {
    this.ageRange = `${this.ageFrom}-${this.ageTo}`;
  }
}
