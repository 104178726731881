import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { Router } from "@angular/router";
import { IPersonProfession } from "@core/interfaces/person-profession.interface";
import { IDepartment } from "@core/interfaces/department.interface";
import { IPersonDepartments } from "@core/interfaces/person-departments.interface";
import { ToastService } from "@core/services/toast.service";
import { environment } from "@environments/environment";

@Component({
  selector: 'medis-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  @Input() sidebarOpen!: boolean;
  @Output() sidebarOpenChange = new EventEmitter<boolean>();

  user: ICurrentUser | null = null;
  departments: any[] = [];
  selectedDepartment: IDepartment | null = null;
  selectedProfession: IPersonProfession | null = null;
  isMenuActive = false;
  departmentProfessionPairs: { department: IDepartment, profession: IPersonProfession }[] = [];
  private isUpdatingUser = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next: (user: ICurrentUser | null) => {
        if (user) {
          this.user = user;
          this.selectedDepartment = this.getSelectedDepartment(user.selectedDepartmentId, user.personDepartments);
          this.selectedProfession = this.getSelectedProfession(user.selectedPersonProfessionId, user.personProfessions);
          this.departmentProfessionPairs = this.createDepartmentProfessionPairs(user.personDepartments, user.personProfessions);
        }
      },
      error: (err: any) => {
        console.error("Error fetching current user: ", err);
      }
    });
  }

  createDepartmentProfessionPairs(departments: IPersonDepartments[], professions: IPersonProfession[]) {
    return departments.flatMap(department => {
      return professions.map(profession => ({
        department: department.department,
        profession: profession
      }));
    });
  }

  getSelectedDepartment(departmentId: number, departments: IPersonDepartments[] = []): IDepartment | null {
    const department = departments.find(d => d.departmentId === departmentId);
    return department ? department.department : null;
  }

  getSelectedProfession(professionId: number, professions: IPersonProfession[]): IPersonProfession | null {
    return professions.find(p => p.personProfessionId === professionId) || null;
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    // Dispatch resize event with delay to let resize-aware components resize
    // when sidebar interrupts document flow (timeout to allow more time for calculations)
    // i.e. trigger FullCalendar handleWindowResize trigger
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500);
    this.sidebarOpenChange.emit(this.sidebarOpen);
  }

  reload() {
    window.location.reload();
  }

  onDepartmentChange(departmentChange: { department: IDepartment; profession: IPersonProfession }) {
    if (this.isUpdatingUser) {
      return;
    }
    if (this.user) {
      const hasChanged = this.user.selectedDepartmentId !== departmentChange.department.departmentId ||
        this.user.selectedPersonProfessionId !== departmentChange.profession.personProfessionId;

      if (hasChanged) {
        this.isUpdatingUser = true;
        this.authService.changeDepartment(departmentChange.department.departmentId, departmentChange.profession.personProfessionId)
          .subscribe({
            next: () => {
              this.user!.selectedDepartmentId = departmentChange.department.departmentId;
              this.user!.selectedPersonProfessionId = departmentChange.profession.personProfessionId;
              this.reload();
              this.selectedProfession = departmentChange.profession;
              this.selectedDepartment = departmentChange.department;
              this.isUpdatingUser = false;
            },
            error: () => {
              this.toastService.error("Osakonna vahetamisel tekkis viga. Palun proovige uuesti.")
              this.isUpdatingUser = false;
            }
          });
      }
    }

    this.isMenuActive = false;
  }

  onMenuToggle(isActive: boolean) {
    this.isMenuActive = !isActive;
  }

  navigateToReservationPage() {
    this.router.navigate(['/reservation']);
  }

  protected readonly environment = environment;
}
