import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IOrderService } from "@core/interfaces/order-service.interface.interface";

@Pipe({
  name: 'rowPrice',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class RowPricePipe implements PipeTransform {

  transform(product: IOrderService): number {
    return parseFloat((product.price * (product.repeat ?? 1)).toFixed(2));
  }

}
