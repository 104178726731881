import { Component } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { DeviceService } from "@core/services/device.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IDeviceTypeAdd } from "@core/interfaces/device-type-add.interface";
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-device-type-add',
  templateUrl: './device-type-add.modal.component.html'
})
export class DeviceTypeAddModalComponent {
  addDeviceTypeForm!: FormGroup;
  formSubmitted = false;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private deviceService: DeviceService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    this.addDeviceTypeForm = this.formBuilder.group({
      deviceCategory: [null, Validators.required],
      deviceType: [null, Validators.required],
    })
  }

  save(): void {
    this.formSubmitted = true;
    if (this.addDeviceTypeForm.invalid) {
      return;
    }

    this.deviceService.saveType(this.deviceTypeFromForm()).subscribe({
      next: result => {
        this.ref.close(result);
      },
      error: err => {
        this.toastService.error(err.message);
      }
    });
  }

  private deviceTypeFromForm(): IDeviceTypeAdd {
    return {
      deviceCategoryId: this.deviceCategory.value,
      name: this.deviceType.value
    }
  }

  closeModal(): void {
    this.ref.close();
  }

  get deviceCategory(): FormControl {
    return this.addDeviceTypeForm.get('deviceCategory') as FormControl;
  }

  get deviceType(): FormControl {
    return this.addDeviceTypeForm.get('deviceType') as FormControl;
  }

}
