<div [formGroup]="formGroup"
     class="patient-general-data flex flex-column gap-4">
  <div class="form-section">
    <h3 class="form-section__title">Patsiendi andmed</h3>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown id="nationality"
                      type="text"
                      styleClass="w-full"
                      [options]="nationality"
                      formControlName="nationality"
                      optionValue="value"
                      optionLabel="label"
                      appendTo="body"
          />
          <label for="nationality">Isikukood tüüp</label>
        </p-floatLabel>
      </div>
      @if (patientNationality.value != Country.EST) {
        <div class="form-section__col w-full">
          <p-floatLabel>
            <p-dropdown [options]="countries"
                        id="country"
                        formControlName="country"
                        styleClass="w-full"
            ></p-dropdown>
            <label for="country">Kondakontsuse tüüp</label>
          </p-floatLabel>
        </div>
      }
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 id="idCode"
                 name="idCode"
                 type="text"
                 class="w-full"
                 formControlName="idCode"
                 (change)="_fetchInsuranceData()"
          />
          <label for="idCode">Isikukood</label>
        </p-floatLabel>
        @if (idCode.invalid && idCode.dirty && idCode.errors) {
          <small class="p-error">Isikukood on kohustuslik</small>
        }
      </div>
      @if (patientNationality.value === Country.EST) {
        <div class="form-section__col w-full flex align-items-center">
          <label class="text-color-secondary">{{ idCode.value | idCodeInfo }}</label>
        </div>
      } @else {
        <div class="form-section__col w-full">
          <p-floatLabel>
            <p-dropdown styleClass="w-full"
                        id="gender"
                        formControlName="gender"
                        [options]="genderOptions"
            ></p-dropdown>
            <label for="gender">Sugu</label>
          </p-floatLabel>
          @if (gender.invalid && gender.dirty && gender.errors) {
            <small class="p-error">Sugu on kohustuslik</small>
          }
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <p-calendar id="birthday"
                        styleClass="w-full"
                        formControlName="birthday"
                        dateFormat="dd.mm.yy"
                        appendTo="body"
            ></p-calendar>
            <label for="birthday">Sünnikuupäev</label>
          </p-floatLabel>
          @if (birthday.invalid && birthday.dirty && birthday.errors) {
            <small class="p-error">Sünnikuupäev on kohustuslik</small>
          }
        </div>
      }
    </div>

    <div class="form-section__row flex gap-4">

      <div class="form-section__col w-full">
        <p-floatLabel>
          <input
            pInputText
            id="firstname"
            type="text"
            class="w-full"
            formControlName="firstname"
          >
          <label for="firstname">Eesnimi</label>
        </p-floatLabel>
        @if (firstname.invalid && firstname.dirty && firstname.errors) {
          <small class="p-error">Eesnimi on kohustuslik</small>
        }
      </div>

      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 id="lastname"
                 type="text"
                 class="w-full"
                 formControlName="lastname"
          >
          <label for="lastname">Perekonnanimi</label>
        </p-floatLabel>
        @if (lastname.invalid && lastname.dirty && lastname.errors) {
          <small class="p-error">Perekonnanimi on kohustuslik</small>
        }
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <medis-general-select-list [listId]="GeneralList.CLIENT_GROUP"
                                   formControlName="clientGroupsIds"
                                   labelName="Kliendigrupid"
                                   [multiple]="true"
                                   (selectedGeneralListItem)="setGroup($event)"
        ></medis-general-select-list>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown
            id="language"
            name="language"
            type="text"
            styleClass="w-full"
            [options]="languages"
            formControlName="language"
            appendTo="body"
          />
          <label for="language">Suhtluskeel</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input
            pInputText
            id="email"
            name="email"
            type="email"
            class="w-full"
            formControlName="email"
          >
          <label for="email">E-post</label>
        </p-floatLabel>
        @if (email.invalid && email.dirty && email.errors) {
          @if (email.hasError('email')) {
            <small class="p-error">Vale e-posti formaat (e.g. example&#64;example.com)</small>
          } @else if (email.hasError('required')) {
            <small class="p-error">E-post on kohustuslik</small>
          }
        }
      </div>
    </div>

    <div class="form-section__row flex">
      <div class="form-section__col w-full">
        <p-checkbox label="Soovib e-kirju"
                    id="agreed"
                    [binary]="true"
                    formControlName="newsletterAgreement"
        >
        </p-checkbox>
      </div>
    </div>
    <div class="form-section__row flex gap-4" formGroupName="phoneNumber">

      <div class="form-section__col w-4">
        <p-floatLabel>
          <p-dropdown
            id="countryCode"
            type="text"
            styleClass="w-full"
            [options]="allCountryCodes"
            formControlName="countryCode"
            appendTo="body"
          />
          <label for="countryCode">Telefon kood</label>
        </p-floatLabel>
      </div>

      <div class="form-section__col w-full">
        <p-floatLabel>
          <input
            pInputText
            id="phone"
            name="phone"
            class="w-full"
            formControlName="phone"
            numbersOnly
          >
          <label for="phone">Telefon</label>
        </p-floatLabel>
        @if (phoneNumber.invalid && phoneNumber.dirty) {
          @if (phoneNumber.invalid && phoneNumber.get('phone')!.getError('pattern')) {
            <small class="p-error">Telefon võib koosneda ainult numbritest</small>
          }
        }
      </div>
    </div>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <medis-general-select-list [listId]="31"
                                   formControlName="riskFactorsIds"
                                   labelName="Füsiloogilised ohutegurid"
                                   [multiple]="true"
        ></medis-general-select-list>
      </div>
    </div>
  </div>

  <div class="form-section">
    <h3 class="form-section__title">Aadress</h3>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <app-address-search (onSelect)="setAddress($event)"
                            label="Otsi aadress..."
                            id="addressSearch"
        ></app-address-search>
      </div>
      <div class="col-6 flex align-items-center">
        <a class="text-color-secondary no-underline hover:underline"
           href="https://inaadress.maaamet.ee/generaator/"
           target="_blank">
          In-ADS (Maa-amet)
        </a>
      </div>
    </div>

    <div class="form-section__row flex">
      <div class="form-section__col w-full -my-3">
        <p-divider layout="horizontal"></p-divider>
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown
            id="contactCountry"
            type="text"
            styleClass="w-full"
            [options]="countries"
            [showClear]="true"
            (onChange)="onCountrySelected($event)"
            formControlName="contactCountry"
            appendTo="body"
          />
          <label for="contactCountry">Riik</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        @if (contactCountry.value === Country.EST) {
          <p-floatLabel>
            <p-dropdown
              id="county"
              type="text"
              styleClass="w-full"
              [options]="counties"
              (onChange)="onCountySelected()"
              formControlName="county"
              appendTo="body"
            />
            <label for="county">Maakond</label>
          </p-floatLabel>
        } @else {
          <p-floatLabel>
            <input pInputText
                   id="longAddress"
                   type="text"
                   class="w-full"
                   formControlName="longAddress"
            />
            <label for="longAddress">Pikk aadress</label>
          </p-floatLabel>
        }
      </div>
    </div>

    @if (contactCountry.value === Country.EST) {
      <div class="form-section__row flex gap-4">
        <div class="form-section__col w-full">
          <p-floatLabel>
            <p-dropdown
              id="city"
              type="text"
              styleClass="w-full"
              [options]="cities"
              (onChange)="onCitySelected()"
              formControlName="city"
              appendTo="body"
            />
            <label for="city">Linn/vald</label>
          </p-floatLabel>
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <p-dropdown
              id="settlement"
              type="text"
              styleClass="w-full"
              [options]="settlements"
              formControlName="settlement"
              appendTo="body"
            />
            <label for="settlement">Linnaosa/küla/alevik</label>
          </p-floatLabel>
        </div>
      </div>
    }

    @if (contactCountry.value === Country.EST) {
      <div class="form-section__row flex gap-4">
        <div class="form-section__col w-full">
          <p-floatLabel>
            <input
              pInputText
              id="street"
              name="street"
              type="text"
              class="w-full"
              formControlName="street"
            >
            <label for="street">Tänav</label>
          </p-floatLabel>
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <input
              pInputText
              id="house"
              name="house"
              type="text"
              class="w-full"
              formControlName="house"
            >
            <label for="house">Maja</label>
          </p-floatLabel>
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <input
              pInputText
              id="archive"
              name="archive"
              type="text"
              class="w-full"
              formControlName="archive"
            >
            <label for="archive">Arhiiv</label>
          </p-floatLabel>
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <input
              pInputText
              id="flat"
              name="flat"
              type="text"
              class="w-full"
              formControlName="flat"
            >
            <label for="flat">Korter</label>
          </p-floatLabel>
        </div>
        <div class="form-section__col w-full">
          <p-floatLabel>
            <input
              pInputText
              id="zip"
              name="zip"
              type="text"
              class="w-full"
              formControlName="zip"
            >
            <label for="zip">Indeks</label>
          </p-floatLabel>
        </div>
      </div>
    }
  </div>

  <medis-patient-workplaces [formArray]="patientWorkplaces"
                            [patient]="patient"
  ></medis-patient-workplaces>

</div>
