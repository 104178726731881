<div [formGroup]="formGroup"
     class="patient-additional-data flex flex-column gap-4">
  <div class="form-section">
    <div class="form-section__title flex justify-content-between align-items-center">
      <h3 class="form-section__title">Kontaktisiku andmed</h3>
      <p-button type="button"
                label="Lisa"
                (click)="addEmergencyContact()"
      ></p-button>
    </div>
    <ng-container *ngIf="patientEmergencyContacts?.controls?.length" formArrayName="emergencyContacts">
      <div class="form-section__row flex flex-wrap gap-4 justify-content-between">
        @for (emergencyContact of patientEmergencyContacts.controls; track $index) {
          <div [formGroup]="getEmergencyContact($index)"
               class="form-section" style="max-width:48.5%;width:100%;">
            <div class="flex justify-content-between">
              <div class="form-section__title">{{ emergencyContact.get('type')?.value?.name }}</div>
              <div class="flex gap-1 align-items-center">
                <p-button type="button"
                          styleClass="p-button-icon-only"
                          severity="secondary"
                          [outlined]="true"
                          icon="pi pi-pencil"
                          (click)="editEmergencyContact(emergencyContact)"
                ></p-button>
                <p-button type="button"
                          styleClass="p-button-icon-only"
                          [outlined]="true"
                          severity="secondary"
                          icon="pi pi-times"
                          medisConfirmAction
                          (confirmedAction)="removeEmergencyContact($event, $index)"
                          [confirmMessage]="getConfirmationLabel(emergencyContact)"
                ></p-button>
              </div>
            </div>
            <div class="form-section__content flex-grow-0 flex-shrink-0">
              <div class="form-section__section-field flex w-full py-2 border-bottom-1 border-400">
                <div class="form-section__section-label w-4 font-semibold">Eesnimi</div>
                <div
                  class="form-section__section-value w-8 text-color-secondary">{{ emergencyContact.get('firstname')?.value }}
                </div>
              </div>
              <div class="form-section__section-field flex w-full py-2 border-bottom-1 border-400">
                <div class="form-section__section-label w-4 font-semibold">Perekonnanimi</div>
                <div
                  class="form-section__section-value w-8 text-color-secondary">{{ emergencyContact.get('lastname')?.value }}
                </div>
              </div>
              <div class="form-section__section-field flex w-full py-2 border-bottom-1 border-400">
                <div class="form-section__section-label w-4 font-semibold">Isikukood</div>
                <div
                  class="form-section__section-value w-8 text-color-secondary">{{ emergencyContact.get('idCode')?.value }}
                </div>
              </div>
              <div class="form-section__section-field flex w-full py-2 border-bottom-1 border-400">
                <div class="form-section__section-label w-4 font-semibold">Telefon</div>
                <div
                  class="form-section__section-value w-8 text-color-secondary">{{ emergencyContact.get('phone')?.value }}
                </div>
              </div>
              <div class="form-section__section-field flex w-full py-2">
                <div class="form-section__section-label w-4 font-semibold">E-post</div>
                <div
                  class="form-section__section-value w-8 text-color-secondary">{{ emergencyContact.get('email')?.value }}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </ng-container>
  </div>

  <div class="form-section">
    <h3 class="form-section__title">Pangakonto number</h3>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 id="bankAccount"
                 formControlName="bankAccount"
                 type="text"
                 class="w-full"
          />
          <label for="bankAccount">IBAN</label>
          @if (bankAccount.invalid && bankAccount.dirty && bankAccount.errors) {
            @if (bankAccount.hasError('error')) {
              <small class="p-error">{{ bankAccount.getError('error') | translate }}</small>
            }
          }
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 id="bankAccountOwner"
                 formControlName="bankAccountOwner"
                 type="text"
                 class="w-full"
          />
          <label for="bankAccountOwner">Konto omaniku nimi</label>
        </p-floatLabel>
      </div>
    </div>
  </div>

  <div [formGroup]="europeanUnionInsuranceDocument" class="form-section">
    <h3 class="form-section__title">Euroopa Liidu kindlustust tõendav dokument</h3>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown styleClass="w-full"
                      id="documentType"
                      formControlName="documentType"
                      [options]="documentTypes"
                      [showClear]="true"
          />
          <label for="documentType">EL documendi liik</label>
        </p-floatLabel>
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 type="text"
                 class="w-full"
                 id="documentNumber"
                 formControlName="documentNumber"
          />
          <label for="documentNumber">Dokumendi number</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 type="text"
                 class="w-full"
                 id="idCode"
                 formControlName="idCode"
          />
          <label for="idCode">Isikukood</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 type="text"
                 class="w-full"
                 id="firstname"
                 formControlName="firstname"
          />
          <label for="firstname">Eesnimi</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 type="text"
                 class="w-full"
                 id="lastname"
                 formControlName="lastname"
          />
          <label for="lastname">Perenimi</label>
        </p-floatLabel>
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-calendar id="birthday"
                      formControlName="birthday"
                      styleClass="w-full"
                      dateFormat="dd.mm.yy"
          ></p-calendar>
          <label for="birthday">Sünnikuupäev</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown id="gender"
                      formControlName="gender"
                      styleClass="w-full"
                      [options]="genderOptions"
          ></p-dropdown>
          <label for="gender">Sugu</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-dropdown id="country"
                      formControlName="country"
                      styleClass="w-full"
                      [options]="countries"
                      [showClear]="true"
          ></p-dropdown>
          <label for="country">Elukoha riik</label>
        </p-floatLabel>
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-calendar id="issueDate"
                      formControlName="issueDate"
                      styleClass="w-full"
                      dateFormat="dd.mm.yy"
          ></p-calendar>
          <label for="issueDate">Väljastatud</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-calendar id="validFromDate"
                      formControlName="validFromDate"
                      styleClass="w-full"
                      dateFormat="dd.mm.yy"
          ></p-calendar>
          <label for="validFromDate">Kehtib alates</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <p-calendar id="validToDate"
                      formControlName="validToDate"
                      styleClass="w-full"
                      dateFormat="dd.mm.yy"
          ></p-calendar>
          <label for="validToDate">Kehtib kuni</label>
        </p-floatLabel>
      </div>
    </div>

    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input pInputText
                 type="text"
                 class="w-full"
                 formControlName="authorityCode"
          />
          <label>Pädeva asutuse kood</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
</div>
