import { Component, Input, OnChanges } from '@angular/core';
import { DateTimeDisplayPipe } from "@shared/pipes/date-time-display.pipe";

@Component({
  selector: 'medis-patient-last-modified-label',
  template: `
    @if (lastModifiedDate) {
      <div class="flex row">
        <p class="text-color-secondary">Viimati muudetud </p>
        &nbsp;
        <p
          [class.date-time--highlight]="isUpdatedLongTimeAgo"
          class="text-color-secondary font-bold">
          {{ this.dateTimeDisplayPipe.transform(this.lastModifiedDate) }}
        </p>
      </div>
    }
  `,
})
export class PatientLastModifiedLabelComponent implements OnChanges{
  @Input() lastModifiedDate!: Date | undefined;
  isUpdatedLongTimeAgo: boolean = false;
  sixMonthsAgo: Date;

  constructor(public dateTimeDisplayPipe: DateTimeDisplayPipe) {
    const today = new Date();
    this.sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));
  }

  ngOnChanges() {
    if (this.lastModifiedDate) {
      this.isUpdatedLongTimeAgo = new Date(this.lastModifiedDate) < this.sixMonthsAgo;
    } else {
      this.isUpdatedLongTimeAgo = false;
    }
  }
}
