import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { PriceListDictionary } from "../interfaces/price.interface";

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/prices`;
  }

  // public getAll(): Observable<IPrice[]> {
  //   return this.http.get<IPrice[]>(`${this.BACKEND_URL}`);
  // }

  // public save(price: IPrice): Observable<IPrice> {
  //   return this.http.post<IPrice>(`${this.BACKEND_URL}`, price);
  // }

  public groupFinanceCode(): Observable<PriceListDictionary> {
    return this.http.get<PriceListDictionary>(`${this.BACKEND_URL}/groupFinanceCode`);
  }
}
