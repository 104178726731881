<div class="flex flex-column gap-4 overflow-y-auto">
  @if (bodyText) {
    <form [formGroup]="reasonForm">
      <div>
        <p>{{ bodyText }}</p>
        <br/>
        @if (showReasonOptions) {
          <p-dropdown
            id="reasonOption"
            class="w-full"
            styleClass="w-full"
            [options]="reasonOptions"
            formControlName="reasonOption"
          />
        }
        @if (showReason) {
          <textarea type="text"
                    name="reason"
                    class="form-control"
                    formControlName="reason"
                    pInputTextarea
                    cols="66"
                    rows="3">
          </textarea>
        }
        @if (showReasonError) {
          <small class="p-error"> Lisa puudumise põhjus! </small>
        }
      </div>
    </form>
  }
</div>
<div class="flex justify-content-end gap-2 p-dialog-footer">
  <p-button
    label="Ei"
    icon="pi pi-times"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
  ></p-button>
  <p-button
    label="Jah"
    icon="pi pi-check"
    (click)="ok()"
  ></p-button>
</div>
