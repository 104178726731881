import { Component, Input } from '@angular/core';
import { EConsultationStatus } from "@core/enums/e-consultation-status.enum";

@Component({
  selector: 'medis-e-consultation-status-label',
  template: `
    @if (status) {
      <p-tag
        rounded="true"
        [value]="labelStyleMap[status].label"
        [styleClass]="labelStyleMap[status].style"
      ></p-tag>
    }
  `,
})
export class EConsultationStatusLabelComponent {
  @Input() status!: EConsultationStatus;

  labelStyleMap = {
    [EConsultationStatus.ASSIGNED]: { label: 'Vastamata', style: 'tag tag--red'},
    [EConsultationStatus.RESOLVED]: { label: 'Kinnitamata', style:'tag tag--yellow'},
    [EConsultationStatus.CONFIRMED]: { label: 'Kinnitatud', style:'tag tag--green'},
    [EConsultationStatus.CREATED]: { label: 'Saabunud', style:'tag tag--blue'},
  };
}
