import { Pipe, PipeTransform } from '@angular/core';
import { Country } from "@core/enums/country.enum";

@Pipe({
  name: 'flag',
  standalone: true,
})
export class FlagPipe implements PipeTransform {




  transform(flag: Country| string): string {
    let className = '';
    const countryMap: Map<Country, string> = new Map<Country, string>([
        [Country.EST, 'EE'],
        [Country.GBR, 'GB'],
        [Country.RUS, 'RU'],
        [Country.FIN, 'FI'],
        [Country.BLR, 'BY'],
        [Country.DEU, 'DE'],
        [Country.FRA, 'FR'],
        [Country.UKR, 'UA'],
      ]);

    const stringMap: Map<string, string> = new Map<string, string>([
        ['et', 'EE'],
        ['en', 'GB'],
        ['fi', 'FI'],
        ['ru', 'RU'],
        ['by', 'BY'],
        ['de', 'DE'],
        ['fr', 'FR'],
        ['ua', 'UA'],
    ]);

    if(typeof flag == 'string') {
      className = stringMap.get(flag) ?? '';
    } else  {
      className = countryMap.get(flag) ?? '';
    }

    return `<div class="fflag fflag-${className} ff-md"></div>`;
  }
}
