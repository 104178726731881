import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IPersonService } from "@core/interfaces/person-service.interface";

@Component({
  selector: "medis-related-service-picker-modal",
  templateUrl: "./person-service-picker.modal.html",
})
export class PersonServicePickerModal {
  selectedServiceIdsWithNames: {id: number, name: string}[] = [];
  sourcePersonServices: IPersonService[] = [];


  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
    if (this.config.data.allPersonServices && this.config.data.planTimePersonServices) {
      this.sourcePersonServices = this.config.data.allPersonServices;
      const planTimePersonServices = this.config.data.planTimePersonServices;
      planTimePersonServices.forEach((personService: IPersonService) => {
        if(personService.serviceId){
          const selectedServiceWithName = {
            id: personService.serviceId,
            name: personService.name
          };
          this.selectedServiceIdsWithNames.push(selectedServiceWithName);
        }
      })
    } else {
      this.selectedServiceIdsWithNames = [];
    }
  }

  saveRelatedServices(){
    this.ref.close(this.selectedServiceIdsWithNames);
  }

  closeModal(){
    this.ref.close();
  }

}
