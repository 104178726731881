export enum OrderServiceStatus {
  CREATED = 0,
  PREPARING = 1,
  CANCELLED = 10,
  CONFIRMED = 20,
  PRESENT = 23,
  REALIZATION = 25,
  UNFULFILLED = 28,
  FINISHED = 30,
  IN_PROVIDER_QUEUE = 31,
  IN_SERVICE_QUEUE = 32,
  BOOKING_NOT_NEEDED = 33,
  NOT_APPEARED = 34,
  CHANGED = 35,
  ABSENCE = 11
}
