/*******************************
 Credits to Timofei Beresnev
 *******************************/

import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  markControlAsDirty(formControl: AbstractControl): void {
    const childControls = (formControl as any).controls;

    if (!childControls) {
      formControl.markAsDirty();
      return;
    }

    Object.keys(childControls).forEach(key => {
      const childControl = childControls[key];
      this.markControlAsDirty(childControl);
    });
  }
}
