import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEConsultation } from "@core/interfaces/e-consultation.interface";
import { Router } from "@angular/router";
import { EConsultationService } from "@core/services/e-consultation.service";

@Component({
  selector: 'medis-e-consultations-table',
  templateUrl: './e-consultations-table.component.html',
  styleUrl: './e-consultations-table.component.scss'
})
export class EConsultationsTableComponent {
  @Input() data: IEConsultation[] = [];
  @Input() providerMode: boolean = false;
  @Input() patientPage: boolean = false;

  @Output() onActionComplete = new EventEmitter();

  constructor(
    private router: Router,
    private eConsultationService: EConsultationService,
  ) {}

  actionComplete() {
    this.onActionComplete.emit();
  }

  openPatientView(patientId: number){
    this.router.navigate([`/patients/${patientId}/details`]);
  }

  openEConsultation = (referral: IEConsultation): void => {
    this.eConsultationService.openEConsultation(referral);
  };
}
