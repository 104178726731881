import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from "@ng-select/ng-select";
import { DateTimeDisplayPipe } from "./pipes/date-time-display.pipe";
import { ServiceBadgeComponent } from "./components/service-badge/service-badge.component";
import { ServiceTypeLabelComponent } from "./components/service-type-label/service-type-label.component";
import { ComplexPartsPopoverComponent } from "./components/service-parts-popover/complex-parts-popover.component";
import { ProviderInfoPopoverComponent } from "./components/provider-info-popover/provider-info-popover.component";
import { ColleagueInfoPopoverComponent } from "./components/collegue-info-popover/colleague-info-popover.component";
import { FinancialSourceLabelComponent } from "./components/financial-source-label/financial-source-label.component";
import { ChannelNamePipe } from "./pipes/channel-name.pipe";
import {
  OrderServiceStatusLabelComponent
} from "./components/order-service-status-label/order-service-status-label.component";
import { OrderActionsComponent } from "./components/order-actions/order-actions.component";
import { BadgeModule } from "primeng/badge";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";
import { InputTextModule } from "primeng/inputtext";
import { MenubarModule } from "primeng/menubar";
import { PasswordModule } from "primeng/password";
import { InputMaskModule } from "primeng/inputmask";
import { ButtonGroupModule } from "primeng/buttongroup";
import { TabMenuModule } from "primeng/tabmenu";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { PatientsTableComponent } from "../feature/office/patients-table/patients-table.component";
import { TableModule } from "primeng/table";
import { ChipsModule } from "primeng/chips";
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { PrintPatientsModalComponent } from "@shared/modals/print-patients/print-patients.modal.component";
import { OrderStatusPipe } from "@shared/pipes/order-status.pipe";
import { PriceSelectListComponent } from './components/price-select-list/price-select-list.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { BreadcrumbComponent } from "@shared/components/breadcrumb/breadcrumb.component";
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DeptMenuComponent } from "@shared/components/dept-menu/dept-menu.component";
import { DataTableComponent } from './components/data-table/data-table.component';
import { arrayToString } from "@shared/pipes/array-to-string.pipe";
import { TabViewModule } from "primeng/tabview";
import { TabsComponent } from "@shared/components/tabs/tabs.component";
import { TableHistoryComponent } from "@shared/modals/table-history/table-history.component";
import { CheckboxModule } from 'primeng/checkbox';
import { ServicePickerComponent } from './components/service-picker/service-picker.component';
import { PickListModule } from "primeng/picklist";
import { SearchBarComponent } from "@shared/components/search-bar/search-bar.component";
import { SinglePatientSearchComponent } from "@shared/components/single-patient-search/single-patient-search.component";
import {
  MultiplePatientSearchComponent
} from "@shared/components/multiple-patient-search/multiple-patient-search.component";
import { GeneralSelectListComponent } from '@shared/components/general-select-list/general-select-list.component';
import { AddressSelectListComponent } from '@shared/components/address-select-list/address-select-list.component';
import { RoomSelectListComponent } from '@shared/components/room-select-list/room-select-list.component';
import { DeviceTypeAddModalComponent } from '@shared/modals/device-type-add/device-type-add.modal.component';
import {
  DeviceCategorySelectListComponent
} from './components/device-category-select-list/device-category-select-list.component';
import { GeneralListAddModalComponent } from '@shared/modals/general-list-add/general-list-add.modal.component';
import { RoomAddModalComponent } from "@shared/modals/room-add/room-add-modal.component";
import { RoomTypeSelectListComponent } from "@shared/components/room-type-select-list/room-type-select-list.component";
import { RadioButtonModule } from "primeng/radiobutton";
import {
  ServiceCategorySelectListComponent
} from '@shared/components/service-category-select-list/service-category-select-list.component';
import {
  ServiceComplexTypeSelectListComponent
} from './components/service-complex-type-select-list/service-complex-type-select-list.component';
import { ProviderSelectListComponent } from './components/provider-select-list/provider-select-list.component';
import { CommentItemComponent } from "@shared/components/comment-item/comment-item.component";
import { OrderPatientComponent } from "@shared/components/order-patient/order-patient.component";
import { PresentModalComponent } from "@shared/modals/present/present.modal.component";
import {
  OrderServiceInvoiceStatusComponent
} from "@shared/components/order-service-invoice-status/order-service-invoice-status.component";
import { TimePipe } from "@shared/pipes/time.pipe";
import { OrderTableComponent } from "@shared/components/order-table/order-table.component";
import { DateFormatPipe } from "@shared/pipes/date-format.pipe";
import { ReferralStatusLabelComponent } from "@shared/components/referral-status-label/referral-status-label.component";
import { MultiSelectModule } from "primeng/multiselect";
import { TimeFormatPipe } from "@shared/pipes/time-format.pipe";
import { ServiceDurationPipe } from "@shared/pipes/service-duration.pipe";
import {
  PresentPatientsTableComponent
} from "../feature/office/present-patients-table/present-patients-table.component";
import { ServiceSelectListComponent } from './components/service-select-list/service-select-list.component';
import { OrderReservationComponent } from './components/order-reservation/order-reservation.component';
import { AgeGroupLabelComponent } from "@shared/components/age-group-label/age-group-label.component";
import { DayOfWeekPipe } from "@shared/pipes/day-of-week.pipe";
import {
  EmergencyGpTeamLabelComponent
} from "@shared/components/emergency-gp-team-label/emergency-gp-team-label.component";
import {
  HiddenInPublicWebLabelComponent
} from "@shared/components/hidden-in-public-web-label/hidden-in-public-web-label.component";
import {
  EConsultationStatusLabelComponent
} from './components/e-consultation-status-label/e-consultation-status-label.component';
import { EConsultationActionsComponent } from './components/e-consultation-actions/e-consultation-actions.component';
import { EConsultationsTodayComponent } from "@shared/components/e-consultations-today/e-consultations-today.component";
import {
  EConsultationsTableComponent
} from "@shared/components/e-consultations-today/e-consultations-table/e-consultations-table.component";
import { ReferralDataComponent } from './components/referral-data/referral-data.component';
import { AccordionModule } from "primeng/accordion";
import {
  EConsultationReplyModalComponent
} from "@shared/modals/e-consultation-reply/e-consultation-reply.modal.component";
import { DateTimePipe } from "@shared/pipes/datetime.pipe";
import { DecisionNamePipe } from "@shared/pipes/decision-name.pipe";
import { Nl2brPipe } from "@shared/pipes/nl2br.pipe";
import { MedicalDocumentsComponent } from './components/medical-documents/medical-documents.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { AddContactModalComponent } from './modals/contact/add-contact.modal/add-contact.modal.component';
import {
  ContactDetailsModalComponent
} from '@shared/modals/contact/contact-details.modal/contact-details.modal.component';
import { MessageModule } from "primeng/message";
import { BrochureAddModal } from "@shared/modals/brochure-add/brochure-add.modal";
import { ChangeTimeComponent } from './components/change-time/change-time.component';
import { ServiceLogModalComponent } from "@shared/modals/service-log/service-log.modal.component";
import { ProductSaleModalComponent } from "@shared/modals/product-sale/product-sale.modal.component";
import { InputNumberModule } from "primeng/inputnumber";
import { RelatedServicePickerModal } from "@shared/modals/related-service-picker/related-service-picker.modal";
import {
  MarketingNameComponent
} from "@shared/components/marketing-name/marketing-name.component";
import { PatientBasicDataComponent } from './components/patient-basic-data/patient-basic-data.component';
import { UserProperNamePipe } from "@shared/pipes/user-proper-name.pipe";
import { PersonAddressPipe } from "@shared/pipes/person-address.pipe";
import { PatientSearchListComponent } from './components/patient-search-list/patient-search-list.component';
import { SkeletonModule } from "primeng/skeleton";
import { FullCalendarModule } from "@fullcalendar/angular";
import { CalendarOptions } from "@fullcalendar/core";
import { CalendarComponent } from './components/calendar/calendar.component';
import {
    OrderReferralStatusLabelComponent
} from "@shared/components/order-referral-status-label/order-referral-status-label.component";
import { BookingsModalComponent } from "@shared/modals/bookings/bookings.modal";
import {
  PatientLastModifiedLabelComponent
} from "@shared/components/patient-last-modified-label/patient-last-modified-label.component";
import { ImplementationPlanComponent } from './components/implementation-plan/implementation-plan.component';

@NgModule({
  declarations: [
    EConsultationsTodayComponent,
    ServiceBadgeComponent,
    PatientsTableComponent,
    PrintPatientsModalComponent,
    PriceSelectListComponent,
    PageHeaderComponent,
    DeptMenuComponent,
    DataTableComponent,
    TabsComponent,
    ServicePickerComponent,
    SearchBarComponent,
    SinglePatientSearchComponent,
    MultiplePatientSearchComponent,
    GeneralSelectListComponent,
    AddressSelectListComponent,
    RoomSelectListComponent,
    DeviceTypeAddModalComponent,
    DeviceCategorySelectListComponent,
    GeneralListAddModalComponent,
    RoomAddModalComponent,
    RoomTypeSelectListComponent,
    ServiceCategorySelectListComponent,
    ServiceComplexTypeSelectListComponent,
    ProviderSelectListComponent,
    OrderPatientComponent,
    PresentModalComponent,
    OrderTableComponent,
    OrderReservationComponent,
    PresentPatientsTableComponent,
    ServiceSelectListComponent,
    EConsultationStatusLabelComponent,
    EConsultationActionsComponent,
    EConsultationsTableComponent,
    ReferralDataComponent,
    EConsultationReplyModalComponent,
    MedicalDocumentsComponent,
    ChangeTimeComponent,
    BrochureAddModal,
    ServiceLogModalComponent,
    AddressSearchComponent,
    AddContactModalComponent,
    ContactDetailsModalComponent,
    ProductSaleModalComponent,
    RelatedServicePickerModal,
    MarketingNameComponent,
    PatientBasicDataComponent,
    PatientSearchListComponent,
    BookingsModalComponent,
    PatientLastModifiedLabelComponent,
    ImplementationPlanComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    DateTimeDisplayPipe,
    ServiceTypeLabelComponent,
    ComplexPartsPopoverComponent,
    ProviderInfoPopoverComponent,
    ColleagueInfoPopoverComponent,
    FinancialSourceLabelComponent,
    ChannelNamePipe,
    OrderServiceStatusLabelComponent,
    OrderActionsComponent,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    ButtonGroupModule,
    TableModule,
    ChipsModule,
    IconFieldModule,
    InputIconModule,
    MenuModule,
    SelectButtonModule,
    TagModule,
    ChipModule,
    BadgeModule,
    SidebarModule,
    FloatLabelModule,
    TooltipModule,
    OverlayPanelModule,
    ToastModule,
    TabMenuModule,
    DialogModule,
    InputTextareaModule,
    MessagesModule,
    OrderStatusPipe,
    BreadcrumbModule,
    InputGroupModule,
    InputGroupAddonModule,
    BreadcrumbComponent,
    arrayToString,
    TabViewModule,
    CheckboxModule,
    TableHistoryComponent,
    PickListModule,
    RadioButtonModule,
    CommentItemComponent,
    OrderServiceInvoiceStatusComponent,
    TimePipe,
    MultiSelectModule,
    DateFormatPipe,
    ReferralStatusLabelComponent,
    TimeFormatPipe,
    ServiceDurationPipe,
    AgeGroupLabelComponent,
    DayOfWeekPipe,
    EmergencyGpTeamLabelComponent,
    HiddenInPublicWebLabelComponent,
    ProgressSpinnerModule,
    AccordionModule,
    DateTimePipe,
    DecisionNamePipe,
    Nl2brPipe,
    DividerModule,
    MessageModule,
    InputNumberModule,
    UserProperNamePipe,
    PersonAddressPipe,
    SkeletonModule,
    FullCalendarModule,
    OrderReferralStatusLabelComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    EConsultationsTodayComponent,
    NgSelectModule,
    ProgressSpinnerModule,
    ButtonModule,
    ButtonGroupModule,
    DividerModule,
    InputTextModule,
    MenubarModule,
    PasswordModule,
    InputMaskModule,
    BadgeModule,
    TabMenuModule,
    TableModule,
    IconFieldModule,
    InputIconModule,
    MenuModule,
    DropdownModule,
    CalendarModule,
    SelectButtonModule,
    TagModule,
    ChipModule,
    SidebarModule,
    FloatLabelModule,
    TooltipModule,
    OverlayPanelModule,
    ToastModule,
    DialogModule,
    InputTextareaModule,
    MessagesModule,
    ServiceBadgeComponent,
    PatientsTableComponent,
    BreadcrumbModule,
    InputGroupModule,
    InputGroupAddonModule,
    PriceSelectListComponent,
    PageHeaderComponent,
    DeptMenuComponent,
    DataTableComponent,
    TabsComponent,
    CheckboxModule,
    ServicePickerComponent,
    SearchBarComponent,
    SinglePatientSearchComponent,
    MultiplePatientSearchComponent,
    GeneralSelectListComponent,
    AddressSelectListComponent,
    RoomSelectListComponent,
    DeviceCategorySelectListComponent,
    RoomTypeSelectListComponent,
    ServiceCategorySelectListComponent,
    ServiceComplexTypeSelectListComponent,
    ProviderSelectListComponent,
    MultiSelectModule,
    OrderTableComponent,
    PresentPatientsTableComponent,
    ServiceDurationPipe,
    ServiceSelectListComponent,
    DateFormatPipe,
    TimeFormatPipe,
    EConsultationStatusLabelComponent,
    EConsultationsTableComponent,
    ReferralDataComponent,
    EConsultationReplyModalComponent,
    OrderPatientComponent,
    MedicalDocumentsComponent,
    OrderReservationComponent,
    AddressSearchComponent,
    ProductSaleModalComponent,
    InputNumberModule,
    MarketingNameComponent,
    PatientBasicDataComponent,
    EConsultationActionsComponent,
    OrderStatusPipe,
    PatientSearchListComponent,
    PatientLastModifiedLabelComponent,
    ImplementationPlanComponent,
  ]
})
export class SharedModule {
}
