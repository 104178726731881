import { Component, OnInit } from '@angular/core';
import { IEConsultation } from "@core/interfaces/e-consultation.interface";
import { ToastService } from "@core/services/toast.service";
import { HttpParams } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DashboardService } from "@core/services/dashboard.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { AuthService } from "@core/services/auth.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'medis-e-consultations-today',
  templateUrl: './e-consultations-today.component.html',
  styleUrls: ['./e-consultations-today.component.less']
})
export class EConsultationsTodayComponent implements OnInit {
  filterForm!: FormGroup;
  eConsultations: IEConsultation[] = [];
  confirmedEConsultations: IEConsultation[] = [];
  user!: ICurrentUser;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.createFilterFormGroup();
    this.authService.getCurrentUser().subscribe({
      next: (user: ICurrentUser | null) => {
        if (user) {
          this.user = user;
          this.loadEConsultations();
        }
      },
      error: (err: any) => {
        console.error("Error fetching current user: ", err);
      }
    });
  }

  private createFilterFormGroup(): void {
    this.filterForm = this.formBuilder.group({
      dateFrom: [new Date()],
      dateTo: [new Date()],
    })
  }

  loadEConsultations(): void {
    this.eConsultations = [];
    let params = new HttpParams()
      .set('withOrderServices', true)
      .set('confirmed', false)
      .set('providerId', this.user.personId)
    forkJoin([
      this.dashboardService.searchEConsultations(params),
      this.dashboardService.getUnassignedEConsultationsForProvider()
    ])
      .pipe(
        tap(([searchResults, unassignedResults]) => {
          this.eConsultations = [...this.eConsultations, ...searchResults, ...unassignedResults];
        })
      )
      .subscribe();
  }

  searchConfirmed() {
    let params = new HttpParams()
      .set('withOrderServices', true)
      .set('confirmed', true)
      .set('providerId', this.user.personId)
      .set('startDate', this.datePipe.transform(this.dateFrom.value, 'yyyy-MM-dd') ?? '')
      .set('endDate', this.datePipe.transform(this.dateTo.value, 'yyyy-MM-dd') ?? '')

    this.dashboardService.searchEConsultations(params).subscribe({
      next: (response) => {
        this.confirmedEConsultations = response;
      },
      error: err => {
        this.toastService.error(err.message)
      }
    })
  }

  get dateFrom(): FormControl {
    return this.filterForm.get('dateFrom') as FormControl;
  }

  get dateTo(): FormControl {
    return this.filterForm.get('dateTo') as FormControl;
  }
}
