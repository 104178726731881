import { Component } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { ModalService } from "@core/services/modal.service";
import { PatientFormComponent } from "app/feature/patients/patient-form/patient-form.component";
import { ModalSize } from "@core/enums/modalSize";

@Component({
  selector: 'medis-replace-patient',
  templateUrl: './replace-patient.modal.component.html',
  styleUrl: './replace-patient.modal.component.scss'
})
export class ReplacePatientModalComponent {
  patient?: IPatient;
  loading = false;
  showError = false;

  constructor(private ref: DynamicDialogRef,
              private modalService: ModalService) {
  }

  selectedPatient(patient: IPatient | undefined): void {
    this.patient = patient;
  }

  submit(): void {
    if (!this.patient) {
      this.showError = true;
      return;
    }
    this.ref.close(this.patient);
  }

  cancel(): void {
    this.ref.close();
  }

  addNewPatient(): void {
    let patientFormRef = this.modalService.openModal({
      header: 'Lisa uus patsient',
      component: PatientFormComponent,
      size: ModalSize.LARGE,
    });

    patientFormRef.onClose.subscribe({
      next: (response: { patient: IPatient }) => {
        if (response?.patient) {
          this.patient = response.patient;
          this.submit();
        }
      }
    })

  }
}
