import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { OrderReferralETervisStatusEnum } from "@core/enums/order-referral-e-tervis-status.enum";

@Pipe({
  name: 'orderETervisStatus',
  standalone: true
})
export class OrderETervisStatusPipe implements PipeTransform {
  private statusMap: IMapping = {
    [OrderReferralETervisStatusEnum.OPEN]: 'AVATUD',
    [OrderReferralETervisStatusEnum.LOCKED]: 'LUKUSTATUD',
    [OrderReferralETervisStatusEnum.FINISHED]: 'LOPETATUD',
    [OrderReferralETervisStatusEnum.BY_INVITATION]: 'VASTUVOTUKUTSEGA',
    [OrderReferralETervisStatusEnum.RECEPTION_HAS_HAPPENED]: 'VASTUVOTT_TOIMUNUD',
    [OrderReferralETervisStatusEnum.NOT_APPEAR]: 'EI_ILMUNUD',
    [OrderReferralETervisStatusEnum.OUTDATED]: 'AEGUNUD',
    [OrderReferralETervisStatusEnum.CANCELLED]: 'TYHISTATUD',
  };

  transform(status: OrderReferralETervisStatusEnum): string {
    return this.statusMap[status];
  }
}
