<div class="flex flex-column gap-4">
  <form [formGroup]="commentForm">
    <div class="flex gap-2">
      <div class="flex flex-column w-full">
        <textarea name="comment"
                  rows="2"
                  cols="50"
                  class="form-control"
                  pInputTextarea
                  autoResize
                  placeholder="Sisesta uus kommentaar"
                  formControlName="comment">
        </textarea>
      </div>
      <div class="flex flex-column">
        <p-button severity="success" (click)="saveComment()">
          Lisa Kommentaar
        </p-button>
      </div>
    </div>
  </form>
  @if (comments.length > 0) {
    <div class="row form-group">
      <div class="w-12">
        <p-table [value]="comments" class="table table--today-plan">
          <ng-template pTemplate="header">
            <tr>
              <th>Aeg</th>
              <th>Kommentaar</th>
              <th>Muutja</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-comment>
            <tr>
              <td>
                {{comment.created | dateTimeDisplay: 'dd.MM.yyyy HH:mm'}}
              </td>
              <td>
                {{comment.comment}}
              </td>
              <td>
                {{comment.userName}}
              </td>
              <td>
                <p-button [rounded]="true"
                          icon="pi pi-times"
                          [text]="true"
                          severity="secondary"
                          medisConfirmAction
                          (confirmedAction)="deleteComment($event, comment.orderServiceCommentId)"
                          confirmMessage="Kas olete kindel, et soovite kommenaari eemaldada?"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  }
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button label="Sulge" (click)="closeModal()" size="small"/>
</div>
