<div class="service-form__section border-round-md">
  <h3 class="service-form-title text-color-secondary text-xl font-bold">Turundusnimi</h3>
  <div class="flex flex-column w-full gap-4">
    <p-selectButton
      [options]="tableViews"
      [(ngModel)]="selectedView"
      (onChange)="changeMarketingNameTab()"
      styleClass="flex white-space-nowrap"
    ></p-selectButton>
    <div class="form-section__col flex flex-column gap-4 w-full">
      <p-floatLabel>
        <input
          class="w-full"
          pInputText
          [formControl]="getMarketingNameControl()"
          id="marketing-name-input"/>
        <label for="marketing-name-input">{{ selectedMarketingNameLanguage.name }}</label>
      </p-floatLabel>
    </div>
    <div class="form-section__col flex flex-column gap-4 w-full">
      <p-floatLabel>
                      <textarea
                        rows="5"
                        cols="120"
                        [value]="''"
                        class="w-full"
                        pInputTextarea
                        [formControl]="getMarketingDescriptionControl()"
                        id="marketing-name-comments"
                        [autoResize]="true">
                      </textarea>
        <label for="marketing-name-comments">{{ selectedMarketingNameLanguage.comments }}</label>
      </p-floatLabel>
    </div>
  </div>
</div>
