import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ExternalReferralService {

  constructor(private authService: AuthService) { }

  initForm(url: string) {
    const f = document.createElement("form");
    f.setAttribute('id','referralform');
    f.setAttribute('name','referralform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('content-type',"application/x-www-form-urlencoded");
    f.setAttribute('action', url);
    this.addInput("Token", this.authService.getAccessToken(), f);
    this.addInput("SystemName", 'Medis', f);

    return f;
  }

  initReferralForm(){
    return this.initForm(environment.referralFormUrl);
  }

  openReferralCreateForm(data: any){
    const f = this.initReferralForm();

    this.addInput("PatientId", data.patientId, f);
    this.addInput("DoctorId", data.providerId, f);
    if (data.misAppointmentId) {
      this.addInput("VisitId", data.misAppointmentId, f);
    }
    else if (data.externalAppointmentId) {
      this.addInput("VisitId", data.externalAppointmentId, f);
    }
    else {
      this.addInput("VisitId", '', f);
    }
    if (data.parentFinancialSourceCode) {
      this.addInput("priceList", data.parentFinancialSourceCode, f);
    }
    else {
      this.addInput("priceList", '', f);
    }
    if (data.financialSourceCode) {
      this.addInput("PayerTypeCode", data.financialSourceCode, f);
    } else {
      this.addInput("PayerTypeCode", '', f);
    }
    this.addInput("DoctorDepartmentId", data.departmentId, f);
    this.addInput("Type", data.type, f);
    this.addInput("SystemId", data.orderServiceId, f);

    this.submitForm(f);
  }

  // openReferralForm(id: number){
  //   const f = this.initReferralForm();
  //
  //   this.addInput("Id", id, f);
  //
  //   this.submitForm(f);
  // }

  openReferralLaborForm(id: number){

    const f = this.initForm(environment.referralLaborUrl);

    this.addInput("Id", id, f);

    this.submitForm(f);
  }

  submitForm(f: HTMLFormElement) {
    document.body.appendChild(f);
    f.submit();
  }

  addInput(name: string, value: any, form: HTMLFormElement) {
    let i = document.createElement("input");
    i.setAttribute("type", "hidden");
    i.setAttribute('name', name);
    i.setAttribute("value", value.toString());
    form.appendChild(i);
  }
}
