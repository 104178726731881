import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DashboardService } from "@core/services/dashboard.service";
import { IPerson } from "@core/interfaces/person.interface";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { AuthService } from "@core/services/auth.service";
import { MisService } from "@core/services/mis.service";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServicesData } from "@core/interfaces/order-services-data.interface";
import { IOrderServiceQueue } from "@core/interfaces/order-service-queue.interface";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PrintPatientsModalComponent } from "@shared/modals/print-patients/print-patients.modal.component";
import { DashboardTableView } from '@core/enums/dashboard-table-view.enum';
import { IBookingTime } from "@core/interfaces/booking-time.interface";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { DatePipe, Location } from "@angular/common";
import { IIdValue } from "@core/interfaces/id-value";
import { AvailableBookingStateEnum } from "@core/enums/available-booking-state-enum";
import { PersonRole } from "@core/enums/person-role.enum";
import { IStatusCount } from "@core/interfaces/status-count.interface";
import { OrderStatusPipe } from "@shared/pipes/order-status.pipe";

@Component({
  selector: 'medis-daily-plan',
  templateUrl: './daily-plan.component.html',
  styleUrl: './daily-plan.component.scss'
})

export class DailyPlanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showProviderFilter!: boolean;

  filterForm!: FormGroup;
  tableViewForm!: FormGroup;
  providerId!: number | null;
  providers: IPerson[] = [];
  currentUser!: ICurrentUser | null;
  loading = false;
  onlyActive = true;
  orderServicesData: {
    orderServicesWaiting: any[],
    orderServiceTimes: any[],
    orderServicesPresent: any[],
    orderServicesFreeTimes: any[]
  } = {
    orderServicesWaiting: [],
    orderServicesFreeTimes: [],
    orderServiceTimes: [],
    orderServicesPresent: [],
  };
  filteredTimes: IOrderServiceTime[] | any[] = [];
  initialFilteredTimes: IOrderServiceTime[] = [];
  presentTimes: IOrderServiceTime[] = [];
  cancelledTimesCount: number = 0;
  presentTimesCount: number = 0;
  osFilter: any = {};
  services: IIdValue[] = [];
  locations: IIdValue[] = [];
  selectedProvider!: number;
  personId: number = 0;
  values: any;
  tableViews: { label: string; value: string | number }[] = [];
  autoUpdateInterval!: any;

  currentUserSubscription!: Subscription;
  isFreeTimesSelected: boolean = false;
  isCancelledTimesSelected: boolean = false;
  isFreeTimesLoaded: boolean = false;
  isMenuOpen: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private misService: MisService,
    private router: Router,
    private route: ActivatedRoute,
    private currentLocation: Location,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private orderStatusPipe: OrderStatusPipe,
  ) {
  }

  ngOnInit() {
    this.createFilterFormGroup();
    this.createTableViewFormGroup();

    this.currentUserSubscription = this.authService.currentUserSubject.subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.providerId = currentUser.personId;
        this.loadProvidersAndOrderServices();
      } else {
        this.authService.setCurrentUserToSubject();
        this.currentUser = this.authService.currentUserSubject.value;
        this.loadProvidersAndOrderServices();
      }
    });

    this.autoUpdateInterval = setInterval(() => {
      if (!this.isMenuOpen) {
        this.loadOrderServices();
      }
    }, 10000);

  }

  onMenuOpenForDailyPlan(isMenuOpen: boolean) {
    this.isMenuOpen = isMenuOpen;
  }

  onServiceChange() {
    this.onTableViewChange(DashboardTableView.ALL_TIMES);
    this.filterOrderServiceTimes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showProviderFilter'].currentValue !== changes['showProviderFilter'].previousValue) {
      this.filteredTimes = [];
      this.cancelledTimesCount = 0;
      this.presentTimesCount = 0;
      this.createFilterFormGroup();
      this.createTableViewFormGroup();
      this.loadProvidersAndOrderServices();
    }
  }

  ngOnDestroy() {
    if (this.autoUpdateInterval) {
      clearInterval(this.autoUpdateInterval);
    }
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  private createFilterFormGroup(): void {
    this.filterForm = this.formBuilder.group({
      provider: [null],
      bookingDate: [new Date()],
      service: [null],
      location: [null],
    })
  }

  private createTableViewFormGroup(): void {
    this.tableViewForm = this.formBuilder.group({
      tableView: [DashboardTableView.ALL_TIMES],
    });
  }

  onTableViewChange(view: DashboardTableView): void {
    this.tableViewForm.get('tableView')?.setValue(view);
    if (view !== DashboardTableView.ALL_TIMES) {
      if (this.isFreeTimesSelected) {
        this.orderServicesData.orderServicesFreeTimes = [];
        this.loadOrderServices();
        this.isFreeTimesSelected = false;
        this.isFreeTimesLoaded = false;
      }
      this.filteredTimes = this.filterTimesBySelectedStatus(this.initialFilteredTimes, view);
    } else {
      this.filteredTimes = this.initialFilteredTimes;
    }
    this.filterOrderServiceTimes();
  }

  onFreeTimesChange(event: any): void {

    this.isCancelledTimesSelected = false;

    if (event.checked) {
      if (this.tableViewForm.value != DashboardTableView.ALL_TIMES) {
        this.onTableViewChange(DashboardTableView.ALL_TIMES);
        this.addFiltersToURL();
      }
      this.loadAvailableOrderServices();
    } else {
      this.orderServicesData.orderServicesFreeTimes = [];
      this.loadOrderServices();
    }
  }

  onCancelledTimesChange(): void {
    this.tableViewForm.get('tableView')?.setValue(DashboardTableView.ALL_TIMES);
    this.filterOrderServiceTimes();
  }

  loadAvailableOrderServices() {
    const personId = this.provider.value ?? this.currentUser?.personId;

    if (personId) {
      const date = this.datePipe.transform(this.bookingDate.value, 'YYYY-MM-dd');
      this.dashboardService.getAvailableOrderServicesByDate(date, personId).subscribe({
        next: (response: IBookingTime[]) => {

          this.orderServicesData.orderServicesFreeTimes = response;
          this.filterOrderServiceTimes();
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }

  loadProvidersAndOrderServices() {
    return this.dashboardService.getDashboardProviders()
      .subscribe({
        next: (response: IPerson[]) => {
          this.providers = response;

          let provider = this.route.snapshot.queryParamMap.get('provider') ?? this.currentUser?.personId;
          let date = this.route.snapshot.queryParamMap.get('date');
          let service = this.route.snapshot.queryParamMap.get('service');
          let location = this.route.snapshot.queryParamMap.get('location');
          let tableView = this.route.snapshot.queryParamMap.getAll('tableView');

          if (this.providers.some(x => x.personId == provider)) {
            this.provider.setValue(Number(provider));
          }

          if (date) {
            this.bookingDate.setValue(new Date(Number(date)));
          }

          if (tableView && tableView.length > 0) {
            if (tableView[0] === 'ALL_TIMES') {
              this.onTableViewChange(DashboardTableView.ALL_TIMES);
            } else {
              const view = tableView[0] as DashboardTableView;
              this.onTableViewChange(Number(view));
            }
          } else {
            this.tableViewForm.get('tableView')?.setValue(DashboardTableView.ALL_TIMES); // Default to ALL_TIMES
          }

          this.loadOrderServices();

          if (service) {
            this.service.setValue(Number(service));
          }
          if (location) {
            this.location.setValue(Number(location));
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  loadOrderServices(showLoading = true) {
    const personId = this.provider.value ?? this.currentUser?.personId;
    this.location.setValue(null);
    this.service.setValue(null);

    if (personId) {
      this.loading = showLoading;
      const date = this.datePipe.transform(this.bookingDate.value, 'YYYY-MM-dd');
      this.dashboardService.getOrderServicesByDate(date, personId, {
        ignoreBlockUi: true
      }).subscribe({
        next: (response) => {
          this.parseOrderServices(response);
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.loading = false;
          this.populateServices();
          this.populateLocations();
        }
      })

    } else {
      this.orderServicesData = {
        orderServicesWaiting: [],
        orderServicesFreeTimes: [],
        orderServiceTimes: [],
        orderServicesPresent: [],
      };

      this.populateServices();
      this.populateLocations();
    }
  }

  datePickerSetNextDay = () => {
    const nextDay = new Date(this.bookingDate.value);
    nextDay.setDate(this.bookingDate.value.getDate() + 1);
    this.bookingDate.setValue(nextDay);
    this.onDateChange();
    this.addFiltersToURL();
  }

  datePickerSetPreviousDay = () => {
    const previousDay = new Date(this.bookingDate.value);
    previousDay.setDate(this.bookingDate.value.getDate() - 1);
    this.bookingDate.setValue(previousDay);
    this.onDateChange();
    this.addFiltersToURL();
  }

  printAllPatientsByDate() {
    const providerName = this.filteredTimes[0] ? this.filteredTimes[0].providerName : null;
    const providerDoctorCode = this.filteredTimes[0] ? this.filteredTimes[0].providerDoctorCode : null;

    this.modalService.openModal({
      component: PrintPatientsModalComponent,
      size: ModalSize.LARGE,
      header: `${providerName} ${providerDoctorCode} (${this.datePipe.transform(this.bookingDate.value, 'dd.MM.YYYY')})`,
      data: this.getPrintData(this.filteredTimes)
    })
  };

  private getPrintData(printData: any[]): any {
    return {
      printData,
      osFilter: this.osFilter,
      isOrderServiceTimesData: true,
      onlyActive: this.onlyActive
    }
  }

  refresh(showLoading = true) {
    this.loadOrderServices(showLoading);
  }

  onDateChange() {
    this.onTableViewChange(DashboardTableView.ALL_TIMES);
    this.resetServiceAndLocationFilters();
    this.loadOrderServices();
  }

  openPatientInMis(patientMisCode: number | null) {
    if (patientMisCode) {
      this.misService.openPatientInMis(patientMisCode);
    }
  }

  onProviderChange = () => {
    this.onTableViewChange(DashboardTableView.ALL_TIMES);
    this.resetServiceAndLocationFilters();
    this.refresh();
  };

  openOrder(orderId: number) {
    this.router.navigate(['orders/edit', orderId])
  }

  createOrder(orderService: IOrderServiceTime) {
    if (orderService.nextOrderId) {
      this.openOrder(orderService.nextOrderId);
    } else {
      this.router.navigate(['orders/create'], {
        queryParams: {
          patientId: orderService.patientId,
          personId: orderService.providerId,
          medicalCaseId: orderService.medicalCaseId,
          misAppointmentId: orderService.misAppointmentId,
          parentOrderServiceId: orderService.orderServiceId,
          externalMedicalCaseId: orderService.externalMedicalCaseId,
          externalAppointmentId: orderService.externalAppointmentId
        }
      });
    }
  }

  parseOrderServices = (response: IOrderServicesData) => {
    const {orderServiceQueues, orderServiceTimes, availableBookings} = response;
    const presentOrderServices = orderServiceQueues.filter(osq => osq.orderServiceStatus === OrderServiceStatus.PRESENT) || [];
    const presentOrderServiceTimes = orderServiceTimes.filter(osq => osq.orderServiceTimeStatus === OrderServiceStatus.PRESENT) || [];
    const orderServicesPresent = [...presentOrderServices, ...presentOrderServiceTimes];
    const orderServicesProviderWaitingList = orderServiceQueues.filter(x => x.providerId);

    this.orderServicesData = {
      orderServiceTimes: this.mergeAvailableBookingsAndOrderServiceTimes(orderServiceTimes, orderServicesProviderWaitingList, availableBookings),
      orderServicesFreeTimes: this.orderServicesData.orderServicesFreeTimes,
      orderServicesWaiting: [],
      orderServicesPresent,
    };

    this.filterOrderServiceTimes();

    return response;
  };

  private filterOrderServiceTimes(): void {
    this.osFilter.serviceId = this.service?.value ?? null;
    this.osFilter.locationId = this.location?.value ?? null;

    if (this.location?.value) {
      this.osFilter.roomId = this.location?.value;
    } else {
      delete (this.osFilter.roomId);
    }

    let items = this.orderServicesData.orderServiceTimes;

    if(!this.isCancelledTimesSelected){
      items = items.filter(
        (time: any) => ![OrderServiceStatus.CHANGED, OrderServiceStatus.CANCELLED].includes(time.orderServiceTimeStatus)
      )
    }

    if (this.isFreeTimesSelected) {
      items.push(...this.orderServicesData.orderServicesFreeTimes);
    } else {
      this.isFreeTimesLoaded = false;
    }

    if (this.tableView.value == DashboardTableView.ALL_TIMES) {

      if (this.osFilter.serviceId && this.osFilter.roomId) {
        this.initialFilteredTimes = items.filter(
          (time) => time.serviceId === this.osFilter.serviceId && time.roomId === this.osFilter.roomId
        );
        this.filteredTimes = this.initialFilteredTimes;
      } else if (this.osFilter.serviceId) {
        this.initialFilteredTimes = items.filter(
          (time) => time.serviceId === this.osFilter.serviceId
        );
        this.filteredTimes = this.initialFilteredTimes;
      } else if (this.osFilter.roomId) {
        this.initialFilteredTimes = items.filter(
          (time) => time.roomId === this.osFilter.roomId
        );
        this.filteredTimes = this.initialFilteredTimes;
      } else {
        this.initialFilteredTimes = items;
        this.filteredTimes = this.initialFilteredTimes;
      }
      if (this.filteredTimes.length === 0) {
        this.filteredTimes = [];
      }
    } else {
      this.initialFilteredTimes = items;
      const selectedView = this.tableViewForm.get('tableView')?.value;
      this.filteredTimes = this.filterTimesBySelectedStatus(this.initialFilteredTimes, selectedView);
    }

    this.filteredTimes.sort((a, b) => (a.timeFrom > b.timeFrom) ? 1 : ((b.timeFrom > a.timeFrom) ? -1 : 0));

    if (this.isFreeTimesSelected) {
      this.filteredTimes = this.mergeAvailableBookings(this.filteredTimes);
      if (this.orderServicesData.orderServicesFreeTimes.length > 0) {
        this.isFreeTimesLoaded = true;
      }
    }

    this.presentTimes = this.orderServicesData.orderServiceTimes
      .filter(time => (time.orderServiceTimeStatus ?? time.orderServiceStatus) === OrderServiceStatus.PRESENT);
    this.presentTimesCount = this.presentTimes.length;
    this.cancelledTimesCount = this.orderServicesData.orderServiceTimes
      .filter(time => (time.orderServiceTimeStatus ?? time.orderServiceStatus) === OrderServiceStatus.CANCELLED).length;

    this.updateTableViews(this.initialFilteredTimes);
  }

  private filterTimesBySelectedStatus(times: IOrderServiceTime[], selectedViews: DashboardTableView): IOrderServiceTime[] {
    return times.filter(time => {
      if (selectedViews == DashboardTableView.IN_PROVIDER_QUEUE) {
        return time.orderServiceQueueId! > 0;
      } else {
        const status = time.orderServiceQueueStatus ?? time.orderServiceTimeStatus ?? time.orderServiceStatus;
        return selectedViews == this.mapOrderServiceStatusToDashboard(status);
      }
    });
  }

  mergeAvailableBookings(filteredTimes: IOrderServiceTime[]): any[] {
    let result = [];
    let group: {
      freeTimeGroupId: number,
      timeFrom: string,
      timeTo: string,
      planTimeId: number,
      items: any[]
    } = {freeTimeGroupId: 1, timeFrom: "", timeTo: "", planTimeId: 0, items: []};
    for (let i = 0; i < filteredTimes.length; i++) {
      const item = filteredTimes[i];
      if (item.state == "Free") {
        if (group.planTimeId !== (item.planTimeId ?? 0)) {
          if (group.items.length > 0) {
            result.push(group);
          }
          group = {freeTimeGroupId: i + 1, timeFrom: "", timeTo: "", planTimeId: item.planTimeId ?? 1, items: []};
        }

        if (group.timeFrom == "") {
          group.timeFrom = item.timeFrom;
        }
        const existingItem = group.items.find(groupItem => groupItem.serviceId === item.serviceId);

        if (existingItem) {
          if (item.timeUpto > existingItem.timeUpto) {
            existingItem.timeUpto = item.timeUpto;
            group.timeTo = item.timeUpto;
          }
        } else {
          group.timeTo = item.timeUpto;
          group.items.push(item);
        }

      } else {
        // if(group.items.length > 0){
        //   result.push(group);
        // }
        // result.push(item);
        // group = {freeTimeGroupId: i+1, timeFrom: "", timeTo: "", planTimeId:item.planTimeId ?? 0, items: [] };
        //temp
        if (item?.orderServiceTimeStatus === OrderServiceStatus.CANCELLED || item?.orderServiceQueueStatus === OrderServiceStatus.CANCELLED) {
          continue;
        } else {
          if (group.items.length > 0) {
            result.push(group);
          }
          result.push(item);
          group = {freeTimeGroupId: i + 1, timeFrom: "", timeTo: "", planTimeId: item.planTimeId ?? 0, items: []};
        }
      }
    }
    if (group.items.length > 0) {
      result.push(group);
    }

    return result;
  }

  updateTableViews(times: IOrderServiceTime[]): void {
    const statusCounts = this.mapTimesByStatuses(times);

    this.tableViews = [
      {label: `Kõik ajad (${times.length})`, value: DashboardTableView.ALL_TIMES}
    ];

    for (const status in OrderServiceStatus) {
      const count = statusCounts[status] || 0;
      const label = this.orderStatusPipe.transform(Number(status));

      if (count > 0) {
        this.tableViews.push({label: `${label} (${count})`, value: Number(status)});
      }

      if (count == 0 && this.tableViewForm.value.tableView == status) {
        this.onTableViewChange(DashboardTableView.ALL_TIMES);
      }
    }
  }

  private mapTimesByStatuses(times: IOrderServiceTime[]): IStatusCount {
    return times.reduce((acc, time) => {
      const status = this.mapTypesToNumbers(time.orderServiceQueueStatus ?? time.orderServiceTimeStatus ?? time.orderServiceStatus);
      if (status !== null && status !== undefined) {
        if (status in acc) {
          acc[status]++;
        } else {
          acc[status] = 1;
        }
      }

      if (time.orderServiceQueueStatus) {
        if (acc[OrderServiceStatus.IN_PROVIDER_QUEUE]) {
          acc[OrderServiceStatus.IN_PROVIDER_QUEUE]++;
        } else {
          acc[OrderServiceStatus.IN_PROVIDER_QUEUE] = 1;
        }
      }

      return acc;
    }, {} as IStatusCount);
  }

  private mapTypesToNumbers(status: any): number{
    let map = new Map<any, any>([
      [OrderServiceStatus.CHANGED, OrderServiceStatus.CANCELLED]
    ]);
    return map.get(status) || status;
  }

  private mapOrderServiceStatusToDashboard(orderServiceStatus: any): DashboardTableView {


    const map = new Map<any, any>([
      [OrderServiceStatus.CREATED, DashboardTableView.CREATED],
      [OrderServiceStatus.PREPARING, DashboardTableView.PREPARING],
      [OrderServiceStatus.CANCELLED, DashboardTableView.CANCELLED],
      [OrderServiceStatus.CONFIRMED, DashboardTableView.CONFIRMED],
      [OrderServiceStatus.PRESENT, DashboardTableView.PRESENT],
      [OrderServiceStatus.REALIZATION, DashboardTableView.REALIZATION],
      [OrderServiceStatus.UNFULFILLED, DashboardTableView.UNFULFILLED],
      [OrderServiceStatus.FINISHED, DashboardTableView.FINISHED],
      [OrderServiceStatus.IN_PROVIDER_QUEUE, DashboardTableView.IN_PROVIDER_QUEUE],
      [OrderServiceStatus.IN_SERVICE_QUEUE, DashboardTableView.IN_SERVICE_QUEUE],
      [OrderServiceStatus.BOOKING_NOT_NEEDED, DashboardTableView.BOOKING_NOT_NEEDED],
      [OrderServiceStatus.NOT_APPEARED, DashboardTableView.NOT_APPEARED],
      [OrderServiceStatus.ABSENCE, DashboardTableView.ABSENCE],
      [OrderServiceStatus.CHANGED, DashboardTableView.CANCELLED],
      [AvailableBookingStateEnum.FREE, DashboardTableView.FREE],
    ]);
    return map.get(orderServiceStatus) || DashboardTableView.ALL_TIMES;

  }

  mergeAvailableBookingsAndOrderServiceTimes = (orderServiceTimes: IOrderServiceTime[], waitingList: IOrderServiceQueue[], availableBookings: IBookingTime[]) => {
    let mergedData = [...orderServiceTimes || [], ...availableBookings || [], ...waitingList || []];
    mergedData.sort((a, b) => {
      const timeA = a.timeFrom != null ? new Date(a.timeFrom).getTime() : 0;
      const timeB = b.timeFrom != null ? new Date(b.timeFrom).getTime() : 0;

      if (a.orderServiceTimeStatus === OrderServiceStatus.FINISHED && b.orderServiceTimeStatus !== OrderServiceStatus.FINISHED) {
        return -1;
      } else if (a.orderServiceTimeStatus !== OrderServiceStatus.FINISHED && b.orderServiceTimeStatus === OrderServiceStatus.FINISHED) {
        return 1;
      } else {
        return timeA - timeB;
      }
    });

    return mergedData;
  }

  populateLocations() {
    const combinedArray = [...this.orderServicesData.orderServiceTimes, ...this.orderServicesData.orderServicesPresent];
    this.locations = [...combinedArray].map(uniqueKey => {
      return {id: uniqueKey.roomId, value: `${uniqueKey.departmentShort} ${uniqueKey.roomCode}`};
    }).filter((value, index, array) => {
      return array.findIndex(val => value.id === val.id) === index;
    });
  }

  populateServices() {
    this.services = [...this.orderServicesData.orderServiceTimes, ...this.orderServicesData.orderServicesPresent]
      .map(item => {
        return {id: item.serviceId, value: item.serviceName}
      })
      .filter((value, index, array) => {
        return array.findIndex(val => value.id === val.id) === index;
      });
  }

  onLocationChange() {
    this.filterOrderServiceTimes();
  }

  resetServiceAndLocationFilters() {
    this.filterForm.patchValue({
      service: null,
      location: null,
    });
    this.services = [];
    this.locations = [];
  }

  addFiltersToURL() {
    let date = new Date(this.bookingDate.value).getTime();
    let url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {
        provider: this.provider.value,
        date: date,
        location: this.location.value,
        service: this.service.value,
        tableView: this.tableView.value
      }
    }).toString()

    this.currentLocation.go(url)
  }

  updateList(timeData: { time: IOrderServiceTime, status: OrderServiceStatus }): void {
    timeData.time.orderServiceTimeStatus = timeData.time.orderServiceStatus = timeData.status;
    this.filterOrderServiceTimes();
  }

  get tableView(): FormControl {
    return this.tableViewForm.get('tableView') as FormControl;
  }

  get provider(): FormControl {
    return this.filterForm.get('provider') as FormControl;
  }

  get bookingDate(): FormControl {
    return this.filterForm.get('bookingDate') as FormControl;
  }

  get service(): FormControl {
    return this.filterForm.get('service') as FormControl;
  }

  get location(): FormControl {
    return this.filterForm.get('location') as FormControl;
  }

  protected readonly PersonRole = PersonRole;
}
