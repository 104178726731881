import {Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'lorex-print-obj',
  templateUrl: './print-obj.component.html',
  styleUrls: ['./print-obj.component.scss']
})
export class PrintObjComponent implements OnInit {

  @Input() objToPrint: any;

  private dragging = false;
  public draggable = true;
  private resizing = false;
  private offsetX = 0;
  private offsetY = 0;
  private startWidth = 0;
  private startHeight = 0;
  private resizeStartX = 0;
  private resizeStartY = 0;
  private resizeDirection: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.positionElementInitially();
  }

  positionElementInitially() {
    // Example initial positioning logic
    const viewportWidth = window.innerWidth;
    const initialLeft = (viewportWidth / 2) - (100 / 2); // Assuming initial width is 100px
    const initialTop = 20; // 20px from the top of the viewport
    this.renderer.setStyle(this.el.nativeElement, 'left', `${initialLeft}px`);
    this.renderer.setStyle(this.el.nativeElement, 'top', `${initialTop}px`);
  }

  @HostListener('document:dblclick', ['$event'])
  onDoubleClick(event: MouseEvent) {
    // Toggle draggable state only if the double-click is within the element
    if (this.el.nativeElement.contains(event.target)) {
      this.draggable = !this.draggable;
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('resize-handle')) {
      this.resizing = true;
      this.resizeStartX = event.clientX;
      this.resizeStartY = event.clientY;
      this.startWidth = this.el.nativeElement.offsetWidth;
      this.startHeight = this.el.nativeElement.offsetHeight;
      this.resizeDirection = target.classList[1]; // Assuming the second class denotes the direction
      event.preventDefault();
    } else if (this.draggable) {
      this.dragging = true;
      this.offsetX = event.clientX - this.el.nativeElement.getBoundingClientRect().left;
      this.offsetY = event.clientY - this.el.nativeElement.getBoundingClientRect().top;
      event.preventDefault();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.dragging && this.draggable) {
      const newLeft = event.clientX - this.offsetX;
      const newTop = event.clientY - this.offsetY;
      this.renderer.setStyle(this.el.nativeElement, 'left', `${newLeft}px`);
      this.renderer.setStyle(this.el.nativeElement, 'top', `${newTop}px`);
    } else if (this.resizing) {
      let newWidth = this.startWidth;
      let newHeight = this.startHeight;

      switch (this.resizeDirection) {
        case 'top-left':
          newWidth = this.startWidth - (event.clientX - this.resizeStartX);
          newHeight = this.startHeight - (event.clientY - this.resizeStartY);
          break;
        case 'top-right':
          newWidth = this.startWidth + (event.clientX - this.resizeStartX);
          newHeight = this.startHeight - (event.clientY - this.resizeStartY);
          break;
        case 'bottom-left':
          newWidth = this.startWidth - (event.clientX - this.resizeStartX);
          newHeight = this.startHeight + (event.clientY - this.resizeStartY);
          break;
        case 'bottom-right':
          newWidth = this.startWidth + (event.clientX - this.resizeStartX);
          newHeight = this.startHeight + (event.clientY - this.resizeStartY);
          break;
      }
      this.renderer.setStyle(this.el.nativeElement, 'width', `${Math.max(newWidth, 20)}px`);
      this.renderer.setStyle(this.el.nativeElement, 'height', `${Math.max(newHeight, 20)}px`);
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragging = false;
    this.resizing = false;
  }

}
