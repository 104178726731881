<medis-referral-data
  [patient]="patient"
  [referral]="referral"
/>

<div class="dialog__section border-round-md mt-3">
  <h3 class="dialog__section-title text-color-secondary text-xl font-bold">Vastus</h3>
  <div class="dialog__section-info flex flex-row" [style]="{gap: '28px'}">
    <div class="flex flex-column w-12">
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        required
        [(ngModel)]="referral!.referralAnswer!.answer">
      </textarea>
    </div>
  </div>
</div>


<div class="p-dialog-footer justify-content-end">
  <p-button
    label="Katkesta"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
    size="small"/>
  <p-button
    label="Salvesta"
    icon="pi pi-check"
    [disabled]="! referral!.referralAnswer!.answer"
    size="small"
    (onClick)="save()"
  ></p-button>
</div>
