import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LocationService } from "@core/services/location.service";
import { ToastService } from "@core/services/toast.service";
import { IRoom } from "@core/interfaces/room.interface";

@Component({
  selector: 'medis-room-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        [id]="formControlName"
        class="w-full"
        styleClass="w-full"
        [options]="rooms"
        [filter]="rooms.length > 15"
        filterBy="codeName"
        [(ngModel)]="selectedRoomId"
        [showClear]="true"
        (onChange)="onSelect($event.value)"
        optionValue="roomId"
      >
        <ng-template pTemplate="selectedItem">
          @if (selectedRoom) {
            <div class="flex align-items-center gap-2">
              <div>{{ selectedRoom.codeName + (selectedRoom.roomDepartment ? ' | ' + selectedRoom.roomDepartment : '') }}</div>
            </div>
          }
        </ng-template>
        <ng-template let-room pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ room.codeName + (room.roomDepartment ? ' | ' + room.roomDepartment : '') }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomSelectListComponent),
      multi: true
    }
  ]
})
export class RoomSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string = '';
  @Input() labelName: string = '';

  @Output() onRoomChange = new EventEmitter<IRoom>();

  rooms: IRoom[] = [];
  selectedRoomId!: number;
  selectedRoom: IRoom | undefined;
  isDisabled = false;

  constructor(private locationService: LocationService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getRooms();
  }

  getRooms(): void {
    this.locationService.getAllRooms().subscribe({
      next: resolve => {
        this.rooms = resolve;
        this.selectedRoom = this.rooms.find(room => room.roomId === this.selectedRoomId);
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.selectedRoomId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: any) {
    this.selectedRoomId = value;
    this.selectedRoom = this.rooms.find(room => room.roomId === this.selectedRoomId);
    this.onRoomChange.emit(this.selectedRoom);
    this.onChange(value);
    this.onTouched();
  }

}
