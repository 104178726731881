import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userProperName',
  standalone: true
})
export class UserProperNamePipe implements PipeTransform {
  transform(name: string | undefined): string {
    if(!name) {
      return '';
    }
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

}
