import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { IEConsultation } from "../interfaces/e-consultation.interface";
import { IService } from "@core/interfaces/service.interface";
import { IEConsultationUpdateLog } from "@core/interfaces/e-consultation-update-log.interface";
import { IPerson } from "@core/interfaces/person.interface";
import {
  EConsultationReplyModalComponent
} from "@shared/modals/e-consultation-reply/e-consultation-reply.modal.component";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";

@Injectable({
  providedIn: 'root'
})
export class EConsultationService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient, private modalService: ModalService) {
    this.BACKEND_URL = `${environment.backendUrl}/econsultations`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/econsultations`;
  }

  public get(id: number): Observable<IEConsultation> {
    return this.http.get<IEConsultation>(`${this.BACKEND_URL}/${id}`);
  }

  public assign(eConsultation: IEConsultation): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/assign`, eConsultation);
  }

  public resolve(eConsultation: IEConsultation): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/resolve`, eConsultation);
  }

  public confirm(eConsultation: IEConsultation): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/confirm`, eConsultation);
  }

  public cancel(eConsultationId: number): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/cancel/${eConsultationId}`, {});
  }

  public updateReferrals(): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/update`);
  }

  public getReferrals(): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/unassigned`);
  }

  public getServices(): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.BACKEND_URL}/services`);
  }

  public getProviders(serviceId: number): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/${serviceId}`);
  }

  public findServiceForBooking(serviceId: number): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.BACKEND_URL}/findService/${serviceId}`);
  }

  public getUnConfirmedEConsultations(): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/unconfirmed`);
  }

  public getConfirmedEConsultations(params: HttpParams): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/confirmed`, {params});
  }

  public getUpdateLog(): Observable<IEConsultationUpdateLog[]> {
    return this.http.get<IEConsultationUpdateLog[]>(`${this.OLD_BACKEND_URL}/updatelogs`);
  }

  // public getMisVisits(eConsultationId: number): Observable<IVisit[]> {
  //   return this.http.get<IVisit[]>(`${this.BACKEND_URL}/misvisits/${eConsultationId}`);
  // }

  public getSpecificEConsultations(params: HttpParams): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/specificEconsultations`, {params});
  }

  // public getDigiluguDocuments(params: HttpParams): Observable<IDigiluguDocumentListData> {
  //   return this.http.get<IDigiluguDocumentListData>(`${this.BACKEND_URL}/digiluguDocuments`, {params});
  // }

  // public getReferralReplyDocument(document: IDigiluguDocumentListData): Observable<IReferralReplyDocumentData> {
  //   return this.http.post<IReferralReplyDocumentData>(`${this.BACKEND_URL}/replyDocument`, document);
  // }

  public saveBooking(data: any): Observable<IEConsultation> {
    return this.http.post<IEConsultation>(`${this.BACKEND_URL}/saveBooking`, data);
  }

  openEConsultation = (referral: IEConsultation): void => {
    this.modalService.openModal({
      component: EConsultationReplyModalComponent,
      size: ModalSize.LARGE,
      header: 'E-konsultatsiooni vastus',
      data: { eConsultationId: referral.eConsultationId, readonly: true }
    })
  };
}
