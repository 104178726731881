import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmationService } from "primeng/api";

/**
 * @description
 * Directive to invoke confirmation on an input
 *
 * ### Example
 * ```html
 * <button
 *   medisConfirmDialog
 *   [confirmHeader]="'Delete Confirmation'"
 *   [confirmMessage]="'Are you sure you want to delete this item?'"
 *   [rejectLabel]="'No'"
 *   [acceptLabel]="'Yes'"
 *   (confirmedAction)="onConfirm($event)">
 *   Delete
 * </button>
 * ```
 */
@Directive({
  selector: '[medisConfirmAction]',
  standalone: true,
})
export class ConfirmActionDirective {
  @Input() confirmHeader = 'Kinnita tegevust';
  @Input() confirmMessage = 'Kas te olete kindel?'
  @Input() rejectLabel = 'Katkesta';
  @Input() acceptLabel = 'Kinnita';
  @Output() confirmedAction = new EventEmitter<boolean>();

  constructor(private confirmationService: ConfirmationService) {
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    this.confirmationService.confirm({
      key: 'confirm-action',
      header: this.confirmHeader,
      message: this.confirmMessage,
      rejectLabel: this.rejectLabel,
      acceptLabel: this.acceptLabel,
      accept: () => this.confirmedAction.emit(true),
      reject: () => this.confirmedAction.emit(false),
    });
  }
}
