import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from "@core/services/patient.service";
import { IPatient } from "@core/interfaces/patient.interface";
import { ISearchResult } from "@core/interfaces/search-result.interface";

@Component({
  selector: 'medis-search-bar',
  templateUrl: './search-bar.component.html'
})
export class SearchBarComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  @Input() hasButton: boolean = false;
  @Input() redirect: boolean = false;
  @Input() searchQuery: string = '';
  @Output() searchResults = new EventEmitter<ISearchResult>();

  patientSearchResult: IPatient[] = [];
  @Input() set patientCode(value: string|undefined) {
    if(value){
      this.searchQuery = value;
      this.performSearch();
    }
  };

  constructor(private router: Router, private patientService: PatientService, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if(this.searchQuery || this.hasButton) {
      this.searchInput.nativeElement.focus();
      this.cdRef.detectChanges();
    }
  }

  performSearch() {
    if (!this.searchQuery.trim()) {
      return;
    }

    if (this.redirect) {
      this.patientService.searchWithExternal(this.searchQuery).subscribe({
        next: (response: IPatient[]) => {
          this.patientSearchResult = response;
          if(this.patientSearchResult.length === 1 && this.patientSearchResult[0].idCode === this.searchQuery){
            this.router.navigate([`/patients/${response[0].patientId}/details`]);
            this.searchQuery = '';
          } else {
            this.router.navigate(['/patients/list'], { queryParams: { searchQuery: this.searchQuery } });
            this.searchQuery = '';
          }
        }
      })
    } else {
      this.patientService.searchWithExternal(this.searchQuery).subscribe({
        next: (response: IPatient[]) => {
          this.patientSearchResult = response;
          this.searchResults.emit({ results: this.patientSearchResult, query: this.searchQuery });
        },
        error: (err: Error) => {
          console.log(err);
        }
      })
    }
  }

}
