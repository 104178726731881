import { Component } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { IReferral } from "@core/interfaces/referral.interface";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PatientService } from "@core/services/patient.service";

@Component({
  selector: 'app-edit-referral-answer',
  templateUrl: './edit-referral-answer.component.html',
  styleUrl: './edit-referral-answer.component.scss'
})
export class EditReferralAnswerComponent {
  patient!: IPatient;
  referral!: IReferral;


  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private patientService: PatientService,
  ) {

    let modalConfig = this.config.data;

    if(modalConfig){
      this.patient = modalConfig.patient;
      this.referral = modalConfig.referral;
    }
  }


  save() {
    this.patientService.saveReferralAnswer(this.referral).subscribe({
      next: () => {
        this.closeModal();
      }
    })
  }

  closeModal() {
    this.ref.close(false);
  }
}
