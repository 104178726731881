<div class="flex flex-column gap-4">
  @if (patient) {
    <medis-order-patient [patient]="patient"/>
  }
  <form [formGroup]="summaryForm">
    <div class="flex flex-column w-12 gap-4">

    <medis-another-payer [form]="summaryForm"
                         [sendLinkReady]="sendLinkReady"
                         (onPayerToggle)="updateSummaryForm($event)"
    />

      @for (company of groupedCompaniesData; track company) {
        <div class="bg-white data-details__section border-round-md">
          <div class="flex flex-row justify-content-between">
            <h3 class="text-color-secondary text-xl font-bold">{{ company.companyName }}</h3>
          </div>

          <medis-discount-select [unpaidServices]="company.services" (onDiscountSelect)="updateUnpaidServices()"/>

          <medis-company-selected-times [unpaidServices]="company.services"
                                        [patient]="patient"
                                        [summaryForm]="summaryForm"
                                        [externalBooking]="externalBooking"
                                        (onSendPaymentClick)="sendLinkReady = $event"
                                        (onServicePayment)="updateUnpaidServices()"
          />
        </div>
      }

    </div>
  </form>

  <div class="flex row justify-content-end py-4 gap-2">
    <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
  </div>
</div>

