import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Gender } from "@core/enums/gender.enum";
import { EhakService } from "@core/services/ehak.service";
import { IEhakCounty } from "@core/interfaces/ehak-county.interface";
import { IEhakCity } from "@core/interfaces/ehak-city.interface";
import { IEhakSettlement } from "@core/interfaces/ehak-settlement.interface";
import { GeneralList } from '@core/enums/general-list.enum';
import { IPatient } from "@core/interfaces/patient.interface";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { CountryService } from "@core/services/country.service";
import { Country } from "@core/enums/country.enum";
import { PatientService } from "@core/services/patient.service";
import { EuropeanUnionInsuranceDocumentType } from "@core/enums/european-union-insurance-document-type";
import { IPatientInsurance } from "@core/interfaces/patient-insurance.interface";

@Component({
  selector: 'medis-patient-general-data',
  templateUrl: './patient-general-data.component.html',
  styleUrl: './patient-general-data.component.scss'
})
export class PatientGeneralDataComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() patient: IPatient = {} as IPatient;
  counties: string[] = [];
  cities: string[] = [];
  settlements: string[] = [];

  nationality = [
    {
      label: this.countryService.getCountryLabel(Country.EST),
      value: Country.EST
    },
    {
      label: 'Muu',
      value: 'other'
    }
  ];

  languages = [
    {
      label: this.countryService.getCountryLanguage(Country.EST),
      value: 'est',
    },
    {
      label: this.countryService.getCountryLanguage(Country.RUS),
      value: 'rus',
    },
    {
      label: this.countryService.getCountryLanguage(Country.GBR),
      value: 'eng',
    },
  ];

  genderOptions = [
    {
      label: 'Mees',
      value: Gender.MALE,
    },
    {
      label: 'Naine',
      value: Gender.FEMALE,
    },
  ];

  countries = this.countryService.getAllCountriesLabels();
  allCountryCodes = this.countryService.getAllCountryCodes();

  constructor(private ehakService: EhakService,
              private patientService: PatientService,
              private countryService: CountryService) {
  }

  ngOnInit() {
    this.contactCountry?.value == Country.EST ? this.longAddress.disable() : this.longAddress.enable();
    this._fetchCounties();
  }

  onCountrySelected(event: any): void {
    this.counties = [];
    this.cities = [];
    this.settlements = [];
    this.county?.reset();
    this.city?.reset();
    this.settlement?.reset();

    if (event.value === Country.EST) {
      this.longAddress.disable();
      this._fetchCounties();
    } else {
      this.longAddress.enable();
    }
  }

  onCountySelected(): void {
    this.cities = [];
    this.settlements = [];
    this.city?.reset();
    this.settlement?.reset();
    this._fetchCities();
    this._fetchSettlements();
  }

  onCitySelected(): void {
    this.settlements = [];
    this.settlement.reset();
    this._fetchSettlements();
  }

  setAddress(val: any): void {
    this.contactCountry?.setValue(Country.EST);
    this._fetchCounties();
    if (val.maakond) this.county?.setValue(val.maakond);
    if (val.omavalitsus) this.city?.setValue(val.omavalitsus);
    if (val.asustusyksus) this.settlement?.setValue(val.asustusyksus);
    if (val.kort_nr) this.flat?.setValue(val.kort_nr);
    if (val.sihtnumber) this.zip?.setValue(val.sihtnumber);
    if (val.aadress_nr) this.house?.setValue(val.aadress_nr);

    let street = val.nimi || val.liikluspind;
    if (street) this.street?.patchValue(street);
  }

  get county(): FormControl {
    return this.formGroup?.get('county') as FormControl;
  }

  get city(): FormControl {
    return this.formGroup?.get('city') as FormControl;
  }

  get longAddress(): FormControl {
    return this.formGroup?.get('longAddress') as FormControl;
  }

  get settlement(): FormControl {
    return this.formGroup?.get('settlement') as FormControl;
  }

  get zip(): FormControl {
    return this.formGroup?.get('zip') as FormControl;
  }

  get street(): FormControl {
    return this.formGroup?.get('street') as FormControl;
  }

  get flat(): FormControl {
    return this.formGroup?.get('flat') as FormControl;
  }

  get house(): FormControl {
    return this.formGroup?.get('house') as FormControl;
  }

  get patientNationality(): FormControl {
    return this.formGroup?.get('nationality') as FormControl;
  }

  get idCode(): FormControl {
    return this.formGroup?.get('idCode') as FormControl;
  }

  get firstname(): FormControl {
    return this.formGroup?.get('firstname') as FormControl;
  }

  get lastname(): FormControl {
    return this.formGroup?.get('lastname') as FormControl;
  }

  get email(): FormControl {
    return this.formGroup?.get('email') as FormControl;
  }

  get gender(): FormControl {
    return this.formGroup?.get('gender') as FormControl;
  }

  get birthday(): FormControl {
    return this.formGroup?.get('birthday') as FormControl;
  }

  get contactCountry(): FormControl {
    return this.formGroup.get('contactCountry') as FormControl;
  }

  get patientWorkplaces(): FormArray {
    return this.formGroup.get('workplaces') as FormArray;
  }

  get phoneNumber(): FormGroup {
    return this.formGroup.get('phoneNumber') as FormGroup;
  }

  get country(): FormGroup {
    return this.formGroup.get('country') as FormGroup;
  }

  get clientGroupsIds(): FormGroup {
    return this.formGroup.get('clientGroupsIds') as FormGroup;
  }

  private _fetchCounties(): void {
    if (this.contactCountry.value === Country.EST) {
      this.ehakService.getCounties().subscribe({
        next: (counties: IEhakCounty[]) => {
          this.counties = counties.map(c => c.mnimi);
          this._fetchCities()
        }
      });
    }
  }

  private _fetchCities(): void {
    const countyCode = this.formGroup?.get('county')?.value;
    if (countyCode) {
      this.ehakService.getCities({mnimi: countyCode}).subscribe({
        next: (cities: IEhakCity[]) => {
          this.cities = cities.map(c => c.onimi);
          this._fetchSettlements();
        }
      });
    }
  }

  private _fetchSettlements(): void {
    if (this.county?.value && this.city?.value) {
      this.ehakService.getSettlements({
        mnimi: this.county?.value,
        onimi: this.city?.value
      }).subscribe({
        next: (settlements: IEhakSettlement[]) => {
          this.settlements = settlements.map(a => a.animi);
        }
      });
    }
  }

  _fetchInsuranceData() {
    if (!this.idCode.hasError('idCodeValidator') && this.patientNationality.value == Country.EST) {
      this.patientService.getInsuranceData(this.idCode.value).subscribe({
        next: (eh: IPatientInsurance) => {
          this.firstname.setValue(eh.firstname);
          this.lastname.setValue(eh.lastname);
        }
      })
    }
  }

  setGroup(event: IGeneralList) {
    if (event?.generalListId) {
      this.formGroup.get('clientGroupsIds')?.patchValue([event.generalListId]);
    }
  }

  protected readonly GeneralList = GeneralList;
  protected readonly Country = Country;
}
