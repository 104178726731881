import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IRelatedService } from "@core/interfaces/related-service.interface";

@Component({
  selector: "medis-related-service-picker-modal",
  templateUrl: "./related-service-picker.modal.html",
})
export class RelatedServicePickerModal {
  selectedServiceIdsWithNames: {id: number, name: string}[] = [];

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
    if (this.config.data && this.config.data.length > 0) {
      const relatedServices = this.config.data;
      relatedServices.forEach((relatedService: IRelatedService) => {
        if(relatedService.relatedServiceId){}
        const selectedServiceWithName = {
          id: relatedService.relatedServiceId,
          name: relatedService.name
        };
        this.selectedServiceIdsWithNames.push(selectedServiceWithName);
      })
    } else {
      this.selectedServiceIdsWithNames = [];
    }
  }

  saveRelatedServices(){
    this.ref.close(this.selectedServiceIdsWithNames);
  }

  closeModal(){
    this.ref.close();
  }

}
