export enum EuropeanUnionInsuranceDocumentType {
  EHIC = 'EHIC - Euroopa ravikindlustuskaart',
  REPL = 'REPL - Euroopa ravikindlustuskaardi asendussertifikaat',
  S045 = 'Tõend S045 - Hüvitise saamise õigust kinnitav dokument – ajutine viibimine',
  E112 = 'Vorm E112 - Tõend haigus- või rasedus- ja sünnitushüvitise saamise õiguse säilimise kohta',
  S2 = 'Tõend S2 - Plaanilise ravi saamise õigus',
  S037 = 'Tõend S037 - Vastus hüvitiste saamise õigust kinnitava dokumendi pikendamise taotlusele - plaaniline ravi',
  E123 = 'Vorm E123 - Tõend õiguse kohta saada mitterahalisi hüvitisi tööõnnetuste ja kutsehaiguste vastu kindlustamise kaudu',
  DA1 = 'Tõend DA1 - Õigus ravikindlustusele tööõnnetuste ja kutsehaiguste korral'
}
