import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IReason } from "@core/interfaces/reason.interface";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";

@Injectable({
  providedIn: 'root'
})
export class OrderServiceTimeService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/orderServiceTimes`;
  }

  public cancelOrderServiceTime(orderServiceTimeId: number, reason: IReason): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/cancel/${orderServiceTimeId}`, reason);
  }

  public setPresent(orderServiceTimeId: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/present/${orderServiceTimeId}`, {});
  }

  public setFinished(orderServiceTimeId: number, reasonData: IReason): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/finished/${orderServiceTimeId}`, reasonData);
  }

  public cancelPresent(orderServiceTimeId: number, reasonData: IReason): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/cancelpresent/${orderServiceTimeId}`, reasonData);
  }

  public sortAndGetConfirmedTimes(orderServiceTimes: IOrderServiceTime[] = []): IOrderServiceTime[] {
    let times: IOrderServiceTime[] = [];
    orderServiceTimes.sort((a, b) => {
      const dateComparison = new Date(a.date).getTime() - new Date(b.date).getTime();
      if (dateComparison !== 0) {
        return dateComparison;
      }

      return a.timeFrom.localeCompare(b.timeFrom);
    }).forEach(time => times.push(time));
    return times?.filter(time => time.orderServiceTimeStatus === OrderServiceStatus.CONFIRMED);
  }
}
