import { Component, Input, TemplateRef, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'medis-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() set tabs(tabs: MedisTab[]){
    if (tabs.length > 0) {
      this.tabList = tabs;
      tabs.forEach((value, index) => {
        if (value.isActive) {
          this.activeIndex = index;
        }
      })
    }
  }

  @Output() tabChange: EventEmitter<number> = new EventEmitter();

  tabList: MedisTab[] = [];
  activeIndex = 0;

  ngOnInit() {}

  switchHeaders(event: any) {
    let index : number = event.index;

    if(this.tabList[index].action){
      this.tabList[index].action();
    }
  }

  onTabChange(event: any) {
    this.tabChange.emit(event);
  }
}

export interface MedisTab {
  title: string;
  content: TemplateRef<any> | null;
  action?: any;
  isActive?: boolean;
}
