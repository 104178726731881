import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

/**
* @description
* Extension of built-in validators that can be used by form controls.
*
* A validator is a function that processes a `FormControl` or collection of
* controls and returns an error map or null. A null map means that validation has passed.
*
* @see [Form Validation](/guide/form-validation)
*
*/
export class MedisValidators extends Validators {

  /**
   * @description
   * Checks validity if IBAN code.
   *
   * @return
   * Returns an object with `error` parameter that hosts error
   * that can be translated using TranslateService
   *
   * @param control
   * @constructor
   */
  static IBAN(control: AbstractControl): ValidationErrors | null {
    const code = control.value;

    if (!code) return null;

    let iban: string = code.replace(/ /g, '').toUpperCase();

    // Check if IBAN is too short or too long
    if (iban.length < 15  || iban.length > 34) {
      switch (true) {
        case iban.length < 15 : return { error: 'IBAN.minLength' }
        case iban.length > 15 : return { error: 'IBAN.maxLength' }
      }
    }

    // Move first four chars to the end
    iban = iban.slice(4) + iban.slice(0, 4);

    // Convert letters to numbers (A=10, B=11, ..., Z=35)
    iban = iban.split('').map((n) => {
      let code = n.charCodeAt(0);
      if (code >= 65 && code <= 90) {
        // Convert alphabetic to numeric
        return code - 55;
      } else if (code >= 48 && code <= 57) {
        // Keep numeric as is
        return n;
      }
      return n;
    }).join('');

    // Extract groups of up to 7 digits from the IBAN
    const ibanGroups = iban.match(/\d{1,7}/g);

    if (!ibanGroups) {
      return { error: 'IBAN.invalid' }; // Return error if no valid groups are found
    }

    // Calculate the remainder by reducing the groups
    const remainder = ibanGroups.reduce((num: string, curr: string) => {
      return (Number(num + curr) % 97).toString();
    }, '0'); // Initialize with '0' to prevent issues with an empty array

    // Return true if the remainder equals 1
    return Number(remainder) === 1 ? null : { error: 'IBAN.invalid' };
  }
}

