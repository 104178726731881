import { Component } from '@angular/core';
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { IRoom } from "@core/interfaces/room.interface";
import { LocationService } from "@core/services/location.service";

@Component({
  selector: 'medis-room-add',
  templateUrl: './room-add-modal.component.html'
})
export class RoomAddModalComponent {
  addRoomForm!: FormGroup;
  formSubmitted = false;

  constructor(
    private locationService: LocationService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef
  ) {
    this.addRoomForm = this.formBuilder.group({
      codeName: [null, Validators.required],
      address: [null, Validators.required],
      roomType: [null],
      phone: [null],
      isSpecial: [null],
      description: [null],
    })
  }

  save(): void {
    this.formSubmitted = true;
    if (this.addRoomForm.invalid) {
      return;
    }

    this.locationService.saveRoom(this.roomFromForm()).subscribe({
      next: result => {
        this.ref.close(result);
      },
      error: err => {
        this.toastService.error(err.message);
      }
    });
  }

  private roomFromForm(): IRoom {
    return {
      codeName: this.codeName.value,
      addressId: this.address.value,
      roomTypeId: this.roomType.value,
      phone: this.phone.value,
      isSpecial: this.isSpecial.value,
      description: this.description.value,
    }
  }

  closeModal(): void {
    this.ref.close();
  }

  get codeName(): FormControl {
    return this.addRoomForm.get('codeName') as FormControl;
  }

  get address(): FormControl {
    return this.addRoomForm.get('address') as FormControl;
  }

  get roomType(): FormControl {
    return this.addRoomForm.get('roomType') as FormControl;
  }

  get phone(): FormControl {
    return this.addRoomForm.get('phone') as FormControl;
  }

  get isSpecial(): FormControl {
    return this.addRoomForm.get('isSpecial') as FormControl;
  }

  get description(): FormControl {
    return this.addRoomForm.get('description') as FormControl;
  }

}
