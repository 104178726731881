import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize, Subject } from "rxjs";
import { IPatient } from "@core/interfaces/patient.interface";
import { PatientService } from "@core/services/patient.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'medis-patient-search-list',
  template: `
    <p-floatLabel class="w-full">
      <p-dropdown id="patientSearchList"
                  [ngModel]="selectedPatient"
                  (onChange)="onSelect($event.value)"
                  [options]="patientResultOptions"
                  [loading]="loading"
                  [showClear]="true"
                  styleClass="w-full"
                  [filter]="true"
                  (onClear)="clearResults()"
                  (onFilter)="attemptSearch($event)"
                  dropdownIcon="pi pi-search"
                  appendTo="body"
      />
      <label id="patientSearchList">{{ label }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientSearchListComponent),
      multi: true
    }]
})
export class PatientSearchListComponent implements ControlValueAccessor {
  @Input() label: string = 'Otsi';
  @Output() patientSelected = new EventEmitter<any>();

  selectedPatient: IPatient | null = null;

  searchSubject = new Subject<{ originalEvent: any, filter: string }>();
  patientResults: IPatient[] = [];
  patientResultOptions?: { label: string, value: string }[] = [];

  loading = false;

  constructor(private patientService: PatientService) {
    this.searchSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged() // Ignore duplicate inputs
    ).subscribe(search => this.executeSearch(search));
  }

  clearResults(): void {
    this.patientResults = [];
    this.patientResultOptions = [];
  }

  attemptSearch(search: { originalEvent: any, filter: string }): void {
    if (search && search.filter?.length >= 3) {
      this.searchSubject.next(search);
    } else {
      this.clearResults();  // Clear results if the input is empty
    }
  }

  private executeSearch(search: { originalEvent: any, filter: string }): void {
    if (search && search.filter && search.filter.length >= 3 && !this.loading) {
      this.loading = true;
      this.patientService.search(search.filter)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (e: any) => {
            this.patientResults = e;
            this.patientResultOptions = e.map((one: IPatient) => ({
              label: `${one.firstname} ${one.lastname}`,
              value: one.patientId
            }));
          }
        });
    }
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedPatient = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedPatient = value;
    let patientObj = this.patientResults.find((one: any) => one.patientId === value);
    this.patientSelected.emit(patientObj)
    this.onChange(value);
    this.onTouched();
  }
}
