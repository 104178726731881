import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IPlanTimeLog } from "@core/interfaces/plan-time-log.interface";

interface PlanTimeLogItem {
  name: string;
  oldValue?: string | string[] | boolean;
  newValue?: string | string[] | boolean;
}

@Component({
  selector: "medis-plantime-history",
  templateUrl: "./plantime-history.modal.html",
  styleUrls: ["./plantime-history.modal.scss"],
})

export class PlanTimeHistoryModal {

  logs: IPlanTimeLog[] = [];
  eventGuid = 0;
  expandedRows: { [key: string]: boolean } = {};

  columns: OrderTableColumn[] = [
    {
      name: OrderColumnName.TIME,
      width: '11%'
    },
    {
      name: OrderColumnName.TIME_ROW,
      width: '11%'
    },
    {
      name: OrderColumnName.USER,
      width: '11%'
    },
    {
      name: OrderColumnName.ACTION,
      width: '11%'
    },
    {
      name: OrderColumnName.FIELD,
      width: '14%'
    },
    {
      name: OrderColumnName.OLD_VALUE,
      width: '18%'
    },
    {
      name: OrderColumnName.NEW_VALUE,
      width: '18%'
    },
  ];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    if (this.config.data.logs) {
      this.logs = this.config.data.logs.map((log: IPlanTimeLog) => {
        const tempId = this.createEventId();
        const changes = this.extractChanges(log);
        if (changes.length > 0) {
          this.expandedRows[tempId] = true;
        }

        return {
          ...log,
          tempId,
          changes
        };
      });
    } else {
      this.logs = [];
    }
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  extractChanges(log: IPlanTimeLog): PlanTimeLogItem[] {
    const fields: { [key: string]: { old: any, new: any } } = {
      'Osutaja': {
        old: this.defineLogValue(log.action, log.oldProvider, log.newProvider),
        new: this.defineLogValue(log.action, log.oldProvider, log.newProvider) == null ? null : log.newProvider
      },
      'Osakond': {
        old: this.defineLogValue(log.action, log.oldDepartment, log.newDepartment),
        new: this.defineLogValue(log.action, log.oldDepartment, log.newDepartment) == null ? null : log.newDepartment
      },
      'Ruum': {
        old: this.defineLogValue(log.action, log.oldRoom, log.newRoom),
        new: this.defineLogValue(log.action, log.oldRoom, log.newRoom) == null ? null : log.newRoom
      },
      'Aparaadid': {
        old: this.defineLogValue(log.action, log.oldDevices, log.newDevices),
        new: this.defineLogValue(log.action, log.oldDevices, log.newDevices) == null ? null : log.newDevices
      },
      'Ära kuva välisveebis': {
        old: this.defineLogValue(log.action, log.oldIsHiddenInPublicWeb, log.newIsHiddenInPublicWeb),
        new: this.defineLogValue(log.action, log.oldIsHiddenInPublicWeb, log.newIsHiddenInPublicWeb) == null ? null : log.newIsHiddenInPublicWeb
      },
      'Teenused': {
        old: this.defineLogValue(log.action, log.oldServices, log.newServices),
        new: this.defineLogValue(log.action, log.oldServices, log.newServices) == null ? null : log.newServices
      },
      'Kolleeg': {
        old: this.defineLogValue(log.action, log.oldRelatedPerson, log.newRelatedPerson),
        new: this.defineLogValue(log.action, log.oldRelatedPerson, log.newRelatedPerson) == null ? null : log.newRelatedPerson
      },
    };

    return Object.entries(fields)
      .filter(([_, values]) => values.old != null || values.new != null)
      .map(([name, values]) => ({ name, oldValue: values.old, newValue: values.new }));
  }

  defineLogValue(action: string | undefined, oldValue: any, newValue: any) {
    if(action === 'Muudetud'){
      if(!oldValue && !newValue){
        return null;
      }
      if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        if (JSON.stringify(oldValue.sort()) === JSON.stringify(newValue.sort())) {
          return null;
        } else return oldValue
      } else {
        if(oldValue === newValue) {
          return null;
        } else {
          if(!oldValue){
            return ''
          } else {
            return oldValue
          }
        }
      }

    } else {
      if(this.isBoolean(newValue) || this.isBoolean(oldValue)){
        if(oldValue === undefined){
          return '';
        } else {
          return oldValue;
        }
      } else {
        return newValue ? '' : oldValue;
      }
    }
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.tempId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.tempId];
  }

  createEventId() {
    return Number(this.eventGuid++);
  }

  closeModal(){
    this.ref.close();
  }

  protected readonly OrderColumnName = OrderColumnName;
  protected readonly Boolean = Boolean;
}

interface OrderTableColumn {
  name: OrderColumnName;
  width?: string;
}

enum OrderColumnName {
  TIME = 'AEG',
  TIME_ROW = 'AJARIDA',
  USER = 'KASUTAJA',
  ACTION = 'TEGEVUS',
  FIELD = 'VÄLI',
  OLD_VALUE = 'VANA VÄÄRTUS',
  NEW_VALUE = 'UUS VÄÄRTUS',
}
