import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { IPerson } from "../interfaces/person.interface";
import { IOrderServicesData } from "../interfaces/order-services-data.interface";
import { DailyPlanComponent } from "../../feature/office/daily-plan/daily-plan.component";
import { IBookingTime } from "@core/interfaces/booking-time.interface";
import { EConsultationsTodayComponent } from "@shared/components/e-consultations-today/e-consultations-today.component";
import { IEConsultation } from "@core/interfaces/e-consultation.interface";
import { IPrescription } from "@core/interfaces/prescription.interface";
import { IPrescriptionConfirmResponse } from "@core/interfaces/prescription-confirm-response.interface";
import { IWorkIncapacityCertificate } from "@core/interfaces/work-incapacity-certificate.interface";
import { IMedicalCase } from "@core/interfaces/medical-case.interface";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/dashboard`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/dashboard`;
  }

  // public getUserDashboardBlocks(type: string): Observable<string[]> {
  //   return this.http.get<string[]>(`${this.BACKEND_URL}/blocks/${type}`);
  // }

  // public getBlocksOrdersCount(params: any): Observable<KeyValuePair<number, number>[]> {
  //   return this.http.get<KeyValuePair<number, number>[]>(`${this.BACKEND_URL}/blocks/ordersCount`, {params});
  // }

  // public getProvidersByDate(date: string | null): Observable<IPerson[]> {
  //   return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/${date}`);
  // }

  public getDashboardProviders(): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers`);
  }

  public getOrderServicesByDate(date: string | null, providerId: number, params: any): Observable<IOrderServicesData> {
    return this.http.get<IOrderServicesData>(`${this.BACKEND_URL}/bookings/${date}/${providerId}`, {params});
  }

  public getAvailableOrderServicesByDate(date: string | null, providerId: number): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/bookings/available/${date}/${providerId}`);
  }

  // public cancelOrderServiceTimePresentStatus(orderServiceTimeId: number, reasonData: any): Observable<any> {
  //   return this.http.put<any>(`${this.BACKEND_URL}/orderservicetime/${orderServiceTimeId}/cancelpresent`, reasonData);
  // }
  //
  // public cancelOrderServiceQueuePresentStatus(orderServiceQueueId: number, reasonData: any): Observable<any> {
  //   return this.http.put<any>(`${this.BACKEND_URL}/orderservicequeue/${orderServiceQueueId}/cancelpresent`, reasonData);
  // }

  public searchEConsultations(params: HttpParams): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/econsultations`, {params});
  }

  public getUnassignedEConsultationsForProvider(): Observable<IEConsultation[]> {
    return this.http.get<IEConsultation[]>(`${this.BACKEND_URL}/econsultations/unassigned/provider`);
  }

  public getPrescriptions(params: HttpParams): Observable<IPrescription[]> {
    return this.http.get<IPrescription[]>(`${this.BACKEND_URL}/prescriptions`, {params});
  }

  public confirmPrescription(prescriptionId: string): Observable<IPrescriptionConfirmResponse> {
    return this.http.put<IPrescriptionConfirmResponse>(`${this.BACKEND_URL}/prescriptions/${prescriptionId}`, {});
  }

  public getWorkIncapacityCerts(params: HttpParams): Observable<IWorkIncapacityCertificate[]> {
    return this.http.get<IWorkIncapacityCertificate[]>(`${this.BACKEND_URL}/workIncapacityCertificate`, {params});
  }

  public confirmWorkIncapacityCert(certId: string): Observable<IWorkIncapacityCertificate> {
    return this.http.put<IWorkIncapacityCertificate>(`${this.BACKEND_URL}/workIncapacityCertificate/${certId}`, {});
  }

  public getOpenMedicalCases(params: HttpParams): Observable<IMedicalCase[]> {
    return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/openMedicalCases`, {params});
  }

  public getBlocks(orderTypes: any): any {
    return {
      'office': [
        {
          name: 'today-patients',
          title: 'Päevaplaan',
          component: DailyPlanComponent,
          icon: 'pi pi-table',
          showProviderFilter: true,
        },
        {
          name: 'eConsultations',
          title: 'E-konsultatsioonid',
          visitType: orderTypes.e_consultation.serviceSearchFilter.visitType,
          component: EConsultationsTodayComponent,
        },
        {
          name: 'prescriptions',
          title: 'Retseptid',
        },
        {
          name: 'workIncapacityCertificates',
          title: 'Töövõimetuslehed',
        },
        {
          name: 'openMedicalCases',
          title: 'Avatud haigusjuhud/külastused',
        },
      ],
      'radiology': [
        {
          name: 'xray',
          state: 'radiology.xray',
          title: 'Röntgen',
        },
        {
          name: 'mrt',
          state: 'radiology.mrt',
          title: 'MRT'
        },
        {
          name: 'kt',
          state: 'radiology.kt',
          title: 'KT'
        },
        {
          name: 'ultrasound',
          state: 'radiology.ultrasound',
          title: 'Ultraheli'
        }
      ]
    }
  }
}
