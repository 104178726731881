import { Component, OnInit } from "@angular/core";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IServiceLog } from "@core/interfaces/service-log.interface";
import { DateTimePipe } from "@shared/pipes/datetime.pipe";

@Component({
  selector: "medis-service-log-modal",
  templateUrl: "./service-log.modal.component.html",
  providers: [DateTimePipe]
})
export class ServiceLogModalComponent implements OnInit {
  instance: DynamicDialogComponent | undefined;
  serviceLogs: IServiceLog[] = [];
  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    protected dt: DateTimePipe,
  ) {
    this.serviceLogs = this.config?.data;
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    if (this.serviceLogs) {
      console.log("hurra", this.serviceLogs);
    }
  }

  closeModal(): void {
    this.instance?.close();
  }

}
