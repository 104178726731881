@if (!searchPerformed && patients.length === 0 && !selectedPatient) {
  <div
    class="flex justify-content-center align-items-center py-4-5"
  >
    <medis-search-bar
      [hasButton]="true"
      [redirect]="false"
      (searchResults)="handleSearchResults($event)"
    />
  </div>
}
@if (selectedPatient) {
  <app-single-patient-search
    [data]="selectedPatient"
    [simpleView]="true"
    (clearSearch)="onClearSearch()"
  />
}
@if (searchPerformed && !selectedPatient || searchPerformed && !patients) {
  <div
    class="flex justify-content-between align-items-center py-4-5"
  >
    <medis-search-bar
      [hasButton]="true"
      [redirect]="false"
      (searchResults)="handleSearchResults($event)"
    />
    <p-button
      severity="primary"
      [style]="{ height: '2.5rem' }"
      label="Lisa uus patsient"
    >
    </p-button>
  </div>
  <app-multiple-patient-search
    [patients]="patients"
    (selectPatient)="setSelectedPatient($event)"
  />
}

<div class="flex flex-row w-12 gap-8 pt-4 pb-4 justify-content-between">
      <div class="flex flex-column w-6 w-full">
        <p-floatLabel>
          <p-dropdown
            id="selectedCompanyId"
            [options]="companies"
            [(ngModel)]="selectedCompanyId"
            optionValue="companyId"
            optionLabel="name"
            appendTo="body"
            class="w-full"
            styleClass="w-full"
            (onChange)="getCompanyProducts()"/>
          <label for="selectedCompanyId">Asutus</label>
        </p-floatLabel>
      </div>
      @if (selectedCompanyId) {
        <div class="flex flex-row w-6 gap-2">
            <div class="w-full">
              <p-selectButton
                [options]="productTypes"
                [multiple]="false"
                [unselectable]="true"
                [(ngModel)]="selectedProductType"
                optionValue="value"
                class="w-full"
                styleClass="w-full"
                (onChange)="onProductTypeChange($event.value)"
              />
            </div>
            <div class="w-full">
              <p-floatLabel>
                <p-dropdown
                  id="productId"
                  [options]="companyProducts"
                  [(ngModel)]="selectedProduct"
                  [emptyMessage]="emptyListTitle"
                  optionLabel="name"
                  class="w-full"
                  styleClass="w-full"
                  appendTo="body"/>
                <label for="selectedCompanyId">{{ productTitle }}</label>
              </p-floatLabel>
            </div>
          <div class="min-w-max">
            <p-button
              [label]="addButtonTitle"
              [disabled]="!selectedProduct"
              (click)="addProduct()"
            ></p-button>
          </div>
        </div>
      }
</div>

@if (selectedProducts.length > 0) {
  <p-table [value]="this.selectedProducts">
    <ng-template pTemplate="header">
      <tr>
        <th>Toode/Teenus</th>
        <th>Hind(€)</th>
        <th>Kogus(tk)</th>
        <th>Tasuda(€)</th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.name }}</td>
        <td>{{ product.price }}</td>
        <td>
          <p-inputNumber
            [(ngModel)]="product.repeat"
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="repeat"
            [step]="1"
            [min]="1"
            decrementButtonClass="p-button-secondary p-button-outlined"
            incrementButtonClass="p-button-secondary p-button-outlined"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            (ngModelChange)="divideOrAdd(product)"
          />
        </td>
        <td>{{getRowPrice(product)}}</td>
        <td>
          <p-button
            [outlined]="true"
            severity="secondary"
            [style]="{ height: '2.5rem', width: '2.5rem' }"
            icon="pi pi-times"
            (click)="deleteProduct(product.serviceId)"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>
}

<div class="p-dialog-footer justify-content-end">
  <p-button label="Sulge"
            [outlined]="true"
            severity="secondary"
            (click)="closeModal()"
  />
  <p-button label="Mine maksma ({{totalSum}}€)"
            severity="contrast"
            [disabled]="selectedProducts.length === 0 || !selectedPatient"
            (click)="pay()"/>
</div>
