import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "@core/services/toast.service";
import { ListService } from "@core/services/list.service";

@Component({
  templateUrl: './general-list-add.modal.component.html'
})
export class GeneralListAddModalComponent implements OnInit {
  label: string = '';
  listId!: number;

  addGeneralListItemForm!: FormGroup;
  formSubmitted = false;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private listService: ListService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    this.addGeneralListItemForm = this.formBuilder.group({
      name: [null, Validators.required],
    })
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      this.label = this.instance.data['label'];
      this.listId = this.instance.data['listId'];
    }
  }

  save(): void {
    this.formSubmitted = true;
    if (this.addGeneralListItemForm.invalid) {
      return;
    }

    this.listService.save({ generalListTypeId: this.listId, name: this.name.value }).subscribe({
      next: result => {
        this.ref.close(result);
      },
      error: err => {
        this.toastService.error(err.message);
      }
    });
  }

  closeModal(): void {
    this.ref.close();
  }

  get name(): FormControl {
    return this.addGeneralListItemForm.get('name') as FormControl;
  }

}
