<div [class]="!isModal ? 'form-section' : 'form-section__other flex flex-column gap-4'">
  <div class="flex justify-content-between align-items-center">
    <h3 class="form-section__title">Töökohad</h3>
    <p-button type="button"
              label="Lisa"
              severity="primary"
              (click)="addWorkplace()"
    >
    </p-button>
  </div>

  @if (formArray.value && formArray.controls.length) {
    <p-table [value]="[{}]">
      <ng-template pTemplate="header">
        <tr>
          <th>Juriidiline nimi</th>
          <th>Registrikood</th>
          <th>Allüksus</th>
          <th>Ametikoht</th>
          <th>&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        @for (workplace of formArray.controls; track $index) {
          <tr [formGroup]="getPatientProfession($index)">
            <td>
              <input pInputText
                     id="workplace{{$index}}"
                     type="text"
                     formControlName="workplace"
              />
            </td>
            <td>
              <input pInputText
                     type="text"
                     formControlName="workplaceRegisterNumber"
              />
            </td>
            <td>
              <input pInputText
                     type="text"
                     formControlName="workplaceSubdivision"
              />
            </td>
            <td>
              <input pInputText
                     type="text"
                     formControlName="profession"
              />
            </td>
            <td>
              <div class="float-right">
                <p-button type="button"
                          [rounded]="true"
                          icon="pi pi-times"
                          [text]="true"
                          severity="secondary"
                          medisConfirmAction
                          (confirmedAction)="removeWorkplace($event, $index)"
                          confirmMessage="Kas olete kindel, et soovite eemaldada {{ workplace.get('workplace')?.value?.length ? workplace.get('workplace')?.value : 'töökoha' }}?"
                >
                </p-button>
              </div>
            </td>
          </tr>
        }
      </ng-template>
    </p-table>
  }
</div>
