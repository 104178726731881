import { Component, OnInit } from '@angular/core';
import { IWorkIncapacityCertificate } from "@core/interfaces/work-incapacity-certificate.interface";
import { DashboardService } from "@core/services/dashboard.service";
import { ToastService } from "@core/services/toast.service";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";
import { IPrescription } from "@core/interfaces/prescription.interface";
import { Router } from "@angular/router";
import { IPrescriptionConfirmResponse } from "@core/interfaces/prescription-confirm-response.interface";
import { IMedicalCase } from "@core/interfaces/medical-case.interface";

enum TableViewType {
  ONLY_MY = 'ONLY_MY',
  TEAM = 'TEAM',
}

enum DocumentStatus {
  CONFIRMED = 'Confirmed',
}

enum DocumentType {
  PRESCRIPTIONS = 'prescriptions',
  WORK_INCAPACITY_CERTIFICATES = 'work-incapacity-certs',
  OPEN_MEDICAL_CASES = 'open-medical-cases',
}

type GetActionKeys = 'getPrescriptions' | 'getWorkIncapacityCerts' | 'getOpenMedicalCases';
type OpenActionKeys = 'openPrescription' | 'opencCoifw' | 'openCase';

@Component({
  selector: 'medis-medical-documents',
  templateUrl: './medical-documents.component.html',
})
export class MedicalDocumentsComponent implements OnInit {
  documents: IWorkIncapacityCertificate[] | IPrescription[] | IMedicalCase[] = [];
  documentType!: string;
  cols!: string[];

  documentMappings: Record<DocumentType, { getCols: () => string[], getAction: string, openAction: string }> = {
    [DocumentType.PRESCRIPTIONS]: {
      getCols: this.getPrescriptionCols.bind(this),
      getAction: 'getPrescriptions',
      openAction: 'openPrescription',
    },
    [DocumentType.WORK_INCAPACITY_CERTIFICATES]: {
      getCols: this.getWorkIncapacityCertsCols.bind(this),
      getAction: 'getWorkIncapacityCerts',
      openAction: 'opencCoifw',
    },
    [DocumentType.OPEN_MEDICAL_CASES]: {
      getCols: this.getOpenMedicalCasesCols.bind(this),
      getAction: 'getOpenMedicalCases',
      openAction: 'openCase',
    }
  };

  tableViews: { label: string; value: string }[] = [
    { label: 'Minu kinnitada', value: TableViewType.ONLY_MY },
    { label: 'Minu nimistutega seotud', value: TableViewType.TEAM },
  ];

  selectedView: TableViewType = TableViewType.ONLY_MY;

  loadings: { [key: string]: boolean } = {};

  constructor(
    private dashBoardService: DashboardService,
    private toastService: ToastService,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    this.documentType = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    this.getColumns();
    this.getData();
  }

  private getColumns(): void {
    this.cols = (this.documentMappings[this.documentType as DocumentType] || this.getOpenMedicalCasesCols).getCols.call(this);
  }

  private getPrescriptionCols(): string[] {
    return ['Patsient', 'Kinnitaja', 'Kuupäev', 'Kirjeldus', 'Kogus', 'Annustamine'];
  }

  private getWorkIncapacityCertsCols(): string[] {
    return ['Patsient', 'Periood', 'Arst'];
  }

  private getOpenMedicalCasesCols(): string[] {
    return ['Haigusjuhu number', 'Avamise kuupäev', 'Patsient', 'Arst'];
  }

  private getData() {
    const action = (this.documentMappings[this.documentType as DocumentType]?.getAction ||
      this.documentMappings[DocumentType.OPEN_MEDICAL_CASES].getAction) as GetActionKeys;
    (this.dashBoardService[action](
      new HttpParams().set('byTeam', this.selectedView === TableViewType.TEAM)
    ) as Observable<IPrescription[] | IWorkIncapacityCertificate[] | IMedicalCase[]>)
      .subscribe({
        next: (resolve: IWorkIncapacityCertificate[] | IPrescription[] | IMedicalCase[]) => {
          this.documents = resolve;
        },
        error: (err: Error) => {
          this.toastService.error(err.message);
        }
      })
  }

  onTableViewChange(view: TableViewType): void {
    this.selectedView = view;
    this.getData();
  }

  open(document: IWorkIncapacityCertificate | IPrescription): void {
    const action = (this.documentMappings[this.documentType as DocumentType]?.openAction ||
      this.documentMappings[DocumentType.OPEN_MEDICAL_CASES].openAction) as OpenActionKeys;
    this.externalMedicalCaseService[action](document.id);
  }

  confirm(document: IWorkIncapacityCertificate | IPrescription): void {
    const action = this.documentType === DocumentType.PRESCRIPTIONS
      ? 'confirmPrescription'
      : 'confirmWorkIncapacityCert';
    (this.dashBoardService[action](document.id) as Observable<IPrescriptionConfirmResponse | IWorkIncapacityCertificate>).subscribe({
      next: resolve => {
        if (this.documentType === DocumentType.PRESCRIPTIONS && (resolve as IPrescriptionConfirmResponse).messages) {
          const prescriptionResponse = resolve as IPrescriptionConfirmResponse;
          if (prescriptionResponse.messages.some(m => m.type === 'E')) {
            return this.toastService.error('Retsepti kinnitamine ebaõnnestus');
          }
        }

        document.status = DocumentStatus.CONFIRMED;
        this.toastService.success('Kinnitatud');
      },
      error: () => {
        this.toastService.error('Kinnitamine ebaõnnestus');
      }
    })
  }

  async confirmAll(): Promise<void> {
    const promises = this.documents.map(async (document: any) => {
      if (document.status !== DocumentStatus.CONFIRMED) {
        this.loadings[document.id] = true;
        try {
          const action = this.documentType === DocumentType.PRESCRIPTIONS
            ? 'confirmPrescription'
            : 'confirmWorkIncapacityCert';
          const res = await firstValueFrom(this.dashBoardService[action](document.id) as Observable<IPrescriptionConfirmResponse | IWorkIncapacityCertificate>);
          if (this.documentType === DocumentType.PRESCRIPTIONS && (res as IPrescriptionConfirmResponse).messages) {
            const prescriptionResponse = res as IPrescriptionConfirmResponse;
            if (prescriptionResponse.messages.some(m => m.type === 'E')) {
              return this.toastService.error('Ühe või mitme retsepti kinnitamine ebaõnnestus, sest retseptil pole piisavalt andmeid (soodustus on kontrollimata või pole täidetud annustamise välju). Palun kinnitage retsept(id) vajutades nupule "Ava muutmiseks"');
            }
          }
          document.status = DocumentStatus.CONFIRMED;
        } catch (error) {
          this.toastService.error('Retsepti kinnitamine ebaõnnestus.');
        } finally {
          this.loadings[document.id] = false;
        }
      }
    });

    try {
      await Promise.all(promises);
      this.toastService.success('Kinnitatud');
    } catch (error) {
      this.toastService.error('Kinnita kõik ebaõnnestus');
    }
  }

  protected readonly DocumentStatus = DocumentStatus;
  protected readonly DocumentType = DocumentType;
}
