import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const timeParts = value.split(':');
    if (timeParts.length < 2) return value;

    return `${timeParts[0]}:${timeParts[1]}`;
  }
}
