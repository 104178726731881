import { Component, Input, OnInit } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { OrderReferralETervisStatusEnum } from "@core/enums/order-referral-e-tervis-status.enum";

@Component({
  selector: 'medis-referral-etervis-status-label',
  templateUrl: './referral-etervis-status-label.component.html',
  styleUrl: './referral-etervis-status-label.component.scss'
})
export class ReferralEtervisStatusLabelComponent implements OnInit{
  @Input() eTervisStatus!: OrderReferralETervisStatusEnum;
  tagStyle!: string;

  private readonly sourceStyleMap: IMapping = {
    [OrderReferralETervisStatusEnum.FINISHED]: 'tag tag--secondary',
    [OrderReferralETervisStatusEnum.NOT_APPEAR]: 'tag tag--blue',
    [OrderReferralETervisStatusEnum.LOCKED]: 'tag tag--purple',
    [OrderReferralETervisStatusEnum.BY_INVITATION]: 'tag tag--blue',
    [OrderReferralETervisStatusEnum.OPEN]: 'tag tag--green',
    [OrderReferralETervisStatusEnum.RECEPTION_HAS_HAPPENED]: 'tag tag--blue',
    [OrderReferralETervisStatusEnum.OUTDATED]: 'tag tag--blue',
    [OrderReferralETervisStatusEnum.CANCELLED]: 'tag tag--blue',
  };

  ngOnInit() {
    this.tagStyle = `white-space-nowrap ${this.sourceStyleMap[this.eTervisStatus]}`;
  }
}
