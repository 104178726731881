 <p-table [value]="data"
          dataKey="freeTimeGroupId"
          [expandedRowKeys]="expandedRows"
          (onRowExpand)="onRowExpand($event)"
          (onRowCollapse)="onRowCollapse($event)"
          class="table table--today-plan">
    <ng-template pTemplate="header">
      <tr>
        @if(isFreeTimesLoaded){
          <th class="free-arrow pr-0"></th>
        }
        <th class="time-range">Aeg</th>
        <th class="location">Asukoht</th>
        <th class="patient">Patsient</th>
        <th class="service">Teenus</th>
        <th class="payer">Maksja</th>
        <th class="status">Staatus</th>
        <th class="actions"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-time let-expanded="expanded">
      <tr [class.row--highlight]="(time.orderServiceTimeStatus ?? time.orderServiceQueueStatus ?? time.orderServiceStatus) === OrderServiceStatus.PRESENT && !time.misAppointmentId"
          [class.row--highlight-free]="time.freeTimeGroupId"
      >
        @if(isFreeTimesLoaded){
          <td class="pr-0">
            @if(time.freeTimeGroupId){
              <p-button
                type="button"
                [pRowToggler]="time"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></p-button>
            }
          </td>
        }
        <td>
          @if(time.freeTimeGroupId){
            <div class="flex flex-row align-items-center gap-2">
              <div>{{ time.timeFrom | timeFormat }} - {{ time.timeTo | timeFormat }}</div>
              <span><p-badge value="L" [pTooltip]="'Lisa number'" severity="success" class="badge-outlined" [ngStyle]="{'visibility': 'hidden'}"/></span>
            </div>
          } @else if(time.orderServiceQueueId && time.orderServiceQueueStatus){
            <div class="flex flex-row align-items-center gap-2">
              <div>{{ time.range }}</div>
              <span><p-badge value="L" [pTooltip]="'Lisa number'" severity="success" class="badge-outlined"/></span>
            </div>
          } @else {
            <div class="flex flex-row align-items-center gap-2">
              <div>{{ time.range }}</div>
              <span><p-badge value="L" [pTooltip]="'Lisa number'" severity="success" class="badge-outlined" [ngStyle]="{'visibility': 'hidden'}"/></span>
            </div>
            }
        </td>
        <td>
          @if(time.freeTimeGroupId){
            {{ time.items?.[0].departmentShort }} {{ time.items?.[0].roomCode }}
          } @else{
            {{ time.departmentShort }} {{ time.roomCode }}
          }
        </td>
        <td>
          @if(!time.freeTimeGroupId) {
            <medis-patient-basic-data
              [patientName]="time.patientName"
              [patientCode]="time.patientIDcode"
              (onPatientClick)="openPatientView(time.patientId)"
            />
          }
        </td>
        <td>
          <div class="flex gap-2 align-items-center">
            @if(time.freeTimeGroupId && !expanded){
              <medis-service-badge [badges]="time.items?.[0].serviceBadges"></medis-service-badge>
              {{ time.items?.[0].serviceName }}&nbsp; @if(time.items.length > 1){
                (+{{ time.items.length - 1 }})
              }
            } @else if(time.freeTimeGroupId && expanded){
                <medis-service-badge [badges]="time.items?.[0].serviceBadges"></medis-service-badge>
                {{ time.items?.[0].serviceName }}
            } @else {
              <medis-service-badge [badges]="time.serviceBadges"></medis-service-badge>
              {{ time.serviceName }}&nbsp;
              @if (time.commentsCount && time.commentsCount > 0) {
                <p-button [rounded]="true"
                          icon="pi pi-comment"
                          [text]="true"
                          severity="secondary"
                          (click)="openCommentsByOrder(time)"/>
              }
              @if (time.complexServiceId) {
                <div class="text-muted">
                  {{ time.complexServiceName }}
                  <medis-service-type-label [type]="time.complexServiceComplexType"></medis-service-type-label>
                  <!--              <medis-service-parts-popover [serviceId]="time.complexServiceId"-->
                  <!--                                           [serviceType]="time.complexServiceComplexType"></medis-service-parts-popover>-->
                </div>
              }
            }
          </div>
        </td>
        <td>
          @if(!time.freeTimeGroupId && time.financialSourceName){
            <medis-financial-source-label [financialSourceId]="time.financialSourceId"></medis-financial-source-label>
          } @else if (time.freeTimeGroupId && expanded){
            <medis-financial-source-label [financialSourceId]="time.items?.[0].financialSourceId ?? time.items?.[0].financialSourceName"></medis-financial-source-label>
          }
        </td>
        <td>
          @if (time.orderServiceTimeStatus || time.orderServiceStatus) {
              <medis-order-service-status-label [status]="time.orderServiceQueueStatus ?? time.orderServiceTimeStatus ?? time.orderServiceStatus"
                                                [invoiceStatus]="time.invoiceStatus">
              </medis-order-service-status-label>
          }
        </td>
        <td>
          <div class="table__actions flex justify-content-end gap-3">
              @if (!time.freeTimeGroupId && presentReady(time) && isToday(time.date)) {
                <p-button label="Kohal"
                          (onClick)="setPresent(time)"
                          severity="primary"
                          styleClass="w-full font-bold py-2"
                          [outlined]="true"/>
              }
              @if (!time.freeTimeGroupId) {
                <p-button label="Ava"
                          (onClick)="openPatient(time)"
                          severity="secondary"
                          styleClass="w-full font-bold py-2"
                          [outlined]="true"/>
                <medis-order-actions [time]="time"
                                     (menuOpenChange)="onMenuOpenChange($event)"
                                     (onTimeChanged)="onTimeChanged($event)"
                                     (onTimeAdded)="refreshList.emit($event)"
                />
              }
            @if(time.freeTimeGroupId && time.items.length === 1 || time.freeTimeGroupId && expanded){
              <p-button label="Broneeri"
                        severity="secondary"
                        styleClass="w-full font-bold py-2"
                        (click)="navigateToReservation(time.items?.[0].serviceId, time.items?.[0].personId, time.timeFrom, time.timeTo, time.items?.[0].date)"
                        [outlined]="true"/>
            }
          </div>
        </td>
      </tr>
    </ng-template>
   <ng-template pTemplate="rowexpansion" let-order>
     <tr class="border-0">
       <td colspan="8" class="p-0 border-0">
         <p-table [value]="order.items.slice(1)" dataKey="freeTimeGroupId">
           <ng-template pTemplate="body" let-item>
             <tr class="border-0" [class.row--highlight-free]="item">
                 <td class="free-arrow pr-0"></td>
                 <td class="time-range"></td>
                 <td class="location"></td>
                 <td class="patient"></td>
                 <td class="service">
                   <div class="flex gap-2 align-items-center">
                     <medis-service-badge [badges]="item.serviceBadges"></medis-service-badge>
                     {{ item.serviceName }}
                   </div>
                 </td>
                 <td class="payer">
                   <medis-financial-source-label [financialSourceId]="item.financialSourceId ?? item.financialSourceName"></medis-financial-source-label>
                 </td>
                 <td class="status"></td>
                 <td class="actions">
                   <div class="flex justify-content-end">
                     <p-button label="Broneeri"
                               severity="secondary"
                               (click)="navigateToReservation(item.serviceId, item.personId, order.timeFrom, order.timeTo, item.date)"
                               styleClass="w-full font-bold py-2"
                               [outlined]="true"/>
                   </div>
                 </td>
               </tr>
           </ng-template>
         </p-table>
       </td>
     </tr>
   </ng-template>
   <ng-template pTemplate="emptymessage">
     <tr>
       <td [attr.colspan]="7">Midagi pole leitud.</td>
     </tr>
   </ng-template>
  </p-table>

