@if (services.length > 0) {

  <medis-service-select-list
    [(ngModel)]="selectedServiceId"
    (onServiceSelect)="setService($event)"
    [services]="services"
  >

  </medis-service-select-list>

} @else if (loading) {
    <p-skeleton height="2.857rem">
    </p-skeleton>
}


<div class="p-dialog-footer justify-content-end sticky-footer">
  <p-button
    label="Sulge"
    [outlined]="true"
    severity="secondary"
    (onClick)="closeModal()"
  ></p-button>
  <p-button
    label="Salvesta"
    (click)="submit()"
  ></p-button>
</div>

