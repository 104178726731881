import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'nl2br',
  standalone: true
})
export class Nl2brPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(msg?: string, isXhtml: boolean = true): SafeHtml {
    if (!msg) return '';

    const breakTag = isXhtml ? '<br />' : '<br>';
    const formattedMsg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');

    return this.sanitizer.bypassSecurityTrustHtml(formattedMsg);
  }
}
