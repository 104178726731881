import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from "@core/services/patient.service";
import { ToastService } from "@core/services/toast.service";
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { ChangeTimeComponent } from "@shared/components/change-time/change-time.component";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";

@Component({
  selector: 'medis-implementation-plan',
  templateUrl: './implementation-plan.component.html',
  styleUrl: './implementation-plan.component.scss'
})
export class ImplementationPlanComponent implements OnInit {
  @Input() patientId!: number;

  order!: IOrder;
  expandedRows: { [key: string]: boolean } = {};

  constructor(
    private patientService: PatientService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.patientService.getPatientPhysiotherapy(this.patientId)
      .subscribe({
        next: result => {
          this.order = result;
        },
        error: (err) => {
          if (err.error && err.error.errors?.length > 0) {
            err.error.errors.forEach((err: Error) => {
              this.toastService.error(err.message);
            })
          } else {
            this.toastService.error(err.message);
          }
        }
      })
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.orderServiceId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.orderServiceId];
  }

  changeTime(orderService: IOrderService): void {
    const data: IOrderReservationConfig = {
      serviceId: orderService?.serviceId,
      providerId: this.order?.providerId,
      patient: this.order?.patient,
      times: orderService?.orderServiceTimes,
      orderService,
      bookingCount: orderService?.repeat,
      disableService: true,
      showMultiple: true,
      returnSavedData: true
    };

    const dialogRef = this.modalService.openModal({
      component: ChangeTimeComponent,
      size: ModalSize.EXTRA_LARGE,
      header: 'Vabad ajad',
      data,
      styleClass: 'order-reservation-modal',
    })

    dialogRef.onClose.subscribe((result: IOrderService) => {
      if(result){
        const index = this.order.orderServices.findIndex(os => os.orderServiceId === orderService.orderServiceId);
        if (index !== -1) {
          this.order.orderServices[index].orderServiceTimes = result.orderServiceTimes;
        }
      }
    });
  }
}
