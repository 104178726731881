<div [formGroup]="formGroup"
     class="add-contact-modal flex flex-column w-full gap-4 py-4"
>
  <div class="modal__col w-full">
    <medis-general-select-list [listId]="GeneralList.CONTACT_TYPE"
                               formControlName="type"
                               appendTo="body"
                               labelName="Seos"
                               (selectedGeneralListItem)="setType($event)"
    ></medis-general-select-list>
    @if (type.invalid && type.dirty && type.errors) {
      <small class="p-error">Seos on kohustuslik</small>
    }
  </div>
  <div class="modal__col w-full">
    <medis-patient-search-list formControlName="contactPerson"
                               (patientSelected)="patientSelected($event)"
                               label="Isik"
    ></medis-patient-search-list>
    @if (contactPerson.invalid && contactPerson.dirty && contactPerson.errors) {
      <small class="p-error">Isik on kohustuslik</small>
    }
  </div>
  <p-message severity="info"
             styleClass="w-full flex justify-content-start border-0"
             text="Tellimusel puudub patsiendi ja tellija muutmiste ajalugu"
  ></p-message>
  <div class="modal__col w-full flex gap-2 justify-content-end">
    <p-button type="button"
              styleClass="p-button-secondary"
              [outlined]="true"
              (click)="closeModal()"
              label="Sulge"
    ></p-button>
    <p-button type="button"
              styleClass="p-button-secondary"
              label="Lisa uus isik"
              (click)="createNewContact()"
    ></p-button>
    <p-button type="button"
              label="Salvesta"
              (click)="submit()"
    ></p-button>
  </div>
</div>
