import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IDevice } from "../interfaces/device.interface";
import { IDeviceType } from "../interfaces/device-type.interface";
import { IDeviceCategory } from "../interfaces/device-category.interface";
import { IDeviceTypeAdd } from "@core/interfaces/device-type-add.interface";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/devices`;
  }

  public getAllDevices(page: string = '1', pageSize: string = '1000'): Observable<IDevice[]> {
    return this.http.get<IDevice[]>(`${this.BACKEND_URL}/${page}/${pageSize}`);
  }

  public get(id: number): Observable<IDevice> {
    return this.http.get<IDevice>(`${this.BACKEND_URL}/${id}`);
  }

  public save(device: IDevice): Observable<IDevice> {
    return this.http.post<IDevice>(`${this.BACKEND_URL}`, device);
  }

  public saveType(deviceType: IDeviceTypeAdd): Observable<IDeviceType> {
    return this.http.post<IDeviceType>(`${this.BACKEND_URL}/types`, deviceType);
  }

  // public saveCategory(serviceCategory: IDeviceCategory): Observable<IDeviceCategory> {
  //   return this.http.post<IDeviceCategory>(`${this.BACKEND_URL}/categories`, serviceCategory);
  // }

  public getTypes(): Observable<IDeviceType[]> {
    return this.http.get<IDeviceType[]>(`${this.BACKEND_URL}/types`);
  }

  public getCategories(): Observable<IDeviceCategory[]> {
    return this.http.get<IDeviceCategory[]>(`${this.BACKEND_URL}/categories/all`);
  }

  // public getRelatedProviders(deviceId: number): Observable<IPerson[]> {
  //   return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/${deviceId}`);
  // }

  // public getPlanTimes(deviceId: number, date: string): Observable<IPlanTime[]> {
  //   return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/planTimes/${deviceId}/${date}`);
  // }

  // public searchPlanTimes(deviceId?: number, roomId?: number, personId?: number, startDate?: string, endDate?: string): Observable<IReferral[]> {
  //   return this.http.get<IReferral[]>(`${this.BACKEND_URL}/planTimes/${deviceId}/${roomId}/${personId}/${startDate}/${endDate}/`);
  // }

  // public savePlanTimes(planTimes: IPlanTime[]): Observable<any> {
  //   return this.http.post<any>(`${this.BACKEND_URL}/planTimes/save`, planTimes);
  // }

  // public getDevicePersonServices(deviceId: number, personId: number): Observable<IPersonService[]> {
  //   return this.http.get<IPersonService[]>(`${this.BACKEND_URL}/${deviceId}/person/${personId}/services`);
  // }

  // public getDevicesByRoomAndProvider(roomId: number, providerId: number): Observable<IDevice[]> {
  //   return this.http.get<IDevice[]>(`${this.BACKEND_URL}/room/${roomId}/person/${providerId}`);
  // }

  // public getDevicesForSelect(): Observable<IDevice[]> {
  //   return this.http.get<IDevice[]>(`${this.BACKEND_URL}/forSelect`);
  // }
}
