import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { PriceService } from "@core/services/price.service";
import { PriceListDictionary } from "@core/interfaces/price.interface";
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-price-select-list',
  templateUrl: './price-select-list.component.html',
  styleUrl: './price-select-list.component.scss'
})
export class PriceSelectListComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() formSubmitted = false;

  pricesMap!: PriceListDictionary;
  selectedPrices: any[] = [];
  prices: any[] = [];

  constructor(
    private priceListService: PriceService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.priceListService.groupFinanceCode().subscribe({
      next: resolve => {
        this.prices = Object.keys(resolve);
        this.pricesMap = resolve;
        this.selectedPrices = this.financeCode.value && this.pricesMap[this.financeCode.value].length > 0
          ? this.pricesMap[this.financeCode.value].filter(item => item.validTo ? new Date() <= new Date(item.validTo) : true)
          : [];
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  updateSelectedPriceList(): void {
    this.selectedPrices = this.pricesMap[this.financeCode.value].length > 0
      ? this.pricesMap[this.financeCode.value].filter(item => item.validTo ? new Date() <= new Date(item.validTo) : true)
      : [];
  }

  get financeCode(): FormControl {
    return this.form.get('financeCode') as FormControl;
  }

  protected readonly Object = Object;
}
