<div class="dialog__content flex flex-column gap-4">
    <div class="dialog__table">
      <h4 class="mt-0 mb-2">Staatuse ajalugu</h4>
      @if(orderServiceLogs.length === 0) {
        <p-messages severity="info" class="table-message">
          <ng-template pTemplate>
            @for(msg of orderServiceLogMessage; track msg) {
              <div>
                <span class="p-message-summary">{{ msg.summary }}</span>
              </div>
            }
          </ng-template>
        </p-messages>
      }
      @if(orderServiceLogs.length > 0) {
      <p-table styleClass="p-datatable--round" [value]="orderServiceLogs">
        <ng-template pTemplate="header">
          <tr>
            <th>Aeg</th>
            <th>Kuupäev</th>
            <th>Info</th>
            <th>Sisestaja</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <div class="flex gap-2 align-items-center">
                @if(item.oldStatus !== '' && item.oldStatus){
                  <p-tag
                    [styleClass]="'white-space-nowrap tag tag--small ' + (getStatusInfo(item.oldStatus).class || '')"
                    rounded="true"
                    [value]="getStatusInfo(item.oldStatus).name || ''"
                  ></p-tag>
                  <span class="pi pi-arrow-right"></span>
                }
                <p-tag
                  [styleClass]="'white-space-nowrap tag tag--small ' + (getStatusInfo(item.newStatus).class || '')"
                  rounded="true"
                  [value]="getStatusInfo(item.newStatus).name || ''"
                ></p-tag>
              </div>
            </td>
            <td>
              <span class="white-space-nowrap">{{ item.created | DateTime }}</span>
            </td>
            <td><div [innerHTML]="item.info"></div></td>
            <td>{{ item.userName }}</td>
          </tr>
        </ng-template>
      </p-table>
      }
    </div>
  @if(orderServiceInvoiceLogs.length > 0) {
    <div class="dialog__table">
      <h4 class="mt-0 mb-2">Maksmise ajalugu</h4>
      <p-table styleClass="p-datatable--round" [value]="orderServiceInvoiceLogs">
        <ng-template pTemplate="header">
          <tr>
            <th>Aeg</th>
            <th>Kuupäev</th>
            <th>Info</th>
            <th>Sisestaja</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <div class="flex gap-2 align-items-center">
                @if(item.oldStatus !== ''){
                  <p-tag
                    [styleClass]="'white-space-nowrap tag tag--small ' + (getInvoiceStatusInfo(item.oldStatus).class)"
                    rounded="true"
                    [value]="getInvoiceStatusInfo(item.oldStatus).name"
                  ></p-tag>
                }
                <span class="pi pi-arrow-right"></span>
                <p-tag
                  [styleClass]="'white-space-nowrap tag tag--small ' + (getInvoiceStatusInfo(item.newStatus).class || '')"
                  rounded="true"
                  [value]="getInvoiceStatusInfo(item.newStatus).name || ''"
                ></p-tag>
              </div>
            </td>
            <td>
              <span class="white-space-nowrap">{{ item.created | DateTime }}</span>
            </td>
            <td>{{ item.info }}</td>
            <td>{{ item.userName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
    <div class="dialog__table">
      <h4 class="mt-0 mb-2">Kirjade saatmise ajalugu</h4>
      @if(orderServiceNotificationLogs.length === 0) {
        <p-messages severity="info" class="table-message">
          <ng-template pTemplate>
            @for(msg of orderServiceNotificationMessage; track msg) {
              <div>
                <span class="p-message-summary">{{ msg.summary }}</span>
              </div>
            }
          </ng-template>
        </p-messages>
      }
      @if(orderServiceNotificationLogs.length > 0) {
      <p-table styleClass="p-datatable--round" [value]="orderServiceNotificationLogs">
        <ng-template pTemplate="header">
          <tr>
            <th>Kuupäev</th>
            <th>Kirja tüüp</th>
            <th>Aadress</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-email>
          <tr>
            <td>{{ email.created | DateTime }}</td>
            <td>{{ email.subject }}</td>
            <td>{{ email.recipientAddress }}</td>
          </tr>
        </ng-template>
      </p-table>
      }
    </div>
  <div class="flex justify-content-end gap-2 p-dialog-footer">
    <p-button
      outlined="true"
      severity="secondary"
      label="Sulge"
      (click)="closeModal()"
    ></p-button>
  </div>
</div>
