<div class="patient-form container--full flex justify-content-center">
  <form class="flex flex-column gap-4 py-4"
        role="form"
        [formGroup]="patientForm"
        (ngSubmit)="submit()"
        #ngForm="ngForm"
        style="max-width: 1025px; width: 100%">
    @if (!isModal) {
      <medis-page-header [title]="'Patsiendi andmete muutumine'"
                         [breadcrumbs]="breadcrumbs"
                         [isPatientEdit]="true"
                         [lastModifiedDate]="patient.modified"
      ></medis-page-header>
    }

    @if (generalDataGroup) {
      <app-patient-general-data [formGroup]="generalDataGroup"
                                [patient]="patient"
      ></app-patient-general-data>
    }

    @if (additionalDataGroup) {
      <app-patient-edit-form [formGroup]="additionalDataGroup"
      ></app-patient-edit-form>
    }

    <div class="form-section__row">
      <div class="form-section__col flex justify-content-end gap-2">
        <p-button type="button"
                  label="Katkesta"
                  severity="secondary"
                  [outlined]="true"
                  (click)="back()"
        ></p-button>
        <p-button type="submit"
                  label="Salvesta"
                  severity="primary"
        ></p-button>
      </div>
    </div>
  </form>
</div>
