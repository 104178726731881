import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IAddress } from "@core/interfaces/address.interface";
import { IPlanTime } from "@core/interfaces/plan-time.interface";

@Injectable({
  providedIn: 'root'
})
export class PlanTimeService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/plantimes`;
  }

  public getPersonServiceDepartments(serviceId?: number, personId?: number): Observable<IAddress[]> {

    let params = new HttpParams();

    if(serviceId) params = params.set('serviceId', serviceId);
    if(personId) params = params.set('personId', personId);


    return this.http.get<IAddress[]>(`${this.BACKEND_URL}/addresses`, {params: params});
  }

  public getPlanTimeByTime(params: HttpParams): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantime`, {params});
  }

  public getPlanTimeByPersonAndTime(providerId: number, date: string): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/${providerId}/${date}`);
  }

  public getCalendarPlanTimes(providerId: number, dateFrom: string, dateUpto: string): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/calendar/${providerId}/${dateFrom}/${dateUpto}`);
  }

  public getPlanTimeDayLogs(providerId: number, date: string): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/${providerId}/${date}/logs`);
  }

  public savePlanTime(planTime: IPlanTime): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/save`, planTime);
  }

  public copyPlanTime(config: any): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/copy`, config);
  }

  public updatePlanTimeStatus(params: HttpParams): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/updateStatus`,  {}, { params });
  }

  public deletePlanTime(params: HttpParams): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}`, {params});
  }

}
