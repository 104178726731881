<form [formGroup]="form">

    <p-floatLabel>
      <p-dropdown
        id="financeCode"
        class="w-full"
        styleClass="w-full"
        [ngClass]="{'ng-invalid ng-dirty' : (formSubmitted && financeCode.errors)}"
        [options]="prices"
        [filter]="true"
        formControlName="financeCode"
        (onChange)="updateSelectedPriceList()"
      />
      <label for="financeCode">Finantskood</label>
    </p-floatLabel>
    @if (formSubmitted && financeCode.errors) {
    <small class="p-error"> Finantskood on kohustuslik </small>
    } @if (selectedPrices.length > 0) {
    <p-table styleClass="mt-4" [value]="selectedPrices">
      <ng-template pTemplate="header">
        <tr>
          <th>Hind</th>
          <th>Maksja</th>
          <th>Kehtivus</th>
          <th>Kood</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-priceList>
        <tr>
          <td>{{ priceList.price }}</td>
          <td>{{ priceList.payerTypeName }}</td>
          <td>
            {{ priceList.validFrom | dateTimeDisplay : "dd.MM.yyyy" }} -
            {{ priceList.validTo | dateTimeDisplay : "dd.MM.yyyy" }}
          </td>
          <td>{{ priceList.misCode }}</td>
        </tr>
      </ng-template>
    </p-table>
    }

</form>
