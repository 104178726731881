<p-pickList
  [source]="sourceServices"
  [target]="targetServices"
  sourceHeader="Saadaval"
  targetHeader="Valitud"
  (onSourceSelect)="handleSourceClick($event)"
  (onTargetSelect)="handleTargetClick($event)"
  (onMoveAllToSource)="onTargetChange()"
  (onMoveAllToTarget)="onTargetChange()"
  [responsive]="true"
  [showSourceControls]="false"
  [showTargetControls]="false"
  filterBy="name"
  sourceFilterPlaceholder="Filter by name"
  targetFilterPlaceholder="Filter by name"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  breakpoint="1400px"
  styleClass="p-picklist-hide-move-to"
>
  <ng-template let-service pTemplate="item">
    <div class="h-3rem flex align-items-center gap-1" (click)="onItemClick(service)" >
      <medis-service-badge [badges]="service.serviceBadges"/>
      <div>{{ service.displayName ?? service.name }}</div>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessagetarget">
    @if (loading) {
      <div class="flex flex-column gap-4">
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="emptymessagesource">
    @if (loading) {
      <div class="flex flex-column gap-4">
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
        <p-skeleton height="3rem"></p-skeleton>
      </div>
    }
  </ng-template>
</p-pickList>
@if (targetServices.length < 1 && formSubmitted && required) {
  <small class="p-error">
    Vähemalt üks teenus peab olema lisatud
  </small>
}
