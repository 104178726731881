import { Component, OnInit } from '@angular/core';
import { IService } from "@core/interfaces/service.interface";
import { PatientService } from "@core/services/patient.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { OrderService } from "@core/services/order.service";
import { finalize } from "rxjs";

@Component({
  selector: 'app-create-new-referral',
  templateUrl: './create-new-referral.component.html',
  styleUrl: './create-new-referral.component.scss'
})
export class CreateNewReferralComponent implements OnInit {
  selectedServiceId?: number;
  services: IService[] = [];
  loading = false;
  readonly patientId?: number;
  private selectedService?: IService;

  constructor(
    private patientService: PatientService,
    private orderService: OrderService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.patientId = config.data.patientId;
  }

  ngOnInit() {
    this.loading = true;
    this.patientService.getReferralsServices()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response: IService[]) => {
          this.services = response;
        }
      })
  }

  submit() {

    let objectToSave = {
      orderId: 0,
      patientId: this.patientId!,
      orderServices: [
        {
          serviceId: this.selectedServiceId!
        },
      ]
    };

    this.orderService.save(objectToSave).subscribe({
      next: (result: any) => {
        this.ref.close({
          order: result,
          service: this.selectedService
        });
      }
    })
  }

  closeModal() {
    this.ref.close(false);
  }

  setService(service: IService) {
    this.selectedService = service;
  }
}
