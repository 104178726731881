import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InputSwitchChangeEvent } from "primeng/inputswitch";

@Component({
  selector: 'medis-another-payer',
  templateUrl: './another-payer.component.html',
  styleUrl: './another-payer.component.scss'
})
export class AnotherPayerComponent {
  @Input() form!: FormGroup;
  @Input() sendLinkReady: boolean = false;

  @Output() onPayerToggle: EventEmitter<FormGroup> = new EventEmitter();

  anotherPayerToggle(event: InputSwitchChangeEvent): void {
    if (event.checked) {
      this.payerEmail.setValidators([Validators.required, Validators.email]);
      this.payerPhone.setValidators([Validators.required]);
    } else {
      this.payerEmail.removeValidators([Validators.required, Validators.email]);
      this.payerPhone.removeValidators([Validators.required]);
    }
    this.payerEmail.updateValueAndValidity();
    this.payerPhone.updateValueAndValidity();

    this.onPayerToggle.emit(this.form);
  }

  get anotherPayer(): FormControl {
    return this.form.get('anotherPayer') as FormControl;
  }

  get payerEmail(): FormControl {
    return this.form.get('payerEmail') as FormControl;
  }

  get payerPhone(): FormControl {
    return this.form.get('payerPhone') as FormControl;
  }
}
