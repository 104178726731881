import { Component } from '@angular/core';
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'order-service-queue-label',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TooltipModule,
  ],
  templateUrl: './order-service-queue-label.component.html',
})
export class OrderServiceQueueLabelComponent {
}
