<aside
  class="sidebar flex h-screen overflow-hidden"
  [class.w-19rem]="sidebarOpen"
  [class.w-0]="!sidebarOpen" >
  <div class="p-2 w-full h-full overflow-auto pb-8">
    <p-menu styleClass="w-full sidebar-menu border-noround pt-1" [model]="menuItemsTop">
      <ng-template pTemplate="item" let-item>
        <li class="border-round-md" [ngClass]="{'p-menuitem--active': isActiveItem(item)}" (click)="setActiveItem(item)">
          <a [routerLink]="item.routerLink" class="p-menuitem-link gap-2">
            <i [class]="item.icon"></i>
            <span>{{ item.label }}</span>
          </a>
        </li>
      </ng-template>
    </p-menu>
    <p-menu styleClass="w-full sidebar-menu border-noround border-top-1 border-white-alpha-20" [model]="menuItemsBottom">
      <ng-template pTemplate="item" let-item>
        <li [ngClass]="{'p-menuitem--active': isActiveItem(item)}" (click)="setActiveItem(item)">
          <a (click)="item.command ? item.command() : null" class="p-menuitem-link gap-2">
            <i [class]="item.icon"></i>
            <span>{{ item.label }}</span>
          </a>
        </li>
      </ng-template>
    </p-menu>
  </div>
</aside>
