import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IAddress } from "@core/interfaces/address.interface";
import { IPlanTime } from "@core/interfaces/plan-time.interface";

@Injectable({
  providedIn: 'root'
})
export class PlanTimeService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/plantimes`;
  }

  public getPersonServiceDepartments(serviceId?: number, personId?: number): Observable<IAddress[]> {

    let params = new HttpParams();

    if(serviceId) params = params.set('serviceId', serviceId);
    if(personId) params = params.set('personId', personId);


    return this.http.get<IAddress[]>(`${this.BACKEND_URL}/addresses`, {params: params});
  }

  public getPlanTimeByTime(params: HttpParams): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantime`, {params});
  }

}
