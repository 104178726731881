import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ServiceService } from "@core/services/service.service";
import { IBrochure } from "@core/interfaces/brochure.interface";
import { BrochureType } from "@core/enums/brochure-type";
import { BrochureTypePipe } from "@shared/pipes/brochure-type.pipe";
import { IBrochureTranslate } from "@core/interfaces/brochure-translate.interface";
import { ToastService } from "@core/services/toast.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

enum BrochureName {
  ESTONIAN = 'et',
  RUSSIAN = 'ru',
  FINNISH = 'fi',
  ENGLISH = 'en'
}

@Component({
  selector: "medis-brochure-add-modal",
  templateUrl: "./brochure-add.modal.html",
  providers: [BrochureTypePipe]
})
export class BrochureAddModal implements OnInit {
  tableViews = [
    { label: 'Eesti keeles', value: BrochureName.ESTONIAN },
    { label: 'Vene keeles', value: BrochureName.RUSSIAN },
    { label: 'Soome keeles', value: BrochureName.FINNISH },
    { label: 'Inglise keeles', value: BrochureName.ENGLISH },
  ];
  selectedView = BrochureName.ESTONIAN;
  @Output() saveBrochure = new EventEmitter<IBrochure | null>();
  brochures: IBrochure[] = [];
  brochureOptions: { label: string; type: string; value: IBrochure }[] = [];
  selectedBrochure: IBrochure | null = null;
  selectedBrochureType: string | null = null;
  selectedBrochureTranslate: IBrochureTranslate | null = null;
  selectedBrochureTranslateName: string | null = null;
  selectedBrochureTranslateDescription: string | null = null;
  brochureTypes: string[] = [];


  constructor(private serviceService: ServiceService,
              private brochureTypePipe: BrochureTypePipe,
              private toastService: ToastService,
              public config: DynamicDialogConfig,
              public ref: DynamicDialogRef) {
    this.selectedBrochure = this.config.data ? { ...this.config.data } : null;
  }
  ngOnInit() {
    this.serviceService.getBrochures().subscribe({
      next: (response: IBrochure[]) => {
        this.brochures = response;
        this.transformBrochureOptions();
      },
      error: (error: Error) => {
        console.error("Error fetching brochures", error);
      }
    });
    this.brochureTypes = Object.values(BrochureType).map(type =>
      this.brochureTypePipe.transform(type)
    );
    if(this.selectedBrochure){
      this.onBrochureChange(this.selectedBrochure);
    }
  }

  transformBrochureOptions() {
    this.brochureOptions = this.brochures.map(brochure => ({
      label: brochure.brochureTranslates[0]?.name || '',
      type: this.brochureTypePipe.transform(brochure.brochureType) || '',
      value: brochure,
    }));
  }

  onBrochureChange(brochure: IBrochure){
    if(brochure){
      this.selectedBrochureType = this.brochureTypes.find(type => type === this.brochureTypePipe.transform(this.selectedBrochure!.brochureType)) ?? null;
      this.selectedBrochureTranslate = this.selectedBrochure?.brochureTranslates.find((translate: IBrochureTranslate) =>  this.selectedView === translate.language ) ?? null;
      if(this.selectedBrochureTranslate){
        this.selectedBrochureTranslateName = this.selectedBrochureTranslate!.name
        this.selectedBrochureTranslateDescription = this.selectedBrochureTranslate!.description
      }
    } else {
      this.resetBrochureDetails();
    }
  }

  resetBrochureDetails() {
    this.selectedBrochureTranslate = null;
    this.selectedBrochureTranslateName = null;
    this.selectedBrochureTranslateDescription = null;
    this.selectedBrochureType = null;
  }

  onLanguageChange(){
    this.selectedBrochureTranslate = this.selectedBrochure?.brochureTranslates.find((translate: IBrochureTranslate) =>  this.selectedView === translate.language ) ?? null;
    if(this.selectedBrochureTranslate){
      this.selectedBrochureTranslateName = this.selectedBrochureTranslate!.name
      this.selectedBrochureTranslateDescription = this.selectedBrochureTranslate!.description
    } else {
      this.selectedBrochureTranslateName = null;
      this.selectedBrochureTranslateDescription = null;
    }
  }

  onSave() {
    if (this.selectedBrochureType) {
      // Update brochure details
      this.selectedBrochure!.brochureType = this.selectedBrochureType;

      if (this.selectedBrochureTranslate) {
        this.selectedBrochureTranslate.name = this.selectedBrochureTranslateName || '';
        this.selectedBrochureTranslate.description = this.selectedBrochureTranslateDescription || '';
      }

      this.ref.close(this.selectedBrochure);
    } else {
      this.toastService.error("Palun valige tüüp!");
    }
  }

  closeModal(){
    this.ref.close();
  }
}
