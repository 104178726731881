import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { ToastService } from "@core/services/toast.service";
import { IPersonService } from "@core/interfaces/person-service.interface";
import { IDevice } from "@core/interfaces/device.interface";
import { finalize } from "rxjs";
import { PickListSourceSelectEvent, PickListTargetSelectEvent } from "primeng/picklist";

@Component({
  selector: 'medis-service-picker',
  templateUrl: './service-picker.component.html',
  styleUrl: './service-picker.component.scss'
})
export class ServicePickerComponent implements OnInit {
  @Input() formSubmitted = false;
  @Input() required = false;
  @Input() includeNames = false;
  @Input() selectedServiceIds: number[] = [];
  @Input() selectedServiceIdsWithNames: { id: number, name: string }[] = [];
  @Input() params!: HttpParams;
  @Input() personServices?: IPersonService[];
  @Input() devices?: IDevice[];
  @Input() fetchData? = true;
  loading = false;

  @Output() selectedServiceIdsChange = new EventEmitter<number[]>();
  @Output() selectedServiceIdsWithNamesChange = new EventEmitter<{ id: number, name: string }[]>();

  sourceServices: IService[] | IPersonService[] | IDevice[] = [];
  targetServices: IService[] | IPersonService[] | IDevice[] = [];
  selectedItems: any[] = [];

  constructor(
    private serviceService: ServiceService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (this.fetchData) {
      this.getServices();
    }
    if (this.personServices && this.includeNames) {
      if (this.selectedServiceIdsWithNames.length > 0) {
        this.sourceServices = [...this.personServices].filter(service =>
          !this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
        );
        this.targetServices = this.personServices.filter(service =>
          this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
        );
      } else {
        this.sourceServices = this.personServices;
      }
    }
    if (this.devices && this.includeNames) {
      const devicesWithFullName = this.devices.map(device => ({
        ...device,
        displayName: `${device.name} ${device.deviceCode}`
      }));

      if (this.selectedServiceIdsWithNames.length > 0) {
        this.sourceServices = devicesWithFullName.filter(device =>
          !this.selectedServiceIdsWithNames.some(selected => selected.id === device.deviceId)
        );
        this.targetServices = devicesWithFullName.filter(device =>
          this.selectedServiceIdsWithNames.some(selected => selected.id === device.deviceId)
        );
      } else {
        this.sourceServices = devicesWithFullName;
      }
    }

  }

  onItemClick(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index === -1) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  private getServices(): void {
    this.loading = true;
    this.serviceService.getServicesForSelect(this.params)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: services => {
          if (this.includeNames) {
            if (this.selectedServiceIdsWithNames.length > 0) {
              this.sourceServices = services.filter(service =>
                !this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
              );
              this.targetServices = services.filter(service =>
                this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
              );
            } else {
              this.sourceServices = services;
            }
            this.cdr.markForCheck();
          } else {
            if (this.selectedServiceIds.length > 0) {
              this.sourceServices = services.filter(service => !this.selectedServiceIds.includes(service.serviceId));
              this.targetServices = services.filter(service => this.selectedServiceIds.includes(service.serviceId));
            } else {
              this.sourceServices = services;
            }
            this.cdr.markForCheck();
          }
        },
        error: err => {
          this.toastService.error(err.message);
        }
      })
  }

  onTargetChange(): void {
    const selectedItemsWithNames = this.targetServices.map(service => {
      const id = (service as any).serviceId ?? (service as any).deviceId;
      const name = service.name;
      return { id, name };
    });
    if (this.includeNames) {
      this.selectedServiceIdsWithNamesChange.emit(selectedItemsWithNames);
    } else {
      this.selectedServiceIds = selectedItemsWithNames.map(item => item.id);
      this.selectedServiceIdsChange.emit(this.selectedServiceIds);
    }
    this.cdr.markForCheck();
  }

  handleSourceClick($event: PickListSourceSelectEvent): void {
    const selectedItem = $event.items[0];
    if (selectedItem) {
      this.targetServices.push(selectedItem);
      const index = this.sourceServices.indexOf(selectedItem);
      this.sourceServices.splice(index, 1);
      this.onTargetChange();
      $event.items.length = 0;
    }
  }

  handleTargetClick($event: PickListTargetSelectEvent): void {
    const selectedItem = $event.items[0];
    if (selectedItem) {
      this.sourceServices.push(selectedItem);
      const index = this.targetServices.indexOf(selectedItem);
      this.targetServices.splice(index, 1);
      this.onTargetChange();
      $event.items.length = 0;
    }
  }
}
