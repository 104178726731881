<div>
  <div class="flex flex-column w-full gap-4 pb-2 pt-4">
    <p-floatLabel>
      <p-dropdown
        id="brochure"
        [options]="brochureOptions"
        [showClear]="true"
        [filter]="true"
        filterBy="label"
        styleClass="w-full"
        [(ngModel)]="selectedBrochure"
        (ngModelChange)="onBrochureChange($event)"
      >
      <ng-template let-brochure pTemplate="selectedItem">
        <span class="brochure-selected">
          {{ brochure.label }} | {{ brochure.type }}
        </span>
      </ng-template>
      <ng-template let-brochure pTemplate="item">
        <span class="brochure-item">
          {{ brochure.label }} | {{ brochure.type }}
        </span>
      </ng-template>
      </p-dropdown>
      <label for="brochure">Brošuur</label>
    </p-floatLabel>

    <p-floatLabel>
      <p-dropdown
        id="brochure-type"
        styleClass="w-full"
        [options]="brochureTypes"
        [(ngModel)]="selectedBrochureType"
      />
      <label for="brochure-type">Tüüp</label>
    </p-floatLabel>
  </div>
  <p-divider/>
  <div>
    <div class="flex flex-column w-full gap-4 pt-2">
      <p-selectButton
        [options]="tableViews"
        [(ngModel)]="selectedView"
        (onChange)="onLanguageChange()"
        styleClass="flex white-space-nowrap"
      ></p-selectButton>
      <div class="form-section__col flex flex-column gap-4 w-full">
        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            [(ngModel)]="selectedBrochureTranslateName"
            id="brochure-name"/>
          <label for="brochure-name">Nimetus ({{ selectedView }})</label>
        </p-floatLabel>
      </div>
      <div class="form-section__col flex flex-column gap-4 w-full">
        <p-floatLabel>
              <textarea
                rows="5"
                cols="120"
                [value]="''"
                class="w-full"
                pInputTextarea
                [(ngModel)]="selectedBrochureTranslateDescription"
                id="brochure-description"
                [autoResize]="true">
              </textarea>
          <label for="brochure-description">Kirjeldus ({{ selectedView }})</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
  <div class="flex row justify-content-end py-4 gap-2">
    <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
    <p-button label="Salvesta" (click)="onSave()"/>
  </div>
</div>
