import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(birthDate: Date | string | null | undefined): number | null {
    if (!birthDate) return null;

    const dateValue = typeof birthDate === 'string' ? new Date(birthDate) : birthDate;

    if (isNaN(dateValue.getTime())) {
      return null;
    }

    const today = new Date();
    let age = today.getFullYear() - dateValue.getFullYear();
    const monthDifference = today.getMonth() - dateValue.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dateValue.getDate())) {
      age--;
    }

    return age;
  }
}
