import { Component, Input } from '@angular/core';
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hidden-in-public-web-label',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TooltipModule,
  ],
  templateUrl: './hidden-in-public-web-label.component.html',
})
export class HiddenInPublicWebLabelComponent {
  @Input() isHiddenInPublicWeb: boolean = false;
}
