import { Pipe, PipeTransform } from '@angular/core';
import { BrochureType } from "../../core/enums/brochure-type";

@Pipe({
  name: 'brochureType',
  standalone: true
})
export class BrochureTypePipe implements PipeTransform {

  transform(type: string): string {
    if (!type) {
      return '';
    }
    switch (type) {
      case BrochureType.SERVICE_INFO:
        return 'Infoleht';
      case BrochureType.PRINT_INFO:
        return 'Teabeleht';
      case BrochureType.NOTIFICATION:
        return 'Teavituskiri';
      case BrochureType.REMINDER:
        return 'Meeldetuletuskiri';
      default: return type;
    }
  }

}
