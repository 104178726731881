import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from "primeng/table";
import { TableColumn } from "@core/interfaces/table-column.interface";
import { Menu } from "primeng/menu";
import { MenuItem } from "primeng/api";
import { ObjectUtils } from "primeng/utils";

@Component({
  selector: 'medis-data-table',
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss'
})
export class DataTableComponent extends ObjectUtils implements OnChanges {
  @Input() data: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() filterFields: string[] = [];
  @Input() addButtonLabel: string = '';
  @Input() loading = false;
  @Input() displayDefaultFilters = true;
  @Input() paginator = true;

  /**
   * @param action - provide action for a button
   * @param actionTree - provide array of buttons with actions
   */
  @Input() buttonColumns?: Array<{
    title?: string;
    icon: string;
    componentRef?: any,
    componentRefInputs?: any,
    action?: (row: any) => void;
    actionTree?: Array<{ title?: string; icon?: string; action: (row: any) => void }>;
  }>;

  /**
   * Provide a conditional ngClass to a table row
   */
  @Input() rowNgClass: (row: any) => { [style: string]: boolean } = () => ({});
  searchTerm: string = '';
  actions: MenuItem[] = [];
  @Input() firstColumnLinkTemplate?: string = 'edit/:id';

  @ViewChild('dt') dt: Table | undefined;

  constructor() {
    super();
    ObjectUtils.removeAccents = (str) => str;
  }

  /**
   * Deconstructs componentRefInputs of column
   *
   * @param row - must contain object field name expected by key of componentRef column
   * @param column - must contain input name expected by component
   */
  getDynamicInputs(row: any, column: TableColumn): { [key: string]: any } {
    if (!column.componentRefInputs) {
      return {}; // No inputs provided
    }

    const inputs: { [key: string]: any } = {};
    for (const [inputName, fieldName] of Object.entries(column.componentRefInputs)) {
      inputs[inputName] = row[fieldName];
    }
    return inputs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Changes to `data` mean object is reinitialized
    if (changes['data']) {
      // So we reset search model and filters on the table
      this.searchTerm = '';
      this.dt?.clearFilterValues();
    }
  }

  applyFilterGlobal($event: any = '') {
    if(this.dt) {
      const input = ($event.target as HTMLInputElement).value;
      this.dt.filterGlobal(input, 'contains');
    }
  }

  isBoolean(data: any): boolean {
    return typeof data === 'boolean';
  }

  createHref(row: any, col: TableColumn): string {
    let template: any = col.hrefTemplate ?? this.firstColumnLinkTemplate;
    return template.split(':id').join(row[col.idKey!]);
  }

  toggleActions($event: MouseEvent, menu: Menu, row: any, tree: any): void {
    menu.toggle($event);
    this.actions = tree.map((x: any) => ({ label: x.title, command: () => x.action(row) }));
  }

  protected readonly Array = Array;
}
