<div class="flex flex-column gap-4">
  <form class="flex flex-column gap-4"
        role="form"
        [formGroup]="addDeviceTypeForm">
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-12">
        <medis-device-category-select-list
          id="deviceCategory"
          labelName="Valdkond"
          formControlName="deviceCategory"
          [formSubmitted]="formSubmitted"
        ></medis-device-category-select-list>
        @if (formSubmitted && deviceCategory.errors) {
          <small class="p-error">Valdkond on kohustuslik</small>
        }
      </div>
    </div>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-12">
        <p-floatLabel>
          <input
            id="deviceType"
            name="deviceType"
            type="text"
            class="w-full"
            [ngClass]="{'ng-invalid ng-dirty': formSubmitted && deviceType.errors}"
            formControlName="deviceType"
            pInputText
          />
          <label for="deviceType">Tüüp</label>
          @if (formSubmitted && deviceType.errors) {
            <small class="p-error">Tüüp on kohustuslik</small>
          }
        </p-floatLabel>
      </div>
    </div>
  </form>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button
    label="Katkesta"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
    size="small"/>
  <p-button
    label="Salvesta"
    icon="pi pi-check"
    size="small"
    (click)="save()"
  ></p-button>
</div>

