import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { ToastService } from "@core/services/toast.service";
import { PersonService } from "@core/services/person.service";
import { IPerson } from "@core/interfaces/person.interface";

@Component({
  selector: 'medis-provider-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        id="types"
        class="w-full"
        [ngClass]="{ ngClass, 'ng-invalid' : invalid }"
        styleClass="w-full"
        appendTo="body"
        [name]="labelName"
        [options]="providers"
        [filter]="providers.length > 15"
        filterBy="fullName"
        [ngModel]="selectedProviderId"
        (onChange)="onSelect($event.value)"
        [showClear]="showClear"
        (onClear)="selectedProviderId = undefined"
        optionValue="personId"
        optionLabel="fullName"
        [placeholder]="labelName"
      />
      <label for="types">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProviderSelectListComponent),
      multi: true
    }
  ]
})
export class ProviderSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string = '';
  @Input() showClear = false;
  @Input() ngClass: string | string[] | Set<string> | {[p: string]: any} | null | undefined;
  @Output() onProviderChange = new EventEmitter<IMapping>();
  @Input() invalid = false;

  providers: IPerson[] = [];
  @Input() selectedProviderId: any;

  constructor(private personService: PersonService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes(preselectPersonId?: string): void {
    this.personService.getProvidersForSelect().subscribe({
      next: resolve => {
        this.providers = resolve;

        if (preselectPersonId) {
          this.onSelect(preselectPersonId);
        }
      },
      error: (err: Error) => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedProviderId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: string) {
    this.selectedProviderId = +value;
    this.onProviderChange.emit({providerId: value});
    this.onChange(value);
    this.onTouched();
  }

  refreshAndSetProvider(provider: IPerson) {
    const personId = provider?.personId?.toString();
    if (personId) {
      this.getTypes(personId);
    }
  }
}
