import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { IRoomType } from "@core/interfaces/room-type.interface";
import { LocationService } from "@core/services/location.service";

@Component({
  selector: 'medis-room-type-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        id="types"
        class="w-full"
        styleClass="w-full"
        appendTo="body"
        [options]="roomTypes"
        [filter]="roomTypes.length > 15"
        filterBy="name"
        [ngModel]="selectedType"
        (onChange)="onSelect($event.value)"
        optionValue="roomTypeId"
        optionLabel="name"
      />
      <label class="text-color-secondary"
             for="types">Ruumi tüüp</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomTypeSelectListComponent),
      multi: true
    }
  ]
})
export class RoomTypeSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string = '';
  @Input() formSubmitted = false;

  roomTypes: IRoomType[] = [];
  selectedType: any;

  constructor(private locationService: LocationService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes(): void {
    this.locationService.getRoomTypes().subscribe({
      next: resolve => {
        this.roomTypes = resolve;
      },
      error: (err: Error) => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedType = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedType = value;
    this.onChange(value);
    this.onTouched();
  }
}
