@if (order && order.orderServices && order.orderServices.length > 0) {
  <p-table [value]="order.orderServices" dataKey="orderServiceId"
           [expandedRowKeys]="expandedRows"
           (onRowExpand)="onRowExpand($event)"
           (onRowCollapse)="onRowCollapse($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Periood</th>
        <th [colSpan]="2">Teenus</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-orderService let-expanded="expanded">
      <tr [class.expanded-background]="expanded">
        <td>
          <div class="flex align-items-center">
            @if (orderService.orderServiceTimes?.length > 0) {
              <p-button
                type="button"
                [pRowToggler]="orderService"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></p-button>
            }
            <div class="ml-2 gap-1">
              <p class="m-0">{{ orderService.fromDate | dateFormat }} - {{ orderService.toDate | dateFormat }}</p>
              <span class="text-base font-semibold">{{ orderService.repeat ?? 1 }} korda</span>
            </div>
          </div>
        </td>
        <td>
          <div class="flex gap-2 align-items-center">
            <medis-service-badge [badges]="orderService.serviceBadges"></medis-service-badge>
            {{ orderService.serviceName }}
          </div>
        </td>
        <td class="last-col">
          <div class="flex justify-content-end">
            <p-button label="Vali aeg" [outlined]=true severity="secondary" (click)="changeTime(orderService)"/>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-orderService let-expanded="expanded">
      @for (orderServiceTime of orderService.orderServiceTimes; track $index) {
        <tr class="border-0 expanded-background">
          <td></td>
          <td [colSpan]="2">
            Aeg {{ $index + 1 }} - <span [innerHTML]="orderServiceTime.date | dateTimeDisplay: 'dd.MM.yyyy': true"></span> &nbsp;
            {{ orderServiceTime?.timeFrom | timeFormat }} - {{ orderServiceTime?.timeUpto | timeFormat }}
            &nbsp;
            {{ orderServiceTime?.roomCode }} &nbsp;
            {{ orderServiceTime?.departmentShort }}
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
}

