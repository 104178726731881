import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IDepartment } from "@core/interfaces/department.interface";

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/departments`;
  }

  public getDepartments(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(`${this.BACKEND_URL}/`);
  }
}
