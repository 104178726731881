import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-service-picker',
  templateUrl: './service-picker.component.html',
  styleUrl: './service-picker.component.scss'
})
export class ServicePickerComponent implements OnInit {
  @Input() formSubmitted = false;
  @Input() required = false;
  @Input() includeServiceNames = false;
  @Input() selectedServiceIds: number[] = [];
  @Input() selectedServiceIdsWithNames: {id: number, name: string}[] = [];
  @Input() params!: HttpParams;

  @Output() selectedServiceIdsChange = new EventEmitter<number[]>();
  @Output() selectedServiceIdsWithNamesChange = new EventEmitter<{id: number, name: string}[]>();

  sourceServices: IService[] = [];
  targetServices: IService[] = [];
  selectedItems: any[] = [];

  constructor(
    private serviceService: ServiceService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getServices();
  }

  onItemClick(item: any) {
    const index = this.selectedItems.indexOf(item);
    if (index === -1) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  private getServices(): void {
    this.serviceService.getServicesForSelect(this.params).subscribe({
      next: services => {
        if(this.includeServiceNames){
          if (this.selectedServiceIdsWithNames.length > 0) {
            this.sourceServices = services.filter(service =>
              !this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
            );
            this.targetServices = services.filter(service =>
              this.selectedServiceIdsWithNames.some(selected => selected.id === service.serviceId)
            );
          } else {
            this.sourceServices = services;
          }
          this.cdr.markForCheck();
        } else {
          if (this.selectedServiceIds.length > 0) {
            this.sourceServices = services.filter(service => !this.selectedServiceIds.includes(service.serviceId));
            this.targetServices = services.filter(service => this.selectedServiceIds.includes(service.serviceId));
          } else {
            this.sourceServices = services;
          }
          this.cdr.markForCheck();
        }
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onTargetChange(event: any) {
    if(this.includeServiceNames){
      const selectedServicesWithNames = this.targetServices.map(service => ({
        id: service.serviceId,
        name: service.name
      }));
      this.selectedServiceIdsWithNamesChange.emit(selectedServicesWithNames);
      this.cdr.markForCheck();
    } else {
      this.selectedServiceIds = this.targetServices.map(s => s.serviceId);
      this.selectedServiceIdsChange.emit(this.selectedServiceIds);
      this.cdr.markForCheck();
    }
  }
}
