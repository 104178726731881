<p-table [value]="data" class="table table--today-plan">
  <ng-template pTemplate="header">
    <tr>
      <th>Kuupäev</th>
      <th>Number</th>
      <th>Tellija</th>
      @if (patientPage) {
        <th>Eriala</th>
      }
      <th>Eesmärk</th>
      @if (!patientPage) {
        <th>Patsient</th>
      }
      <th>Teenus</th>
      <th>Osutaja</th>
      <th>Staatus</th>
      @if (!patientPage) {
        <th></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-eCon>
    <tr>
      <td>
        {{ eCon.date | dateTimeDisplay: 'dd.MM.yyyy' }}
      </td>
      <td>
        <a class="link" (click)="openEConsultation(eCon)">
          {{ eCon.referral.documentNr }}
        </a>
      </td>
      <td>
        {{ eCon.referral.authorName }}
        <i>{{ eCon.referral.sourceCompanyName }}</i>
      </td>
      @if (patientPage) {
        <td>
          {{ eCon.referral.targetProfession }}
        </td>
      }
      <td>
        {{ eCon.reason }}
      </td>
      @if (!patientPage) {
        <td>
          <medis-patient-basic-data
            [patientName]="eCon.patientName"
            [patientCode]="eCon.patientCode"
            (onPatientClick)="openPatientView(eCon.patientId)"
          />
        </td>
      }
      <td>
        {{ eCon.serviceName }}
      </td>
      <td>
        {{ eCon.providerName }}
      </td>
      <td>
        @if (patientPage) {
          <medis-order-referral-status-label [referralStatus]="eCon.status"></medis-order-referral-status-label>
        } @else {
          <medis-e-consultation-status-label [status]="eCon.status"/>
        }
      </td>
      @if (!patientPage) {
        <td>
          <medis-e-consultation-actions
            [eConsultation]="eCon"
            [providerMode]="providerMode"
            (onActionComplete)="actionComplete()"
          />
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="patientPage ? 8 : 9">Midagi pole leitud.</td>
    </tr>
  </ng-template>
</p-table>

