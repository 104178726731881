import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { environment } from "@environments/environment";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ExternalMedicalCaseService {

  constructor(
    private authService: AuthService,
    private datePipe: DatePipe,
  ) {
  }

  openPrescription(prescriptionId: string) {
    let f = document.createElement("form");
    f.setAttribute('id', 'prescriptionsopenform');
    f.setAttribute('name', 'prescriptionsopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("prescriptionId", prescriptionId, f);

    document.body.appendChild(f);

    f.submit();
  }

  open(data: OpenExternalMedicalCaseData): void {
    let f = this.preFillForm('external');

    if (data.patient) {
      this.addInput("patient.id", data.patient.id, f);
    }
    if (data.doctor) {
      this.addInput("doctor.id", data.doctor.id, f);
      this.addInput("doctor.departmentId", data.doctor.departmentId, f);
    }
    if (data.orderService) {
      this.addInput("orderService.id", data.orderService.id, f);
      this.addInput("orderService.bookingTime", data.orderService.bookingTime, f);
      this.addInput("orderService.service.id", data.orderService.service.id, f);
      this.addInput("orderService.service.name", data.orderService.service.name, f);
    } else {
      this.addInput("orderService.id", 1, f);
      this.addInput("orderService.bookingTime", this.datePipe.transform(new Date(Date.now()), 'yyyy-MM-ddT00:00:00') , f);
      this.addInput("orderService.service.id", 1, f);
      this.addInput("orderService.service.name", 1, f);
    }

    if (data.medicalCaseId) {
      this.addInput("medicalCaseId", data.medicalCaseId, f);
    }

    document.body.appendChild(f);

    f.submit();
  }

  // openPatient(patientId: number) {
  //   let f = document.createElement("form");
  //   f.setAttribute('id', 'patientopenform');
  //   f.setAttribute('name', 'patientopenform');
  //   f.setAttribute('method', "post");
  //   f.setAttribute('target', "_blank");
  //   f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
  //   this.addInput("token", this.authService.getAccessToken(), f);
  //   this.addInput("patientId", patientId, f);
  //
  //   document.body.appendChild(f);
  //
  //   f.submit();
  // }

  opencCoifw(coifwId: string) {
    let f = document.createElement("form");
    f.setAttribute('id', 'coifwopenform');
    f.setAttribute('name', 'coifwopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("coifwId", coifwId, f);

    document.body.appendChild(f);

    f.submit();
  }

  openCase(caseId: string) {
    let f = document.createElement("form");
    f.setAttribute('id', 'caseopenform');
    f.setAttribute('name', 'caseopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("caseId", caseId, f);

    document.body.appendChild(f);

    f.submit();
  }

  openPhysiotherapyForm() {
    let f = document.createElement("form");
    f.setAttribute('id', 'physioopenform');
    f.setAttribute('name', 'physioopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'Medis');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("toApplication", "PHYSIO", f);

    document.body.appendChild(f);

    f.submit();
  }

  openStudyForm() {
    let f = this.preFillForm('Medis');

    this.addInput("toApplication", "PHYSIO", f);
    this.addInput("ToModule", "examination", f);

    document.body.appendChild(f);

    f.submit();
  }

  openProceduresForm() {
    let f = this.preFillForm('Medis');

    this.addInput("toApplication", "PHYSIO", f);
    this.addInput("ToModule", "procedure", f);

    document.body.appendChild(f);

    f.submit();
  }

  private addInput(name: string, value: any | null, form: HTMLFormElement): void {
    const input = document.createElement("input");
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value ? value.toString() : null);
    form.appendChild(input);
  }

  openMedicalCaseForm() {
    let f = document.createElement("form");
    f.setAttribute('id', 'medicalcaseopenform');
    f.setAttribute('name', 'medicalcaseopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'external');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("patient.id", 245113, f);
    this.addInput("doctor.id", 12057, f);
    this.addInput("doctor.departmentId", 1, f);
    this.addInput("orderService.id", 1, f);
    this.addInput("orderService.bookingTime", "2024-03-11T00:00:00", f);
    this.addInput("orderService.service.id", 1, f);
    this.addInput("orderService.service.name", 1, f);
    this.addInput("toApplication", "MCASE", f);
    this.addInput("toModule", "mcase-case-dashboard", f);

    document.body.appendChild(f);

    f.submit();
  }

  private preFillForm(actionPostfix: string): HTMLFormElement {
    let f = document.createElement("form");
    f.setAttribute('id', 'proceduresopenform');
    f.setAttribute('name', 'proceduresopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', `${environment.externalMedicalCaseUrl}${actionPostfix}`);
    this.addInput("token", this.authService.getAccessToken(), f);

    return f;
  }

}

export interface OpenExternalMedicalCaseData {
  patient?: {
    id: number
  },
  doctor?: {
    id: number, departmentId: number
  },
  orderService?: {
    id: number, bookingTime: string, service: { id: number, name: string }
  },
  medicalCaseId?: number
}
