import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IPatient } from "@core/interfaces/patient.interface";
import { IPatientWorkplace } from "@core/interfaces/patient-workplace.interface";
import { HelperService } from "@core/services/helper.service";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { PatientService } from "@core/services/patient.service";
import { idCodeValidator } from "@shared/validators/idcodeValidator";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@core/services/toast.service";
import { MenuItem } from "primeng/api";
import { HREFTarget } from "@core/enums/HREFTarget.enum";
import { TitleCasePipe, UpperCasePipe } from "@angular/common";
import { IEmergencyContact } from "@core/interfaces/emergency-contact.interface";
import { ListService } from "@core/services/list.service";
import { GeneralList } from "@core/enums/general-list.enum";
import { IEuropeanUnionInsuranceDocument } from "@core/interfaces/european-union-insurance-document.interface";
import { FormValidationService } from "@core/services/form-validation.service";
import { CountryService } from "@core/services/country.service";
import { Country } from "@core/enums/country.enum";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrl: './patient-form.component.scss',
  providers: [TitleCasePipe, UpperCasePipe]
})
export class PatientFormComponent implements OnInit {
  patientForm: FormGroup = new FormGroup({});
  patient: IPatient = {} as IPatient;
  breadcrumbs?: MenuItem[] = [];
  contactTypes: IGeneralList[] = [];

  isModal = false;

  constructor(private formBuilder: FormBuilder,
              private helperService: HelperService,
              private patientService: PatientService,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private upperCasePipe: UpperCasePipe,
              private listService: ListService,
              private countryService: CountryService,
              private formValidationService: FormValidationService,
              private router: Router,
              private dialogConfig: DynamicDialogConfig,
              private ref: DynamicDialogRef) {
    if (this.dialogConfig?.modal) {
      this.isModal = true;
    }
  }

  ngOnInit(): void {
    const patientId = this.activatedRoute.snapshot.params['id'];
    if (patientId) {
      this.patientService.get(patientId).subscribe({
        next: (data: IPatient) => {
          this.patient = data;
        },
        error: () => {
          this.toastService.error('Error fetching patient data');
        },
        complete: () => {
          this.initGeneralData();
          this.initAdditionalData();
          this.initBreadcrumbs();
        }
      });
    } else {
      if (this.dialogConfig?.data?.patient) {
        this.patient = this.dialogConfig?.data?.patient;
      }
      this.initGeneralData();
    }
    this.fetchContactTypes();
  }

  get generalDataGroup(): FormGroup {
    return this.patientForm?.get('generalData') as FormGroup;
  }

  get additionalDataGroup(): FormGroup {
    return this.patientForm?.get('additionalData') as FormGroup;
  }

  get nationality(): FormControl {
    return this.generalDataGroup?.get('nationality') as FormControl;
  }

  handlePatientNationality(): void {
    this.handleDisabledFields(this.nationality?.value);
    this.nationality?.valueChanges.subscribe(value => {
      this.handleDisabledFields(value);
    });
  }

  private handleDisabledFields(value: string): void {
    if (value === Country.EST) {
      this.generalDataGroup.get('firstname')?.disable();
      this.generalDataGroup.get('lastname')?.disable();
      this.generalDataGroup.get('gender')?.disable();
      this.generalDataGroup.get('birthday')?.disable();
      this.generalDataGroup.get('country')?.disable();
    } else {
      this.generalDataGroup.get('firstname')?.enable();
      this.generalDataGroup.get('lastname')?.enable();
      this.generalDataGroup.get('gender')?.enable();
      this.generalDataGroup.get('birthday')?.enable();
      this.generalDataGroup.get('country')?.enable();
    }
  }

  fetchContactTypes(): void {
    this.listService.getByType(GeneralList.CONTACT_TYPE).subscribe(types => {
      this.contactTypes = types;
    });
  }

  submit(): void {
    if (this.patientForm.invalid) {
      this.formValidationService.markControlAsDirty(this.patientForm);
      return;
    }
    let generalData = this.patientForm.getRawValue().generalData;
    let additionalData = this.patientForm.getRawValue().additionalData;

    let patientDto: IPatientDto = {
      patientId: this.patient?.patientId ?? 0,
      patientContactId: this.patient?.contact?.patientContactId ?? 0,
      newsletterAgreementId: this.patient?.newsletterAgreement?.newsletterAgreementId ?? 0,
      idCode: generalData.idCode,
      email: generalData.email,
      language: generalData.language,
      country: generalData.country,
      contactCountry: generalData.contactCountry,
      gender: generalData.gender,
      newsletterAgreementAgreed: generalData.newsletterAgreement,
      riskFactorsIds: generalData.riskFactorsIds,
      clientGroupsIds: generalData.clientGroupsIds,
      workplaces: generalData.workplaces,
    }
    if (generalData.phoneNumber.phone) {
      patientDto.phone = this.constructPhone(generalData.phoneNumber.countryCode, generalData.phoneNumber.phone);
    }

    if (generalData.contactCountry === Country.EST) {
      patientDto.county = generalData.county;
      patientDto.street = generalData.street;
      patientDto.city = generalData.city;
      patientDto.settlement = generalData.settlement;
      patientDto.house = generalData.house;
      patientDto.flat = generalData.flat;
      patientDto.zip = generalData.zip;
      patientDto.archive = generalData.archive;
    } else {
      patientDto.longAddress = generalData.longAddress;
    }

    if (generalData.birthday) {
      patientDto.birthday = new Date(generalData.birthday).toISOString();
    }
    if (generalData.gender) {
      patientDto.gender = generalData.gender;
    }
    if (generalData.firstname) {
      patientDto.firstName = generalData.firstname;
    }
    if (generalData.lastname) {
      patientDto.lastName = generalData.lastname;
    }

    // map additionData if it exists
    if (additionalData) {

      if (additionalData.bankAccount) patientDto.bankAccount = additionalData.bankAccount;
      if (additionalData.bankAccountOwner) patientDto.bankAccountOwner = this.upperCasePipe.transform(additionalData.bankAccountOwner);
      if (additionalData.emergencyContacts) {
        patientDto.emergencyContacts = additionalData.emergencyContacts.map((one: IEmergencyContact) => ({
          patientId: this.patient?.patientId ?? 0,
          emergencyContactId: one.emergencyContactId ?? 0,
          firstName: one.firstname,
          lastname: one.lastname,
          idCode: one.idCode,
          phone: one.phone,
          email: one.email,
          type: one.type?.generalListId,
        }));
      }
      if (additionalData.europeanUnionInsuranceDocument?.europeanUnionInsuranceDocumentId) {
        patientDto.euInsuranceDocument = {
          ...additionalData.europeanUnionInsuranceDocument,
          birthday: additionalData.europeanUnionInsuranceDocument.birthday
            ? new Date(additionalData.europeanUnionInsuranceDocument.birthday).toISOString()
            : null,
          issueDate: additionalData.europeanUnionInsuranceDocument.issueDate
            ? new Date(additionalData.europeanUnionInsuranceDocument.issueDate).toISOString()
            : null,
          validFromDate: additionalData.europeanUnionInsuranceDocument.validFromDate
            ? new Date(additionalData.europeanUnionInsuranceDocument.validFromDate).toISOString()
            : null,
          validToDate: additionalData.europeanUnionInsuranceDocument.validToDate
            ? new Date(additionalData.europeanUnionInsuranceDocument.validToDate).toISOString()
            : null,
        };
      }
    }

    this.savePatient(patientDto);
  }


  private deconstructPhone(phone: string): { prefix: string, phone: string } {
    let phonePrefix = this.countryService.getCountryPhoneCode(Country.EST);
    let phoneNumber = phone ?? '';
    if (phone && phone.startsWith('+')) {
      this.countryService.getAllCountryCodes().forEach((cc) => {
        if (phone.startsWith(cc)) {
          let tempPhone = phone;
          phonePrefix = cc;
          phoneNumber = tempPhone.replace(cc, '');
        }
      });
    }

    return {prefix: phonePrefix, phone: phoneNumber}
  }

  private constructPhone(prefix: string, phone: string): string {
    return (prefix ? prefix : '') + (phone ? phone : '');
  }

  private initGeneralData(): void {
    this.patientForm = this.formBuilder.group({
      generalData: this.formBuilder.group({
        nationality: [this.getNationality()],
        idCode: [this.patient?.idCode ?? null, [Validators.required, idCodeValidator]],
        firstname: [this.patient?.firstname ?? null, Validators.required],
        lastname: [this.patient?.lastname ?? null, Validators.required],
        gender: [this.patient?.gender ?? null, Validators.required],
        birthday: [this.patient?.birthday ? new Date(this.patient?.birthday) : null, Validators.required],
        clientGroupsIds: [this.patient?.clientGroupIds ? this.patient?.clientGroupIds : null],
        language: [this.patient?.language ?? null],
        email: [this.patient?.contact?.email ?? null, {validators: [Validators.email], updateOn: 'submit'}],
        phoneNumber: this.formBuilder.group({
          countryCode: [this.deconstructPhone(this.patient?.phone).prefix],
          phone: [this.deconstructPhone(this.patient?.phone).phone],
        }),
        riskFactorsIds: [this.patient?.contact?.workplaceRiskFactors?.length ? (this.patient?.contact?.workplaceRiskFactors.map((wrf: IGeneralList) => wrf.generalListId)) : null],
        longAddress: [this.patient?.contact?.longAddress ?? null],
        country: [this.patient?.country ? this.patient?.country : null],
        contactCountry: [this.patient?.contact?.country ? this.patient?.contact?.country : null],
        county: [this.patient?.contact?.county ?? null],
        city: [this.patient?.contact?.city ?? null],
        settlement: [this.patient?.contact?.settlement ?? null],
        house: [this.patient?.contact?.house ?? null],
        street: [this.patient?.contact?.street ?? null],
        flat: [this.patient?.contact?.flat ?? null],
        zip: [this.patient?.contact?.zip ?? null],
        archive: [this.patient?.archive ?? null],
        workplaces:
          this.formBuilder.array(
            this.patient.workplaces ? this.patient.workplaces.map(
              (patient: IPatientWorkplace) => this.formBuilder.group({
                patientWorkplaceId: [patient.patientWorkplaceId || null],
                patientId: [patient.patientId ?? null],
                workplace: [patient.workplace || null],
                workplaceRegisterNumber: [patient.workplaceRegisterNumber || null],
                workplaceSubdivision: [patient.workplaceSubdivision || null],
                profession: [patient.profession || null],
                delete: [patient.delete || null]
              })) : []
          ),
        newsletterAgreement: [this.patient.newsletterAgreement?.agreed ?? false],
      })
    });

    this.handlePatientNationality();
  }

  initAdditionalData(): void {
    this.patientForm?.addControl('additionalData', this.formBuilder.group(
      {
        emergencyContacts: this.formBuilder.array(
          this.patient.emergencyContacts ? this.patient.emergencyContacts.map(
            (contact: IEmergencyContact) => this.formBuilder.group({
              emergencyContactId: [contact.emergencyContactId ?? null],
              patientId: [contact.patientId ?? null],
              patientContactId: [contact.patientContactId ?? null],
              email: [contact.email ?? null],
              phone: [contact.phone ?? null],
              idCode: [contact.idCode ?? null],
              firstname: [contact.firstname ?? null],
              lastname: [contact.lastname ?? null],
              type: [contact.type ?? null],
              typeDescription: [contact.typeDescription ?? null],
            })
          ) : [],
        ),
        bankAccount: [this.patient?.bankAccount ?? null, {
          validators: [Validators.minLength(14), Validators.maxLength(34)],
          updateOn: 'submit'
        }],
        bankAccountOwner: [this.patient?.bankAccountOwner ?? null],
        europeanUnionInsuranceDocument: this.formBuilder.group({
          europeanUnionInsuranceDocumentId: [this.patient?.europeanUnionInsuranceDocument?.europeanUnionInsuranceDocumentId ?? null],
          patientId: [this.patient?.europeanUnionInsuranceDocument?.patientId ?? null],
          documentType: [this.patient?.europeanUnionInsuranceDocument?.documentType ?? null],
          documentNumber: [this.patient?.europeanUnionInsuranceDocument?.documentNumber ?? null],
          idCode: [this.patient?.europeanUnionInsuranceDocument?.idCode ?? null],
          firstname: [this.patient?.europeanUnionInsuranceDocument?.firstname ?? null],
          lastname: [this.patient?.europeanUnionInsuranceDocument?.lastname ?? null],
          birthday: [this.patient?.europeanUnionInsuranceDocument?.birthday ? new Date(this.patient?.europeanUnionInsuranceDocument?.birthday) : null],
          gender: [this.patient?.europeanUnionInsuranceDocument?.gender ?? null],
          country: [this.patient?.europeanUnionInsuranceDocument?.country ?? null],
          issueDate: [this.patient?.europeanUnionInsuranceDocument?.issueDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.issueDate) : null],
          validFromDate: [this.patient?.europeanUnionInsuranceDocument?.validFromDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.validFromDate) : null],
          validToDate: [this.patient?.europeanUnionInsuranceDocument?.validToDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.validToDate) : null],
          authorityCode: [this.patient?.europeanUnionInsuranceDocument?.authorityCode ?? null],
        }) ?? null,
      })
    );
  }

  getNationality(): string | Country {
    if (this.patient && this.patient.idCode) {
      if (this.helperService.validatePersonCode(this.patient.idCode)) {
        return Country.EST;
      } else {
        return 'other'
      }
    }
    return Country.EST;
  }

  initBreadcrumbs(): void {
    this.breadcrumbs =
      [
        {
          label: 'Patsiendid',
          url: '/patients/list',
          target: HREFTarget.SELF
        },
        {
          label: `Andmete muutumine`,
          disabled: true
        }
      ];
  }

  savePatient(patient: IPatientDto): void {
    // If we had patientId and isModal is true, it essentially means we
    // are quick-editing patient from PresentModalComponent
    const quickEditingPatient = this.patient?.patientId && this.isModal;
    this.patientService.save(patient).subscribe({
      next: (patient: IPatient) => {
        // And we redirect patient to /patients/view ONLY if we are NOT quick-editing
        if (!quickEditingPatient) {
          this.router.navigate([`/patients/view/${patient.patientId}`]);
        }

        if (this.isModal) {
          this.ref?.close({patient: patient});
        }

        this.toastService.success('Patsient salvestatud');
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  back(): void {
    if (this.isModal) {
      this.ref.close();
    } else {
      history.back();
    }
  }
}

interface IPatientDto {
  patientId: number,
  patientContactId: number,
  newsletterAgreementId: number,
  idCode?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  longAddress?: string,
  language?: string,
  country?: string,
  contactCountry?: string,
  street?: string,
  county?: string,
  city?: string,
  settlement?: string,
  house?: string,
  flat?: string,
  zip?: string,
  gender?: string,
  archive?: string,
  birthday?: string,
  newsletterAgreementAgreed?: boolean,
  riskFactorsIds?: number[],
  clientGroupsIds?: number[],
  bankAccount?: string,
  bankAccountOwner?: string,
  workplaces: IPatientWorkplace,
  emergencyContacts?: IEmergencyContact[],
  euInsuranceDocument?: IEuropeanUnionInsuranceDocument
}
