import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenuModule } from "primeng/menu";
import { MenuItem } from "primeng/api";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IService } from "@core/interfaces/service.interface";
import { OrderTypesService } from "@core/services/order-types.service";
import { ListService } from "@core/services/list.service";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServicesData } from "@core/interfaces/order-services-data.interface";
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { OrderReferralStatus } from "@core/enums/order-referral-status.enum";
import { ModalService } from "@core/services/modal.service";
import { TableHistoryComponent } from "@shared/modals/table-history/table-history.component";
import { ModalSize } from "@core/enums/modalSize";
import { HttpParams } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { IOrderServiceLog } from "@core/interfaces/order-service-log.interface";
import { OrderServiceLogStatus } from "@core/enums/order-service-log-status.enum";
import { SstService } from "@core/services/sst.service";
import { OrderServiceService } from "@core/services/order-service.service";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";
import { ConfirmModalComponent } from "@shared/modals/confirm/confirm.modal.component";
import { ToastService } from "@core/services/toast.service";
import { OrderServiceQueueService } from "@core/services/order-service-queue.service";
import { OrderServiceTimeService } from "@core/services/order-service-time.service";
import { IReason } from "@core/interfaces/reason.interface";
import { LogService } from "@core/services/log.service";
import { DashboardService } from "@core/services/dashboard.service";
import { ChangeTimeComponent } from "@shared/components/change-time/change-time.component";
import { PlanTimeService } from "@core/services/plan-time.service";
import { OrderService } from "@core/services/order.service";
import { ExternalReferralService } from "@core/services/external-referral.service";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";

@Component({
  selector: 'medis-order-actions',
  standalone: true,
  imports: [
    ButtonModule,
    SplitButtonModule,
    MenuModule,
    OverlayPanelModule,
  ],
  templateUrl: './order-actions.component.html'
})
export class OrderActionsComponent implements OnInit {
  @Input() time?: IOrderServiceTime;
  @Input() service!: IService;
  @Input() orderService?: IOrderService;
  @Input() orderServiceData!: IOrderServicesData;
  @Input() order?: IOrder;
  @Input() presentOrders: boolean = false;

  @Output() onTimeChanged = new EventEmitter();
  @Output() onTimeAdded = new EventEmitter();
  @Output() menuOpenChange = new EventEmitter<boolean>();

  orderTypes: any = [];
  actions: MenuItem[] | undefined;
  orderServiceStatus: OrderServiceStatus | null | undefined;
  patientIdCode!: string;
  patientMisCode!: number | null;
  nextOrderId!: number | null | undefined;
  messages: string[] = [];
  isMenuOpen: boolean = false;
  documentClickListener: (() => void) | null = null;

  constructor(
    private orderTypesService: OrderTypesService,
    private listService: ListService,
    private modalService: ModalService,
    private orderServiceService: OrderServiceService,
    private orderServiceQueueService: OrderServiceQueueService,
    private orderServiceTimeService: OrderServiceTimeService,
    private logService: LogService,
    private planTimeService: PlanTimeService,
    private sstService: SstService,
    private toastService: ToastService,
    private dashboardService: DashboardService,
    private _orderService: OrderService,
    private externalReferralService: ExternalReferralService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    this.orderTypes = this.orderTypesService.getOrderTypes(this.listService.listCodesMap.value);
    this.orderServiceStatus = this.getOrderServiceStatus();
    this.patientIdCode = this.order?.patient?.idCode ?? this.time?.patientIDcode ?? '';
    this.patientMisCode = this.order?.patient?.misCode ?? this.time?.patientMisCode ?? null;
    this.nextOrderId = this.time?.nextOrderId ?? this.order?.nextOrderId;
    this.initActions();
  }

  private initActions(): void {

    let config: IOrderReservationConfig = {
      serviceId: this.time?.serviceId,
      providerId: this.time?.providerId,
      patient: this.time?.patient,
      timeFrom: this.time?.timeFrom,
      timeTo: this.time?.timeUpto,
      addressId: this.time?.roomId,
      disableService: true,
      orderServiceTime: this.time,
      showMultiple: false
    };

    this.actions = [
      {label: 'Ava tellimus', visible: !this.presentOrders},
      {label: 'Prindi', visible: this.printActionVisible() && !this.presentOrders}, //vsegda
      {
        label: 'Kommentaarid',
        visible: !this.presentOrders,
        command: () => this.orderServiceService.openCommentsByOrder(this.time ?? this.orderService ?? null)
      },
      {
        label: this.orderServiceStatus === OrderServiceStatus.PREPARING ? 'Broneeri aeg' : 'Muuda aeg',// esli est time
        visible: this.bookTimeActionVisible() && !this.presentOrders,
        command: () => {
          const dialogRef = this.modalService.openModal({
            size: ModalSize.EXTRA_LARGE,
            component: ChangeTimeComponent,
            data: config,
            header: `${this.time!.patientName} - Broneeringu muutmine`,
            styleClass: 'order-reservation-modal',
          });

          dialogRef.onClose.subscribe((result: any) => {
            if(result){
              this.time!.orderServiceTimeStatus = OrderServiceStatus.CHANGED;
              this.onTimeChanged.emit({time: this.time, status: OrderServiceStatus.CHANGED});
              this.onTimeAdded.emit(true);
              this.toastService.success("Broneering muudetud");
            }
          });
        }
      },
      {
        label: 'Tühista aeg',
        visible: this.cancelTimeActionVisible() && !this.presentOrders,
        command: () => this.cancelTime()
      },
      { label: 'Koosta arve', visible: this.orderServiceStatus === OrderServiceStatus.PRESENT && !this.presentOrders },
      {
        label: 'Ava Labor',
        visible: this.openReferralLaborVisible() && !this.presentOrders,
        command: () => this.openReferralLaborForm()
      },
      {
        label: 'Ava TSK', visible: !this.presentOrders && !!this.time, command: () => {
          this.sendSSTMessage()
        }
      },
      {
        label: 'Lõpetatud',// esli est time
        visible: this.orderServiceStatus !== OrderServiceStatus.FINISHED && !this.presentOrders && !!this.time,
        command: () => this.setFinished()
      },
      {
        label: 'Tühista kohal', // esli est time
        visible: this.orderServiceStatus === OrderServiceStatus.PRESENT && !!this.time,
        command: () => this.cancelPresent()
      },
      {label: 'Kuva ajalugu', visible: !this.presentOrders, command: () => this.showPatientReservationHistory()}, // vsegda
      {
        label: this.nextOrderId ? 'Ava külastuse tellimus' : 'Lisa uus tellimus',
        visible: this.orderServiceStatus === OrderServiceStatus.PRESENT && !this.presentOrders,
      },
      {
        label: 'Vabasta saatekiri',
        visible: this.releaseReferralVisible(),
        command: () => this.deleteReferral()
      },
      //{label: 'Loo uus tellimus', visible: this.presentOrders}, //ok
    ];
  }

  toggleMenu(event: any, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
    this.isMenuOpen = !this.isMenuOpen;
    this.menuOpenChange.emit(this.isMenuOpen);

    if (this.isMenuOpen) {
      this.documentClickListener = this.renderer.listen('document', 'click', (e) => {
        if (!this.el.nativeElement.contains(e.target) && !overlayPanel.el.nativeElement.contains(e.target)) {
          this.isMenuOpen = false;
          this.menuOpenChange.emit(this.isMenuOpen);
          if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
          }
        }
      });
    }
  }


  private getOrderServiceStatus(): OrderServiceStatus | null | undefined {
    return this.time ? (this.time.orderServiceTimeStatus || this.time.orderServiceQueueStatus || this.time.orderServiceStatus) : null;
  }

  private printActionVisible(): boolean {
    const statuses = [OrderServiceStatus.CANCELLED, OrderServiceStatus.FINISHED, OrderServiceStatus.ABSENCE];

    return (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceTimes &&
      this.orderServiceData.orderServiceTimes.length > 0 &&
      this.orderServiceData.orderServiceTimes.some(ost => ost.orderServiceTimeStatus && !statuses.includes(ost.orderServiceTimeStatus))
    ) || (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceQueues &&
      this.orderServiceData.orderServiceQueues.length > 0 &&
      this.orderServiceData.orderServiceQueues.some(osq => osq.orderServiceTimeStatus && !statuses.includes(osq.orderServiceTimeStatus))
    );
  }

  private bookTimeActionVisible(): boolean {
    if (!this.time) return false;

    const todayDate = new Date();
    const currentTime = todayDate.toTimeString().split(' ')[0];
    const bookingDate = new Date(this.time?.date);
    const bookingTimeStart = this.time?.timeFrom;
    const statuses = [OrderServiceStatus.CONFIRMED, OrderServiceStatus.IN_PROVIDER_QUEUE, OrderServiceStatus.PREPARING, OrderServiceStatus.ABSENCE];
    const isSameDay = todayDate.toDateString() === bookingDate.toDateString();
    const bookingInFuture = isSameDay ? currentTime < bookingTimeStart : todayDate < bookingDate;

    return !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus) && bookingInFuture;
  }

  private cancelTimeActionVisible(): boolean {
    const statuses = [
      OrderServiceStatus.CONFIRMED,
      OrderServiceStatus.PREPARING,
      OrderServiceStatus.IN_PROVIDER_QUEUE,
      OrderServiceStatus.IN_SERVICE_QUEUE
    ];

    return !!this.time && !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus);
  }

  private openReferralLaborVisible(): boolean {
    return !!this.time && !!this.time.referralType && this.time.referralStatus === OrderReferralStatus.CONFIRMED && !!this.time.referralId;
  }

  private releaseReferralVisible(): boolean {
    if (!this.time) return false;

    const timeParts = this.time.timeUpto.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    const date = new Date(this.time.date);
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    date.setSeconds(date.getSeconds() + seconds);

    return date < new Date() && this.orderServiceStatus === OrderServiceStatus.CONFIRMED && this.orderService?.referralId !== null;
  }

  private filterInvoiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.INVOICE_STATUS);
  }

  private filterOrderServiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.STATUS || log.statusField === this.OrderServiceLogStatus.REFERRAL_STATUS);
  }

  showPatientReservationHistory() {
    forkJoin({
      orderServiceLogs: this.logService.getOrderServiceLogs( (this.time ?? this.orderService)!.orderServiceId ),
      orderServiceNotificationLogs: this.logService.getOrderServiceNotificationLogs( (this.time ?? this.orderService)!.orderServiceId )
    }).subscribe({
      next: (response) => {
        const orderServiceLogs = this.filterOrderServiceLogs(response.orderServiceLogs);
        const orderServiceInvoiceLogs = this.filterInvoiceLogs(response.orderServiceLogs);
        const orderServiceNotificationLogs = Array.isArray(response.orderServiceNotificationLogs) ? response.orderServiceNotificationLogs : [response.orderServiceNotificationLogs];
        this.modalService.openModal({
          component: TableHistoryComponent,
          size: ModalSize.MEDIUM,
          data: {
            orderServiceLogs,
            orderServiceInvoiceLogs,
            orderServiceNotificationLogs,
          },
          header: `${(this.time ?? this.orderService)!.serviceName} - staatuse ajalugu (tellimuse nr. ${(this.time ?? this.orderService)!.orderId})`
        });
      },
      error: (e: Error) => {
        this.toastService.error(e.message);
      }
    });
  }

  protected readonly OrderServiceLogStatus = OrderServiceLogStatus;

  private sendSSTMessage(): void {
    let httpParams = new HttpParams()
      .set("providerId", this.time!.providerId ?? 0)
      .set("timeFrom", this.time!.timeFrom)
      .set("date", this.time!.date);
    this.planTimeService
      .getPlanTimeByTime(httpParams)
      .subscribe({
        next: (response) => {
          this.sstService.open({
            patientId: this.time!.patientId,
            patientCode: this.time!.patientIDcode,
            providerId: this.time!.providerId,
            providerCode: this.time!.providerCode,
            departmentId: response[0].departmentId,
            departmentName: response[0].departmentName
          })
        }
      });
  }

  private cancelTime(): void {
    const invoiceCompletedStatuses = [InvoiceStatus.COMPLETED, InvoiceStatus.PREPAID];
    const CONFIRMATION_MODAL_TITLE = 'Tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Teenuse eest on tasutud, kas olete kindel, et soovite broneeringu koos arvega tühistada? Broneeringu aja muutmisel arvet ei tühistata.'

    if (this.order?.invoiceStatus && invoiceCompletedStatuses.includes(this.order.invoiceStatus)) {
      const ref = this.modalService.openModal({
        component: ConfirmModalComponent,
        size: ModalSize.SMALL,
        header: CONFIRMATION_MODAL_TITLE,
        data: {bodyText: CONFIRMATION_MODAL_BODY_TEXT}
      })

      ref.onClose.subscribe((confirmed: boolean | string) => {
        if (confirmed) {
          this.openCancellationConfirmationModal();
        }
      })
    } else {
      this.openCancellationConfirmationModal();
    }
  }

  private openCancellationConfirmationModal(): void {
    const CONFIRMATION_MODAL_TITLE = 'Tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid tühistada? Kui Jah, siis sisesta põhjus'

    const ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: {
        bodyText: CONFIRMATION_MODAL_BODY_TEXT,
        showReason: true,
        showReasonOptions: this.time?.service ? this.time.service.isRehabilitation : this.time!.isRehabilitation,
      }
    })

    ref.onClose.subscribe((confirmation: any) => {
      if (confirmation) {
        let reasonData: IReason = {};
        if (confirmation.reason) reasonData.reason = confirmation.reason;

        let id = this.time?.orderServiceQueueId ?? this.time?.orderServiceTimeId;
        if (!id) {
          return this.toastService.error('OrderServiceTimeId või OrderServiceQueueId on puudu.');
        }

        const request = this.time?.orderServiceQueueId
          ? (id: number, reasonData: IReason) => this.orderServiceQueueService.cancelOrderServiceTimeQueue(id, reasonData)
          : (id: number, reasonData: IReason) => this.orderServiceTimeService.cancelOrderServiceTime(id, reasonData);

        request(id, reasonData)
          .subscribe({
            next: () => {
              this.onTimeChanged.emit({time: this.time, status: OrderServiceStatus.CANCELLED});
            },
            error: (err) => {
              this.toastService.error(err.message);
            }
        })
      }
    })
  };

  private setFinished(): void {
    const CONFIRMATION_MODAL_TITLE = 'Lõpetatamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid lõpetada? Kui Jah, siis sisesta põhjus.'

    const ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: {bodyText: CONFIRMATION_MODAL_BODY_TEXT, showReason: true}
    })

    ref.onClose.subscribe((confirmed: any) => {
      if (confirmed) {
        let reasonData: IReason = {};
        if (confirmed.reason) reasonData.reason = confirmed.reason;

        const request = this.time?.orderServiceQueueId
          ? (id: number, reasonData: { reason?: string  }) => this.orderServiceQueueService.setFinished(id, reasonData)
          : (id: number, reasonData: { reason?: string  }) => this.orderServiceTimeService.setFinished(id, reasonData);

        request(this.time?.orderServiceQueueId ?? this.time?.orderServiceTimeId ?? 0, { reason: reasonData!.reason})
          .subscribe({
          next: () => {
            this.onTimeChanged.emit({time: this.time, status: OrderServiceStatus.FINISHED});
          },
          error: (err) => {
            this.toastService.error(err.message);
          }
        });
      }
    })
  }

  cancelPresent(): void {
    const CONFIRMATION_MODAL_TITLE = 'Kohal tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid "Kohal" tühistada?'

    const modalRef = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: {bodyText: CONFIRMATION_MODAL_BODY_TEXT, showReason: true}
    })

    modalRef.onClose.subscribe((resp: any) => {
      if (resp && this.time) {
        let reasonData: IReason = {};
        if (resp.reason) reasonData.reason = resp.reason;

        if (this.time.orderServiceTimeId) {
          this.orderServiceTimeService
            .cancelPresent(this.time.orderServiceTimeId, reasonData)
            .subscribe({
              next: () => {
                this.onTimeChanged.emit({time: this.time, status: OrderServiceStatus.CONFIRMED});
              },
              error: (err) => {
                this.toastService.error(err.message);
              }
            })
        }
        if (this.time.orderServiceQueueId) {
          this.orderServiceQueueService
            .cancelPresent(this.time.orderServiceQueueId, reasonData)
            .subscribe({
              next: () => {
                this.onTimeChanged.emit({time: this.time, status: OrderServiceStatus.CONFIRMED});
              },
              error: (err) => {
                this.toastService.error(err.message);
              }
            })
        }
      }
    })
  }

  private deleteReferral(): void {
    const CONFIRMATION_MODAL_TITLE = 'Saatekirja vabastamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid saatekirja vabastada?'

    const modalRef = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: { bodyText: CONFIRMATION_MODAL_BODY_TEXT }
    })

    modalRef.onClose.subscribe((resp: any) => {
      if (resp) {
        this._orderService.deleteReferral(this.time!.orderServiceId).subscribe({
          next: () => {
            this.toastService.success('Saatekiri vabastatud!')
          },
          error: (err) => {
            this.toastService.error(err.message);
          }
        });
      }
    })
  }

  private openReferralLaborForm(): void {
    if (this.time?.referralId) {
      this._orderService.getReferral(this.time.referralId)
        .subscribe({
          next: (response) => {
            if (response && response.externalReferralId) {
              this.externalReferralService.openReferralLaborForm(+response.externalReferralId);
            }
          }
        })
    }
  }
}
