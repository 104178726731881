import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'time',
  standalone: true
})
export class TimePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: string | undefined, format: string = 'HH:mm'): string {
    if (value) {
      return this.datePipe.transform(value, format) ?? '';
    }
    return '';
  }

}
