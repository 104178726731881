import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PlanTimeCopyType } from "@core/enums/plantime-copy-type.enum";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IPlanTime } from "@core/interfaces/plan-time.interface";
import { ToastService } from "@core/services/toast.service";
import { PlanTimeService } from "@core/services/plan-time.service";
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, timer } from "rxjs";

@Component({
  selector: 'medis-copy-plantime-modal',
  templateUrl: './copy-plantime.modal.html',
  styleUrl: './copy-plantime.modal.scss'
})

export class CopyPlantimeModal {
  copyForm!: FormGroup;
  selectedCopyType: PlanTimeCopyType = PlanTimeCopyType.REPEAT_DAYS;
  copyOptions: {label: string; value: string}[] = [
    { label: 'Korduvad päevad', value: PlanTimeCopyType.REPEAT_DAYS },
    { label: 'Üksikpäevad', value: PlanTimeCopyType.SINGLE_DAYS }
  ];

  selectedWeekDays: number[] = [];
  weekDayOptions: any[] = [
    { label: 'E', value: 1 },
    { label: 'T', value: 2 },
    { label: 'K', value: 3 },
    { label: 'N', value: 4 },
    { label: 'R', value: 5 },
  ];
  planTimes: IPlanTime[] = []
  totalRequests: number = 0;
  completedRequests: number = 0;
  isCopying: boolean = false;
  isRequestsReady: boolean = false;
  progress: number = 0;
  errorArray: { message: string; errorCode: string; date: string }[] = [];
  private progressTimeout!: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    public toastService: ToastService,
    public ref: DynamicDialogRef,
    public planTimeService: PlanTimeService,
    private cdRef: ChangeDetectorRef,
  ) {
    if(this.config.data.planTimes){
      this.planTimes = this.config.data.planTimes;
    } else {
      this.toastService.warn("Tekkis viga. Palun proovige hiljem");
      this.ref.close();
    }
    this.createCopyForm()
  }

  private createCopyForm() {
    this.copyForm = this.formBuilder.group({
      dates: this.formBuilder.array(
        [this.createDateGroup()],
        Validators.required
      ),
    });
  }

  private createDateGroup(date: Date = new Date()): FormGroup {
    return this.formBuilder.group({
      date: [date, Validators.required],
    });
  }

  addDate() {
    const lastDateGroup = this.dates.at(this.dates.length - 1);
    const lastDate = lastDateGroup ? lastDateGroup.value.date : new Date();
    this.dates.push(this.formBuilder.group({
      date: [lastDate, Validators.required],
    }));
  }

  removeDate(indexToRemove: number) {
    this.dates.removeAt(indexToRemove);
  }

  getDateFormGroup(index: number): FormGroup {
    return this.dates.at(index) as FormGroup;
  }

  get dates(): FormArray {
    return this.copyForm.get('dates') as FormArray;
  }

  changeCopyOptions(event: any){
    this.selectedCopyType = event.value;
  }

  changeWeekDaysOptions(event: any){
    this.selectedWeekDays = event.value;
  }

  closeModal(){
    this.selectedWeekDays = [];
    this.dates.controls = [];
    this.progress = 0;
    if(this.isRequestsReady){
      this.isRequestsReady = false;
      this.isCopying = false;
    }
    this.ref.close();
  }

  private handleCopyRequest(date: string, planTimeId: number){
    const config = {
      planTimeId: planTimeId,
      date: date,
    }
    this.planTimeService.copyPlanTime(config).subscribe({
      next: () => {
        this.updateProgress();
      },
      error: (err: any) => {
        this.handleError(err, config.date);
        this.updateProgress();
      }
    })
  }

  private handleError(err: any, date: string) {
    if (err && err.error && err.error.errors) {
      err.error.errors.forEach((errorItem: any) => {
        this.errorArray.push({
          message: errorItem.message,
          errorCode: errorItem.errorCode,
          date: date,
        });
      });
    }
  }

  private checkCompletion() {
    if (this.completedRequests >= this.totalRequests) {
      this.finalizeProgress();
    }
  }

  private finalizeProgress() {
    this.isRequestsReady = true;
    this.completedRequests = 0;
    this.totalRequests = 0;
    this.progressTimeout.unsubscribe();
  }

  private updateProgress(): void {
    this.completedRequests += 1;
    this.progress = Math.round((this.completedRequests / this.totalRequests) * 100);
    this.cdRef.detectChanges();
    this.checkCompletion();
  }

  private startProgressSimulation() {
    this.progress = 0;
    const interval = setInterval(() => {
      if (this.progress < 90) {
        this.progress += Math.floor(Math.random() * 10) + 1;
        this.cdRef.detectChanges();
      }
      if (this.isRequestsReady || this.progress >= 100) {
        this.progress = 100;
        clearInterval(interval);
      }
    }, 1000);
  }

  copyPlanTimes(){
    if(this.selectedCopyType === PlanTimeCopyType.REPEAT_DAYS){
      const dateUpto = this.dates.controls[0].value.date;
      if(this.selectedWeekDays.length > 0 && this.isFutureDate(dateUpto)) {
        const datesToSend: Date[] = this.getDatesForRepeatedDays(dateUpto);
        this.totalRequests = datesToSend.length * this.planTimes.length;

        if (this.totalRequests === 0) {
          this.toastService.warn("Pole töögraafikuid kopeerimiseks");
          return;
        }

        this.isCopying = true;

        const maxWaitTime = 20000;
        this.progressTimeout = timer(maxWaitTime).subscribe(() => {
          this.finalizeProgress();
        });

        this.startProgressSimulation();

        for (const planTime of this.planTimes) {
          for (const date of datesToSend) {
            this.handleCopyRequest(date.toISOString().slice(0, -1), planTime.planTimeId);
          }
        }
      } else if(this.selectedWeekDays.length < 1){
        this.toastService.warn("Palun valige korduvad päevad");
      } else if(!this.isFutureDate(dateUpto)) {
        this.toastService.warn("Palun valige tuleviku kuupäeva");
      }
    } else if(this.selectedCopyType === PlanTimeCopyType.SINGLE_DAYS){
      const date = this.dates.controls[0].value.date;
      if(!date){
        this.toastService.warn("Palun valige kuupäeva");
      } else if(!this.isFutureDate(date)) {
        this.toastService.warn("Palun valige tuleviku kuupäeva");
      } else {
        const choosedDates = this.getDatesForSingleDays(this.dates.controls.map(date => date.value.date))

        this.totalRequests = choosedDates.length * this.planTimes.length;

        if (this.totalRequests === 0) {
          this.toastService.warn("Pole töögraafikuid kopeerimiseks");
          return;
        }

        this.isCopying = true;

        const maxWaitTime = 20000;
        this.progressTimeout = timer(maxWaitTime).subscribe(() => {
          this.finalizeProgress();
        });

        this.startProgressSimulation();

        for (const planTime of this.planTimes) {
          for (const date of choosedDates) {
            this.handleCopyRequest(date, planTime.planTimeId);
          }
        }
      }
    }
  }

  private isFutureDate(date: Date): boolean{
    const currentDate = new Date();
    return date > currentDate;
  }

  private getDatesForSingleDays(singleDates: Date[]){
    const dates: string[] = [];
    for (const date of singleDates) {
      const formattedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      dates.push(formattedDate.toISOString().slice(0, -1));
    }
    return dates;
  }

  private getDatesForRepeatedDays(dateUpto: Date): Date[] {
    const dates: Date[] = [];
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() + 1);

    const endDate = new Date(dateUpto);
    endDate.setHours(0, 0, 0, 0);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (this.selectedWeekDays.includes(dayOfWeek)) {
        dates.push(new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  protected readonly PlanTimeCopyType = PlanTimeCopyType;
}
