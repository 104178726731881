import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import {IGoods} from "../interfaces/goods.interface";

@Injectable({
  providedIn: 'root'
})
export class GoodsService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/goods`;
  }

  public get(id: number): Observable<IGoods> {
    return this.http.get<IGoods>(`${this.BACKEND_URL}/${id}`);
  }

  public getAll(): Observable<IGoods[]> {
    return this.http.get<IGoods[]>(`${this.BACKEND_URL}`);
  }

  public save(goods: IGoods): Observable<IGoods> {
    return this.http.post<IGoods>(`${this.BACKEND_URL}/save`, goods);
  }
}
