export enum OrderReferralETervisStatusEnum {
  OPEN = "AVATUD",
  LOCKED = "LUKUSTATUD",
  FINISHED = "LOPETATUD",
  BY_INVITATION = "VASTUVOTUKUTSEGA",
  RECEPTION_HAS_HAPPENED = "VASTUVOTT_TOIMUNUD",
  NOT_APPEAR = "EI_ILMUNUD",
  OUTDATED = "AEGUNUD",
  CANCELLED = "TYHISTATUD"

}
