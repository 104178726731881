import { Pipe, PipeTransform } from '@angular/core';
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IPerson } from "@core/interfaces/person.interface";

@Pipe({
  name: 'userFullName',
  standalone: true
})
export class UserFullNamePipe implements PipeTransform {
  transform(user: ICurrentUser|IPerson|null): string {
    if(!user) return '';
    return user.firstName + ' ' + user.lastName;
  }

}
