import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { OrderServiceService } from "@core/services/order-service.service";
import { IUpdateOrderServiceDiscountRequest } from "@core/interfaces/update-order-service-discount-request.interface";
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-discount-select',
  templateUrl: './discount-select.component.html',
  styleUrl: './discount-select.component.scss'
})

export class DiscountSelectComponent implements OnInit {
  @Input() unpaidServices: IOrderServicePayment[] = [];

  @Output() onDiscountSelect: EventEmitter<{ discount: number; reason: string }> = new EventEmitter();

  discountForm!: FormGroup;
  formSubmitted: boolean = false;
  discounts = [
    { label: '0%', value: 0 },
    { label: '5%', value: 5 },
    { label: '10%', value: 10 },
    { label: '20%', value: 20 },
    { label: '30%', value: 30 },
    { label: '40%', value: 40 },
    { label: '50%', value: 50 },
    { label: '100%', value: 100 },
  ];
  discountChanged: boolean = false;
  hasDiscountReason: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private orderServiceService: OrderServiceService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.hasDiscountReason = !!this.unpaidServices[0].discountReason;
    this.createDiscountForm();
  }

  private createDiscountForm(): void {
    this.discountForm = this.formBuilder.group({
      discount: [this.unpaidServices[0].discount ?? 0],
      reason: [this.unpaidServices[0].discountReason ?? ''],
    })
  }

  selectDiscount(): void {
    this.discountChanged = this.unpaidServices[0].discount !== this.discount.value;
    if (this.discountChanged) {
      this.reason.setValue('');
      this.reason.setValidators([Validators.required]);
    } else {
      this.hasDiscountReason = !!this.unpaidServices[0].discountReason;
      this.reason.setValue(this.unpaidServices[0].discountReason);
      this.reason.setValidators([]);
    }

    this.discountForm.updateValueAndValidity();
  }

  submit(): void {
    this.formSubmitted = true;
    if (!this.reason.value) {
      return;
    }
    this.discountChanged = false;
    this.updateDiscount(this.discount.value, this.reason.value);
  }

  private updateDiscount(discount: number, reason: string): void {
    let updateData: IUpdateOrderServiceDiscountRequest = {
      orderServiceIds: this.unpaidServices.map(us => us.orderServiceId),
      discount,
      discountReason: reason
    };

    this.orderServiceService.updateDiscount(updateData)
      .subscribe({
        next: () => { this.onDiscountSelect.emit(); },
        error: () => { this.toastService.error('Allahindluse muutmine ebaõnnestus'); },
      })
  }

  get discount(): FormControl {
    return this.discountForm.get('discount') as FormControl;
  }

  get reason(): FormControl {
    return this.discountForm.get('reason') as FormControl;
  }
}
