<div class="w-screen h-screen overflow-hidden">
  @if (authorized) {
  <medis-header [(sidebarOpen)]="sidebarOpen"></medis-header>
}

  <main class="page h-full w-full flex surface-ground">
    @if (authorized) {
      <medis-sidebar [sidebarOpen]="sidebarOpen"></medis-sidebar>
    }
    <div class="page__content flex-grow-1 overflow-auto pb-8">
      <router-outlet>
      </router-outlet>
    </div>
  </main>
</div>

<medis-confirm-action-outlet></medis-confirm-action-outlet>
<p-toast [life]="7000"
         position="bottom-right">

</p-toast>

