<div class="w-full flex flex-column pb-4 gap-4">
  <div class="flex flex-column w-full gap-1">
    <medis-patient-search-list label="Otsi patsienti"
                               (patientSelected)="selectedPatient($event)"
    ></medis-patient-search-list>
    @if (showError && !patient) {
      <small class="p-error">Salvestamiseks palun valige patsienti</small>
    }
  </div>
  <div class="modal-footer flex justify-content-end gap-2">
    <p-button type="button"
              [outlined]="true"
              severity="secondary"
              label="Katkesta"
              (click)="cancel()"
    ></p-button>
    <p-button type="button"
              severity="secondary"
              label="Lisa uus patsient"
              (click)="addNewPatient()"
              [loading]="loading"
    ></p-button>
    <p-button type="submit"
              label="Salvesta"
              (click)="submit()"
              [loading]="loading"
    ></p-button>
  </div>
</div>
