import { Component, Input, OnInit } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { FullSexPipe } from "@shared/pipes/full-sex.pipe";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { PatientFormComponent } from "app/feature/patients/patient-form/patient-form.component";
import { PersonAddressPipe } from "@shared/pipes/person-address.pipe";
import { DateTimeDisplayPipe } from "@shared/pipes/date-time-display.pipe";

@Component({
  selector: 'medis-order-patient',
  providers: [PersonAddressPipe, DateTimeDisplayPipe],
  templateUrl: './order-patient.component.html',
  styleUrl: './order-patient.component.scss'
})
export class OrderPatientComponent implements OnInit {
  @Input() patient!: IPatient;

  userDetails: any;

  constructor(
    private genderPipe: FullSexPipe,
    private addressPipe: PersonAddressPipe,
    private modalService: ModalService,
    ) {
  }

  ngOnInit() {
    if (this.patient) {
      this.initializeUserDetails();
    }
  }

  initializeUserDetails(): void {
    this.userDetails = {
      leftInfo: [
        { label: 'Eesnimi', value: this.patient?.firstname },
        { label: 'Perekonnanimi', value: this.patient?.lastname },
        { label: 'Isikukood', value: this.patient?.idCode },
        { label: 'Sugu', value: this.genderPipe.transform(this.patient?.gender) },
      ],
      rightInfo: [
        { label: 'Telefon', value: this.patient?.contact?.phone },
        { label: 'E-post', value: this.patient?.contact?.email },
        { label: 'Aadress', value: this.addressPipe.transform(this.patient?.contact) },
      ]
    };
  }

  openPatientEdit(patient: IPatient): void {
    let ref = this.modalService.openModal({
      header: 'Patsiendi andmete muutumine',
      size: ModalSize.LARGE,
      component: PatientFormComponent,
      data: {
        patient: patient
      },
    });

    ref.onClose.subscribe((result: any) => {
      if (result?.patient) {
        this.patient = result.patient;
        this.initializeUserDetails();
      }
    })
  }
}
