import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BookingsService } from "@core/services/bookings.service";
import { ToastService } from "@core/services/toast.service";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";
import { IOrderReservationOutput } from "@core/interfaces/order-reservation-output.interface";

@Component({
  selector: 'app-change-time',
  templateUrl: './change-time.component.html',
  styleUrl: './change-time.component.scss'
})
export class ChangeTimeComponent {
  config: IOrderReservationConfig;
  orderReservationOutput: IOrderReservationOutput|null = null;
  returnSavedData: boolean = false;
  orderServiceTimeIds: number[] = [];

  setOrderReservationOutput(output: IOrderReservationOutput) {
    this.orderReservationOutput = output;
  }

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public bookingsService: BookingsService,
    public ref: DynamicDialogRef,
    private toastService: ToastService,
  ) {
    this.config = this.dynamicDialogConfig.data;
    this.orderServiceTimeIds = this.config.times?.map(time => time.orderServiceTimeId) ?? [];
    this.config.submitHandler = () => this.showSelectedPatient();
    this.returnSavedData = this.dynamicDialogConfig.data?.returnSavedData;
  }

  showSelectedPatient(){
    if(this.orderReservationOutput!.times && this.orderReservationOutput!.times.length){
      this.bookingsService.changeBooking({
        orderServiceTimeId: this.config.orderServiceTime?.orderServiceTimeId,
        orderServiceId: this.config.orderServiceTime?.orderServiceId ?? this.config?.orderService?.orderServiceId,
        orderServiceTimesModel: this.orderReservationOutput!.times.filter(time => !time.orderServiceTimeId),
        financialSourceId: this.orderReservationOutput?.financialSourceId,
        changedOrderServiceTimeIds: this.getChangedTimeIds(),
      }).subscribe({
        next: (response: IOrderService) => {
          if (response && this.ref) {
            this.ref.close(this.returnSavedData ? response : true);
          }
        },
        error: () => {
          this.toastService.error("Broneeringu muutmise viga");
        }
      })

    } else if(!this.orderReservationOutput!.patient){
      this.toastService.error("Teenuse broneerimiseks valige patsient.")
    } else if(!this.orderReservationOutput!.times.length){
      this.toastService.error("Teenuse broneerimiseks valige aeg.")
    } else if(!this.orderReservationOutput!.service){
      this.toastService.error("Teenuse broneerimiseks valige teenus.")
    }
  }

  private getChangedTimeIds(): number[] {
    const bookingTimes = this.orderReservationOutput?.times.map(time => time.orderServiceTimeId) ?? [];

    return this.orderServiceTimeIds.filter(id => !bookingTimes.includes(id));
  }
}
