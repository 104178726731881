import {
  Component, ContentChild, ElementRef,
  EventEmitter,
  Input, OnInit,
  Output, TemplateRef,
  ViewChild
} from '@angular/core';
import { FullCalendarComponent } from "@fullcalendar/angular";

@Component({
  selector: 'medis-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements OnInit {

  @ViewChild('calendar') calendar!: FullCalendarComponent;
  @Input() calendarOptions!: any;
  @Output() calendarObject = new EventEmitter();

  @ContentChild('eventContent') eventContent!: TemplateRef<any>;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {

    const element = this.elementRef.nativeElement;
    let observer = new MutationObserver(() => {
      if (this.calendar && this.calendar.getApi()) {
        this.calendar.templateMap['eventContent'] = this.eventContent;
        this.calendarObject.emit(this.calendar);
        observer.disconnect();
      }
    });
    observer.observe(element, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true
    });

  }
}
