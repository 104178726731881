import { Component, OnInit } from '@angular/core';
import { NgForOf } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { Button } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { FloatLabelModule } from "primeng/floatlabel";
import { InputTextareaModule } from "primeng/inputtextarea";

@Component({
  selector: 'medis-confirm-modal',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    Button,
    DropdownModule,
    FloatLabelModule,
    InputTextareaModule
  ],
  templateUrl: './confirm.modal.component.html',
  styleUrl: './confirm.modal.component.less'
})
export class ConfirmModalComponent implements OnInit {
  bodyText: string = '';
  showReasonOptions: boolean = false;
  showReason: boolean = false;
  showReasonError: boolean = false;
  immutableData: any = null;
  reasonOptions: string[] = [
    'Ravi saadud', 'Patsiendi surm', 'Patsient loobus teenusest', 'Patsient tasub ise', 'Tervisliku seisundi muudatus',
    'Edasi lükatud patsiendi soovil', 'Tervishoiuasutusest tingitud põhjus', 'Saatekirja vastus "ei teosta"', 'Üle aja läinud makse'
  ];

  reasonForm!: FormGroup;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      this.bodyText = this.instance.data['bodyText'];
      this.showReason = !!this.instance.data['showReason'];
      this.showReasonOptions = !!this.instance.data['showReasonOptions'];
    }
    this.reasonForm = this.formBuilder.group({
      reasonOption: [null],
      reason: [null],
    })
  }

  submit() {
    if (this.showReasonOptions && !this.reasonOption.value) {
      this.showReasonError = true;
      return;
    }

    this.showReasonError = false;
    const reasonObject = { reasonOption : this.reasonOption.value, reason: this.reason.value};
    this.ref.close(this.reason.value ?  reasonObject : true);
  }

  changeReasonOption(): void {
    this.showReasonError = !this.reasonOption.value;
  }

  cancel() {
    if (this.immutableData) this.ref.close(this.immutableData);
    this.ref.close();
  }

  closeModal(): void {
    this.ref.close();
  }

  get reasonOption(): FormControl {
    return this.reasonForm.get('reasonOption') as FormControl;
  }

  get reason(): FormControl {
    return this.reasonForm.get('reason') as FormControl;
  }
}
