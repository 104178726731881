import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { ServiceType } from "@core/enums/service-type";

@Component({
  selector: 'medis-service-complex-type-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        id="types"
        class="w-full"
        styleClass="w-full"
        appendTo="body"
        [options]="complexTypes"
        [filter]="complexTypes.length > 15"
        filterBy="name"
        [ngModel]="selectedComplexTypeId"
        (onChange)="onSelect($event.value)"
        optionValue="value"
        optionLabel="label"
      />
      <label class="text-color-secondary"
             for="types">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceComplexTypeSelectListComponent),
      multi: true
    }
  ]
})
export class ServiceComplexTypeSelectListComponent implements ControlValueAccessor {
  @Input() labelName: string = '';
  @Output() onTypeChange = new EventEmitter<IMapping>();

  complexTypes: any = [
    { value: ServiceType.SERVICE, label: "Teenus" },
    { value: ServiceType.COMBO_SERVICE, label: "Teenusepakett" },
    { value: ServiceType.IN_COMBO_ONLY, label: "Kuulub ainult paketti" },
    { value: ServiceType.FULL_SERVICE, label: "Kompleksteenus" },
  ]
  selectedComplexTypeId: number | undefined;

  constructor() {}

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedComplexTypeId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedComplexTypeId = value;
    this.onTypeChange.emit({types: value});
    this.onChange(value);
    this.onTouched();
  }
}
