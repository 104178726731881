import { Component, Input, OnInit } from "@angular/core";
import { IMarketingName } from "@core/interfaces/marketing-name.interface";
import { MarketingName } from "@core/enums/marketing-name.enum";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "medis-marketing-name",
  templateUrl: "./marketing-name.component.html",
  styleUrls: ["./marketing-name.component.scss"],
})
export class MarketingNameComponent implements OnInit {
  @Input() form!: FormGroup;

  marketingNameLanguages: IMarketingName[] = [
    { name: 'Turundusnimi', comments: 'Kommentaarid' },
    { name: 'Маркетинговое название', comments: 'Комментарии' },
    { name: 'Myyntiluvan haltija', comments: 'Kommentit' },
    { name: 'Marketing name', comments: 'Comments' },
  ];

  selectedMarketingNameLanguage: IMarketingName = this.marketingNameLanguages[0];

  tableViews = [
    { label: 'Eesti keeles', value: MarketingName.ESTONIAN },
    { label: 'Vene keeles', value: MarketingName.RUSSIAN },
    { label: 'Soome keeles', value: MarketingName.FINNISH },
    { label: 'Inglise keeles', value: MarketingName.ENGLISH },
  ];

  selectedView = MarketingName.ESTONIAN;

  ngOnInit() {
    this.changeMarketingNameTab(); // Initialize based on default selected language
  }

  changeMarketingNameTab() {
    this.selectedMarketingNameLanguage = this.marketingNameLanguages.find(
      (mnp) => mnp.name === this.selectedView
    ) ?? this.marketingNameLanguages[0];

    this.updateFormControls();
  }

  updateFormControls() {
    // Update the correct form fields based on selected language
    if (this.selectedView === MarketingName.ESTONIAN) {
      this.form.get('marketingName')?.setValue(this.form.get('marketingName')?.value);
      this.form.get('marketingDescription')?.setValue(this.form.get('marketingDescription')?.value);
    } else if (this.selectedView === MarketingName.RUSSIAN) {
      this.form.get('marketingNameRU')?.setValue(this.form.get('marketingNameRU')?.value);
      this.form.get('marketingDescriptionRU')?.setValue(this.form.get('marketingDescriptionRU')?.value);
    } else if (this.selectedView === MarketingName.FINNISH) {
      this.form.get('marketingNameFI')?.setValue(this.form.get('marketingNameFI')?.value);
      this.form.get('marketingDescriptionFI')?.setValue(this.form.get('marketingDescriptionFI')?.value);
    } else if (this.selectedView === MarketingName.ENGLISH) {
      this.form.get('marketingNameEN')?.setValue(this.form.get('marketingNameEN')?.value);
      this.form.get('marketingDescriptionEN')?.setValue(this.form.get('marketingDescriptionEN')?.value);
    }
  }

  getMarketingNameControl(): FormControl {
    switch (this.selectedView) {
      case MarketingName.ESTONIAN:
        return this.form.get('marketingName') as FormControl;
      case MarketingName.RUSSIAN:
        return this.form.get('marketingNameRU') as FormControl;
      case MarketingName.FINNISH:
        return this.form.get('marketingNameFI') as FormControl;
      case MarketingName.ENGLISH:
        return this.form.get('marketingNameEN') as FormControl;
      default:
        return new FormControl(); // Return an empty FormControl as fallback
    }
  }

  getMarketingDescriptionControl(): FormControl {
    switch (this.selectedView) {
      case MarketingName.ESTONIAN:
        return this.form.get('marketingDescription') as FormControl;
      case MarketingName.RUSSIAN:
        return this.form.get('marketingDescriptionRU') as FormControl;
      case MarketingName.FINNISH:
        return this.form.get('marketingDescriptionFI') as FormControl;
      case MarketingName.ENGLISH:
        return this.form.get('marketingDescriptionEN') as FormControl;
      default:
        return new FormControl(); // Return an empty FormControl as fallback
    }
  }
}
