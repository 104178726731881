export enum BadgeType {
  INFO = 'info',
  SUCCESS = 'success',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  DANGER = 'danger',
  PRIMARY = 'primary',
  DEFAULT = 'default',
  AZURE = 'azure',
  PINK = 'pink',
}
