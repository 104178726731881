import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IBookingConfirmationRequest } from "@core/interfaces/booking-confirmation-request.interface";
import { IOrderPaymentSmsRequest } from "@core/interfaces/order-payment-sms-request.interface";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/notifications`;
  }

  public sendBookingConfirmation(requestBody: IBookingConfirmationRequest): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/confirmBooking`, requestBody);
  }

  public sendServicesPaymentLink(query: IOrderPaymentSmsRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/payment/send`, query);
  }
}
