import { Component, Input, OnInit } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { IReferral } from "@core/interfaces/referral.interface";
import { IPerson } from "@core/interfaces/person.interface";
import { DateFormatPipe } from "@shared/pipes/date-format.pipe";

@Component({
  selector: 'medis-referral-data',
  templateUrl: './referral-data.component.html',
  providers: [DateFormatPipe],
  styleUrl: './referral-data.component.scss'
})
export class ReferralDataComponent implements OnInit {
  @Input() patient!: IPatient;
  @Input() referral!: IReferral | undefined;
  @Input() sentDate!: Date | undefined;
  @Input() provider!: IPerson | undefined;
  @Input() basicView: boolean = false;

  basicInfo: any;
  patientInfo: any;
  referralInfo: any;
  subscriberDoctorInfo: any;
  providerInfo: any;
  otherInfoBlocks: any;

  constructor(
    private dateFormatPipe: DateFormatPipe,
    ) {
  }

  ngOnInit() {
    if (this.referral) {
      this.initBasicInfo();
    }
    if (this.patient) {
      this.initPatientInfo();
    }
    if (this.referral) {
      this.initReferralInfo();
    }
    if (this.provider) {
      this.initProviderInfo();
    }
  }

  private initBasicInfo(): void {
    this.basicInfo = {
      leftInfo: [
        { label: 'Number', value: this.referral?.documentNr },
        { label: 'Eriala', value: this.referral?.targetProfession },
        { label: 'Teenus', value: `${this.referral?.targetServiceCode ?? ''} ${this.referral?.targetServiceName ?? ''}` },
        { label: 'Asutus', value: this.referral?.targetCompany },
        { label: 'Cito', value: this.referral?.cito },
      ],
      rightInfo: [
        { label: 'Salvestatud', value: this.dateFormatPipe.transform(this.referral?.created) },
        { label: 'Saadetud', value: this.dateFormatPipe.transform(this.referral?.documentDate) },
        { label: 'Kinnitatud', value: this.dateFormatPipe.transform(this.sentDate) },
        {
          label: 'Kehtivus',
          value: `${this.dateFormatPipe.transform(this.referral?.validFrom)} - ${this.dateFormatPipe.transform(this.referral?.validTo)}`
        }
      ]
    };
  }

  private initPatientInfo(): void {
    this.patientInfo = [
      { label: 'Nimi', value: this.patient.fullName },
      { label: 'Isikukood', value: this.patient.idCode },
      { label: 'Telefon', value: this.patient.contact.phone },
      { label: 'E-post', value: this.patient.contact.email },
    ]
  }

  private initReferralInfo(): void {
    this.referralInfo = [
      { label: 'Nimetus', value: this.referral?.sourceCompanyName },
      { label: 'Kood', value: this.referral?.sourceCompanyCode },
      { label: 'Telefon', value: this.referral?.sourceCompanyPhone },
      { label: 'Aadress', value: this.referral?.sourceCompanyAddress },
    ];
    this.subscriberDoctorInfo = {
      leftInfo: [
        { label: 'Nimi', value: this.referral?.authorName },
        { label: 'Arsti kood', value: this.referral?.authorCode },
      ],
      rightInfo: [
        { label: 'Eriala', value: this.referral?.authorProfession },
        { label: 'Eriala kood', value: this.referral?.authorProfessionCode },
      ]
    };
    this.otherInfoBlocks = [
      { label: 'Eesmärk', value: this.referral?.reason, visible: true },
      { label: 'Anamnees', value: this.referral?.anamnesis, visible: !this.basicView },
      { label: 'Staatus', value: null, visible: !this.basicView, isTable: true },
      { label: 'Diagnoos', value: this.referral?.diagnosis, visible: true },
      { label: 'Uuringud', value: this.referral?.procedures, visible: !this.basicView },
      { label: 'Info', value: this.referral?.info, visible: !this.basicView },
    ]
  }

  private initProviderInfo(): void {
    this.providerInfo = {
      leftInfo: [
        { label: 'Nimi', value: this.provider?.fullName },
        { label: 'Arsti kood', value: this.provider?.doctorCode },
        { label: 'Asutus', value: this.provider?.company },
      ],
      rightInfo: [
        { label: 'Eriala', value: this.provider?.personProfessionName },
        { label: 'Eriala kood', value: this.provider?.tehikSpecialityId },
      ]
    };
  }
}
