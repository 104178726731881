import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { IPatient } from "@core/interfaces/patient.interface";
import { interval, Subscription, switchMap, takeWhile } from "rxjs";
import { InvoiceService } from "@core/services/invoice.service";

@Component({
  templateUrl: './directo-payment.modal.component.html',
  styleUrl: './directo-payment.modal.component.scss'
})
export class DirectoPaymentModalComponent implements OnInit, OnDestroy {
  products: IOrderService[] = [];
  unpaidServices: IOrderServicePayment[] = [];
  patient: IPatient;
  link!: string;

  serviceInvoiceSubscription?: Subscription;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private invoiceService: InvoiceService,
  ) {
    this.products = this.dynamicDialogConfig.data?.products ?? [];
    this.unpaidServices = this.dynamicDialogConfig.data?.unpaidServices ?? [];
    this.patient = this.dynamicDialogConfig.data?.patient;
    this.link = this.dynamicDialogConfig.data?.link;
  }

  ngOnInit(): void {
    if (this.unpaidServices && this.unpaidServices.length > 0) {
      this.pollServiceInvoices();
    }
  }

  private pollServiceInvoices() {
    this.serviceInvoiceSubscription = interval(3000)
      .pipe(
        switchMap(() => this.invoiceService.checkInvoices(this.unpaidServices)),
        takeWhile((completed) => !completed)
      )
      .subscribe();
  }

  payAgain() {
    window.open(this.link, '_blank');
  }

  updateList(orderServiceId: number) {
    this.unpaidServices = this.unpaidServices.filter(s => s.orderServiceId !== orderServiceId);
    if (this.unpaidServices.length === 0) this.closeModal();
  }

  closeModal(): void {
    this.ref.close();
  }

  ngOnDestroy() {
    this.serviceInvoiceSubscription?.unsubscribe();
  }
}
