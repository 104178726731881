import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IStockCode } from "@core/interfaces/stock-code.interface";

@Pipe({
  name: 'stockCodesName',
  standalone: true
})
@Injectable({
  providedIn: 'root' // This makes the pipe available application-wide
})
export class stockCodesNamePipe implements PipeTransform {
  transform(stockCodes: IStockCode[]): string {
    return stockCodes
      .map(x => x.code)
      .join(', ');
  }

}
