import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEConsultation } from "@core/interfaces/e-consultation.interface";
import { EConsultationStatus } from "@core/enums/e-consultation-status.enum";
import { EConsultationService } from "@core/services/e-consultation.service";
import { ToastService } from "@core/services/toast.service";
import { Router } from "@angular/router";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import {
  EConsultationReplyModalComponent
} from "@shared/modals/e-consultation-reply/e-consultation-reply.modal.component";

@Component({
  selector: 'medis-e-consultation-actions',
  template: `
    @if (eConsultation.status == EConsultationStatus.CREATED && providerMode) {
      <p-button label="Vasta"
                (onClick)="assignAndResolveEConsultation()"
                [outlined]="true"
      />
    }
    @if (eConsultation.status == EConsultationStatus.ASSIGNED) {
      <p-button label="Vasta"
                (onClick)="resolveEConsultation()"
                [outlined]="true"
      />
    }
    @if (eConsultation.status == EConsultationStatus.RESOLVED) {
      <p-button severity="success"
                [label]="getConfirmButtonTitle()"
                (onClick)="resolveEConsultation()"
      />
    }
    @if (eConsultation.status == EConsultationStatus.CONFIRMED && eConsultation.orderId) {
      <p-button severity="primary"
                label="Vaata tellimus"
                (onClick)="resolveEConsultation()"
                [outlined]="true"
      />
    }
  `,
})
export class EConsultationActionsComponent {
  @Input() eConsultation!: IEConsultation;
  @Input() providerMode: boolean = false;

  @Output() onActionComplete = new EventEmitter();

  protected readonly EConsultationStatus = EConsultationStatus;
  private readonly DECISION_CODES = ['1', '2', '3', '4', '5'];

  constructor(
    private eConsultationService: EConsultationService,
    private toastService: ToastService,
    private router: Router,
    private modalService: ModalService,
  ) {}

  assignAndResolveEConsultation = () => {
    this.eConsultationService.assign(this.eConsultation).subscribe({
      next: () => {
        this.resolveEConsultation();
      },
      error: err => {
        this.toastService.error(err.message)
      }
    })
  }

  resolveEConsultation = () => {
    if (this.eConsultation.orderId) {
      return this.goToOrderEdit();
    }

    const ref = this.modalService.openModal({
      component: EConsultationReplyModalComponent,
      size: ModalSize.LARGE,
      header: 'E-konsultatsiooni vastus',
      data: { eConsultationId: this.eConsultation.eConsultationId, readonly: false }
    })

    ref.onClose.subscribe((updated: boolean) => {
      if (updated) {
        this.onActionComplete.emit();
      }
    })
  };

  private goToOrderEdit = () => {
    this.router.navigate([`order/edit/${this.eConsultation.orderId}`]);
  };

  getConfirmButtonTitle = () => {
    if (this.eConsultation.orderId) {
      return 'Jätka tellimuse täitmist';
    }
    return this.DECISION_CODES.includes(this.eConsultation.decisionCode) ? 'Ava kinnitamiseks' : 'Tee tellimus';
  };
}
