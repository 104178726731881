import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GazetteerService {
  private URL = `https://inaadress.maaamet.ee/inaadress/gazetteer`

  constructor(private http: HttpClient) { }

  public get(search: string): Observable<any> {
    let params = new HttpParams()
      .set('results', 10)
      .set('features', 'EHITISHOONE')
      .set('address', search)
      .set('appartment', 1)
      .set('ihist', 1993)
      .set('unik', 0)
      .set('tech', 1)
      .set('iTappAsendus', 0)
      .set('ky', 0)
      .set('poi', 0)
      .set('knr', 0)
      .set('help', 1);
    return this.http.get<any>(this.URL, { params });
  }
}
