import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  public error(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'error',
      summary: 'Viga',
      detail: message ?? "Default message",
    });
  }

  public message(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message ?? "Default message",
    });
  }

  public success(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message ?? "Default message",
    });
  }

  public warn(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'warn',
      summary: 'Warn',
      detail: message ?? "Default message",
    });
  }

  public contrast(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'contrast',
      summary: 'Contrast',
      detail: message ?? "Default message",
    });
  }

  public secondary(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'secondary',
      summary: summary ?? 'Secondary',
      detail: message ?? "Default message",
    });
  }
}
