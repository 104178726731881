import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IDevice } from "@core/interfaces/device.interface";

@Component({
  selector: "medis-device-add-modal",
  templateUrl: "./device-add.modal.html",
})

export class DeviceAddModal {

  selectedDeviceIdsWithNames: {id: number, name: string}[] = [];
  sourceDevices: IDevice[] = [];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    if(this.config.data.allDevices && this.config.data.selectedDevices){
      this.sourceDevices = this.config.data.allDevices;
      const selectedDevices = this.config.data.selectedDevices;
      selectedDevices.forEach((device: IDevice) => {
        if(device.deviceId){
          const selectedDeviceWithName = {
            id: device.deviceId,
            name: device.name + " " + device.deviceCode
          }
          this.selectedDeviceIdsWithNames.push(selectedDeviceWithName);
        }
      })
    } else {
      this.selectedDeviceIdsWithNames = [];
    }
  }

  saveRelatedServices(){
    this.ref.close(this.selectedDeviceIdsWithNames);
  }

  closeModal(){
    this.ref.close();
  }
}
