import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IPatient } from "@core/interfaces/patient.interface";
import { IPatientWorkplace } from "@core/interfaces/patient-workplace.interface";
import { HelperService } from "@core/services/helper.service";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { PatientService } from "@core/services/patient.service";
import { idCodeValidator } from "@shared/validators/idcodeValidator";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@core/services/toast.service";
import { MenuItem } from "primeng/api";
import { HREFTarget } from "@core/enums/HREFTarget.enum";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { IEmergencyContact } from "@core/interfaces/emergency-contact.interface";
import { IEuropeanUnionInsuranceDocument } from "@core/interfaces/european-union-insurance-document.interface";
import { FormValidationService } from "@core/services/form-validation.service";
import { CountryService } from "@core/services/country.service";
import { Country } from "@core/enums/country.enum";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MedisValidators } from "@shared/validators/medis-validators";

@Component({
  selector: 'medis-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrl: './patient-form.component.scss',
})
export class PatientFormComponent implements OnInit {
  patientForm: FormGroup = new FormGroup({});
  patient: IPatient = {} as IPatient;
  breadcrumbs?: MenuItem[] = [];

  isModal = false;
  showAdditionalData = false;

  constructor(private formBuilder: FormBuilder,
              private helperService: HelperService,
              private patientService: PatientService,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private upperCasePipe: UpperCasePipe,
              private countryService: CountryService,
              private formValidationService: FormValidationService,
              private router: Router,
              private dialogConfig: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private datePipe: DatePipe) {
    if (this.dialogConfig?.modal) {
      this.isModal = true;
    }
  }

  ngOnInit(): void {
    const patientId = this.activatedRoute.snapshot.params['id'] || this.dialogConfig?.data?.patientId;
    if (patientId) {
      this.patientService.get(patientId).subscribe({
        next: (data: IPatient) => {
          this.patient = data;
        },
        error: () => {
          this.toastService.error('Error fetching patient data');
        },
        complete: () => {
          this.init();
          this.showAdditionalData = true;
        },
      });
    } else {
      if (this.dialogConfig?.data?.patient) {
        this.patient = this.dialogConfig.data.patient;
      }
      this.init();
    }
  }

  private init(): void {
    this.initGeneralData();
    this.initAdditionalData();
    this.initBreadcrumbs();
  }

  get generalDataGroup(): FormGroup {
    return this.patientForm?.get('generalData') as FormGroup;
  }

  get additionalDataGroup(): FormGroup {
    return this.patientForm?.get('additionalData') as FormGroup;
  }

  get nationality(): FormControl {
    return this.generalDataGroup?.get('nationality') as FormControl;
  }

  handlePatientNationality(): void {
    this.handleDisabledFields(this.nationality?.value);
    this.nationality?.valueChanges.subscribe(value => {
      this.handleDisabledFields(value);
    });
  }

  private handleDisabledFields(value: string): void {
    let columns = [
      'firstname',
      'lastname',
      'gender',
      'birthday',
      'country'
    ];

    for (let col of columns) {
      if (value == Country.EST) {
        this.generalDataGroup.get(col)?.disable();
      } else {
        this.generalDataGroup.get(col)?.enable();
      }
    }
  }

  submit(): void {
    if (this.patientForm.invalid) {
      this.formValidationService.markControlAsDirty(this.patientForm);
      return;
    }

    const generalData = this.patientForm.getRawValue().generalData;
    const additionalData = this.patientForm.getRawValue().additionalData;

    let patientDto: IPatientDto = this.patientDtoFromGeneralData(generalData);

    if (generalData.contactCountry === Country.EST) {
      this.mapEstonianAddress(patientDto, generalData);
    } else {
      this.mapInternationalAddress(patientDto, generalData);
    }

    this.mapAdditionalData(patientDto, additionalData);
    this.savePatient(patientDto);
  }

  private patientDtoFromGeneralData(generalData: any): IPatientDto {
    return {
      patientId: this.patient?.patientId ?? 0,
      patientContactId: this.patient?.contact?.patientContactId ?? 0,
      newsletterAgreementId: this.patient?.newsletterAgreement?.newsletterAgreementId ?? 0,
      idCode: generalData.idCode,
      email: generalData.email,
      language: generalData.language,
      country: generalData.country,
      contactCountry: generalData.contactCountry,
      gender: generalData.gender,
      newsletterAgreementAgreed: generalData.newsletterAgreement,
      riskFactorsIds: generalData.riskFactorsIds,
      clientGroupsIds: generalData.clientGroupsIds,
      workplaces: generalData.workplaces,
      phone: generalData.phoneNumber?.phone
        ? this.constructPhone(generalData.phoneNumber.countryCode, generalData.phoneNumber.phone)
        : null,
      birthday: generalData.birthday
        ? this.datePipe.transform(generalData.birthday, 'yyyy-MM-dd')
        : null,
      firstName: generalData.firstname ?? null,
      lastName: generalData.lastname ?? null,
    };
  }

  private mapEstonianAddress(patientDto: IPatientDto, generalData: any): void {
    Object.assign(patientDto, {
      county: generalData.county ?? null,
      street: generalData.street ?? null,
      city: generalData.city ?? null,
      settlement: generalData.settlement ?? null,
      house: generalData.house ?? null,
      flat: generalData.flat ?? null,
      zip: generalData.zip ?? null,
      archive: generalData.archive ?? null,
    });
  }

  private mapInternationalAddress(patientDto: IPatientDto, generalData: any): void {
    patientDto.longAddress = generalData.longAddress ?? null;
  }

  private mapAdditionalData(patientDto: IPatientDto, additionalData: any): void {
    patientDto.bankAccount = additionalData.bankAccount ?? this.patient?.bankAccount ?? null;
    patientDto.bankAccountOwner = this.upperCasePipe.transform(additionalData.bankAccountOwner) ?? this.patient?.bankAccountOwner ?? null;
    patientDto.emergencyContacts = additionalData.emergencyContacts.map((contact: IEmergencyContact) => ({
      patientId: this.patient?.patientId ?? 0,
      emergencyContactId: contact.emergencyContactId ?? 0,
      firstName: contact.firstname,
      lastname: contact.lastname,
      idCode: contact.idCode,
      phone: contact.phone,
      email: contact.email,
      type: contact.type?.generalListId,
    })) ?? this.patient?.emergencyContacts ?? null;
    patientDto.euInsuranceDocument = this.mapInsuranceDocument(additionalData.europeanUnionInsuranceDocument) ?? this.patient.europeanUnionInsuranceDocument ?? null;
  }

  private mapInsuranceDocument(document: any): any {
    return {
      ...document,
      europeanUnionInsuranceDocumentId: this.patient?.europeanUnionInsuranceDocument?.europeanUnionInsuranceDocumentId ?? 0,
      patientId: this.patient?.patientId ?? 0,
      birthday: document.birthday
        ? this.datePipe.transform(document.birthday, 'yyyy-MM-dd')
        : null,
      issueDate: document.issueDate
        ? this.datePipe.transform(document.issueDate, 'yyyy-MM-dd')
        : null,
      validFromDate: document.validFromDate
        ? this.datePipe.transform(document.validFromDate, 'yyyy-MM-dd')
        : null,
      validToDate: document.validToDate
        ? this.datePipe.transform(document.validToDate, 'yyyy-MM-dd')
        : null,
    };
  }

  private deconstructPhone(phone: string): { prefix: string, phone: string } {
    let phonePrefix = this.countryService.getCountryPhoneCode(Country.EST);
    let phoneNumber = phone ?? '';
    if (phone && phone.startsWith('+')) {
      this.countryService.getAllCountryCodes().forEach((cc) => {
        if (phone.startsWith(cc)) {
          let tempPhone = phone;
          phonePrefix = cc;
          phoneNumber = tempPhone.replace(cc, '');
        }
      });
    }

    return { prefix: phonePrefix, phone: phoneNumber }
  }

  private constructPhone(prefix: string, phone: string): string {
    return (prefix ? prefix : '') + (phone ? phone : '');
  }

  private initGeneralData(): void {
    this.patientForm = this.formBuilder.group({
      generalData: this.formBuilder.group({
        patientId: [{ value: this.patient?.patientId, disabled: true }],
        nationality: [this.getNationality()],
        idCode: [this.patient?.idCode ?? null, [Validators.required, idCodeValidator]],
        firstname: [this.patient?.firstname ?? null, Validators.required],
        lastname: [this.patient?.lastname ?? null, Validators.required],
        gender: [this.patient?.gender ?? null, Validators.required],
        birthday: [this.patient?.birthday ? new Date(this.patient?.birthday) : null, Validators.required],
        clientGroupsIds: [this.patient?.clientGroupIds ? this.patient?.clientGroupIds : null],
        language: [this.patient?.language ?? null],
        email: [this.patient?.contact?.email ?? null, { validators: [Validators.email], updateOn: 'submit' }],
        phoneNumber: this.formBuilder.group({
          countryCode: [this.deconstructPhone(this.patient?.phone).prefix],
          phone: [this.deconstructPhone(this.patient?.phone).phone, Validators.pattern(/^\d+$/)],
        }),
        riskFactorsIds: [this.patient?.contact?.workplaceRiskFactors?.length ? (this.patient?.contact?.workplaceRiskFactors.map((wrf: IGeneralList) => wrf.generalListId)) : null],
        longAddress: [this.patient?.contact?.longAddress ?? null],
        country: [this.patient?.country ? this.patient?.country : null],
        contactCountry: [this.patient?.contact?.country ? this.patient?.contact?.country : null],
        county: [this.patient?.contact?.county ?? null],
        city: [this.patient?.contact?.city ?? null],
        settlement: [this.patient?.contact?.settlement ?? null],
        house: [this.patient?.contact?.house ?? null],
        street: [this.patient?.contact?.street ?? null],
        flat: [this.patient?.contact?.flat ?? null],
        zip: [this.patient?.contact?.zip ?? null],
        archive: [this.patient?.archive ?? null],
        workplaces:
          this.formBuilder.array(
            this.patient.workplaces ? this.patient.workplaces.map(
              (patient: IPatientWorkplace) => this.formBuilder.group({
                patientWorkplaceId: [patient.patientWorkplaceId || null],
                patientId: [patient.patientId ?? null],
                workplace: [patient.workplace || null],
                workplaceRegisterNumber: [patient.workplaceRegisterNumber || null],
                workplaceSubdivision: [patient.workplaceSubdivision || null],
                profession: [patient.profession || null],
                delete: [patient.delete || null]
              })) : []
          ),
        newsletterAgreement: [this.patient.newsletterAgreement?.agreed ?? false],
      })
    });

    this.handlePatientNationality();
  }

  private initAdditionalData(): void {
    this.patientForm?.addControl('additionalData', this.formBuilder.group(
      {
        emergencyContacts: this.formBuilder.array(
          this.patient.emergencyContacts ? this.patient.emergencyContacts.map(
            (contact: IEmergencyContact) => this.formBuilder.group({
              emergencyContactId: [contact.emergencyContactId ?? null],
              patientId: [contact.patientId ?? null],
              patientContactId: [contact.patientContactId ?? null],
              email: [contact.email ?? null],
              phone: [contact.phone ?? null],
              idCode: [contact.idCode ?? null],
              firstname: [contact.firstname ?? null],
              lastname: [contact.lastname ?? null],
              type: [contact.type ?? null],
              typeDescription: [contact.typeDescription ?? null],
            })
          ) : [],
        ),
        bankAccount: [this.patient?.bankAccount ?? null, {
          validators: [MedisValidators.IBAN],
          updateOn: 'submit'
        }],
        bankAccountOwner: [this.patient?.bankAccountOwner ?? null],
        europeanUnionInsuranceDocument: this.formBuilder.group({
          europeanUnionInsuranceDocumentId: [this.patient?.europeanUnionInsuranceDocument?.europeanUnionInsuranceDocumentId ?? null],
          patientId: [this.patient?.europeanUnionInsuranceDocument?.patientId ?? null],
          documentType: [this.patient?.europeanUnionInsuranceDocument?.documentType ?? null],
          documentNumber: [this.patient?.europeanUnionInsuranceDocument?.documentNumber ?? null],
          idCode: [this.patient?.europeanUnionInsuranceDocument?.idCode ?? null],
          firstname: [this.patient?.europeanUnionInsuranceDocument?.firstname ?? null],
          lastname: [this.patient?.europeanUnionInsuranceDocument?.lastname ?? null],
          birthday: [this.patient?.europeanUnionInsuranceDocument?.birthday ? new Date(this.patient?.europeanUnionInsuranceDocument?.birthday) : null],
          gender: [this.patient?.europeanUnionInsuranceDocument?.gender ?? null],
          country: [this.patient?.europeanUnionInsuranceDocument?.country ?? null],
          issueDate: [this.patient?.europeanUnionInsuranceDocument?.issueDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.issueDate) : null],
          validFromDate: [this.patient?.europeanUnionInsuranceDocument?.validFromDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.validFromDate) : null],
          validToDate: [this.patient?.europeanUnionInsuranceDocument?.validToDate ? new Date(this.patient?.europeanUnionInsuranceDocument?.validToDate) : null],
          authorityCode: [this.patient?.europeanUnionInsuranceDocument?.authorityCode ?? null],
        }) ?? null,
      })
    );
  }

  getNationality(): string | Country {
    if (!this.patient?.idCode || this.helperService.validatePersonCode(this.patient.idCode)) {
      return Country.EST;
    }
    return 'other';
  }

  initBreadcrumbs(): void {
    this.breadcrumbs =
      [
        {
          label: 'Patsiendid',
          url: '/patients/list',
          target: HREFTarget.SELF
        },
        {
          label: this.patient.fullName,
          url: `/patients/${this.patient.patientId}/details`,
          target: HREFTarget.SELF
        },
        {
          label: `Andmete muutumine`,
          disabled: true
        }
      ];
  }

  savePatient(patient: IPatientDto): void {
    // If we had patientId and isModal is true, it essentially means we
    // are quick-editing patient from PresentModalComponent
    const quickEditingPatient = this.patient?.patientId && this.isModal;
    this.patientService.save(patient).subscribe({
      next: (patient: IPatient) => {
        this.toastService.success('Patsientiandmete salvestuse õnnestus');

        if (this.isModal) {
          this.ref?.close({ patient: patient });
          return;
        }
        // And we redirect patient to /patients/view ONLY if we are NOT quick-editing
        if (!quickEditingPatient) {
          this.router.navigate([`/patients/${patient.patientId}/details`]);
        }
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  back(): void {
    if (this.isModal) {
      this.ref.close();
      return;
    }
    history.back();
  }
}

interface IPatientDto {
  patientId: number,
  patientContactId: number,
  newsletterAgreementId: number,
  idCode?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string | null,
  longAddress?: string,
  language?: string,
  country?: string,
  contactCountry?: string,
  street?: string,
  county?: string,
  city?: string,
  settlement?: string,
  house?: string,
  flat?: string,
  zip?: string,
  gender?: string,
  archive?: string,
  birthday?: string | null,
  newsletterAgreementAgreed?: boolean,
  riskFactorsIds?: number[],
  clientGroupsIds?: number[],
  bankAccount?: string,
  bankAccountOwner?: string,
  workplaces: IPatientWorkplace,
  emergencyContacts?: {
    emergencyContactId: number,
    firstname: string,
    lastname: string,
    idCode: string,
    phone: string,
    email: string,
    type: number,
  }[] | [],
  euInsuranceDocument?: IEuropeanUnionInsuranceDocument
}
