<div class="pb-4">
  <p-table styleClass="pb-4" [value]="serviceLogs">
    <ng-template pTemplate="header">
      <tr>
        <th>Aeg</th>
        <th>Kasutaja</th>
        <th>Nimi</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log>
      <tr>
        <td>{{ dt.transform(log.created) }}</td>
        <td>{{ log.userIdentifier }}</td>
        <td>{{ log.userName }}</td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex row justify-content-end">
    <p-button
      [outlined]="true"
      severity="secondary"
      label="Sulge"
      (click)="closeModal()"
    />
  </div>
</div>
