@if (basicInfo) {
  <div class="dialog__section border-round-md">
    <h3 class="dialog__section-title text-color-secondary text-xl font-bold">Põhiteave</h3>
    <div class="dialog__section-info flex flex-row" [style]="{gap: '28px'}">
      <div class="flex flex-column w-6">
        @for(item of basicInfo.leftInfo; track item){
          <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
            <div class="dialog__section-label">
              <span><b>{{item.label}}</b></span>
            </div>
            <p class="dialog__section-value m-0 text-color-secondary">{{item.value}}</p>
          </div>
        }
      </div>
      <div class="flex flex-column w-6">
        @for(item of basicInfo.rightInfo; track item){
          <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
            <div class="dialog__section-label">
              <span><b>{{item.label}}</b></span>
            </div>
            <p class="dialog__section-value m-0 text-color-secondary">{{item.value}}</p>
          </div>
        }
      </div>
    </div>
  </div>
}

  <div class="flex flex-row gap-4 pt-4 pb-4">
    @if (patient) {
      <div class="dialog__section border-round-md w-6">
        <div class="flex flex-row justify-content-between">
          <h3 class="dialog__section-title text-color-secondary text-xl font-bold">Patsient</h3>
          <p-tag
            [styleClass]="patient.isInsured ? 'tag--green' : 'tag--red'"
            class="flex align-self-center"
            rounded="true"
            [value]="patient.isInsured ? 'KINDLUSTATUD' : 'KINDLUSTAMATA'"
          ></p-tag>
        </div>
        <div class="dialog__section-info flex flex-column">
          @for(item of patientInfo; track item){
            <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
              <div class="dialog__section-label">
                <span><b>{{item.label}}</b></span>
              </div>
              <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
            </div>
          }
        </div>
      </div>
    }
    @if (referral) {
      <div class="dialog__section border-round-md w-6">
        <h3 class="dialog__section-title text-color-secondary text-xl font-bold">Suunaja</h3>
        <div class="dialog__section-info flex flex-column">
          @for(item of referralInfo; track item){
            <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
              <div class="dialog__section-label">
                <span><b>{{item.label}}</b></span>
              </div>
              <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
            </div>
          }
        </div>
      </div>
    }
  </div>

<div class="accordion flex flex-column">
  @if (referral) {
    <p-accordion>
      <p-accordionTab header="Tellija arst" [selected]="true">
        <div class="accordion__section flex flex-row">
          <div class="w-6">
            @for(item of subscriberDoctorInfo.leftInfo; track item){
              <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                <div class="dialog__section-label">
                  <span><b>{{ item.label }}</b></span>
                </div>
                <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
              </div>
            }
          </div>
          <div class="w-6">
            @for(item of subscriberDoctorInfo.rightInfo; track item){
              <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                <div class="dialog__section-label">
                  <span><b>{{ item.label }}</b></span>
                </div>
                <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
              </div>
            }
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  }

  @if (provider && !basicView) {
    <p-accordion>
      <p-accordionTab header="Vastaja arst" [selected]="true">
        <div class="accordion__section flex flex-row">
          <div class="w-6">
            @for(item of providerInfo.leftInfo; track item){
              <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                <div class="dialog__section-label">
                  <span><b>{{ item.label }}</b></span>
                </div>
                <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
              </div>
            }
          </div>
          <div class="w-6">
            @for(item of providerInfo.rightInfo; track item){
              <div class="dialog__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                <div class="dialog__section-label">
                  <span><b>{{ item.label }}</b></span>
                </div>
                <p class="dialog__section-value m-0 text-color-secondary">{{ item.value }}</p>
              </div>
            }
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  }

  @for (item of otherInfoBlocks; track item) {
    <p-accordion [id]="$index">
      <p-accordionTab [header]="item.label">
        @if (item.isTabel && referral?.objectiveFindings) {
          <p-table styleClass="p-datatable--round" [value]="referral?.objectiveFindings || []">
            <ng-template pTemplate="header">
              <tr>
                <th>Kuupäev</th>
                <th>Üldseisund</th>
                <th>Üldseisundi kirjeldus</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>{{ item.date | dateTimeDisplay: 'dd.MM.yyyy' }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </ng-template>
          </p-table>
        } @else {
          <p class="pl-3">{{ item.value }}</p>
        }
      </p-accordionTab>
    </p-accordion>
  }
</div>
