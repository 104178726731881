export enum OfficeMenuItem {
  DAILY_PLAN = "DAILY_PLAN",
  ORDERS = "ORDERS",
  WORK_TABLE = "WORK_TABLE",
  E_CONSULTATIONS = "E_CONSULTATIONS",
  PRESCRIPTIONS = "PRESCRIPTIONS",
  WORK_INCAPACITY_CERTIFICATES = "WORK_INCAPACITY_CERTIFICATES",
  OPEN_MEDICAL_CASES = "OPEN_MEDICAL_CASES",
  DOCTORS = "DOCTORS",
  EXTERNAL_DOCTORS = "EXTERNAL_DOCTORS",
  SERVICES = "SERVICES",
  PRODUCTS = "PRODUCTS",
  SPECIAL_SERVICES = "SPECIAL_SERVICES",
  DISCOUNTS = "DISCOUNTS",
  ROOMS = "ROOMS",
  DEVICES = "DEVICES",
  FAMILY_DOCTORS = "FAMILY_DOCTORS",
  REPORTS = "REPORTS",
  LABORATORY = "LABORATORY",
  RADIOLOGY = "RADIOLOGY",
  DISEASE_CASE = "DISEASE_CASE",
  REHABILITATION = "REHABILITATION",
  SAMPLER = "SAMPLER",
  STUDIES = "STUDIES",
  PROCEDURES = "PROCEDURES",
  PAYMENTS = "PAYMENTS",
}
