import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'medis-patient-basic-data',
  template: `
    <div class="flex flex-column gap-2-5">
      <a class="text-primary patient-link" (click)="onPatientNameClick()">
        {{ patientName | userProperName }}
      </a>
      <medis-copyable-id-code [idCode]="patientCode"/>
    </div>
  `,
  styleUrl: './patient-basic-data.component.scss'
})
export class PatientBasicDataComponent {
  @Input() patientName?: string;
  @Input() patientCode?: string;

  @Output() onPatientClick: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  onPatientNameClick(): void {
    this.onPatientClick.emit();
  }
}
