import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { IDeviceCategory } from "@core/interfaces/device-category.interface";
import { DeviceService } from "@core/services/device.service";

@Component({
  selector: 'medis-device-category-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        id="id"
        class="w-full"
        styleClass="w-full"
        [ngClass]="{'ng-invalid ng-dirty': formSubmitted && !selectedDevice}"
        appendTo="body"
        [options]="deviceCategories"
        [filter]="deviceCategories.length > 15"
        filterBy="name"
        [ngModel]="selectedDevice"
        (onChange)="onSelect($event.value)"
        optionValue="deviceCategoryId"
        optionLabel="name"
        />
      <label for="id">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeviceCategorySelectListComponent),
      multi: true
    }
  ]
})
export class DeviceCategorySelectListComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string = '';
  @Input() formSubmitted = false;

  deviceCategories: IDeviceCategory[] = [];
  selectedDevice: any;

  constructor(private deviceService: DeviceService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    this.deviceService.getCategories().subscribe({
      next: resolve => {
        this.deviceCategories = resolve;
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedDevice = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedDevice = value;
    this.onChange(value);
    this.onTouched();
  }
}
