 <p-table [value]="data" class="table table--today-plan">
    <ng-template pTemplate="header">
      <tr>
        <th>Aeg</th>
        <th>Patsient</th>
        <th>Teenus</th>
        <th>Maksja</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-time>
      <tr [class.row--highlight]="!time.misAppointmentId">
        <td>
          {{ time.range }}
        </td>
        <td>
          <medis-patient-basic-data
            [patientName]="time.patientName"
            [patientCode]="time.patientIDcode"
            (onPatientClick)="openPatientView(time.patientId)"
          />
        </td>
        <td>
          <div class="flex gap-2 align-items-center">
            <medis-service-badge [badges]="time.serviceBadges"></medis-service-badge>
            {{ time.serviceName }}&nbsp;
            @if (time.commentsCount && time.commentsCount > 0) {
              <p-button [rounded]="true"
                        icon="pi pi-comment"
                        [text]="true"
                        severity="secondary"
                        (click)="openCommentsByOrder(time)"/>
            }
            @if (time.complexServiceId) {
              <div class="text-muted">
                {{ time.complexServiceName }}
                <medis-service-type-label [type]="time.complexServiceComplexType"></medis-service-type-label>
              </div>
            }
          </div>
        </td>
        <td>
          <medis-financial-source-label [financialSourceId]="time.financialSourceId ?? time.financialSourceName"></medis-financial-source-label>
        </td>
        <td style="width: 120px;">
          <div class="table__actions flex justify-content-end gap-3">
            <p-button label="Ava"
                      (onClick)="openPatient(time)"
                      severity="secondary"
                      styleClass="w-full font-bold py-2"
                      [outlined]="true"/>
              <medis-order-actions [time]="time"
                                   [presentOrders]="true"
                                   (onTimeChanged)="onTimeCancel($event)">
              </medis-order-actions>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="5">Midagi pole leitud.</td>
      </tr>
    </ng-template>
  </p-table>

