import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from "@core/services/helper.service";
import { DatePipe } from "@angular/common";
import { Gender } from "@core/enums/gender.enum";

@Pipe({
  name: 'idCodeInfo',
  standalone: true
})
export class IdCodeInfoPipe implements PipeTransform {

  constructor(
    private helperService: HelperService,
    private datePipe: DatePipe,
  ) {}

  transform(idCode: string): string {
    if (idCode && idCode.length === 11) {
      const birthday = this.helperService.getBirthdayFromPersonalCode(idCode);
      const gender = this.helperService.getGenderFromIdCode(idCode);
      const birthdayDisplay = this.datePipe.transform(birthday, 'shortDate');
      const genderDisplay = {
        [Gender.FEMALE]: 'Naine',
        [Gender.MALE]: 'Mees',
      };
      return `${gender ? genderDisplay[gender] : ''} / ${birthdayDisplay}`;
    }
    return '';
  }
}
