<div class="dashboard-filter flex flex-column gap-3 mb-4">
  <div class="dashboard-topbar__top row flex gap-4 align-items-center justify-content-between">
    <div class="dashboard-search flex gap-2 align-items-center">
      <p-iconField styleClass="w-full" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search"/>
        <input
          type="text"
          id="search"
          pInputText
          placeholder="Otsi"
          (input)="applyFilterGlobal($event, 'contains')"
          style="width: 28rem"
        />
      </p-iconField>
    </div>
    <p-button [label]="addButtonLabel" class="ml-auto" routerLink="create"/>
  </div>
</div>

<p-table
  #dt
  [value]="data"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [globalFilterFields]="filterFields"
  [sortField]="columns[0].name"
  [rowsPerPageOptions]="[10, 25, 50]"
  currentPageReportTemplate="Kuvan {first} kuni {last} kokku {totalRecords} kirjet"
  class="table table-products"
>
  <ng-template pTemplate="header">
    <tr>
      @for (column of columns; track column) {
        <th [pSortableColumn]="column.name">{{ column.title }}
          <p-sortIcon [field]="column.name" />
        </th>
      }
      @if (buttonColumns) {
        <th></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      @for (col of columns; track col) {
        <td>
        @if (col.idKey) {
          <a class="text-primary service-link flex row gap-2" routerLink="{{createHref(row, col)}}">
            <span><medis-service-badge [badges]="row.serviceBadges"></medis-service-badge></span>
            <span>{{ row[col.name] }}</span>
          </a>
        } @else if (col.isDate) {
          {{ row[col.name] | dateTimeDisplay: 'dd.MM.YYYY' }}
        } @else if (col.isDateTime) {
          {{ row[col.name] | dateTimeDisplay: 'dd.MM.YYYY HH:mm' }}
        } @else if (Array.isArray(row[col.name])) {
          {{ row[col.name] | arrayToString: col.name }}
        } @else if (isBoolean(row[col.name])) {
          {{ row[col.name] ? 'Jah' : 'Ei' }}
        } @else {
          {{ row[col.name] }} {{ (row[col.name] !== null && row[col.name] !== undefined) && col.postfix ? col.postfix : '' }}
        }
        </td>
      }
      @if (buttonColumns) {
        <td class="flex justify-content-end">
          @for(button of buttonColumns; track button){
            <p-button *ngIf="button.action" label="{{ button.title }}" iconPos="right" icon="{{ button.icon }}" (click)="button.action(row)" [text]="true" [rounded]="true" severity="secondary">
            </p-button>
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length + (buttonColumns?.length ? buttonColumns!.length : 0)">Midagi pole leitud.</td>
    </tr>
  </ng-template>
</p-table>
