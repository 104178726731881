<div class="order-filter flex flex-column gap-5">
  <!--          Upper filter-->
  <div class="order-filter__section flex flex-row w-full gap-4-5">
    <div class="order-filter__order w-4">
      <medis-service-select-list
        labelName="Teenus"
        [selectedServiceId]="selectedBookingOutput.serviceId"
        (onServiceSelect)="onServiceChange($event)"
      >

      </medis-service-select-list>
    </div>
    <div class="order-filter__doctor w-4">
      <p-floatLabel>
        <p-dropdown
          styleClass="w-full"
          id="doctor"
          [options]="providers"
          [showClear]="true"
          [filter]="true"
          filterBy="fullName"
          optionLabel="fullName"
          optionValue="personId"
          [(ngModel)]="selectedBookingOutput.providerId"
          (ngModelChange)="onProviderChange($event)"
        >
        </p-dropdown>
        <label for="doctor">Osutaja</label>
      </p-floatLabel>
    </div>
    <div class="order-filter__location w-4">
      <p-floatLabel>
        <p-dropdown
          styleClass="w-full"
          id="location"
          [options]="addresses"
          [showClear]="true"
          optionLabel="departmentShort"
          [(ngModel)]="selectedBookingOutput.department"
          (ngModelChange)="onLocationChange($event)"
          [disabled]="!selectedBookingOutput.service"
        >
        </p-dropdown>
        <label for="location">Asukoht</label>
      </p-floatLabel>
    </div>
  </div>

  <!--          Lower filter -->
  <div class="order-filter__section flex flex-row w-full gap-4">
    <!-- SelectButton Container -->
    <div class="order-filter__select-button">
      <p-selectButton
        [options]="tableViews"
        (onChange)="changeReservationTab()"
        [(ngModel)]="selectedView"
        [disabled]="loading"
        styleClass="flex white-space-nowrap"
      ></p-selectButton>
    </div>

    <!-- Time Range Calendars -->
    <div class="order-filter__time flex flex-row align-items-center gap-2-5">
      <div class="order-filter__time-start cursor-pointer">
        <p-floatLabel>
          <p-calendar
            [(ngModel)]="startTime"
            [iconDisplay]="'input'"
            [showIcon]="true"
            [timeOnly]="true"
            id="startTime"
          >
            <ng-template
              pTemplate="inputicon"
              let-clickCallBack="clickCallBack"
            >
              <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
          <label for="startTime">Algusaeg</label>
        </p-floatLabel>
      </div>

      <div class="order-filter__time-end cursor-pointer">
        <p-floatLabel>
          <p-calendar
            [(ngModel)]="endTime"
            [iconDisplay]="'input'"
            [showIcon]="true"
            [timeOnly]="true"
            id="endTime"
          >
            <ng-template
              pTemplate="inputicon"
              let-clickCallBack="clickCallBack"
            >
              <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
          <label for="endTime">Lõppaeg</label>
        </p-floatLabel>
      </div>
    </div>

    <!-- Days Checkboxes -->
    <div class="order-filter__weekday flex align-items-center gap-2-5">
      <span class="order-filter__weekday-item">
        <p-checkbox
          [(ngModel)]="weekdays.monday"
          (onChange)="onDaysOfWeekChange($event, 1)"
          value="1"
          id="monday"
        ></p-checkbox>
        <label for="monday">E</label>
      </span>
      <span class="order-filter__weekday-item">
        <p-checkbox
          [(ngModel)]="weekdays.tuesday"
          (onChange)="onDaysOfWeekChange($event, 2)"
          value="2"
          id="tuesday"
        ></p-checkbox>
        <label for="tuesday">T</label>
      </span>
      <span class="order-filter__weekday-item">
        <p-checkbox
          [(ngModel)]="weekdays.wednesday"
          (onChange)="onDaysOfWeekChange($event, 3)"
          value="3"
          id="wednesday"
        ></p-checkbox>
        <label for="wednesday">K</label>
      </span>
      <span class="order-filter__weekday-item">
        <p-checkbox
          [(ngModel)]="weekdays.thursday"
          (onChange)="onDaysOfWeekChange($event, 4)"
          value="4"
          id="thursday"
        ></p-checkbox>
        <label for="thursday">N</label>
      </span>
      <span class="order-filter__weekday-item">
        <p-checkbox
          [(ngModel)]="weekdays.friday"
          (onChange)="onDaysOfWeekChange($event, 5)"
          value="5"
          id="friday"
        ></p-checkbox>
        <label for="friday">R</label>
      </span>
    </div>

    <!-- Date Range Calendars -->
    <div class="order-filter__date flex gap-2-5">
      <div class="order-filter__date-start">
        <p-floatLabel>
          <p-calendar
            [iconDisplay]="'input'"
            [(ngModel)]="selectedStartDate"
            [showIcon]="true"
            id="startDate"
            dateFormat="dd.mm.yy"
            (onSelect)="onDateChange($event, 'start')"
          ></p-calendar>
          <label for="startDate">Algus</label>
        </p-floatLabel>
      </div>

      <div class="order-filter__date-end">
        <p-floatLabel>
          <p-calendar
            [iconDisplay]="'input'"
            [showIcon]="true"
            [(ngModel)]="selectedEndDate"
            id="endDate"
            dateFormat="dd.mm.yy"
            (onSelect)="onDateChange($event, 'end')"
          ></p-calendar>
          <label for="endDate">Lõpp</label>
        </p-floatLabel>
      </div>
    </div>

    <!-- Dropdown that should stretch to fill remaining space -->
    <div class="order-filter__payer flex-grow-1">
      <p-floatLabel>
        <p-dropdown
          styleClass="w-full"
          class="w-full"
          id="payer"
          [options]="financialSources"
          optionLabel="financialSourceName"
          optionValue="financialSourceId"
          [(ngModel)]="selectedFinancialSource"
          [disabled]="!selectedBookingOutput.service"
        ></p-dropdown>
        <label for="payer">Maksja</label>
      </p-floatLabel>
    </div>

    <!-- Dropdown that should stretch to fill remaining space -->
    <div class="order-filter__all-times flex-grow-1">
      <p-floatLabel>
        <p-dropdown
          styleClass="w-full"
          class="w-full"
          [options]="forPublicWebOptions"
          [(ngModel)]="selectedOption"
          id="allTimes"
        ></p-dropdown>
        <label for="allTimes">Kõik ajad</label>
      </p-floatLabel>
    </div>

    @if (multiple || bookingCount > 1) {
      <div class="flex-grow-2 flex">
        <p-floatLabel>
          <input id="bookingCount"
                 pInputText
                 [(ngModel)]="bookingCount"
                 class="max-w-5rem"/>
          <label for="allTimes">Kogus</label>
        </p-floatLabel>
      </div>
    }

    <div class="flex-grow-2 flex">
      <p-button
        label="Otsi"
        icon="pi pi-search"
        (click)="applyFilters()"
      ></p-button>
    </div>
  </div>

  <!-- order-table.component.html -->

  @if (isViewSelected(TableViewsEnum.ALL_TIMES) ||
  isViewSelected(TableViewsEnum.ADD_MULTIPLE)) {
    <div class="order-table flex relative border-c">
      <!-- Left button -->
      <p-button
        *ngIf="bookingTimes.length > 0 && currentIndex != 0"
        class="order-reservation-arrow order-reservation-arrow--left"
        severity="secondary"
        [raised]="true"
        [text]="true"
        icon="pi pi-chevron-left"
        (click)="loadLeftData(bookingTimes)"
      >
      </p-button>

      <!-- Order tables -->
      <!-- Left table -->
      <div class="order-table__container flex gap-4 w-full relative">
        @if (leftTableData.length && !loading) {
          <!-- Left table -->
          <p-table
            [value]="leftTableData"
            [class.w-5]="bookingCount > 1"
            [class.w-6]="bookingCount <= 1"
          >
            <ng-template pTemplate="body" let-leftItem>
              <tr [class.selected-row]="leftItem.isChecked">
                <td>
                  <p-checkbox
                    [(ngModel)]="leftItem.isChecked"
                    [binary]="true"
                    (click)="onBookingSelect(leftItem)"
                    [disabled]="isItemChecked(leftItem)"
                  >
                  </p-checkbox>
                </td>
                <td>
                  <b>{{ leftItem.dayOfWeek | dayOfWeek }}</b>
                  {{ leftItem.date | dateFormat }}
                </td>
                <td>
                  {{ leftItem.timeFrom | timeFormat }} -
                  {{ leftItem.timeUpto | timeFormat }}
                </td>
                <td>
                  <medis-financial-source-label
                    [financialSourceId]="
                  leftItem.isChecked && selectedBookingOutput.financialSourceId
                    ? selectedBookingOutput.financialSourceId
                    : leftItem.financialSourceId ?? leftItem.financialSourceName
                "
                  ></medis-financial-source-label>
                </td>
                @if (leftItem.ageFrom && leftItem.ageTo) {
                  <td>
                    <age-group-label
                      [ageFrom]="leftItem.ageFrom"
                      [ageTo]="leftItem.ageTo"
                    ></age-group-label>
                  </td>
                }
                @if (!leftItem.ageFrom || !leftItem.ageTo) {
                  <td></td>
                }
                <td>
                  <hidden-in-public-web-label
                    [isHiddenInPublicWeb]="leftItem.isHiddenInPublicWeb"
                  ></hidden-in-public-web-label>
                </td>
                @if (leftItem.price) {
                  <td>
                    <b>{{ leftItem.price }}€</b>
                  </td>
                }
                @if (!leftItem.price) {
                  <td></td>
                }
                <td>{{ leftItem.departmentShort }} {{ leftItem.roomCode }}</td>
                <td>
                  {{ leftItem.providerName }}
                </td>
                <td>
                  <emergency-gp-team-label
                    [isEmergencyGpTeam]="leftItem.isEmergencyGpTeam"
                  ></emergency-gp-team-label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        }
        @if (rightTableData.length && !loading) {
          <p-table
            [value]="rightTableData"
            [class.w-5]="bookingCount > 1"
            [class.w-6]="bookingCount <= 1"
          >
            <ng-template pTemplate="body" let-rightItem>
              <tr [class.selected-row]="rightItem.isChecked">
                <td>
                  <p-checkbox
                    [(ngModel)]="rightItem.isChecked"
                    [binary]="true"
                    (click)="onBookingSelect(rightItem)"
                    [disabled]="isItemChecked(rightItem)"
                  >
                  </p-checkbox>
                </td>
                <td>
                  <b>{{ rightItem.dayOfWeek | dayOfWeek }}</b>
                  {{ rightItem.date | dateFormat }}
                </td>
                <td>
                  {{ rightItem.timeFrom | timeFormat }} -
                  {{ rightItem.timeUpto | timeFormat }}
                </td>
                <td>
                  <medis-financial-source-label
                    [financialSourceId]="
                  rightItem.isChecked && selectedBookingOutput.financialSourceId
                    ? selectedBookingOutput.financialSourceId
                    : rightItem.financialSourceId ??
                      rightItem.financialSourceName
                "
                  ></medis-financial-source-label>
                </td>
                @if (rightItem.ageFrom && rightItem.ageTo) {
                  <td>
                    <age-group-label
                      [ageFrom]="rightItem.ageFrom"
                      [ageTo]="rightItem.ageTo"
                    ></age-group-label>
                  </td>
                }
                @if (!rightItem.ageFrom || !rightItem.ageTo) {
                  <td></td>
                }
                <td>
                  <hidden-in-public-web-label
                    [isHiddenInPublicWeb]="rightItem.isHiddenInPublicWeb"
                  ></hidden-in-public-web-label>
                </td>
                @if (rightItem.price) {
                  <td>
                    <b>{{ rightItem.price }}€</b>
                  </td>
                }
                @if (!rightItem.price) {
                  <td></td>
                }
                <td>{{ rightItem.departmentShort }} {{ rightItem.roomCode }}</td>
                <td>
                  {{ rightItem.providerName }}
                </td>
                <td>
                  <emergency-gp-team-label
                    [isEmergencyGpTeam]="rightItem.isEmergencyGpTeam"
                  ></emergency-gp-team-label>
                </td>
              </tr>
            </ng-template>
          </p-table>

        }
        @if (loading) {
          <div class="w-full flex justify-content-center pb-4">
            <p-progressSpinner
              styleClass="w-4rem h-4rem spinner"
              strokeWidth="4"
              animationDuration=".5s"
            />
          </div>
        }
        <!-- Right table -->
        @if (bookingCount > 1) {
          <div
            class="reservation-times w-2 flex flex-column border-round-md ml-auto"
          >
            <div
              class="reservation-times__header flex row justify-content-between align-items-center p-3"
              [class.header-div--highlight]="bookingCount > 1"
            >
              <span class="text-lg">Valitud ajad</span>
              <span
              ><b
              >{{ selectedBookingOutput.times.length }}/{{ bookingCount }}</b
              ></span
              >
            </div>

            <div class="reservation-times__content bg-white">
              @for (item of selectedBookingOutput.times; track item) {
                <div class="flex row justify-content-between h-4rem px-4">
                  <div class="flex align-items-center gap-2">
                    <span [innerHTML]="item.date | dateTimeDisplay: 'dd.MM.yyyy': true"></span>
                    <span>{{ item.timeFrom | timeFormat }} - {{ item.timeUpto | timeFormat }}</span>
                    <span>
                <medis-financial-source-label
                  [financialSourceId]="
                    selectedBookingOutput.financialSourceId ??
                    item.financialSourceId
                  "
                >
                </medis-financial-source-label>
              </span>
                  </div>
                  <div class="flex align-items-center">
              <span>
                @if (canRemoveTime(item)) {
                  <p-button
                    [rounded]="true"
                    icon="pi pi-times"
                    [text]="true"
                    severity="secondary"
                    (onClick)="onBookingSelect(item)"
                  />
                }
              </span>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <!-- Right button -->
      <p-button
        *ngIf="bookingTimes.length > 20 && rightTableData.length === 10"
        class="order-reservation-arrow order-reservation-arrow--right"
        severity="secondary"
        [ngClass]="{ 'displaced-arrow': bookingCount > 1 }"
        [raised]="true"
        [text]="true"
        icon="pi pi-chevron-right"
        (click)="loadRightData(bookingTimes)"
      >
      </p-button>
    </div>
  }
  @if (isBookingTimesEmpty) {
    <div class="pb-5">
      <p>Broneerimisaegu pole saadaval</p>
    </div>
  }
  @if (isViewSelected(TableViewsEnum.CALENDAR)) {
    <div class="calendar flex gap-4-5 flex-grow-1">
      <div class="w-6">
        <!-- TODO: Add spinner for preload calendar  -->
        <medis-calendar [calendarOptions]="calendarOptions"
                        (calendarObject)="calendar = $event; fetchCalendarTimes()"></medis-calendar>
      </div>
      @if (selectedBookingOutput.service) {
        <div class="w-6 flex gap-4-5">
          <div class="reservation-service w-8">
            <div class="reservation-service__header">
              <div class="reservation-service__title">
                <medis-service-badge
                  [badges]="this.selectedBookingOutput.service!.serviceBadges"
                  class="absolute">

                </medis-service-badge>
                <div
                  [class.pl-5]="this.selectedBookingOutput.service!.serviceBadges.length">{{ this.selectedBookingOutput.service!.name }}
                </div>
              </div>
            </div>
            <div class="reservation-service__content flex flex-column gap-4">
              @if (selectedCalendarDate) {
                <div class="reservation-service__date text-4xl font-semibold">
                  {{ selectedCalendarDate | date }}
                </div>
              }
              <div class="reservation-service__accordion flex flex-column">
                <!-- TODO: Dynamic data, show possible. Each item = p-accordion  -->
                @if (calendarFreeTimes.length != 0 || !loading) {

                  <p-accordion [activeIndex]="0">
                    <p-accordionTab
                      [header]="providerFreeTimes.provider ? providerFreeTimes.provider.fullName : ''"
                      *ngFor="let providerFreeTimes of calendarFreeTimes"
                    >
                      <div class="accordion__section p-3-5">
                        <div class="booking-list">
                          <div
                            class="booking-item"
                            *ngFor="let item of providerFreeTimes.times"
                          >
                            <div class="booking-item__checkbox">
                              <p-checkbox
                                [(ngModel)]="item.isChecked"
                                [binary]="true"
                                (click)="onBookingSelect(item)"
                                [disabled]="isItemChecked(item) || item.state == 'Booked'"
                              >
                              </p-checkbox>
                              <div class="booking-item__time">
                                {{ item.timeFrom | timeFormat }} -
                                {{ item.timeUpto | timeFormat }}
                              </div>
                            </div>

                            <div class="booking-item__source">
                              <medis-financial-source-label
                                [financialSourceId]="
                              item.isChecked &&
                              selectedBookingOutput.financialSourceId
                                ? selectedBookingOutput.financialSourceId
                                : (item.financialSourceId ??
                                  item.financialSourceName ?? null)
                            "
                                [small]="true"
                              ></medis-financial-source-label>
                            </div>

                            <div class="booking-item__location">
                              {{ item.departmentShort }}
                              {{ item.roomCode }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                } @else {
                  @for (col of [1, 2]; track $index) {
                    <th class="w-full">
                      <p-skeleton width="100%" height="3rem"></p-skeleton>
                    </th>
                  }
                }
              </div>
            </div>
          </div>
          @if (bookingCount > 1) {
            <div
              class="reservation-times flex flex-column border-round-md w-4"
            >
              <div
                class="reservation-times__header flex row justify-content-between align-items-center p-3"
                [class.header-div--highlight]="bookingCount > 1"
              >
                <span class="text-lg">Valitud ajad</span>
                <span
                ><b
                >{{ selectedBookingOutput.times.length }}/{{ bookingCount }}</b
                ></span
                >
              </div>

              <div class="reservation-times__content bg-white">
                @for (item of selectedBookingOutput.times; track item) {
                  <div class="flex row justify-content-between h-4rem px-4">
                    <div class="flex align-items-center gap-2">
              <span>
                <b>{{ item.dayOfWeek | dayOfWeek }}</b>
                {{ item.date | dateFormat }}
              </span>
                      <span>{{ item.timeFrom | timeFormat }} - {{ item.timeUpto | timeFormat }}
              </span>
                      <span>
                <medis-financial-source-label
                  [financialSourceId]="
                    selectedBookingOutput.financialSourceId ??
                    item.financialSourceId
                  "
                  [small]="true"
                >
                </medis-financial-source-label>
              </span>
                    </div>
                    <div class="flex align-items-center">
              <span>
                <p-button
                  [rounded]="true"
                  icon="pi pi-times"
                  [text]="true"
                  severity="secondary"
                  (onClick)="onBookingSelect(item)"
                />
              </span>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }

    </div>
  }
  @if (isViewSelected(TableViewsEnum.ADD_NUMBER)) {
    <div><p>This is content for Lisanumber.</p></div>
      }
      <!--          Finalize order section-->
  @if (selectedBookingOutput.times.length && selectedBookingOutput.service && !loading && bookingTimes) {
    <div class="order-summary flex flex-row w-12 gap-4">
      <div class="order-summary__comment w-6">
        <p-floatLabel>
          <input id="comment"
                 pInputText
                 class="w-full"
                 [(ngModel)]="comment"
          />
          <label for="comment">Kommentaar</label>
        </p-floatLabel>
      </div>

      <div class="order-summary__payer flex flex-row w-6 gap-4">
        <div class="w-full">
          <p-floatLabel>
            <p-dropdown
              id="payer-info"
              styleClass="w-full"
              [options]="financialSources"
              optionLabel="financialSourceName"
              optionValue="financialSourceId"
              [(ngModel)]="selectedBookingOutput.financialSourceId"
              [disabled]="isSetPersonPayerByDefault()"
            >
            </p-dropdown>
            <label for="payer-info">Maksjainfo muutmine</label>
          </p-floatLabel>
        </div>

        <div class="order-summary__case w-full">
          <p-floatLabel>
            <p-dropdown id="case" styleClass="w-full"></p-dropdown>
            <label for="case">Haigusjuht</label>
          </p-floatLabel>
        </div>
        <p-button
          severity="success"
          label="Kinnita"
          (onClick)="output.emit(selectedBookingOutput); config?.submitHandler()"
        ></p-button>
      </div>
    </div>
      }
    </div>

    <p-overlayPanel styleClass="calendar-overlaypanel" #op>
      <div class="calendar-overlay">
        <div class="calendar-overlay__header">
          <div class="calendar-overlay__title">
            <!-- TODO: Badge component with right DATA from service serviceBadge array  -->
        <p-badge value="E" styleClass="bg-blue-500"/>
            <span>{{ overlayObj?.serviceName }}</span>
          </div>
        </div>
        <div class="calendar-overlay__content">
          <div class="overlay-service">
            <!-- TODO: Add Check for DATA exits, if exist show item, if not - dont.  -->
            <div class="overlay-service__item">
          <span class="overlay-service__label">Periood</span
          ><span class="overlay-service__value">{{ overlayObj?.range }}</span>
            </div>
            <div class="overlay-service__item">
          <span class="overlay-service__label">Osutaja</span
          ><span class="overlay-service__value">{{
                overlayObj?.providerName
              }}</span>
            </div>
            <div class="overlay-service__item">
              <span class="overlay-service__label">Maksja</span>
              <span class="overlay-service__value">
            <p-tag
              rounded="true"
              value="{{ overlayObj?.financialSourceName }}"
              styleClass="tag tag--small tag--yellow"
            >
            </p-tag>
          </span>
            </div>
            <div class="overlay-service__item">
          <span class="overlay-service__label">Asukoht</span
          ><span class="overlay-service__value">{{ overlayObj?.address }}</span>
            </div>
          </div>
        </div>
      </div>
    </p-overlayPanel>
