<p-confirmDialog #confirmActionRef key="confirm-action">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi pi-question text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
        {{ message.header }}
      </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton
                [outlined]="true"
                severity="secondary"
                [label]="message.rejectLabel"
                (click)="confirmActionRef.reject()"
        ></button>
        <button pButton
                [label]="message.acceptLabel"
                (click)="confirmActionRef.accept()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
