import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from "@angular/common";
import { BadgeModule } from "primeng/badge";
import { TagModule } from "primeng/tag";
import { IMapping } from "@core/interfaces/mapping.interface";
import { OrderReferralStatusPipe } from "@shared/pipes/order-referral-status.pipe";
import { OrderReferralStatus } from "@core/enums/order-referral-status.enum";

@Component({
  selector: 'medis-order-referral-status-label',
  standalone: true,
  imports: [
    NgClass,
    BadgeModule,
    TagModule,
    OrderReferralStatusPipe,
  ],
  template:
  `
    <p-tag
      rounded="true"
      value="{{ referralStatus | orderReferralStatus }}"
      [styleClass]="tagStyle"
    />
  `
})

export class OrderReferralStatusLabelComponent implements OnInit {
  @Input() referralStatus!: OrderReferralStatus;
  tagStyle!: string;

  private readonly sourceStyleMap: IMapping = {
    [OrderReferralStatus.CONFIRMED]: 'tag tag--green',
    [OrderReferralStatus.MISSING]: 'tag tag--red',
    [OrderReferralStatus.EXPIRED]: 'tag tag--red',
    [OrderReferralStatus.CREATED]: 'tag tag--yellow',
    [OrderReferralStatus.OPTIONAL]: 'tag tag--default',
    [OrderReferralStatus.ASSIGNED]: 'tag tag--blue',
    [OrderReferralStatus.RESOLVED]: 'tag tag--purple',
    [OrderReferralStatus.CANCELLED]: 'tag tag--default',
  };

  ngOnInit() {
    this.tagStyle = `white-space-nowrap ${this.sourceStyleMap[this.referralStatus]}`;
  }
}
