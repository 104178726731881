import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ICompany } from "../interfaces/company.interface";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/companies`;
  }

  public save(company: ICompany): Observable<ICompany> {
    return this.http.post<ICompany>(`${this.BACKEND_URL}`, company);
  }

  public getCompanies(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(`${this.BACKEND_URL}`);
  }

  public getCompany(companyId: number): Observable<ICompany> {
    return this.http.get<ICompany>(`${this.BACKEND_URL}/${companyId}`);
  }

  public getExternalCompanies(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(`${this.BACKEND_URL}/external`);
  }

  // public getCompaniesWithProducts(): Observable<ICompany[]> {
  //   return this.http.get<ICompany[]>(`${this.OLD_BACKEND_URL}/withProducts`);
  // }

  public delete(companyId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.BACKEND_URL}/${companyId}`);
  }
}
