import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IPatient } from "../interfaces/patient.interface";
import { IPatientComment } from "../interfaces/patient-comment.interface";
import { IPatientCommentRequest } from "@core/interfaces/patient-comment-request.interface";
import { IOrder } from "@core/interfaces/order.interface";

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/patients`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/patients`;
  }

  public get(id: number): Observable<IPatient> {
    return this.http.get<IPatient>(`${this.BACKEND_URL}/${id}`);
  }

  public search(search: string): Observable<IPatient[]> {
    return this.http.get<IPatient[]>(`${this.OLD_BACKEND_URL}/search/${search}`);
  }

  public searchWithExternal(search: string): Observable<IPatient[]> {
    return this.http.get<IPatient[]>(`${this.BACKEND_URL}/search/${search}/external`);
  }

  public saveComment(comment: IPatientCommentRequest): Observable<IPatientComment> {
    return this.http.post<IPatientComment>(`${this.BACKEND_URL}/comments`, comment);
  }

  public getBookings(params: HttpParams): Observable<any[]>{
    return this.http.get<any[]>(`${this.BACKEND_URL}/bookings`, {params});
  }

  // public getByMisCode(patientMisCode: number, type: string, params: HttpParams): Observable<IMedicalCase[]> {
  //   return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/medicalcases/${patientMisCode}/${type}`, {params});
  // }

  // public getMedicalCase(patientMisId: number, medicalCaseId: number): Observable<IMedicalCase> {
  //   return this.http.get<IMedicalCase>(`${this.BACKEND_URL}/medicalcases/one/${patientMisId}/${medicalCaseId}`);
  // }

  // public getCurrentProviderMedicalCases(patientMisId: number, providerId: number, params: any): Observable<IMedicalCase[]> {
  //   return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/medicalcases/provider/${patientMisId}/${providerId}`, {params});
  // }

  // public getUnusedPatientReferrals(patientId: number): Observable<IMedicalCase[]> {
  //   return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/patients/referrals/unused/${patientId}`);
  // }

  // public getUnusedPatientServiceReferrals(patientId: number, serviceId: number): Observable<IReferral> {
  //   return this.http.get<IReferral>(`${this.BACKEND_URL}/referrals/unused/${patientId}/${serviceId}`);
  // }

  // public getTehikPatientReferrals(patientId: number): Observable<IReferral[]> {
  //   return this.http.get<IReferral[]>(`${this.BACKEND_URL}/referrals/tehik/${patientId}`);
  // }

  // public getFilteredPatientReferrals(params: HttpParams): Observable<IReferral[]> {
  //   return this.http.get<IReferral[]>(`${this.BACKEND_URL}/referrals`, {params});
  // }

  // public getServicesForPatientReferrals(patientId: number): Observable<string[]> {
  //   return this.http.get<string[]>(`${this.BACKEND_URL}/referrals/services/${patientId}`);
  // }

  // public checkInsurance(patientId: number): Observable<boolean> {
  //   return this.http.get<boolean>(`${this.BACKEND_URL}/insurance/${patientId}/check`);
  // }

  // public getInsuranceData(idCode: number): Observable<IPatientInsurance> {
  //   return this.http.get<IPatientInsurance>(`${this.BACKEND_URL}/insurance/${idCode}/data`);
  // }

  // public createMedicalCase(patientId: number, personId: number, departmentId: number): Observable<number> {
  //   return this.http.get<number>(`${this.BACKEND_URL}/medicalcases/create/${patientId}/${personId}/${departmentId}`);
  // }

  // public hasOpenAppointment(medicalCaseId: number): Observable<boolean> {
  //   return this.http.get<boolean>(`${this.BACKEND_URL}/hasOpenAppointment/${medicalCaseId}`);
  // }

  public removeComment(commentId: number): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}/comments/${commentId}`);
  }

  // public saveReferralAnswer(referral: IReferral): Observable<IReferral> {
  //   return this.http.post<IReferral>(`${this.BACKEND_URL}/saveReferralAnswer`, referral);
  // }

  // public confirmReferralAnswer(referralId: number): Observable<boolean> {
  //   return this.http.get<boolean>(`${this.BACKEND_URL}/confirmReferralAnswer/${referralId}`);
  // }

  // public getReferralTehikHtml(referralId: number): Observable<string> {
  //   return this.http.get<string>(`${this.BACKEND_URL}/referral/tehikHtml/${referralId}`);
  // }

  // public getReferralTehikHtmlWithoutReferralId(oid: string, documentNr: string, idCode: string): Observable<string> {
  //   return this.http.get<string>(`${this.BACKEND_URL}/referral/tehikHtml/noReferralId/${oid}/${documentNr}/${idCode}`);
  // }

  public getPatientPhysiotherapy(patientId: number): Observable<IOrder> {
    return this.http.get<IOrder>(`${this.BACKEND_URL}/physiotherapy/${patientId}`);
  }

  // public getPatientImageBankLink(patientIdCode: string): Observable<string | null> {
  //   return this.http.get<string | null>(`${this.BACKEND_URL}/imageBank/${patientIdCode}`);
  // }

  public save(data: any): Observable<any> {
    return this.http.post<IPatient>(`${this.BACKEND_URL}`, data);
  }
}
