import { Component, EventEmitter, Input, OnInit, Output, signal, TemplateRef, ViewChild, } from '@angular/core';
import { IOrder } from '@core/interfaces/order.interface';
import { IPatient } from '@core/interfaces/patient.interface';
import { IService } from '@core/interfaces/service.interface';
import { IAddress } from '@core/interfaces/address.interface';
import { IPerson } from '@core/interfaces/person.interface';
import { IBookingTime } from '@core/interfaces/booking-time.interface';
import { ServiceService } from '@core/services/service.service';
import { OrderService } from '@core/services/order.service';
import { PersonService } from '@core/services/person.service';
import { ToastService } from '@core/services/toast.service';
import { HttpParams } from '@angular/common/http';
import { FinSourceId } from '@core/enums/financial-source.enum';
import { PlanTimeService } from '@core/services/plan-time.service';
import { DatePipe, formatDate } from '@angular/common';
import { finalize, forkJoin, Observable, of, Subscription } from 'rxjs';
import { IOrderServiceTime } from '@core/interfaces/order-service-time.interface';
import { CalendarOptions, DateSelectArg, EventClickArg, EventInput, } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { IOrderReservationOutput } from "@core/interfaces/order-reservation-output.interface";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";
import { OverlayPanel } from 'primeng/overlaypanel';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { BookingsService } from "@core/services/bookings.service";
import { IBookingDates } from "@core/interfaces/booking-dates.interface";
import { IReferral } from "@core/interfaces/referral.interface";
import { PatientService } from "@core/services/patient.service";
import { IMedicalCaseDto } from "@core/interfaces/medical-case.interface";
import { ICompany } from "@core/interfaces/company.interface";
import { CompanyService } from "@core/services/company.service";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { TimeFormatPipe } from "@shared/pipes/time-format.pipe";
import interactionPlugin from "@fullcalendar/interaction";

@Component({
  selector: 'medis-order-reservation',
  templateUrl: './order-reservation.component.html',
  styleUrl: './order-reservation.component.scss',
  providers: [TimeFormatPipe]
})
export class OrderReservationComponent implements OnInit {
  @Input() config?: IOrderReservationConfig;

  @Input() set changePatient(val: IPatient | undefined) {
    this.setSelectedPatient(val);
  }

  @Input() confirmClicked = false;

  @ViewChild('op') op!: OverlayPanel;
  @ViewChild('eventContent') eventContent!: TemplateRef<any>;

  calendar: FullCalendarComponent | null = null;
  selectedCalendarDate: Date | null = null;
  private calendarResponse: IBookingDates | null = null;

  eventGuid = 0;

  createEventId() {
    return String(this.eventGuid++);
  }

  @Output() output = new EventEmitter<IOrderReservationOutput>();

  loading: boolean = false;
  limit: number = 30;

  disableService: boolean = false;

  leftTableData: any[] = [];
  rightTableData: any[] = [];
  currentIndex: number = 0;
  selectedBookingOutput: IOrderReservationOutput = {
    times: []
  };

  orders: IOrder[] = [];
  services: IService[] = [];
  implementationService?: IService;
  financialSources: any = [];
  selectedFinancialSource?: number;

  addresses: IAddress[] = [];
  providers: IPerson[] = [];
  bookingTimes: IBookingTime[] = [];
  isBookingTimesEmpty = false;

  searchPerformed: boolean = false;
  comment: string | null = null;

  tabs: any[] = [];
  tableViews = [
    { label: 'Kõik ajad', value: TableViewsEnum.ALL_TIMES },
    { label: 'Kalender', value: TableViewsEnum.CALENDAR },
    { label: 'Lisanumber', value: TableViewsEnum.ADD_NUMBER },
  ];
  selectedView = TableViewsEnum.ALL_TIMES;

  startTime: Date = new Date();
  endTime: Date = new Date();

  weekdays = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  };
  selectedWeekdays: number[] = [];
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  forPublicWebOptions = [
    { label: 'Kõik ajad', value: null },
    { label: 'Avalik', value: true },
    { label: 'Majasisene', value: false },
  ];

  selectedOption: boolean | null = null;
  bookingCount: number = 1;
  bookingCountDisabled = false;

  constructor(
    private serviceService: ServiceService,
    private orderService: OrderService,
    private bookingService: BookingsService,
    private personService: PersonService,
    private planTimeService: PlanTimeService,
    private toastService: ToastService,
    private datePipe: DatePipe,
    private patientService: PatientService,
    private companyService: CompanyService,
    private authService: AuthService,
    private timeFormat: TimeFormatPipe,
  ) {
  }

  ngOnInit() {
    this.startTime = new Date();
    this.startTime.setHours(8, 0);
    this.endTime = new Date();
    this.endTime.setHours(20, 0);

    this.selectedCalendarDate = new Date(Date.now());

    if (this.config?.showMultiple) {
      this.tableViews.push({
        label: 'Lisa mitu aega',
        value: TableViewsEnum.ADD_MULTIPLE,
      });
    }

    this.disableService = this.config?.disableService ?? false;

    const requests: any = {
      services: this.getServicesRequest(this.config?.providerId),
      providers: this.getProvidersRequest(this.config?.serviceId),
      addresses: this.getAddressesRequest(
        this.config?.serviceId,
        this.config?.providerId
      ),
      companies: this.getCompaniesRequest(),
    };

    if (this.config?.bookingCountDisabled && this.config?.serviceId) {
      requests.service = this.serviceService.get(this.config.serviceId);
    }

    forkJoin(requests).pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response: any) => {
          this.services = response.services;
          this.providers = response.providers;
          this.addresses = response.addresses;
          this.companies = response.companies;
          this.implementationService = response.service;
          this.fetchConfig();
          this.setFinancialSources();

          if (this.config?.onlySelectedCompany) {
            this.companies = this.companies.filter(x => x.companyId == this.selectedBookingOutput.companyFilter?.companyId);
          }
        },
      });
  }

  private fetchConfig(): void {
    if (this.config) {
      if (
        this.config.timeFrom &&
        this.config.timeTo &&
        this.config.timeFrom.length === 3 &&
        this.config.timeTo.length === 3
      ) {
        const newStartTime = new Date();
        newStartTime.setHours(
          parseInt(this.config.timeFrom[0], 10),
          parseInt(this.config.timeFrom[1], 10)
        );
        this.startTime = newStartTime;

        const newEndTime = new Date();
        newEndTime.setHours(
          parseInt(this.config.timeTo[0], 10),
          parseInt(this.config.timeTo[1], 10)
        );
        this.endTime = newEndTime;
      }
      if (this.config.date) {
        const date = this.config?.date;
        this.selectedStartDate = new Date(date ?? '');
        this.selectedEndDate = new Date(date ?? '');
        this.selectedEndDate.setHours(24);
      }
      if (this.config.providerId) {
        this.setSelectedProvider(this.config.providerId);
      } else {
        this.authService.getCurrentUser().subscribe({
          next: (user: ICurrentUser | null) => {
            this.setSelectedProvider(user?.personId || null);
          }
        })
      }
      if (this.config.addressId) this.selectedBookingOutput.department = this.addresses.find(address => address.addressId === this.config?.addressId) || null;
      if (this.config.patientId) this.selectedBookingOutput.patientId = this.config.patientId;
      if (this.config.patient) {
        this.setSelectedPatient(this.config.patient);
      }
      if (this.config.bookingCount) this.bookingCount = this.config.bookingCount;
      if (this.config.times && this.config.times?.length > 0) {
        this.selectedBookingOutput.times = this.config.times;
      }
      if (this.config.financialSourceId) {
        this.selectedBookingOutput.financialSourceId = this.config.financialSourceId;
      }

      //this.multiple = this.config.showMultiple ?? this.multiple;
      this.bookingCountDisabled = this.config.bookingCountDisabled ?? false;

      if (this.config.serviceId) {
        this.selectedBookingOutput.serviceId = this.config.serviceId;
        this.selectedBookingOutput.service =
          this.services.find(
            (service) => service.serviceId === this.config?.serviceId
          ) || this.implementationService;
      }

      if (this.config.companyId) {
        this.selectedBookingOutput.companyFilter = this.companies.find(x => x.companyId == this.config?.companyId);
      }
      this.applyFilters();
    }
  }

  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  onDaysOfWeekChange(event: any, dayNumber: number): void {
    const isChecked = event.checked.length > 0;
    if (isChecked) {
      if (!this.selectedWeekdays.includes(dayNumber)) {
        this.selectedWeekdays.push(dayNumber);
      }
    } else {
      this.selectedWeekdays = this.selectedWeekdays.filter(
        (day) => day !== dayNumber
      );
    }
  }

  onDateChange(date: Date, type: 'start' | 'end') {
    if (type === 'start') {
      this.selectedStartDate = date;
    } else if (type === 'end') {
      this.selectedEndDate = date;
    }
  }

  formatDateISO(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'et_EE');
  }

  applyFilters() {

    if (!this.selectedBookingOutput.service) {
      return;
    }

    if (
      this.selectedBookingOutput.service.isGpTeamRelated &&
      !this.selectedBookingOutput.patientId
    ) {
      this.toastService.warn(
        'Perearsti teenuse aega vaatamiseks patsiendi valimine on kohustuslik!'
      );
      return;
    }

    let params = this.createHttpParams();

    if (this.selectedView == TableViewsEnum.ADD_MULTIPLE) {
      params = params
        .set('onlyDayFirstTime', true)
        .set('limit', this.bookingCount);
    }

    if (this.selectedView == TableViewsEnum.CALENDAR) {
      return;
    }

    this.fetchBookingTimes(params);
  }

  private createHttpParams(): HttpParams {
    let params = new HttpParams();

    params = this.appendDateFilters(params);
    params = this.appendCommonParams(params);

    const formattedStartTime = this.startTime
      ? this.formatTime(this.startTime)
      : '';
    const formattedEndTime = this.endTime ? this.formatTime(this.endTime) : '';

    if (this.startTime || this.endTime) {
      params = params.append(
        'dayTimes',
        `${formattedStartTime}-${formattedEndTime}`
      );
    }

    this.selectedWeekdays.forEach((day) => {
      params = params.append('weekDays', day.toString());
    });

    return params;
  }

  private appendDateFilters(params: HttpParams): HttpParams {
    if (this.selectedStartDate) {
      params = params.append(
        'fromDate',
        this.formatDateISO(this.selectedStartDate)
      );
    }
    if (this.selectedEndDate) {
      params = params.append(
        'toDate',
        this.formatDateISO(this.selectedEndDate)
      );
    }
    if (this.selectedOption !== null) {
      params = params.append('forPublicWeb', this.selectedOption.toString());
    }
    return params;
  }

  private appendCommonParams(params: HttpParams): HttpParams {
    if (this.selectedFinancialSource) {
      params = params.append(
        'financialSourceId',
        this.selectedFinancialSource.toString()
      );
    }
    if (
      this.selectedBookingOutput.service &&
      this.selectedBookingOutput.service.serviceId
    ) {
      params = params.append(
        'serviceId',
        this.selectedBookingOutput.service.serviceId.toString()
      );
    }
    if (
      this.selectedBookingOutput.department &&
      this.selectedBookingOutput.department
    ) {
      params = params.append(
        'addressId',
        this.selectedBookingOutput.department.addressId.toString()
      );
    }
    if (
      this.selectedBookingOutput.patientId
    ) {
      params = params.append(
        'patientId',
        this.selectedBookingOutput.patientId.toString()
      );
    }
    if (
      this.selectedBookingOutput.provider &&
      this.selectedBookingOutput.provider.personId
    ) {
      params = params.append(
        'providerId',
        this.selectedBookingOutput.provider.personId.toString()
      );
    }
    if (
      this.selectedBookingOutput.companyFilter &&
      this.selectedBookingOutput.companyFilter.companyId
    ) {
      params = params.append(
        'companyId',
        this.selectedBookingOutput.companyFilter.companyId.toString()
      );
    }

    params = params.append('limit', this.limit);
    params = params.append('onlyDayFirstTime', 'false');

    return params;
  }

  getServicesRequest(providerId?: number): Observable<IService[]> {
    let params = new HttpParams()
      .set('excludeNotUsedInPlanTimes', 'true')
      .set('expandInfo', 'PartData')
      .set('includeComplexServices', 'true')
      .set('page', '1')
      .set('pageSize', '1000')
      .set('serviceBookingNotNeeded', 'false');

    if (providerId) {
      params = params.append('providerId', providerId.toString());
    }

    return this.serviceService.getServicesForSelect(params);
  }

  getCompaniesRequest(): Observable<ICompany[]> {
    let params = new HttpParams()
      .set('isBuyer', true)
      .set('hasPlanTimes', true);

    return this.companyService.getCompanies(params);
  }

  getServices(providerId?: number): Subscription {
    return this.getServicesRequest(providerId).subscribe({
      next: (response: IService[]) => {
        this.services = response;
      },
      error: (err) => {
        console.error('Error fetching services:', err);
      },
    });
  }

  onServiceChange(service: IService) {
    this.selectedBookingOutput.service = service;
    this.selectedBookingOutput.serviceId = service ? service.serviceId : undefined;
    this.selectedBookingOutput.times = [];
    if (service) {
      this.bookingTimes = [];
      this.addresses = [];
      this.limit = 30;
      this.currentIndex = 0;
      this.selectedBookingOutput.department = null;
      this.selectedOption = null;
      this.selectedBookingOutput.financialSourceId = undefined;
      this.getProviders(service.serviceId);
    } else {
      this.isBookingTimesEmpty = false;
      this.selectedBookingOutput.financialSourceId = null;
      this.selectedOption = null;
      this.bookingTimes = [];
      this.addresses = [];
      this.selectedBookingOutput.department = null;
      this.setSelectedProvider(null);
      this.getProviders();
    }
  }

  onProviderChange(provider: number) {
    this.selectedBookingOutput.provider = this.providers.find(
      (x) => x.personId === provider
    );

    this.getAddressesRequest(
      this.selectedBookingOutput.serviceId,
      provider
    ).subscribe({
      next: (response: IAddress[]) => {
        this.selectedBookingOutput.department = null;
        this.addresses = response;

        this.limit = 30;
        this.currentIndex = 0;
        if (this.selectedBookingOutput.service) {
          this.onSomethingChanged();
        }
      }
    });

    this.getServices(provider);
  }

  onLocationChange(address: IAddress) {
    if (address && address.addressId) {
      this.selectedBookingOutput.department = address;
    }

    if (this.selectedBookingOutput.service) {
      this.limit = 30;
      this.currentIndex = 0;
      this.onSomethingChanged();
    }
  }

  onCompanyChange(company: ICompany | undefined) {
    this.selectedBookingOutput.companyFilter = company;
    this.selectedBookingOutput.financialSourceId = FinSourceId.AnotherJuridicalPerson;
    this.setFinancialSources();
    this.selectedBookingOutput.times = [];
    if (this.selectedBookingOutput.service) {
      this.limit = 30;
      this.currentIndex = 0;
      this.onSomethingChanged();
    }
  }

  onSomethingChanged() {
    //this.selectedBookingOutput.times = [];
    if (this.selectedView == TableViewsEnum.CALENDAR || this.selectedView == TableViewsEnum.ADD_NUMBER) {
      this.fetchCalendarEvents()
      this.fetchCalendarTimesForSelect();
    } else {
      this.applyFilters();
    }
  }

  getProvidersRequest(serviceId?: number) {
    if (serviceId) {
      let params = new HttpParams().append('serviceIds', serviceId);
      return this.personService.getProviders(params);
    } else {
      return this.personService.getActiveProvidersForSelect();
    }
  }

  getProviders(serviceId?: number): Subscription {
    return this.getProvidersRequest(serviceId)
      .subscribe({
        next: (providers: IPerson[]) => {
          this.providers = providers as IPerson[];
          if (this.providers.length > 0) {
            if (!this.providers.find(one => one.personId === this.selectedBookingOutput.providerId)) {
              this.selectedBookingOutput.provider = undefined;
              this.selectedBookingOutput.providerId = undefined;
            }
          }
          this.getAddresses();
          this.onSomethingChanged();
        },
        error: (err: any) => {
          console.error('Error fetching providers:', err);
        },
      });
  }

  getAddressesRequest(
    serviceId?: number,
    providerId?: number
  ): Observable<IAddress[]> {
    if (serviceId)
      return this.planTimeService.getPersonServiceDepartments(
        serviceId,
        providerId
      );
    return of([]);
  }

  getAddresses() {
    if (
      this.selectedBookingOutput.service?.serviceId &&
      this.selectedBookingOutput.provider?.personId
    ) {
      this.getAddressesRequest(
        this.selectedBookingOutput.service.serviceId,
        this.selectedBookingOutput.provider?.personId
      ).subscribe({
        next: (x: IAddress[]) => {
          this.selectedBookingOutput.department = null;
          this.addresses = x;
        },
      });
    }
  }

  calendarTimes: EventInput[] = [];

  fetchBookingTimes(params?: HttpParams): void {
    this.loading = true;
    //this.selectedBookingOutput.times = [];
    this.orderService.getFirstBookingTimes(params)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (times) => {
          if (this.selectedView == TableViewsEnum.ADD_MULTIPLE) {
            this.selectedBookingOutput.times = times;
            this.limit = this.bookingCount;
          }

          this.bookingTimes = times;

          this.bookingTimes.forEach(x => {
            x.isChecked = this.selectedBookingOutput.times.some(
              y => y.timeFrom == x.timeFrom && y.timeUpto == x.timeUpto && y.planTimeId == x.planTimeId && y.providerId == x.providerId && y.serviceId == x.serviceId);
          });

          this.loadInitialData(this.bookingTimes);
          this.calendarVisible = true;

        },
        error: (err: any) => {
          if (err.status === 400) {
            if (err.error.errors && err.error.errors.length > 0) {
              if (err.error.errors.length === 1) {
                this.toastService.error(`${err.error.errors[0].message}`);
              } else {
                err.error.errors.forEach((e: any) => {
                  this.toastService.error(`${e.message}`);
                });
              }
            }
          } else if (err.status === 500) {
            this.toastService.error('500 Internal server error.');
          } else {
            this.toastService.error(
              `Error with status code ${err.status} - ${err}`
            );
          }
        },
      });
  }

  private period?: any;

  fetchCalendarEvents(): void {

    if (!this.calendar || !this.calendar.getApi()
      && !this.selectedBookingOutput.serviceId
      && !this.period
    ) return;

    this.calendarResponse = null;
    this.calendarTimes = [];
    let params = new HttpParams()
      .set('addressId', this.selectedBookingOutput.department?.addressId ?? 0)
      .set('dateFrom', this.datePipe.transform(this.period?.start, 'yyyy-MM-dd') ?? '')
      .set('dateTo', this.datePipe.transform(this.period?.end, 'yyyy-MM-dd') ?? '')
      .set('financialSourceId', this.selectedFinancialSource ?? 0)
      .set('patientId', this.selectedBookingOutput.patientId ?? 0)
      .set('serviceId', this.selectedBookingOutput.serviceId ?? 0);
    this.bookingService.dates(params).subscribe({
      next: (response: IBookingDates) => {
        this.calendarResponse = response;
        this.calendarTimes = response.patientBookedTimes.map((x: any) => {
          return {
            id: this.createEventId(),
            title: this.timeFormat.transform(x.timeFrom) + '-' + this.timeFormat.transform(x.timeUpto),
            allDay: false,
            start: this.getDateTime(x.date, x.timeFrom),
            end: this.getDateTime(x.date, x.timeUpto),
            extendedProps: {
              data: x,
            },
          };
        });
      },
      complete: () => {
        if (this.calendar) {
          this.calendar.getApi().render();
          this.calendar.events = this.calendarTimes;
          this.calendar.getApi().refetchEvents();
        }

      }
    })
  }

  fetchCalendarTimesForSelect(): void {
    if (!this.selectedBookingOutput.serviceId) return;
    let params = new HttpParams()
      .set('date', this.datePipe.transform(this.selectedCalendarDate, 'yyyy-MM-dd') ?? '')
      .set('patientId', this.selectedBookingOutput.patientId ?? 0)
      .set('providerId', this.selectedBookingOutput.providerId ?? 0)
      .set('addressId', this.selectedBookingOutput.department?.addressId ?? 0)
      .set('withBooked', true)
      .set('withPossibleQueueTimes', this.selectedView == TableViewsEnum.ADD_NUMBER)
      .set('companyId', this.selectedBookingOutput.companyFilter?.companyId ?? 0)
      .set('serviceId', this.selectedBookingOutput.serviceId ?? 0);

    this.loading = true;
    this.bookingService.calendarDates(params)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response: IBookingTime[]) => {

          response = response.sort((a: IBookingTime, b: IBookingTime) => {
            return a.timeFrom > b.timeFrom ? 1 : -1;
          });

          this.bookingTimes = response;
          response.forEach((x: any) => {
            x.isChecked = this.selectedBookingOutput.times.some(y => this.isTimesEqual(x, y));
            x.isQueue = this.selectedView == TableViewsEnum.ADD_NUMBER;
          });
          this.collectTimesToCalendar(response);

        },
        error: (err) => {
          this.calendarFreeTimes = [];
          this.toastService.warn(`${err.error.errors[0].message}`);
        }
      })
  }

  private isTimesEqual(x: IBookingTime, y: IBookingTime): boolean {
    return x.date == y.date && x.planTimeId == y.planTimeId && x.timeFrom == y.timeFrom && x.timeUpto == y.timeUpto && x.providerId == y.providerId;
  }

  calendarFreeTimes: any[] = [];

  private collectTimesToCalendar(times: IBookingTime[]) {
    this.calendarFreeTimes = [];
    times
      .map(x => {
        return { providerId: x.providerId, name: x.providerName };
      })
      .filter((value, index, array) => {
        return array.findIndex(x => x.providerId == value.providerId) === index;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((value) => {
        this.calendarFreeTimes.push({
          times: times.filter(y => y.providerId == value?.providerId),
          provider: this.providers.find(y => y.personId == value.providerId)
        })
      });
  }

  isViewSelected(viewLabel: TableViewsEnum): boolean {
    return this.selectedView == viewLabel;
  }

  loadInitialData(data: any[]): void {
    this.leftTableData = data.slice(this.currentIndex, this.currentIndex + 10);
    this.rightTableData = data.slice(
      this.currentIndex + 10,
      this.currentIndex + 20
    );
  }

  loadRightData(data: any[]): void {
    if (this.currentIndex < data.length - 20) {
      this.leftTableData = data.slice(
        this.currentIndex + 10,
        this.currentIndex + 20
      );
      this.rightTableData = data.slice(
        this.currentIndex + 20,
        this.currentIndex + 30
      );
      this.currentIndex += 10;
      if (
        this.limit - this.currentIndex === 20 &&
        !this.isViewSelected(TableViewsEnum.ADD_MULTIPLE)
      ) {
        this.limit += 10;
        this.applyFilters();
      }
    }
  }

  loadLeftData(data: any[]): void {
    if (this.currentIndex > 0) {
      this.currentIndex -= 10;
      this.leftTableData = data.slice(
        this.currentIndex,
        this.currentIndex + 10
      );
      this.rightTableData = data.slice(
        this.currentIndex + 10,
        this.currentIndex + 20
      );
    }
  }

  canRemoveTime(time: IBookingTime): boolean {
    const formattedDate = this.datePipe.transform(time.date, 'yyyy-MM-dd');

    if (!formattedDate) {
      return true;
    }

    const dateStr = `${formattedDate} ${time.timeFrom}`;
    const planDate = new Date(dateStr);

    if (isNaN(planDate.getTime())) {
      return false;
    }

    return planDate > new Date();
  }

  onBookingSelect(item: IBookingTime): void {
    let selectedBooking = this.selectedBookingOutput.times?.findIndex(x => this.isTimesEqual(x, item));
    let tableBooking: IBookingTime = this.bookingTimes!.find(x => this.isTimesEqual(x, item))!;

    if (+selectedBooking >= 0) {
      this.selectedBookingOutput.times?.splice(selectedBooking, 1);
      if (tableBooking) tableBooking.isChecked = false;
    } else {

      if (this.bookingCount == 1 && this.selectedBookingOutput.times.length == 1) {
        this.clearSelectedFromTables();
        this.selectedBookingOutput.times = [];
      }

      if (this.selectedBookingOutput.times?.length < this.bookingCount || this.selectedView === TableViewsEnum.ADD_NUMBER) {
        this.selectedBookingOutput.times?.push(tableBooking);
        tableBooking.isChecked = true;
        this.setFinSource(tableBooking);
      }
    }

    this.showReferral = this.isReferralTimes() ?? false;
    this.setFinancialSources();
  }

  private clearSelectedFromTables(): void {
    this.leftTableData.forEach(x => {
      x.isChecked = false;
    });
    this.rightTableData.forEach(x => {
      x.isChecked = false;
    });
    this.calendarFreeTimes.forEach(x => {
      x.isChecked = false;
    });
  };

  private setFinSource(item: IBookingTime): void {
    if(this.financialSources.some((x: any) => x.financialSourceId == item.financialSourceId)){
      this.selectedBookingOutput.financialSourceId = item.financialSourceId!;
    }
  }

  setSelectedPatient(patient?: IPatient): void {

    if (!patient) {
      this.calendar = null;
    }

    this.selectedBookingOutput.patientId = patient?.patientId;
    this.selectedBookingOutput.patient = patient;
    this.searchPerformed = true;
    this.selectedBookingOutput.times = [];
    if (this.selectedBookingOutput.service) {
      this.applyFilters();
    }
    if (this.selectedView == TableViewsEnum.CALENDAR || this.selectedView == TableViewsEnum.ADD_NUMBER) {
      this.fetchCalendarEvents();
      this.fetchCalendarTimesForSelect();
    }

    this.getMedicalCases();
  }

  multiple: boolean = false;

  changeReservationTab(): void {
    if (this.selectedView == TableViewsEnum.ADD_MULTIPLE) {
      this.multiple = true;
      this.bookingTimes = [];
      this.leftTableData = [];
      this.rightTableData = [];
      this.calendar = null;
    } else if (this.selectedView == TableViewsEnum.CALENDAR) {
      this.bookingTimes = this.bookingTimes.filter(x => !x.isQueue);
      this.fetchCalendarTimesForSelect();
    } else if (this.selectedView == TableViewsEnum.ADD_NUMBER) {
      this.bookingTimes = [];
      this.fetchCalendarTimesForSelect();
    } else {
      this.bookingTimes = this.bookingTimes.filter(x => !x.isQueue);
      this.limit = 30;
      this.applyFilters();
      this.calendar = null;
    }
  }

  isItemChecked(item: IBookingTime): boolean {
    return this.bookingCount > 1 || (!item.isChecked! && this.selectedBookingOutput.times.length >= this.bookingCount);
  }

  calendarOptions = signal<CalendarOptions>({
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: '',
    },
    firstDay: 1,
    dayHeaderFormat: { weekday: 'short' },
    locale: 'et',
    themeSystem: 'standard',
    bootstrapFontAwesome: false,

    initialView: 'dayGridMonth',
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,

    eventStartEditable: false,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    events: this.calendarTimes,
    eventContent: this.eventContent,
    datesSet: (dateInfo: any) => {
      this.period = dateInfo;

      this.fetchCalendarEvents()
    },

    dayCellClassNames: renderProps => {

      if (!this.calendarResponse) return '';
      let propDate = this.datePipe.transform(renderProps.date);
      if (this.calendarResponse.fullyBookedDates.some(x => this.datePipe.transform(x) == propDate)) {
        return 'event-full';
      }

      let date = this.calendarResponse.availableDates.find(x => {
        return this.datePipe.transform(x.date) == propDate;
      });

      let isPayer = date?.financialSourceIds.some(x => x == FinSourceId.PayerPerson);
      let isTk = date?.financialSourceIds.some(x => x == FinSourceId.PayerHealthInsuranceFund);

      if (isPayer && isTk) return 'event-split';
      if (isPayer) return 'event-paid';
      if (isTk) return 'event-free';
      return '';
    },

    eventClassNames: function (arg) {
      if(arg.event.extendedProps['data'].patientId){
        return ["bookedEvent"]
      }
      return [""]
    },
    // Customize button text
    buttonText: {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },

  });
  calendarVisible = true;

  handleDateSelect(selectInfo: DateSelectArg) {
    this.selectedCalendarDate = selectInfo.start;
    if (this.bookingCount == 1) {
      this.selectedBookingOutput.times = [];
    }

    this.fetchCalendarTimesForSelect();
  }

  overlayObj: IOrderServiceTime | null = null;

  handleEventClick(clickInfo: EventClickArg) {
    console.log(clickInfo);
    this.overlayObj = clickInfo.event.extendedProps['data']!;
    this.op.toggle(clickInfo.jsEvent, clickInfo.el);
  }

  getLocation(data: any){
    if(data.departmentShort && data.roomCode){
      return data.departmentShort[0] + " " + data.roomCode;
    }
    return ""
  }

  protected readonly TableViewsEnum = TableViewsEnum;
  protected readonly BookingStatesEnum = BookingStatesEnum;
  showReferral: boolean = false;
  medicalCases: IMedicalCaseDto[] = [];
  companies: ICompany[] = [];

  getMedicalCases(): void {
    if (this.selectedBookingOutput.patientId) {
      this.patientService.getPatientMedicalCases(this.selectedBookingOutput.patientId).subscribe({
        next: (response: IMedicalCaseDto[]) => {
          this.medicalCases = response;
        }
      })
    }
  }

  private getDateTime(date: string, timeFrom: string) {
    const time = timeFrom.split(':');
    let dateR = new Date(date);
    dateR.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
    return dateR;
  }

  setReferral(referral: IReferral) {
    this.selectedBookingOutput.referral = referral ?? undefined;
  }

  successBlock() {
    return this.isReferralTimes() &&
      !this.selectedBookingOutput.referral ||
      (this.selectedBookingOutput.financialSourceId == FinSourceId.AnotherJuridicalPerson && !(this.selectedBookingOutput.companyFilter || this.selectedBookingOutput.companySelected));
  }

  isReferralTimes() {
    return this.selectedBookingOutput
        .service?.financialSources.some(x => x.isReferralRequired && x.financialSourceId == this.selectedBookingOutput.financialSourceId)
      && !this.config?.referral;
  }

  disabledState(item: any) {
    let disabledStates = [BookingStatesEnum.REST, BookingStatesEnum, BookingStatesEnum.QUEUE, BookingStatesEnum.BOOKED];
    return disabledStates.some(x => x == item.state) && this.selectedView == TableViewsEnum.CALENDAR;
  }

  changeFinSource() {
    if (this.selectedBookingOutput.service?.financialSources.some(x => !x.isReferralRequired && x.financialSourceId == this.selectedBookingOutput.financialSourceId)) {
      this.selectedBookingOutput.referral = undefined;
    }
    this.showReferral = this.isReferralTimes() ?? false;
  }

  setMedicalCase() {
    if (!this.selectedBookingOutput.medicalCaseId) {
      this.selectedBookingOutput.medicalCase = undefined;
    } else {
      this.selectedBookingOutput.medicalCase = this.medicalCases.find(x => x.caseId == this.selectedBookingOutput.medicalCaseId);
    }
  }

  private setFinancialSources() {
    if (this.selectedBookingOutput.companyFilter) {
      this.financialSources = [
        {
          financialSourceName: 'Teine juriidiline isik',
          financialSourceId: FinSourceId.AnotherJuridicalPerson,
        },
      ];
      return;
    }

    this.financialSources = [];

    if (this.selectedBookingOutput.times.findIndex(x => x.financialSourceId == FinSourceId.PayerPerson) >= 0) {
      this.financialSources = [
        {
          financialSourceName: 'Patsient',
          financialSourceId: FinSourceId.PayerPerson,
        }
      ];
    } else {

      if(this.selectedBookingOutput.service?.financialSources.some( x => x.financialSourceId == FinSourceId.PayerHealthInsuranceFund)) {
        this.financialSources.push({
          financialSourceName: 'Tervisekassa',
          financialSourceId: FinSourceId.PayerHealthInsuranceFund,
        });
      }

      if(this.selectedBookingOutput.service?.financialSources.some( x => x.financialSourceId == FinSourceId.PayerPerson)) {
        this.financialSources.push( {
          financialSourceName: 'Patsient',
          financialSourceId: FinSourceId.PayerPerson,
        });
      }

    }

    if(this.selectedBookingOutput.service?.financialSources.some( x => x.financialSourceId == FinSourceId.AnotherJuridicalPerson)) {
      this.financialSources.push(
        {
          financialSourceName: 'Teine juriidiline isik',
          financialSourceId: FinSourceId.AnotherJuridicalPerson,
        }
      );
    }
  }

  isCompanyForSelectShow() {
    return !this.selectedBookingOutput.companyFilter && this.selectedBookingOutput.financialSourceId == FinSourceId.AnotherJuridicalPerson;
  }

  private setSelectedProvider(providerId: number | null): void {
    const provider = this.providers.find((provider) => provider.personId === providerId) || null;
    this.selectedBookingOutput.provider = provider;
    this.selectedBookingOutput.providerId = provider?.personId ?? null;
  }

  protected readonly FinSourceId = FinSourceId;
  protected readonly Array = Array;
}

enum TableViewsEnum {
  ALL_TIMES,
  CALENDAR,
  ADD_NUMBER,
  ADD_MULTIPLE,
}

enum BookingStatesEnum {
  REST = 'Rest',
  FREE = 'Free',
  QUEUE = 'Queue',
  BOOKED = 'Booked',
}
