@defer (when true){
  <full-calendar #calendar [options]="calendarOptions()">
    <ng-template #eventContent let-arg>
      <b>{{ arg.timeText }}</b>
      <i>{{ arg.event.title }}</i>
    </ng-template>
  </full-calendar>
} @loading (minimum 2s) {

  <p-table [value]="[{}]" [columns]="[1, 2, 3, 4, 5]">
    <ng-template pTemplate="header">
      <tr>
        @for (col of [1, 2, 3, 4, 5]; track $index) {
          <th [style.width]="20">
            <p-skeleton width="100%" height="3rem"></p-skeleton>
          </th>
        }
      </tr>
    </ng-template>
  </p-table>

}
