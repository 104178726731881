@if (products) {
  <div class="pt-4">
    <div class="flex flex-row justify-content-between">
      <h3 class="text-color-secondary text-base font-bold">Tellimuse sisu</h3>
    </div>
    <div class="col-md-12 mt-2">
      <p-table [value]="products"
               styleClass="p-datatable-sm p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>Toode</th>
            <th>Kogus</th>
            <th>Tasuda(€)</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td>{{ product.name }}</td>
            <td>{{ product.repeat }}</td>
            <td>{{ product | rowPrice }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
}

