import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-patient-basic-data',
  template: `
    <div class="flex flex-column gap-2-5">
      <a class="text-primary patient-link" (click)="onPatientNameClick()">
        {{ patientName | userProperName }}
      </a>
      <span class="text-base font-semibold patient-code">
        <span class="patient-code__value cursor-pointer" (click)="copyIdCode(patientCode)">{{ patientCode }}</span>
      </span>
    </div>
  `,
  styleUrl: './patient-basic-data.component.scss'
})
export class PatientBasicDataComponent {
  @Input() patientName!: string;
  @Input() patientCode!: string;

  @Output() onPatientClick: EventEmitter<any> = new EventEmitter();

  constructor(private toastService: ToastService) {
  }

  copyIdCode(value: string): void {
    navigator.clipboard.writeText(value.toString()).then(() => {
      this.toastService.secondary("Isikukood kopeeritud", "Info");
    }).catch(() => {
      this.toastService.error("Isikukoodi kopeerimine nurjus");
    });
  }

  onPatientNameClick(): void {
    this.onPatientClick.emit();
  }
}
