import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { NgClass } from "@angular/common";
import { OrderStatusPipe } from "../../pipes/order-status.pipe";
import { BadgeModule } from "primeng/badge";
import { TagModule } from "primeng/tag";
import { IMapping } from "@core/interfaces/mapping.interface";
import { BadgeType } from "@core/enums/badge-type.enum";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: 'medis-order-service-status-label',
  standalone: true,
  imports: [
    NgClass,
    OrderStatusPipe,
    BadgeModule,
    TagModule,
    TooltipModule,
  ],
  template: `
    @if (bookingNotNeeded) {
      <span>Ei vaja broneeringut</span>
    } @else {
      <p-tag
        rounded="true"
        class="text-center"
        [pTooltip]="(invoiceStatusTitle || status) | orderStatus"
        [tooltipDisabled]="!isShort"
        value="{{(invoiceStatusTitle || status) | orderStatus : isShort}}"
        [styleClass]="labelClass"
      ></p-tag>
    }
  `
})
export class OrderServiceStatusLabelComponent implements OnInit, OnChanges {
  @Input() status!: OrderServiceStatus | null | undefined;
  @Input() invoiceStatus!: InvoiceStatus;
  @Input() orderService!: IOrderServiceTime;
  @Input() isShort: boolean = false;

  private readonly statusStyleMap: IMapping = {
    [BadgeType.YELLOW]: 'tag tag--yellow',
    [BadgeType.AZURE]: 'tag tag--teal',
    [BadgeType.PURPLE]: 'tag tag--purple',
    [BadgeType.INFO]: 'tag tag--cyan',
    [BadgeType.SUCCESS]: 'tag tag--green',
    [BadgeType.PRIMARY]: 'tag tag--blue',
    [BadgeType.WARNING]: 'tag tag--orange',
    [BadgeType.DANGER]: 'tag tag--red',
    [BadgeType.DEFAULT]: 'tag tag--default',
  };

  labelClass: any = null;
  invoiceStatusTitle: InvoiceStatus | null = null;
  bookingNotNeeded: boolean = false;

  ngOnInit() {
    this.labelClass = this.getLabelClass();
    this.invoiceStatusTitle = this.getInvoiceStatusToShow();
    this.bookingNotNeeded = this.isBookingNotNeeded();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['status'].currentValue !== changes['status'].previousValue) {
      this.labelClass = this.getLabelClass();
    }
  }

  getLabelClass = () => {
    const isComplexPart = this.orderService && this.orderService.complexServiceId;
    const partBookingNotNeeded = isComplexPart && this.orderService && this.orderService?.servicePart?.bookingNotNeeded;
    const serviceBookingNotNeeded = !isComplexPart && this.orderService && this.orderService.bookingNotNeeded;
    // let statusType = BadgeType.DEFAULT;
    // switch (this.status){
    //   case OrderServiceStatus.CONFIRMED:
    //     statusType = BadgeType.PURPLE;
    //     break;
    //   case InvoiceStatus.CREATED:
    //     statusType = BadgeType.PRIMARY;
    //     break;
    //   case OrderServiceStatus.PRESENT:
    //     statusType = BadgeType.SUCCESS;
    //     break;
    // }
    //
    // return this.statusStyleMap[statusType];

    if (this.status === OrderServiceStatus.PRESENT)
      return this.statusStyleMap[BadgeType.SUCCESS];
    if (this.status && [OrderServiceStatus.CREATED].includes(this.status))
      return this.statusStyleMap[BadgeType.PRIMARY];
    if (this.status === OrderServiceStatus.ABSENCE)
      return this.statusStyleMap[BadgeType.PRIMARY];
    if (this.status === OrderServiceStatus.PREPARING || this.status === OrderServiceStatus.REALIZATION)
      return this.statusStyleMap[BadgeType.WARNING];
    if (this.status === OrderServiceStatus.FINISHED)
      return this.statusStyleMap[BadgeType.DEFAULT];
    if (this.invoiceStatus === InvoiceStatus.REQUIRED)
      return this.statusStyleMap[BadgeType.DANGER];
    if (this.invoiceStatus === InvoiceStatus.CREATED)
      return this.statusStyleMap[BadgeType.INFO];
    if (this.invoiceStatus === InvoiceStatus.PAYMENT_LINK_SENT)
      return this.statusStyleMap[BadgeType.PRIMARY];
    if (this.invoiceStatus === InvoiceStatus.PREPAID_FAILED)
      return this.statusStyleMap[BadgeType.WARNING];
    if (partBookingNotNeeded || serviceBookingNotNeeded)
      return this.statusStyleMap[BadgeType.INFO];
    if (this.status === OrderServiceStatus.BOOKING_NOT_NEEDED)
      return this.statusStyleMap[BadgeType.INFO];
    if (this.status && [OrderServiceStatus.CONFIRMED, OrderServiceStatus.UNFULFILLED].includes(this.status))
      return this.statusStyleMap[BadgeType.PURPLE];
    if (this.status && [OrderServiceStatus.IN_SERVICE_QUEUE, OrderServiceStatus.IN_PROVIDER_QUEUE].includes(this.status))
      return this.statusStyleMap[BadgeType.SUCCESS];
    return this.statusStyleMap[BadgeType.DEFAULT];
  };

  isBookingNotNeeded = () => {
    return this.orderService?.bookingNotNeeded || this.orderService?.servicePart?.bookingNotNeeded || false;
  }

  getInvoiceStatusToShow = () => {
    const statuses = [OrderServiceStatus.IN_PROVIDER_QUEUE, OrderServiceStatus.CONFIRMED];
    const invoiceStatuses = [InvoiceStatus.REQUIRED, InvoiceStatus.CANCELLED, InvoiceStatus.PREPAID_FAILED, InvoiceStatus.CREATED, InvoiceStatus.PAYMENT_LINK_SENT];
    return this.status && statuses.includes(this.status) && invoiceStatuses.includes(this.invoiceStatus) ? this.invoiceStatus : null;
  }
}
