export enum AbsenceReason {
  TRAINING = 751,
  VACATION = 760,
  SICK = 922,
  OTHER = 997,
  MEETING = 3022
}

export const AbsenceReasonName: Record<AbsenceReason, string> = {
  [AbsenceReason.TRAINING]: 'Koolitus',
  [AbsenceReason.VACATION]: 'Puhkus',
  [AbsenceReason.SICK]: 'Haige',
  [AbsenceReason.OTHER]: 'Muu',
  [AbsenceReason.MEETING]: 'Koosolek',
}
