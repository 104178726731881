<div class="flex flex-column gap-4">
  @if (bodyText) {
    <form [formGroup]="reasonForm" class="flex flex-column gap-4" (ngSubmit)="submit()">
      <p>{{ bodyText }}</p>

      @if (showReasonOptions) {
        <div class="form-section__col w-12 gap-4">
          <p-floatLabel>
            <p-dropdown
              id="reasonOption"
              class="w-full pt-2 pb-2"
              styleClass="w-full"
              [options]="reasonOptions"
              formControlName="reasonOption"
              (onChange)="changeReasonOption()"
            />
            <label for="reasonOption">Tühistamise põhjus</label>
          </p-floatLabel>
          @if (showReasonError) {
            <small class="p-error">Lisa tühistamise põhjus!</small>
          }
        </div>
      }
      @if (showReason) {
        <div class="form-section__col w-12 gap-4">
          <p-floatLabel>
            <textarea type="text"
                      name="reason"
                      class="form-control w-full"
                      formControlName="reason"
                      pInputTextarea
                      cols="66"
                      rows="3">
            </textarea>
            <label for="reasonOption">Puudumise põhjus</label>
          </p-floatLabel>
        </div>
      }

      <div class="flex row justify-content-end py-4 gap-2">
        <p-button
          label="Ei"
          icon="pi pi-times"
          outlined="true"
          severity="secondary"
          (click)="closeModal()"
        />
        <p-button
          type="submit"
          label="Jah"
          icon="pi pi-check"
        />
      </div>
    </form>
  }
</div>
