import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { IPatient } from "@core/interfaces/patient.interface";
import { ISearchResult } from "@core/interfaces/search-result.interface";
import { catchError, Observable, of, Subscription, switchMap } from "rxjs";
import { CompanyService } from "@core/services/company.service";
import { tap } from "rxjs/operators";
import { ICompany } from "@core/interfaces/company.interface";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { GoodsService } from "@core/services/goods.service";
import { IGoods } from "@core/interfaces/goods.interface";
import { ServiceService } from "@core/services/service.service";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { ConfirmModalComponent } from "@shared/modals/confirm/confirm.modal.component";
import { FinSourceId } from "@core/enums/financial-source.enum";
import { ToastService } from "@core/services/toast.service";
import { IOrder } from "@core/interfaces/order.interface";
import { OrderService } from "@core/services/order.service";
import { InvoiceService } from "@core/services/invoice.service";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { DirectoPaymentModalComponent } from "@shared/modals/directo-payment/directo-payment.modal.component";

enum ProductType {
  GOODS = 'GOODS',
  SERVICE = 'SERVICE',
}

enum AddButtonTitle {
  GOODS = 'Lisa toode',
  SERVICE = 'Lisa teenus',
}

enum ProductTitle {
  GOODS = 'Toode',
  SERVICE = 'Teenus',
}

enum EmptyProductListTitle {
  GOODS = 'Selle asutuse tooteid pole leitud',
  SERVICE = 'Selle asutuse teenuseid pole leitud',
}

@Component({
  templateUrl: './product-sale.modal.component.html',
  styleUrl: './product-sale.modal.component.scss'
})
export class ProductSaleModalComponent implements OnInit, OnDestroy {
  searchPerformed: boolean = false;
  patients: IPatient[] = [];
  selectedPatient!: IPatient | undefined;
  companies!: ICompany[];
  currentUser!: ICurrentUser | null;
  order: IOrder = { orderServices: [] };
  selectedCompanyId!: number | undefined;
  goods: IGoods[] = [];
  services: IGoods[] = [];
  selectedProducts: IOrderService[] = [];
  companyProducts: IGoods[] = [];
  selectedProduct!: IOrderService | null;
  totalSum = 0;
  selectedProductType: ProductType = ProductType.GOODS;
  addButtonTitle: string = AddButtonTitle.GOODS;
  productTitle: string = ProductTitle.GOODS;
  emptyListTitle: string = EmptyProductListTitle.GOODS;
  productTypes: { label: string; value: string }[] = [
    { label: 'Toode', value: ProductType.GOODS },
    { label: 'Teenus', value: ProductType.SERVICE },
  ];

  currentUserSubscription!: Subscription;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private companyService: CompanyService,
    private goodsService: GoodsService,
    private serviceService: ServiceService,
    private authService: AuthService,
    private modalService: ModalService,
    private toastService: ToastService,
    private invoiceService: InvoiceService,
    private orderService: OrderService,
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.currentUserSubscription = this.authService.currentUserSubject.subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.loadData();
      } else {
        this.authService.setCurrentUserToSubject();
      }
    });

    if (this.instance && this.instance.data) {
      this.selectedPatient = this.instance.data['patient'];
    }
  }

  private loadData(): void {
    this.getGoods()
      .pipe(
        switchMap(() => this.getServices()),
        switchMap(() => this.getCompanies())
      )
      .subscribe();
  }

  handleSearchResults(data: ISearchResult): void {
    const patients = data.results;
    const searchQuery = data.query;
    if (patients.length === 1 && patients[0].idCode === searchQuery) {
      this.searchPerformed = true;
      this.selectedPatient = patients[0];
    } else {
      this.searchPerformed = true;
      this.patients = patients;
    }
  }

  onClearSearch() {
    this.searchPerformed = false;
    this.patients = [];
    this.selectedPatient = undefined;
  }

  setSelectedPatient(patient: IPatient) {
    this.selectedPatient = patient;
    this.searchPerformed = true;
  }

  getCompanies(): Observable<any> {
    return this.companyService.getCompaniesWithProducts().pipe(
      tap((response) => {
        this.companies = response;
        const personDepartments = this.currentUser?.personDepartments || [];
        const selectedDepartment = personDepartments.find(
          (d) => d.departmentId === this.currentUser?.selectedDepartmentId
        );
        this.selectedCompanyId = selectedDepartment
          ? this.companies.find(
          (c) => c.companyId === selectedDepartment.department.companyId
        )?.companyId ?? undefined
          : undefined;

        if (this.selectedCompanyId) this.getCompanyProducts(this.selectedCompanyId);
      })
    );
  }

  getGoods(): Observable<any> {
    return this.goodsService.getAll().pipe(
      tap((response) => (this.goods = response))
    );
  }

  getServices(): Observable<any> {
    return this.serviceService.getSpecialServices().pipe(
      tap((response) => (this.services = response))
    );
  }

  getCompanyProducts(previousCompanyId?: number | null): void {
    if (this.selectedProducts.length > 0) {
      const CONFIRMATION_MODAL_TITLE = 'Asutuse muutmisel kaovad valitud tooted/teenused.';
      const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid asutust muuta?'
      const ref = this.modalService.openModal({
        component: ConfirmModalComponent,
        size: ModalSize.MEDIUM,
        header: CONFIRMATION_MODAL_TITLE,
        data: {bodyText: CONFIRMATION_MODAL_BODY_TEXT}
      })

      ref.onClose.subscribe((result: boolean | string | number) => {
        if (result) {
          if (result !== previousCompanyId) {
            this.resetSelectedProducts();
          } else {
            this.selectedCompanyId = previousCompanyId;
          }
          this.updateCompanyProducts();
        }
      })
    } else {
      this.resetSelectedProducts();
      this.updateCompanyProducts();
    }
  }

  onProductTypeChange(product: ProductType): void {
    this.selectedProduct = null;
    this.selectedProductType = product;
    this.addButtonTitle = AddButtonTitle[product];
    this.productTitle = ProductTitle[product];
    this.emptyListTitle = EmptyProductListTitle[product];
    product === ProductType.GOODS ? this.getCompanyGoods() : this.getCompanyServices();
  }

  filterProduct(product: any): boolean {
    return (
      product.stockCodes.length > 0 &&
      product.stockCodes.some((code: any) => code.companyId === this.selectedCompanyId)
    );
  }

  getCompanyGoods(): void {
    this.companyProducts = this.selectedCompanyId
      ? this.goods.filter((g) => this.filterProduct(g))
      : [];
  }

  getCompanyServices(): void {
    this.companyProducts = this.selectedCompanyId
      ? this.services.filter((s) => this.filterProduct(s))
      : [];
  }

  addProduct(): void {
    if (
      this.selectedProducts && this.selectedProducts.length > 0
      && this.selectedProducts.some((p) => p.serviceId === this.selectedProduct?.serviceId)
    ) {
      const product = this.selectedProducts.find((p) => p.serviceId === this.selectedProduct!.serviceId);
      if (product) {
        product.repeat = (product.repeat ?? 0) + 1;
      }
      this.countTotalSum();
    } else if (this.selectedProduct !== null) {
      this.serviceService
        .getServicePrice(this.selectedProduct.serviceId)
        .pipe(
          tap((response) => {
            if (this.selectedProduct) {
              this.selectedProduct.price = parseFloat(response.price.replace(',', '.'));
              this.selectedProduct.financialSourceId = FinSourceId.PayerPerson;
              this.selectedProduct.repeat = 1;
              this.selectedProducts.push(this.selectedProduct);
              this.countTotalSum();
            }
          }),
          catchError((error) => {
            this.toastService.error(error.message);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  deleteProduct(serviceId: number): void {
    this.selectedProducts = this.selectedProducts.filter((p) => p.serviceId !== serviceId);
    this.countTotalSum();
  }

  divideOrAdd(product: any): void {
    if (product.repeat === 1) return;
    this.countTotalSum();
  }

  countTotalSum(): void {
    this.totalSum = this.selectedProducts.reduce((sum, p) => sum + this.getRowPrice(p), 0);
  }

  private resetSelectedProducts(): void {
    this.selectedProduct = null;
    this.selectedProducts = [];
    this.countTotalSum();
  }

  private updateCompanyProducts(): void {
    this.selectedProductType === ProductType.GOODS ? this.getCompanyGoods() : this.getCompanyServices();
  }

  getRowPrice(product: any): number {
    return parseFloat((product.price * product.repeat).toFixed(2));
  }

  pay(): void {
    this.order.orderServices = this.selectedProducts;
    this.order.patientId = this.selectedPatient?.patientId;
    this.order.providerId = this.currentUser?.personId;
    this.order.departmentId = this.currentUser?.selectedDepartmentId;
    this.order.saleCompanyId = this.selectedCompanyId;

    this.orderService.save(this.order).subscribe({
      next: order => {
        if (order.orderId) {
          const orderId = order.orderId;
          this.invoiceService.createInvoice(orderId).subscribe({
            next: response => {
              this.openInvoiceLinks(response.invoiceNumbers, orderId);
            }
          })
        }
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  private openInvoiceLinks(invoiceNumbers: string[], orderId: number): void {
    invoiceNumbers.forEach(invoiceNumber => {
      this.invoiceService.getInvoiceLink(invoiceNumber).subscribe({
        next: response => {
          window.open(response.link, '_blank');
          this.openDirectoModal(response.link, orderId);
        }
      })
    })
  }

  private openDirectoModal(link: string, orderId: number): void {
    this.closeModal();
    this.modalService.openModal({
      component: DirectoPaymentModalComponent,
      size: ModalSize.LARGE,
      header: 'Maksmine Directos',
      data: { orderId, patient: this.selectedPatient, products: this.selectedProducts, link }
    })
  }

  closeModal(): void {
    this.ref.close();
  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }
}
