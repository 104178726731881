<div class="flex flex-column gap-4">
  <form class="flex flex-column gap-4"
        role="form"
        [formGroup]="addRoomForm">
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input
            id="codeName"
            name="codeName"
            type="text"
            class="w-full"
            [ngClass]="{'ng-invalid ng-dirty': formSubmitted && codeName.errors}"
            formControlName="codeName"
            pInputText
          />
          <label for="codeName">Ruumi number</label>
          @if (formSubmitted && codeName.errors) {
            <small class="p-error">Ruumi number on kohustuslik</small>
          }
        </p-floatLabel>
      </div>
      <div class="form-section__col w-full">
        <medis-address-select-list
          id="address"
          labelName="Aadress/asukoht"
          formControlName="address"
          [required]="true"
          [formSubmitted]="formSubmitted"
        ></medis-address-select-list>
      </div>
    </div>
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-full">
        <medis-room-type-select-list
          id="roomType"
          labelName="Ruumi tüüp"
          formControlName="roomType"
        ></medis-room-type-select-list>
      </div>
      <div class="form-section__col w-full">
        <p-floatLabel>
          <input
            id="phone"
            name="phone"
            type="text"
            class="w-full"
            [ngClass]="{'ng-invalid ng-dirty': formSubmitted && phone.errors}"
            formControlName="phone"
            pInputText
          />
          <label for="codeName">Telefon</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="form-section__row flex gap-4">
      <div class="flex flex-row w-6 justify-content-evenly">
        <div class="flex align-items-center">
          <p-radioButton
            name="isSpecial"
            formControlName="isSpecial"
            [value]="true">
          </p-radioButton>
          <label class="ml-2 text-color-secondary">Spetsialiseeritud</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            name="isSpecial"
            formControlName="isSpecial"
            [value]="false">
          </p-radioButton>
          <label class="ml-2 text-color-secondary">Standart</label>
        </div>
      </div>
      <div class="form-section__col w-6">
        <p-floatLabel>
          <textarea id="description"
                    name="description"
                    rows="2"
                    cols="49"
                    class="form-control w-full"
                    pInputTextarea
                    formControlName="description">
          </textarea>
          <label for="description">Lühikirjeldus</label>
        </p-floatLabel>
      </div>
    </div>
  </form>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button
    label="Katkesta"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
    size="small"/>
  <p-button
    label="Salvesta"
    icon="pi pi-check"
    size="small"
    (click)="save()"
  ></p-button>
</div>

