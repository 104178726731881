import { Injectable } from '@angular/core';
import { Country } from "@core/enums/country.enum";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countryCodes = new Map<Country, string>([
    [Country.AFG, '+93'], [Country.ALB, '+355'], [Country.DZA, '+213'], [Country.AND, '+376'], [Country.AGO, '+244'],
    [Country.ARG, '+54'], [Country.ARM, '+374'], [Country.AUS, '+61'], [Country.AUT, '+43'], [Country.AZE, '+994'],
    [Country.BHR, '+973'], [Country.BGD, '+880'], [Country.BLR, '+375'], [Country.BEL, '+32'], [Country.BLZ, '+501'],
    [Country.BEN, '+229'], [Country.BTN, '+975'], [Country.BOL, '+591'], [Country.BIH, '+387'], [Country.BWA, '+267'],
    [Country.BRA, '+55'], [Country.BRN, '+673'], [Country.BGR, '+359'], [Country.BFA, '+226'], [Country.BDI, '+257'],
    [Country.KHM, '+855'], [Country.CMR, '+237'], [Country.CAN, '+1'], [Country.CPV, '+238'], [Country.CAF, '+236'],
    [Country.TCD, '+235'], [Country.CHL, '+56'], [Country.CHN, '+86'], [Country.COL, '+57'], [Country.COM, '+269'],
    [Country.COG, '+242'], [Country.COD, '+243'], [Country.CRI, '+506'], [Country.HRV, '+385'], [Country.CUB, '+53'],
    [Country.CYP, '+357'], [Country.CZE, '+420'], [Country.DNK, '+45'], [Country.DJI, '+253'], [Country.DOM, '+1'],
    [Country.ECU, '+593'], [Country.EGY, '+20'], [Country.SLV, '+503'], [Country.GNQ, '+240'], [Country.ERI, '+291'],
    [Country.EST, '+372'], [Country.ETH, '+251'], [Country.FJI, '+679'], [Country.FIN, '+358'], [Country.FRA, '+33'],
    [Country.GUF, '+594'], [Country.PYF, '+689'], [Country.GAB, '+241'], [Country.GMB, '+220'], [Country.GEO, '+995'],
    [Country.DEU, '+49'], [Country.GHA, '+233'], [Country.GIB, '+350'], [Country.GRC, '+30'], [Country.GRL, '+299'],
    [Country.GTM, '+502'], [Country.GIN, '+224'], [Country.GNB, '+245'], [Country.GUY, '+592'], [Country.HTI, '+509'],
    [Country.HND, '+504'], [Country.HKG, '+852'], [Country.HUN, '+36'], [Country.ISL, '+354'], [Country.IND, '+91'],
    [Country.IDN, '+62'], [Country.IRN, '+98'], [Country.IRQ, '+964'], [Country.IRL, '+353'], [Country.ISR, '+972'],
    [Country.ITA, '+39'], [Country.CIV, '+225'], [Country.JPN, '+81'], [Country.JOR, '+962'], [Country.KAZ, '+7'],
    [Country.KEN, '+254'], [Country.KIR, '+686'], [Country.PRK, '+850'], [Country.KOR, '+82'], [Country.KWT, '+965'],
    [Country.KGZ, '+996'], [Country.LAO, '+856'], [Country.LVA, '+371'], [Country.LBN, '+961'], [Country.LSO, '+266'],
    [Country.LBR, '+231'], [Country.LBY, '+218'], [Country.LIE, '+423'], [Country.LTU, '+370'], [Country.LUX, '+352'],
    [Country.MAC, '+853'], [Country.MKD, '+389'], [Country.MDG, '+261'], [Country.MWI, '+265'], [Country.MYS, '+60'],
    [Country.MDV, '+960'], [Country.MLI, '+223'], [Country.MLT, '+356'], [Country.MHL, '+692'], [Country.MTQ, '+596'],
    [Country.MRT, '+222'], [Country.MUS, '+230'], [Country.MEX, '+52'], [Country.FSM, '+691'], [Country.MDA, '+373'],
    [Country.MCO, '+377'], [Country.MNG, '+976'], [Country.MNE, '+382'], [Country.MAR, '+212'], [Country.MOZ, '+258'],
    [Country.MMR, '+95'], [Country.NAM, '+264'], [Country.NRU, '+674'], [Country.NPL, '+977'], [Country.NLD, '+31'],
    [Country.NCL, '+687'], [Country.NZL, '+64'], [Country.NIC, '+505'], [Country.NER, '+227'], [Country.NGA, '+234'],
    [Country.NIU, '+683'], [Country.NFK, '+672'], [Country.NOR, '+47'], [Country.OMN, '+968'], [Country.PAK, '+92'],
    [Country.PLW, '+680'], [Country.PSE, '+970'], [Country.PAN, '+507'], [Country.PNG, '+675'], [Country.PRY, '+595'],
    [Country.PER, '+51'], [Country.PHL, '+63'], [Country.PCN, '+870'], [Country.POL, '+48'], [Country.PRT, '+351'],
    [Country.QAT, '+974'], [Country.REU, '+262'], [Country.ROU, '+40'], [Country.RUS, '+7'], [Country.RWA, '+250'],
    [Country.SHN, '+290'], [Country.KNA, '+1'], [Country.SPM, '+508'], [Country.WSM, '+685'], [Country.SMR, '+378'],
    [Country.STP, '+239'], [Country.SAU, '+966'], [Country.SEN, '+221'], [Country.SRB, '+381'], [Country.SYC, '+248'],
    [Country.SLE, '+232'], [Country.SGP, '+65'], [Country.SVK, '+421'], [Country.SVN, '+386'], [Country.SLB, '+677'],
    [Country.SOM, '+252'], [Country.ZAF, '+27'], [Country.SSD, '+211'], [Country.ESP, '+34'], [Country.LKA, '+94'],
    [Country.SDN, '+249'], [Country.SUR, '+597'], [Country.SWZ, '+268'], [Country.SWE, '+46'], [Country.CHE, '+41'],
    [Country.SYR, '+963'], [Country.TWN, '+886'], [Country.TJK, '+992'], [Country.TZA, '+255'], [Country.THA, '+66'],
    [Country.TLS, '+670'], [Country.TGO, '+228'], [Country.TKL, '+690'], [Country.TON, '+676'], [Country.TUN, '+216'],
    [Country.TUR, '+90'], [Country.TKM, '+993'], [Country.TUV, '+688'], [Country.UGA, '+256'], [Country.UKR, '+380'],
    [Country.ARE, '+971'], [Country.GBR, '+44'], [Country.USA, '+1'], [Country.URY, '+598'], [Country.UZB, '+998'],
    [Country.VUT, '+678'], [Country.VAT, '+379'], [Country.VEN, '+58'], [Country.VNM, '+84'], [Country.WLF, '+681'],
    [Country.YEM, '+967'], [Country.ZMB, '+260'], [Country.ZWE, '+263']
  ]);

  getCountryPhoneCode(country: Country, prefix: string = ''): string {
    return this.countryCodes.get(country) ? prefix + this.countryCodes.get(country) : '';
  }

  getAllCountryCodes(): string[] {
    return [...this.countryCodes.values()];
  }

  private countries: Map<Country, string> = new Map<Country, string>([
    [Country.EST, "Eesti"], [Country.LVA, "Läti"], [Country.LTU, "Leedu"], [Country.FIN, "Soome"], [Country.RUS, "Venemaa"],
    [Country.UKR, "Ukraina"], [Country.AFG, "Afganistan"], [Country.ALB, "Albaania"], [Country.DZA, "Alžeeria"], [Country.ASM, "Ameerika Samoa"],
    [Country.USA, "Ameerika Ühendriigid"], [Country.AND, "Andorra"], [Country.AGO, "Angola"], [Country.AIA, "Anguilla"], [Country.ATA, "Antarktis"],
    [Country.ATG, "Antigua ja Barbuda"], [Country.MAC, "Aomen"], [Country.ARE, "Araabia Ühendemiraadid"], [Country.ARG, "Argentina"], [Country.ARM, "Armeenia"],
    [Country.ABW, "Aruba"], [Country.AZE, "Aserbaidžaan"], [Country.AUS, "Austraalia"], [Country.AUT, "Austria"], [Country.BHS, "Bahama"],
    [Country.BHR, "Bahrein"], [Country.BGD, "Bangladesh"], [Country.BRB, "Barbados"], [Country.PLW, "Belau"], [Country.BEL, "Belgia"],
    [Country.BLZ, "Belize"], [Country.BEN, "Benin"], [Country.BMU, "Bermuda"], [Country.BTN, "Bhutan"], [Country.BOL, "Boliivia"],
    [Country.BIH, "Bosnia- ja Hertsegoviina"], [Country.BWA, "Botswana"], [Country.BVT, "Bouvet saar"], [Country.BRA, "Brasiilia"], [Country.IOT, "Briti India ookeani ala"],
    [Country.VGB, "Briti Neitsisaared"], [Country.BRN, "Brunei Darussalam"], [Country.BGR, "Bulgaaria"], [Country.BFA, "Burkina Faso"], [Country.BDI, "Burundi"],
    [Country.CPV, "Cabo Verde"], [Country.COL, "Colombia"], [Country.COK, "Cooki saared"], [Country.CRI, "Costa Rica"], [Country.CIV, "Côte d'Ivoire"],
    [Country.DJI, "Djibouti"], [Country.DMA, "Dominica"], [Country.DOM, "Dominikaani Vabariik"], [Country.ECU, "Ecuador"], [Country.EGY, "Egiptus"],
    [Country.GNQ, "Ekvatoriaal-Guinea"], [Country.SLV, "El Salvador"], [Country.ERI, "Eritrea"], [Country.ETH, "Etioopia"], [Country.FLK, "Falklandi (Malviini) saared"],
    [Country.FJI, "Fidži"], [Country.PHL, "Filipiinid"], [Country.FRO, "Fääri saared"], [Country.GAB, "Gabon"], [Country.GMB, "Gambia"],
    [Country.GHA, "Ghana"], [Country.GIB, "Gibraltar"], [Country.GRD, "Grenada"], [Country.GEO, "Gruusia"], [Country.GRL, "Gröönimaa"],
    [Country.GLP, "Guadeloupe"], [Country.GUM, "Guam"], [Country.GTM, "Guatemala"], [Country.GIN, "Guinea"], [Country.GNB, "Guinea-Bissau"],
    [Country.GUY, "Guyana"], [Country.HTI, "Haiti"], [Country.HMD, "Heard ja McDonald"], [Country.CHN, "Hiina"], [Country.ESP, "Hispaania"],
    [Country.NLD, "Holland"], [Country.ANT, "Hollandi Antillid"], [Country.HND, "Honduras"], [Country.HKG, "Hongkong"], [Country.HRV, "Horvaatia"],
    [Country.IRL, "Iirimaa"], [Country.ISR, "Iisrael"], [Country.IND, "India"], [Country.IDN, "Indoneesia"], [Country.IRQ, "Iraak"],
    [Country.IRN, "Iraan"], [Country.ISL, "Island"], [Country.ITA, "Itaalia"], [Country.JPN, "Jaapan"], [Country.JAM, "Jamaica"],
    [Country.YEM, "Jeemen"], [Country.JOR, "Jordaania"], [Country.CXR, "Jõulusaar"], [Country.CYM, "Kaimanisaared"], [Country.KHM, "Kambodža"],
    [Country.CMR, "Kamerun"], [Country.CAN, "Kanada"], [Country.KAZ, "Kasahstan"], [Country.QAT, "Katar"], [Country.KEN, "Kenya"],
    [Country.CAF, "Kesk-Aafrika Vabariik"], [Country.KIR, "Kiribati"], [Country.COM, "Komoorid"], [Country.COG, "Kongo"], [Country.COD, "Kongo DV"],
    [Country.CCK, "Kookossaared (Keelingi saared)"], [Country.PRK, "Korea RDV"], [Country.KOR, "Korea Vabariik"], [Country.GRC, "Kreeka"], [Country.CUB, "Kuuba"],
    [Country.KWT, "Kuveit"], [Country.KGZ, "Kõrgõzstan"], [Country.CYP, "Küpros"], [Country.LAO, "Laos"], [Country.LSO, "Lesotho"],
    [Country.LBR, "Libeeria"], [Country.LIE, "Liechtenstein"], [Country.LBN, "Liibanon"], [Country.LBY, "Liibüa"], [Country.LUX, "Luksemburg"],
    [Country.ZAF, "Lõuna-Aafrika Vabariik"], [Country.SGS, "Lõuna-Georgia ja Lõuna-Sandwich"], [Country.ESH, "Lääne-Sahara"], [Country.MDG, "Madagaskar"], [Country.MKD, "Makedoonia"],
    [Country.MYS, "Malaisia"], [Country.MWI, "Malawi"], [Country.MDV, "Maldiivid"], [Country.MLI, "Mali"], [Country.MLT, "Malta"],
    [Country.MAR, "Maroko"], [Country.MHL, "Marshalli Saared"], [Country.MTQ, "Martinique"], [Country.MRT, "Mauritaania"], [Country.MUS, "Mauritius"],
    [Country.MYT, "Mayotte"], [Country.MEX, "Mehhiko"], [Country.FSM, "Mikroneesia"], [Country.MDA, "Moldova"], [Country.MCO, "Monaco"],
    [Country.MNG, "Mongoolia"], [Country.MSR, "Montserrat"], [Country.MOZ, "Mosambiik"], [Country.XYY, "Mujal nimetamata territooriumid"], [Country.XXX, "Määramata"],
    [Country.MMR, "Myanmar (Birma)"], [Country.NAM, "Namiibia"], [Country.NRU, "Nauru"], [Country.NPL, "Nepal"], [Country.NIC, "Nicaragua"],
    [Country.NGA, "Nigeeria"], [Country.NER, "Niger"], [Country.NIU, "Niue"], [Country.NFK, "Norfolk"], [Country.NOR, "Norra"],
    [Country.OMN, "Omaan"], [Country.PNG, "Paapua Uus-Guinea"], [Country.PAK, "Pakistan"], [Country.PSE, "Palestiina okupeeritud alad"], [Country.PAN, "Panama"],
    [Country.PRY, "Paraguay"], [Country.PER, "Peruu"], [Country.PCN, "Pitcairn"], [Country.POL, "Poola"], [Country.PRT, "Portugal"],
    [Country.GUF, "Prantsuse Guajaana"], [Country.ATF, "Prantsuse Lõunaalad"], [Country.PYF, "Prantsuse Polüneesia"], [Country.FRA, "Prantsusmaa"], [Country.PRI, "Puerto Rico"],
    [Country.MNP, "Põhja-Mariaanid"], [Country.VAT, "Püha Tool (Vatikan)"], [Country.REU, "Réunion"], [Country.SWE, "Rootsi"], [Country.ROM, "Rumeenia"],
    [Country.RWA, "Rwanda"], [Country.SLB, "Saalomoni Saared"], [Country.SHN, "Saint Helena"], [Country.KNA, "Saint Kitts ja Nevis"], [Country.LCA, "Saint Lucia"],
    [Country.SPM, "Saint Pierre ja Miquelon"], [Country.VCT, "Saint Vincent ja Grenadiinid"], [Country.DEU, "Saksamaa"], [Country.SEN, "Senegal"], [Country.SLE, "Sierra Leone"],
    [Country.SGS, "Sügis-Korea"], [Country.SYC, "Seychellid"], [Country.SLE, "Sierra Leone"], [Country.SGN, "Singapur"], [Country.SJM, "Svalbard ja Jan Mayen"],
    [Country.SVN, "Sloveenia"], [Country.SLB, "Saalomoni saared"], [Country.SOM, "Somalia"], [Country.ZMB, "Zambia"], [Country.ZWE, "Zimbabwe"],
  ]);


  getCountryLabel(country: Country | string): string {
    return this.countries.get(country as Country) ?? '';
  }

  getAllCountriesLabels(): {value: Country, label: string}[] {
    return [...this.countries.keys()]
      .map( x => {
        return { value: x, label: this.getCountryLabel(x)}
      });
  }

  private languages: Map<Country, string> = new Map<Country, string>([
    [Country.EST, "Eesti"], [Country.LVA, "Läti"], [Country.LTU, "Leedu"], [Country.FIN, "Soome"], [Country.RUS, "Vene"], [Country.GBR, 'Inglise'],
  ]);

  getCountryLanguage(Country: Country): string {
    return this.languages.get(Country) ?? '';
  }
}
