import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GazetteerService } from "@core/services/gazetteer.service";
import { debounceTime, finalize, Subject } from "rxjs";

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrl: './address-search.component.scss'
})
export class AddressSearchComponent {
  @Input() id: string = '';
  @Input() label: string = '';
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  searchSubject = new Subject<{ originalEvent: any, filter: string }>();

  searchValue?: string = undefined;
  loading = false;
  results: any[] = [];
  selected: any = {};
  gazetteerObj: any[] = [];

  constructor(private gazetteer: GazetteerService) {
    this.searchSubject.pipe(debounceTime(1000))  // Delays between searches
      .subscribe(search => this.executeSearch(search));
  }

  executeSearch(search: { originalEvent: any, filter: string }) {
    if (search && search.filter.length >= 3 && !this.loading) {
      this.loading = true;
      this.gazetteer.get(search.filter.replace(/%20/g, '+'))
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (e: any) => {
            if(e.addresses) {
              this.results = e.addresses.map((one: any) => {
                return {
                  id: search.filter,
                  obj: one,
                  value: [one.pikkaadress, one.kort_nr].join('-')
                }
              });
            }
            this.gazetteerObj = e.addresses;  // Full obj for when option is selected
          }
        });
    }
  }

  attemptSearch(search: { originalEvent: any, filter: string }): void {
    if (!search.filter || search.filter.length < 3 && this.results.length > 0) {
      this.clearResults();  // Clear results if the input is empty
    } else {
      this.searchSubject.next(search);
    }
  }

  clearResults(): void {
    this.results = [];
    this.gazetteerObj = [];
  }

  addressSelected(event: {originalEvent: any, value: any}): void {

    if(!event?.value.obj) {return;}

    const selectedAddress = this.results.find((item: any) => item.obj.adr_id === event.value.obj.adr_id);  // Find selected address string
    if (selectedAddress) {
      this.onSelect.emit(event.value.obj);  // Emit full object
    }
  }

}
