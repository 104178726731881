import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IEhakCounty } from "../interfaces/ehak-county.interface";
import { IEhakCity } from "../interfaces/ehak-city.interface";
import { IEhakSettlement } from "../interfaces/ehak-settlement.interface";

@Injectable({
  providedIn: 'root'
})
export class EhakService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/ehak`;
  }

  public getCounties(): Observable<IEhakCounty[]> {
    return this.http.get<IEhakCounty[]>(`${this.BACKEND_URL}/maakonds`);
  }

  public getCities(params: { mnimi: string }): Observable<IEhakCity[]> {
    return this.http.get<IEhakCity[]>(`${this.BACKEND_URL}/omavalitsused`, { params });
  }

  public getSettlements(params: { mnimi: string, onimi: string }): Observable<IEhakSettlement[]> {
    return this.http.get<IEhakSettlement[]>(`${this.BACKEND_URL}/asustusyksused`, { params });
  }
}
