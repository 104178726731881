export enum FinSourceId {
  PayerHealthInsuranceFund = 753,
  PayerPerson = 754,
  AnotherJuridicalPerson = 755,
}

export enum FinSourceName {
  PayerHealthInsuranceFund = 'Payer_HealthInsuranceFund',
  PayerPerson = 'Payer_Person'
}
