import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { firstValueFrom, Observable } from "rxjs";
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { IInvoice } from "@core/interfaces/invoice.interface";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/invoices`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/invoices`;
  }

  public createInvoice(orderId: number): Observable<{invoiceNumbers: string[]}> {
    return this.http.post<{invoiceNumbers: string[]}>(`${this.BACKEND_URL}/create/${orderId}`, {});
  }

  public createPresentInvoice(orderServiceId: number): Observable<{ invoiceNumber: string }> {
    return this.http.post<{ invoiceNumber: string }>(`${this.BACKEND_URL}/create/${orderServiceId}/orderService`, {});
  }

  public createOrderServicesInvoice(query: { orderServiceIds: number[] }): Observable<{ invoiceNumbers: string[] }> {
    return this.http.post<{ invoiceNumbers: string[] }>(`${this.BACKEND_URL}/create/orderServices`, query);
  }

  public get(invoiceNumber: string): Observable<{ link: string }> {
    return this.http.get<{ link: string }>(`${this.BACKEND_URL}/${invoiceNumber}/link`);
  }

  public getInvoiceData(orderServiceId: number): Observable<IInvoice> {
    return this.http.get<IInvoice>(`${this.BACKEND_URL}/${orderServiceId}/data`);
  }

  public getInvoiceLink(invoiceNumber: string): Observable<{ link: string }> {
    return this.http.get<{ link: string }>(`${this.BACKEND_URL}/${invoiceNumber}/link`);
  }

  // public updateInvoiceData(orderServiceId: number): Observable<IInvoice> {
  //   return this.http.get<IInvoice>(`${this.BACKEND_URL}/${orderServiceId}/update`);
  // }

  public getClientInvoices(patientId: number, params: HttpParams): Observable<IOrderServicePayment[]> {
    return this.http.get<IOrderServicePayment[]>(`${this.BACKEND_URL}/list/${patientId}`, {params});
  }

  public reCreatePrepaidInvoice(orderServiceId: number): Observable<string> {
    return this.http.post<string>(`${this.BACKEND_URL}/create/${orderServiceId}/prepayment`, {});
  }

  public async checkInvoices(unpaidServices: IOrderServicePayment[]): Promise<boolean> {
    const allCompletedPromises = unpaidServices.map((service) =>
      firstValueFrom(
        this.getInvoiceData(service.orderServiceId)
      ).then((response) => {
        service.invoice = response;
        return response.invoiceStatus === InvoiceStatus.COMPLETED;
      })
    );

    return Promise.all(allCompletedPromises).then((statuses) =>
      statuses.every((status) => status)
    );
  }
}
