export enum Country {
  AFG = "AFG", ALB = "ALB", DZA = "DZA", AND = "AND", AGO = "AGO", ARG = "ARG", ARM = "ARM", AUS = "AUS", AUT = "AUT", AZE = "AZE",
  BHR = "BHR", BGD = "BGD", BLR = "BLR", BEL = "BEL", BLZ = "BLZ", BEN = "BEN", BTN = "BTN", BOL = "BOL", BIH = "BIH", BWA = "BWA",
  BRA = "BRA", BRN = "BRN", BGR = "BGR", BFA = "BFA", BDI = "BDI", KHM = "KHM", CMR = "CMR", CAN = "CAN", CPV = "CPV", CAF = "CAF",
  TCD = "TCD", CHL = "CHL", CHN = "CHN", COL = "COL", COM = "COM", COG = "COG", COD = "COD", CRI = "CRI", HRV = "HRV", CUB = "CUB",
  CYP = "CYP", CZE = "CZE", DNK = "DNK", DJI = "DJI", DOM = "DOM", ECU = "ECU", EGY = "EGY", SLV = "SLV", GNQ = "GNQ", ERI = "ERI",
  EST = "EST", ETH = "ETH", FJI = "FJI", FIN = "FIN", FRA = "FRA", GUF = "GUF", PYF = "PYF", GAB = "GAB", GMB = "GMB", GEO = "GEO",
  DEU = "DEU", GHA = "GHA", GIB = "GIB", GRC = "GRC", GRL = "GRL", GTM = "GTM", GIN = "GIN", GNB = "GNB", GUY = "GUY", HTI = "HTI",
  HND = "HND", HKG = "HKG", HUN = "HUN", ISL = "ISL", IND = "IND", IDN = "IDN", IRN = "IRN", IRQ = "IRQ", IRL = "IRL", ISR = "ISR",
  ITA = "ITA", CIV = "CIV", JPN = "JPN", JOR = "JOR", KAZ = "KAZ", KEN = "KEN", KIR = "KIR", PRK = "PRK", KOR = "KOR", KWT = "KWT",
  KGZ = "KGZ", LAO = "LAO", LVA = "LVA", LBN = "LBN", LSO = "LSO", LBR = "LBR", LBY = "LBY", LIE = "LIE", LTU = "LTU", LUX = "LUX",
  MAC = "MAC", MKD = "MKD", MDG = "MDG", MWI = "MWI", MYS = "MYS", MDV = "MDV", MLI = "MLI", MLT = "MLT", MHL = "MHL", MTQ = "MTQ",
  MRT = "MRT", MUS = "MUS", MEX = "MEX", FSM = "FSM", MDA = "MDA", MCO = "MCO", MNG = "MNG", MNE = "MNE", MAR = "MAR", MOZ = "MOZ",
  MMR = "MMR", NAM = "NAM", NRU = "NRU", NPL = "NPL", NLD = "NLD", NCL = "NCL", NZL = "NZL", NIC = "NIC", NER = "NER", NGA = "NGA",
  NIU = "NIU", NFK = "NFK", NOR = "NOR", OMN = "OMN", PAK = "PAK", PLW = "PLW", PSE = "PSE", PAN = "PAN", PNG = "PNG", PRY = "PRY",
  PER = "PER", PHL = "PHL", PCN = "PCN", POL = "POL", PRT = "PRT", QAT = "QAT", REU = "REU", ROU = "ROU", RUS = "RUS", RWA = "RWA",
  SHN = "SHN", KNA = "KNA", SPM = "SPM", WSM = "WSM", SMR = "SMR", STP = "STP", SAU = "SAU", SEN = "SEN", SRB = "SRB", SYC = "SYC",
  SLE = "SLE", SGP = "SGP", SVK = "SVK", SVN = "SVN", SLB = "SLB", SOM = "SOM", ZAF = "ZAF", SSD = "SSD", ESP = "ESP", LKA = "LKA",
  SDN = "SDN", SUR = "SUR", SWZ = "SWZ", SWE = "SWE", CHE = "CHE", SYR = "SYR", TWN = "TWN", TJK = "TJK", TZA = "TZA", THA = "THA",
  TLS = "TLS", TGO = "TGO", TKL = "TKL", TON = "TON", TUN = "TUN", TUR = "TUR", TKM = "TKM", TUV = "TUV", UGA = "UGA", UKR = "UKR",
  ARE = "ARE", GBR = "GBR", USA = "USA", URY = "URY", UZB = "UZB", VUT = "VUT", VAT = "VAT", VEN = "VEN", VNM = "VNM", WLF = "WLF",
  YEM = "YEM", ZMB = "ZMB", ZWE = "ZWE", ATG = "ATG", ABW = "ABW", BHS = "BHS", ASM = "ASM", AIA = "AIA", ATA = "ATA", SGS = "SGS",
  BRB = "BRB", BMU = "BMU", VGB = "VGB", BVT = "BVT", IOT = "IOT", COK = "COK", DMA = "DMA", FLK = "FLK", FRO = "FRO", GRD = "GRD",
  GLP = "GLP", GUM = "GUM", HMD = "HMD", ANT = "ANT", JAM = "JAM", CXR = "CXR", CYM = "CYM", CCK = "CCK", ESH = "ESH", MYT = "MYT",
  MSR = "MSR", XYY = "XYY", XXX = "XXX", ATF = "ATF", PRI = "PRI", MNP = "MNP", ROM = "ROM", VCT = "VCT", LCA = "LCA", SGN = "SGN",
  SJM = "SJM",
}
