  <medis-calendar class="calendar" [calendarOptions]="calendarOptions"
                  (calendarObject)="calendar = $event; fetchCalendarTimes()">
    <ng-template #eventContent let-arg>
      <i class="ml-1">{{ arg.event.title }}</i>
    </ng-template>
  </medis-calendar>

<p-overlayPanel styleClass="calendar-overlaypanel" #overlay [dismissable]="true">
  @if (calendarPlanTime) {
    <div class="calendar-overlay">
      <div class="calendar-overlay__header">
        <div class="calendar-overlay__title">
          {{ calendarPlanTime.person?.fullName | uppercase }}
          {{ calendarPlanTime.room?.roomDepartment | uppercase }}
          {{ calendarPlanTime.room?.codeName }}
        </div>
      </div>
      <div class="calendar-overlay__content">
        <div class="overlay-plan-time">
          @if (calendarPlanTime.timeFrom && calendarPlanTime.timeUpto) {
            <div class="overlay-plan-time__item">
              <span class="overlay-plan-time__label">Periood</span>
              <span class="overlay-plan-time__value">
                {{ calendarPlanTime.timeFrom | timeFormat }} - {{ calendarPlanTime.timeUpto | timeFormat }}
              </span>
            </div>
          }
          @if (calendarPlanTime.personServices.length && calendarPlanTime.personServices; as services) {
            <div class="overlay-plan-time__item">
              <span class="overlay-plan-time__label">Teenused</span>
              <span class="overlay-plan-time__value">
                <div class="flex flex-column gap-2">
                  @for (service of services; track $index) {
                    <div>{{ service.name }}</div>
                  }
                </div>
              </span>
            </div>
          }
          @if (finSources?.length && finSources; as sources) {
            <div class="overlay-plan-time__item">
              <span class="overlay-plan-time__label">Maksja</span>
              <span class="overlay-plan-time__value">
                <span class="flex gap-2">
                  @for (finSource of sources; track $index) {
                    <medis-financial-source-label
                      [financialSourceId]="finSource.financialSourceName"/>
                  }
                </span>
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  }
</p-overlayPanel>
