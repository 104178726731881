import { Component, Input, OnInit } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Gender } from "@core/enums/gender.enum";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { AddContactModalComponent } from "@shared/modals/contact/add-contact.modal/add-contact.modal.component";
import {
  ContactDetailsModalComponent
} from "@shared/modals/contact/contact-details.modal/contact-details.modal.component";
import { IEmergencyContact } from "@core/interfaces/emergency-contact.interface";
import { EuropeanUnionInsuranceDocumentType } from "@core/enums/european-union-insurance-document-type";
import { ListService } from "@core/services/list.service";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { GeneralList } from "@core/enums/general-list.enum";
import { CountryService } from "@core/services/country.service";

@Component({
  selector: 'app-patient-edit-form',
  templateUrl: './patient-additional-data.component.html',
  styleUrl: './patient-additional-data.component.scss'
})
export class PatientAdditionalDataComponent implements OnInit {
  @Input() patient: IPatient = {} as IPatient;
  @Input() formGroup: FormGroup = new FormGroup({});
  documentTypes = Object.entries(EuropeanUnionInsuranceDocumentType).map(([key, value]) => ({
    label: value,
    value: key
  }));

  countries = this.countryService.getAllCountriesLabels();

  genderOptions = [
    {
      label: 'Mees',
      value: Gender.MALE,
    },
    {
      label: 'Naine',
      value: Gender.FEMALE,
    },
  ];

  contactTypes: IGeneralList[] = [];

  constructor(private formBuilder: FormBuilder,
              private modalService: ModalService,
              private countryService: CountryService,
              private listService: ListService) {
  }

  ngOnInit(): void {
    this.listService.getByType(GeneralList.CONTACT_TYPE).subscribe(types => {
      this.contactTypes = types;
    });
  }

  get patientEmergencyContacts(): FormArray {
    return this.formGroup?.get('emergencyContacts') as FormArray;
  }

  get europeanUnionInsuranceDocument(): FormGroup {
    return this.formGroup?.get('europeanUnionInsuranceDocument') as FormGroup;
  }

  get bankAccount(): FormControl {
    return this.formGroup?.get('bankAccount') as FormControl;
  }

  getEmergencyContact(idx: number): FormGroup {
    return this.patientEmergencyContacts?.at(idx) as FormGroup;
  }

  addEmergencyContact(): void {
    let ref = this.modalService.openModal({
      header: 'Lisage uus kontaktisik',
      size: ModalSize.SMALL,
      component: AddContactModalComponent,
      data: {},
    });

    ref.onClose.subscribe((result: any) => {
      if (result?.contact) {
        let contact = result.contact;
        this.patientEmergencyContacts.push(this.createEmergencyContact(contact, result.contactType ?? null));
      }
    })
  }

  removeEmergencyContact(idx: number): void {
    this.patientEmergencyContacts.removeAt(idx);
  }

  editEmergencyContact(contactObject: any): void {
    let ref = this.modalService.openModal({
      header: 'Uue isiku lisamine',
      size: ModalSize.SMALL,
      component: ContactDetailsModalComponent,
      data: {
        contact: contactObject.getRawValue() as IEmergencyContact
      },
    });

    ref.onClose.subscribe((result: any) => {
      if (result?.contact) {
      contactObject.patchValue(result.contact)
      }
    });
  }

  createEmergencyContact(contactObject: any, contactType?: number | null): FormGroup {
    return this.formBuilder.group({
      emergencyContactId: [contactObject.emergencyContactId ?? 0],
      patientId: [this.patient?.patientId ?? 0],
      patientContactId: [contactObject.patientContactId ?? null],
      email: [contactObject?.contact?.email ?? null],
      phone: [contactObject?.phone ?? null],
      idCode: [contactObject.idCode ?? null],
      firstname: [contactObject.firstname ?? null],
      lastname: [contactObject.lastname ?? null],
      type: [contactType ?? null],
      typeDescription: [contactObject.typeDescription ?? null],
    })
  }
}
