import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";

@Pipe({
  name: 'decisionName',
  standalone: true
})
export class DecisionNamePipe implements PipeTransform {
  private decisionMap: IMapping = {
    1: 'e-konsultatsioon',
    2: 'vastuvõtt 7p jooksul',
    3: 'vastuvõtt 8-42p jooksul',
    4: 'vastuvõtt rohkem kui 42p pärast',
    5: 'mittesobiv saatekiri',
    8: 'Patsient suunatakse haiglaravile',
    9: 'Patsient suunatakse päevaravile/päevakirurgiasse'
  }

  transform(decisionCode: string): string {
    return this.decisionMap[+decisionCode];
  }

}
