import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IReason } from "@core/interfaces/reason.interface";

@Injectable({
  providedIn: 'root'
})
export class OrderServiceTimeService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/orderServiceTimes`;
  }

  public cancelOrderServiceTime(orderServiceTimeId: number, reason: IReason): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/cancel/${orderServiceTimeId}`, reason);
  }

  public setPresent(orderServiceTimeId: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/present/${orderServiceTimeId}`, {});
  }

  public setFinished(orderServiceTimeId: number, reasonData: IReason): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/finished/${orderServiceTimeId}`, reasonData);
  }

  public cancelPresent(orderServiceTimeId: number, reasonData: IReason): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/cancelpresent/${orderServiceTimeId}`, reasonData);
  }
}
