<div class="flex flex-column gap-4 pt-4">
  <form class="flex flex-column gap-4" [formGroup]="addressForm" (ngSubmit)="submit()">
    @for (item of formItems; track item) {
      <div class="form-section__row flex gap-4">
        <div class="form-section__col w-12">
          <p-floatLabel>
            <input
              class="w-full"
              pInputText
              [formControlName]="item.formControlName"
              [id]="item.formControlName"
              [ngClass]="{'ng-invalid ng-dirty': formSubmitted && addressForm.get(item.formControlName)?.errors}"/>
            <label [for]="item.formControlName">{{ item.label }}</label>
          </p-floatLabel>
          @if (formSubmitted && addressForm.get(item.formControlName)?.errors && item.required) {
            <small class="p-error my-2"> {{ item.label }} on kohustuslik </small>
          }
        </div>
      </div>
    }

    <div class="flex row justify-content-end py-4 gap-2">
      <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
      <p-button type="submit" label="Salvesta"/>
    </div>
  </form>
</div>
