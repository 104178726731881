import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IReferral } from "@core/interfaces/referral.interface";
import { PatientService } from "@core/services/patient.service";
import { IPatient } from "@core/interfaces/patient.interface";
import { finalize } from "rxjs";
import { IService } from "@core/interfaces/service.interface";
import { OrderReferralETervisStatusEnum } from "@core/enums/order-referral-e-tervis-status.enum";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { HtmlModalComponent } from "@shared/components/html-modal/html-modal.component";

interface data {
  patientId?: number;
  service?: IService | null;
  patient?: IPatient | null;
  referralNr?: string;
  hideBook?: boolean
  showComentButton?: boolean
  type?: ReferralTypesToLoad | string;
}

enum ReferralTypesToLoad {
  UNUSED = 'unused',
  ALL = 'all'
}

@Component({
  selector: 'medis-unused-referrals',
  templateUrl: './unused-reservations.component.html',
  styleUrl: './unused-reservations.component.scss'
})
export class UnusedReservationsComponent {
  referrals: IReferral[] = [];

  @Output() referralOutput = new EventEmitter<IReferral>();
  @Output() referralsOutput = new EventEmitter<IReferral[]>();

  service?: IService | null;
  patientId?: number;
  referralNr?: string;
  type?: ReferralTypesToLoad;
  hideBook?: boolean;
  loading: boolean = true;
  selectedReferral?: IReferral;
  referralsToShow: IReferral[] = [];
  showComentButton: any;
  private patient?: IPatient;

  @Input() set data(value: data) {
    this.service = value.service;
    if (value.patient) {
      this.patient = value.patient;
      this.patientId = value.patient.patientId;
    } else {
      this.patientId = value.patientId;
    }
    this.referralNr = value.referralNr;
    this.type = value.type as ReferralTypesToLoad;
    this.hideBook = value.hideBook ?? false;
    this.showComentButton = value.showComentButton ?? false;
    this.load();
  }

  constructor(
    private patientService: PatientService,
    private modalService: ModalService,
  ) {
  }


  loadBooked() {
    this.referrals = [];
    this.loading = true;
    if (this.patientId) {
      this.patientService.getTehikPatientReferrals(this.patientId)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (data: IReferral[]) => {
            this.referrals = this.referralsToShow = data;
            this.loading = false;
          }
        });
    }
  }

  load() {
    this.referrals = [];
    this.loading = true;
    if (this.type == ReferralTypesToLoad.UNUSED) {
      if (this.patientId && this.service && this.referralNr) {
        this.patientService.getUnusedPatientServiceNumberReferrals(this.patientId, this.service.serviceId, this.referralNr)
          .pipe(finalize(() => this.loading = false))
          .subscribe({
            next: (data: IReferral) => {
              if (data) {
                this.referrals = this.referralsToShow = [data];
                this.referralOutput.emit(data);
              }
            },
          });
      } else if (this.patientId && this.service) {
        this.patientService.getUnusedPatientServiceReferrals(this.patientId, this.service.serviceId)
          .pipe(finalize(() => this.loading = false))
          .subscribe({
            next: (data: IReferral[]) => {

              if (data.length <= 1) {
                this.referrals = this.referralsToShow = data[0] ? [data[0]] : [];
                this.referralOutput.emit(data[0]);
              } else {
                this.referrals = this.referralsToShow = data;
              }
            },
          })
      } else if (this.patientId) {
        this.patientService.getUnusedPatientReferrals(this.patientId)
          .pipe(finalize(() => this.loading = false))
          .subscribe({
            next: (data: IReferral[]) => {
              this.referrals = this.referralsToShow = data;
              this.referralsOutput.emit(this.referrals);
            }
          })
      }
    } else if (this.type == ReferralTypesToLoad.ALL) {
      this.loadBooked();
    }

  }

  booked(referral: IReferral) {
    this.selectedReferral = referral;
    this.referralsToShow = [referral];
    this.referralOutput.emit(referral)
  }

  remove() {
    this.selectedReferral = undefined;
    this.referralsToShow = this.referrals;
    this.referralOutput.emit();
  }

  columnNumber() {
    let count = 11
    let result = [];

    for (let i = 1; i <= count; i++) {
      result.push(i);
    }

    return result;
  }

  protected readonly OrderReferralETervisStatusEnum = OrderReferralETervisStatusEnum;

  showInfo(referral: IReferral) {

    if (referral.referralId) {
      this.patientService.getReferralTehikHtml(referral.referralId).subscribe({
        next: (data: any) => {
          this.openHtmlModal(data.data);
        }
      })
    } else {
      this.patientService.getReferralTehikHtmlWithoutReferralId(referral.oid, referral.documentNr, this.patient?.patientId ?? 0).subscribe(
        {
        next: (data: any) => {
          this.openHtmlModal(data.data);
        }
      })
    }

  }

  private openHtmlModal(html: string) {
    this.modalService.openModal(
      {
        component: HtmlModalComponent,
        size: ModalSize.EXTRA_LARGE,
        header: 'Saatekiri',
        contentStyle: { 'padding-left' : 0 },
        data: {
          html: html
        }
      }
    )
  }
}
