import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { ToastService } from "@core/services/toast.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastService: ToastService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
      catchError((error: HttpErrorResponse) => {
        let message;
        if(error.status == 0){
          message = 'Serveri viga';
        } else {
          message = error.error.errors[0].message;
        }

        this.toastService.error(message);
        return of();
      }));
  }
}
