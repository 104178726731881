import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalService } from "@core/services/modal.service";
import {
  ContactDetailsModalComponent
} from "@shared/modals/contact/contact-details.modal/contact-details.modal.component";
import { ModalSize } from "@core/enums/modalSize";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { IPatient } from "@core/interfaces/patient.interface";
import { debounceTime, distinctUntilChanged, finalize, Subject } from "rxjs";
import { PatientService } from "@core/services/patient.service";
import { GeneralList } from "@core/enums/general-list.enum";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { FormValidationService } from "@core/services/form-validation.service";

@Component({
  templateUrl: './add-contact.modal.component.html',
  styleUrl: './add-contact.modal.component.scss'
})
export class AddContactModalComponent implements OnInit {

  formGroup!: FormGroup;
  dialogRef: DynamicDialogComponent | undefined;

  patient: IPatient | null = null;
  loading = false;
  private selectedType: IGeneralList = {} as IGeneralList;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private ref: DynamicDialogRef,
              private patientService: PatientService,
              private formValidationService: FormValidationService) {
  }

  ngOnInit(): void {
    this.dialogRef = this.dialogService.getInstance(this.ref)
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [null, Validators.required],
      contactPerson: [null, Validators.required],
    })
  }

  createNewContact(): void {
    let contactDetails = this.modalService.openModal({
      header: 'Uue kontaktisiku lisamine',
      size: ModalSize.SMALL,
      component: ContactDetailsModalComponent,
    })

    contactDetails.onClose.subscribe((result: any) => {
      if (result?.contact) {
        this.submit(result.contact);
      }
    })
  }

  closeModal(): void {
    this.ref?.close(false);
  }

  submit(contact?: any): void {
    const contactPatient = contact || this.patient;
    const contactType = contact?.type || this.selectedType;

    if (!contact && this.formGroup.invalid) {
      this.formValidationService.markControlAsDirty(this.formGroup);
      return;
    }

    this.ref?.close({
      submitted: true,
      contact: contactPatient,
      contactType: contactType
    });
  }

  patientSelected(patient: IPatient): void {
    if (patient) {
      this.patient = patient;
    }
  }

  setType(event: any) {
    this.selectedType = event;
  }

  get type(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  get contactPerson(): FormControl {
    return this.formGroup.get('contactPerson') as FormControl;
  }

  protected readonly GeneralList = GeneralList;
}
