import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { OrderReferralStatus } from "@core/enums/order-referral-status.enum";

@Pipe({
  name: 'orderReferralStatus',
  standalone: true
})
export class OrderReferralStatusPipe implements PipeTransform {
  private statusMap: IMapping = {
    [OrderReferralStatus.CONFIRMED]: 'Saatekiri seotud',
    [OrderReferralStatus.MISSING]: 'Saatekiri sidumata',
    [OrderReferralStatus.OPTIONAL]: 'Saatekiri pole vajalik',
    [OrderReferralStatus.CREATED]: 'Saatekiri salvestatud',
    [OrderReferralStatus.ASSIGNED]: 'Saatekiri edastatud',
    [OrderReferralStatus.RESOLVED]: 'Saatekiri lahendatud',
    [OrderReferralStatus.CANCELLED]: 'Saatekiri tühistatud',
    [OrderReferralStatus.EXPIRED]: 'Saatekiri aegunud',
    [OrderReferralStatus.NOT_ALLOWED]: 'Saatekiri keelatud',
  };

  transform(status: OrderReferralStatus): string {
    return this.statusMap[status];
  }
}
