<p-floatLabel>
  <p-dropdown [(ngModel)]="searchValue"
              (onChange)="addressSelected($event)"
              [options]="results"
              [showClear]="true"
              styleClass="w-full"
              [id]="id"
              [filter]="true"
              filterBy="id"
              optionLabel="value"
              (onClear)="clearResults()"
              (onFilter)="attemptSearch($event)"
              dropdownIcon="pi pi-search"
  />
  <label [for]="id">{{ label }}</label>
</p-floatLabel>
