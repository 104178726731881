import { Pipe, PipeTransform } from '@angular/core';
import { DataKey } from "@core/enums/data-key.enum";
import { stockCodesNamePipe } from "./stock-codes.pipe";

@Pipe({
  name: 'arrayToString',
  standalone: true
})
export class arrayToString implements PipeTransform {

  constructor(private stockCodesNamePipe: stockCodesNamePipe) {}

  transform(data: any[] = [], dataKey: string | DataKey): string {
    if (data && data.length > 0) {
      switch (dataKey) {
        case DataKey.STOCK_CODES:
          return this.stockCodesNamePipe.transform(data);
        case DataKey.CODES:
          return data.map(item => item.code).join(', ');
        default:
          return data.join(', ')
      }
    }
    return '';

  }

}
