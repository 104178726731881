import { Component, Input, OnInit } from '@angular/core';
// import { FinancialSource } from "@core/enums/financial-sources.enum";
import { NgClass } from "@angular/common";
import { BadgeModule } from "primeng/badge";
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { IMapping } from "@core/interfaces/mapping.interface";
import { BadgeType } from "@core/enums/badge-type.enum";
import { FinSourceId } from "@core/enums/financial-source.enum";
import { FinancialSource } from "@core/enums/financial-sources.enum";

@Component({
  selector: 'medis-financial-source-label',
  standalone: true,
  imports: [
    NgClass,
    BadgeModule,
    TagModule,
    TooltipModule,
  ],
  template: `
    <p-tag
      rounded="true"
      [value]="source.short"
      [styleClass]="source.style + (small ? ' tag--small' : '')"
      [pTooltip]="source.full"
      tooltipPosition="top"
    ></p-tag>
  `
})

export class FinancialSourceLabelComponent implements OnInit {
  @Input() set financialSourceId(val: FinancialSource | FinSourceId | null | undefined | string){
    if(val){
      this.source = this.labels[val]
        || {short: val, full: val, style: this.sourceStyleMap[BadgeType.INFO]};
    } else {
      this.source = {short: '', full: '', style: this.sourceStyleMap[BadgeType.INFO]}
    }
  }
  @Input() short: boolean = true;
  @Input() small: boolean = false;
  source: any;

  private readonly sourceStyleMap: IMapping = {
    [BadgeType.INFO]: 'tag tag--blue',
    [BadgeType.WARNING]: 'tag tag--orange',
    [BadgeType.YELLOW]: 'tag tag--yellow',
    [BadgeType.PURPLE]: 'tag tag--purple',
    [BadgeType.AZURE]: 'tag tag--azure',
  };

  public readonly labels: { [key:string]: {}} = {
    [FinSourceId.PayerPerson]: { short: 'PT', full: 'Patsient', style: this.sourceStyleMap[BadgeType.PURPLE] },
    [FinancialSource.PERSON]: { short: 'PT', full: 'Patsient', style: this.sourceStyleMap[BadgeType.PURPLE] },

    [FinSourceId.PayerHealthInsuranceFund]: { short: 'TK', full: 'Tervisekassa', style: this.sourceStyleMap[BadgeType.YELLOW] },
    [FinancialSource.INSURANCE]: { short: 'TK', full: 'Tervisekassa', style: this.sourceStyleMap[BadgeType.YELLOW] },

    [FinSourceId.AnotherJuridicalPerson]: { short: 'TJI', full: 'Teine juriidiline isik', style: this.sourceStyleMap[BadgeType.AZURE] },
    [FinancialSource.ANOTHER_JURIDICAL_PERSON]: { short: 'TJI', full: 'Teine juriidiline isik', style: this.sourceStyleMap[BadgeType.AZURE] }
  };

  ngOnInit() {

  }
}
