<div class="flex flex-column w-12 gap-4">
  <div class="bg-white modal-details__section border-round-md">
    <div class="flex flex-row">
      <h3 class="text-color-secondary text-xl font-bold">Lisainfo</h3>
    </div>
    <div class="flex flex-column">
      <h4 class="mb-1 mt-1">- Teid suunatakse automaatselt Directosse maksma (Kassa-arve avaneb uuel vahelehel)</h4>
      <h4 class="mb-1 mt-1">- Teosta makse Directos ning seejärel prindi patsiendile infoleht</h4>
      <h4 class="mb-1 mt-1">- Kui soovid arvet muuta, vali 'Mine tagasi'</h4>
      <h4 class="mb-1 mt-1">- Kui Kassa-arve ei avane või Directo makse registreerimine ebaõnnestub, vali "Mine uuesti maksma"</h4>
    </div>

    @if (products.length > 0) {
      <medis-order-selected-products [products]="products"/>
    }

    @if (unpaidServices.length > 0) {
      <medis-company-selected-times [unpaidServices]="unpaidServices"
                                    [patient]="patient"
                                    (onServiceCancel)="updateList($event)"
      />
    }

  </div>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button label="Mine uuesti maksma "
            severity="contrast"
            (click)="payAgain()"
  />
  <p-button label="Sulge"
            [outlined]="true"
            severity="secondary"
            (click)="closeModal()"
  />
</div>
