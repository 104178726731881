import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";
import { AuthService } from "./auth.service";

interface sstFormOpenData {
    patientId?: number;
    patientCode?: string;
    providerId?: number;
    providerCode?: string;
    departmentId?: number|null;
    departmentName?: string|null;
}

@Injectable({
  providedIn: 'root'
})
export class SstService {

  constructor(private authService: AuthService) {
  }

  open(data: sstFormOpenData): void {
    let f = document.createElement("form");
    f.setAttribute('id', 'sstopenform');
    f.setAttribute('name', 'sstopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.sstUrl);

    this.addInput("patientId", data.patientId, f);
    this.addInput("patientPersonalCode", data.patientCode, f);
    this.addInput("providerId", data.providerId, f);
    this.addInput("providerCode", data.providerCode, f);
    this.addInput("departmentId", data.departmentId, f);
    this.addInput("departmentName", data.departmentName, f);
    this.addInput("token", this.authService.getAccessToken(), f);

    document.body.appendChild(f);

    f.submit();
  }

  private addInput(name: string | number, value: any, form: HTMLFormElement) {
    if (value) {
      let i = document.createElement("input");
      i.setAttribute("type", "hidden");
      i.setAttribute('name', String(name).toString());
      i.setAttribute("value", String(value).toString());
      form.appendChild(i);
    }
  }
}
