import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IEmergencyContact } from "@core/interfaces/emergency-contact.interface";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { FormValidationService } from "@core/services/form-validation.service";

@Component({
  selector: 'app-contact-details.modal',
  templateUrl: './contact-details.modal.component.html',
  styleUrl: './contact-details.modal.component.scss'
})
export class ContactDetailsModalComponent implements OnInit {
  formGroup!: FormGroup;
  contact: IEmergencyContact = {} as IEmergencyContact;
  selectedType: IGeneralList = {} as IGeneralList;

  dialogRef?: DynamicDialogComponent;

  constructor(private dialogConfig: DynamicDialogConfig,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private ref: DynamicDialogRef,
              private formValidationService: FormValidationService) {
  }

  ngOnInit() {
    if (this.dialogConfig.data?.contact) {
      this.contact = this.dialogConfig.data.contact;
      this.dialogConfig.header = 'Kontakisiku andmete muutumine'
      this.dialogRef = this.dialogService.getInstance(this.ref);
      this.selectedType = this.contact.type;
    }
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      emergencyContactId: [this.contact.emergencyContactId ?? 0],
      patientId: [this.contact.patientId ?? 0],
      type: [this.contact.type?.generalListId ?? null, Validators.required],
      idCode: [this.contact.idCode ?? null, Validators.required],
      firstname: [this.contact.firstname ?? null, Validators.required],
      lastname: [this.contact.lastname ?? null, Validators.required],
      phone: [this.contact.phone ?? null, Validators.required],
      email: [this.contact.email ?? null, Validators.required],
    });
  }

  closeModal(): void {
    this.ref?.close(false);
  }

  submit(): void {
    if (this.formGroup.invalid) {
      this.formValidationService.markControlAsDirty(this.formGroup);
      return;
    }
    let contact = this.formGroup.getRawValue();
    contact.type = this.selectedType;
    this.ref?.close({ contact: contact })
  }

  setPatientType(value: any): void {
    this.selectedType = value;
  }

  get type(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  get idCode(): FormControl {
    return this.formGroup.get('idCode') as FormControl;
  }

  get firstname(): FormControl {
    return this.formGroup.get('firstname') as FormControl;
  }

  get lastname(): FormControl {
    return this.formGroup.get('lastname') as FormControl;
  }

  get phone(): FormControl {
    return this.formGroup.get('phone') as FormControl;
  }

  get email(): FormControl {
    return this.formGroup.get('email') as FormControl;
  }
}
