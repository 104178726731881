import { Component, OnInit } from '@angular/core';
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderService } from "@core/services/order.service";
import { IOrderServiceComment } from "@core/interfaces/order-service-comment.interface";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DateTimeDisplayPipe } from "../../pipes/date-time-display.pipe";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { Button } from "primeng/button";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FloatLabelModule } from "primeng/floatlabel";
import { TableModule } from "primeng/table";
import { ModalService } from "@core/services/modal.service";
import { ToastService } from "@core/services/toast.service";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { ConfirmActionDirective } from "@shared/directives/confirm-action/confirm-action.directive";

@Component({
  selector: 'medis-comment-order-actions-modal',
  standalone: true,
  templateUrl: './comment-order-actions.modal.component.html',
  imports: [
    ReactiveFormsModule,
    DateTimeDisplayPipe,
    Button,
    InputTextareaModule,
    FloatLabelModule,
    TableModule,
    ConfirmActionDirective,
  ],
  styleUrl: './comment-order-actions.modal.component.less'
})
export class CommentOrderActionsModalComponent implements OnInit {
  private readonly CONFIRMATION_MODAL_TITLE: string = 'Kommentaari eemaldamine';
  private readonly CONFIRMATION_MODAL_BODY_TEXT: string = 'Kas oled kindel, et soovid kustutada?';

  commentForm!: FormGroup;
  OrderServiceObj!: IOrderServiceTime | IOrderService;
  commentLoading = false;
  comments: IOrderServiceComment[] = [];
  currentUser!: ICurrentUser | null;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private orderService: OrderService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    if (this.instance && this.instance.data) {
      this.createForm();
      this.OrderServiceObj = this.instance.data['orderServiceObj'];
      if (this.OrderServiceObj.orderServiceId) {
        this.getComments();
      }
      if (this.authService.hasAccessToken()) {
        this.currentUser = this.authService.currentUserSubject.value;
      }
    }
  }

  private createForm() {
    this.commentForm = this.formBuilder.group({
      comment: [null],
    })
  }

  closeModal(): void {
    this.instance?.close();
  }

  getComments = () => {
    this.commentLoading = true;
    this.orderService
      .getOrderServiceComments(this.OrderServiceObj.orderServiceId)
      .subscribe({
        next: (comments) => {
          this.comments = comments.map(x => ({ ...x, }));
          this.commentLoading = false;
        },
        error: (err) => {
          this.toastService.error(err.message);
        }
      })
  };

  saveComment = () => {
    const commentText = (this.comment.value || '').trim();
    if (!commentText) {
      return;
    }

    this.commentLoading = true;

    const comment = {
      orderServiceId: this.OrderServiceObj.orderServiceId,
      comment: commentText,
      userName: this.currentUser ? this.currentUser.firstName + ' ' + this.currentUser.lastName : '',
      userIdentifier: this.currentUser ? this.currentUser.email : ''
    }

    this.orderService
      .saveOrderServiceComment(comment)
      .subscribe({
        next: () => {
          this.getComments();
          this.commentLoading = false;
          this.comment.setValue('');
        },
        error: (err) => {
          this.toastService.error(err.message);
        }
      })
  };

  deleteComment = (event: boolean, orderServiceCommentId: number) => {
    if (!event) return;

    this.commentLoading = true;

    const params = new HttpParams()
      .set('orderServiceCommentId', orderServiceCommentId);

    this.orderService.deleteOrderServiceComment(params)
      .subscribe({
        next: () => {
          this.getComments();
          this.commentLoading = false;
        },
        error: (err) => {
          this.toastService.error(err.message);
        }
      });
  };

  get comment(): FormControl {
    return this.commentForm.get('comment') as FormControl;
  }
}
