import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IPatient } from "@core/interfaces/patient.interface";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { OrderServiceService } from "@core/services/order-service.service";
import { interval, Subscription, switchMap, takeWhile } from "rxjs";
import { InvoiceService } from "@core/services/invoice.service";

@Component({
  selector: "medis-order-summary",
  templateUrl: "./order-summary.modal.html",
  styleUrl: "./order-summary.modal.scss",
})

export class OrderSummaryModal implements OnInit, OnDestroy {
  summaryForm!: FormGroup;
  patient!: IPatient;
  unpaidServices: IOrderServicePayment[] = [];
  groupedCompaniesData: { companyId: number; companyName: string; services: IOrderServicePayment[] }[] = [];
  sendLinkReady: boolean = false;
  externalBooking: boolean = false;

  serviceInvoiceSubscription?: Subscription;

  groupServicesByCompany(
    unpaidServices: IOrderServicePayment[]
  ): { companyId: number; companyName: string; services: IOrderServicePayment[] }[] {
    const map = new Map<number, { companyName: string; services: IOrderServicePayment[] }>();

    for (const service of unpaidServices) {
      const key = service.companyId;
      if (!map.has(key)) {
        map.set(key, { companyName: service.companyName, services: [] });
      }
      map.get(key)!.services.push(service);
    }

    return Array.from(map.entries()).map(([companyId, group]) => ({
      companyId,
      companyName: group.companyName,
      services: group.services,
    }));
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private orderServiceService: OrderServiceService,
    private invoiceService: InvoiceService,
  ) {
    if (this.config.data) {
      this.patient = this.config.data.patient;
      this.groupedCompaniesData = this.groupServicesByCompany(this.config.data?.unpaidServices);
      this.unpaidServices = this.config.data.unpaidServices;
      this.externalBooking = this.config.data.externalBooking ?? false;
    }
  }

  ngOnInit(): void {
    this.createSummaryForm();
    this.pollServiceInvoices();
  }

  private createSummaryForm(): void {
    this.summaryForm = this.formBuilder.group({
      anotherPayer: [false],
      payerEmail: [null],
      payerPhone: [null],
    })
  }

  pollServiceInvoices() {
    this.serviceInvoiceSubscription = interval(3000)
      .pipe(
        switchMap(() => this.invoiceService.checkInvoices(this.unpaidServices)),
        takeWhile((completed) => !completed)
      )
      .subscribe();
  }

  updateUnpaidServices() {
    this.orderServiceService
      .getUnpaidServices(this.patient.patientId)
      .subscribe({
        next: (services) => {
          if (services && services.length > 0) {
            this.unpaidServices = services;
            this.updateViewData();
            this.groupedCompaniesData = this.groupServicesByCompany(services);
          } else {
            this.ref.close(true);
          }
        }
      })
  }

  updateViewData() {
    this.unpaidServices.forEach(s => {
      s.selected = true;
    });
  }

  updateSummaryForm(summaryForm: FormGroup): void {
    this.summaryForm = summaryForm;
    this.summaryForm.updateValueAndValidity();
  }

  closeModal(){
    this.ref.close(false);
  }

  ngOnDestroy() {
    this.serviceInvoiceSubscription?.unsubscribe();
  }

  get anotherPayer(): FormControl {
    return this.summaryForm.get('anotherPayer') as FormControl;
  }
}
