import { Component, Input } from '@angular/core';
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'emergency-gp-team-label',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TooltipModule,
  ],
  templateUrl: 'emergency-gp-team-label.component.html',
})
export class EmergencyGpTeamLabelComponent {
  @Input() isEmergencyGpTeam: boolean = false;
}
