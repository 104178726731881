import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceDuration',
  standalone: true,
})
export class ServiceDurationPipe implements PipeTransform {
  transform(duration:  string | number | null | undefined): string {
    if (!duration) return "0 minutit";
    return duration.toString() + " minutit";
  }
}
