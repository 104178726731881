import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { ToastService } from "@core/services/toast.service";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: 'medis-service-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        [id]="formControlName"
        class="w-full"
        styleClass="w-full"
        appendTo="body"
        [options]="services"
        [filter]="services.length > 15"
        filterBy="name"
        [ngModel]="selectedServiceId"
        (onChange)="onSelect($event.value)"
        optionValue="serviceId"
        [showClear]="showClear"
        optionLabel="name"
      >
        <ng-template pTemplate="selectedItem">
          @if (selectedService) {
            <div class="flex align-items-center gap-2 relative">
              <medis-service-badge [badges]="selectedService!.serviceBadges"
                                   class="absolute"></medis-service-badge>
              <div
                [class.pl-5]="selectedService!.serviceBadges.length">{{ selectedService!.name }}
              </div>
            </div>
          }
        </ng-template>
        <ng-template let-service pTemplate="item">
          <div class="flex align-items-center gap-2">
            <medis-service-badge [badges]="service.serviceBadges"></medis-service-badge>
            <div>{{ service.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label class="text-color-secondary"
             [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceSelectListComponent),
      multi: true
    }
  ]
})
export class ServiceSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string = '';
  @Input() formControlName: string = '';
  @Input() showClear = false;
  @Output() onProviderChange = new EventEmitter<IMapping>();
  @Output() onServiceSelect = new EventEmitter<IService>();

  services: IService[] = [];
  @Input() selectedServiceId: any;
  selectedService: IService | undefined;

  constructor(private serviceService: ServiceService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes(): void {
    let params = new HttpParams()
      .set('excludeNotUsedInPlanTimes', 'true')
      .set('expandInfo', 'PartData')
      .set('includeComplexServices', 'true')
      .set('page', '1')
      .set('pageSize', '1000')
      .set('serviceBookingNotNeeded', 'false');
    this.serviceService.getServicesForSelect(params).subscribe({
      next: (resolve: IService[]) => {
        this.services = resolve;
        this.selectedService = this.services.find(x => x.serviceId == this.selectedServiceId);
      },
      error: (err: Error) => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedServiceId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedServiceId = value;
    this.selectedService = this.services.find(x => x.serviceId ==  value);
    this.onServiceSelect.emit(this.selectedService);
    this.onProviderChange.emit({serviceId: value});
    this.onChange(value);
    this.onTouched();
  }
}
