export enum ChannelId {
  MEDIS = 1,
  MIS = 2,
  MDR = 3
}

export function getChannelName(channelId: number): string {
  switch (channelId) {
    case ChannelId.MEDIS:
      return 'MEDIS';
    case ChannelId.MIS:
      return 'MIS';
    case ChannelId.MDR:
      return 'MDR';
    default:
      return 'Unknown Channel';
  }
}
