import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "./header/header.component";
import {SharedModule} from "@shared/shared.module";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {UserFullNamePipe} from "@shared/pipes/user-full-name.pipe";



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserFullNamePipe
  ]
})
export class HeaderModule { }
