import { Component, Input, OnInit } from '@angular/core';
import { IPerson } from "@core/interfaces/person.interface";
import { PersonService } from "@core/services/person.service";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DepartmentsService } from "@core/services/departments.service";
import { IDepartment } from "@core/interfaces/department.interface";
import { IService } from "@core/interfaces/service.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { ServiceService } from "@core/services/service.service";
import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { idCodeValidator } from "@shared/validators/idcodeValidator";
import { GeneralList } from "@core/enums/general-list.enum";
import { ToastService } from "@core/services/toast.service";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { HREFTarget } from "@core/enums/HREFTarget.enum";

@Component({
  selector: 'app-doctor-edit',
  templateUrl: './doctor-edit.component.html',
  styleUrl: './doctor-edit.component.scss'
})
export class DoctorEditComponent implements OnInit {

  private readonly CREATE_FORM_TITLE = 'Osutaja lisamine';
  private readonly EDIT_FORM_TITLE = 'Osutaja muutmine';

  @Input('id') id?: number;
  isModal?: boolean = false;
  modalInstance?: DynamicDialogComponent;

  person: IPerson | null = null;
  pageTitle: string = '';

  departments: IDepartment[] = [];
  relatedPersons: IPerson[] = [];
  services: IService[] = [];

  doctorEditForm!: FormGroup;

  get firstName(): FormControl {
    return this.doctorEditForm.get('firstName') as FormControl;
  }

  get lastName(): FormControl {
    return this.doctorEditForm.get('lastName') as FormControl;
  }

  get code(): FormControl {
    return this.doctorEditForm.get('code') as FormControl;
  }

  get personProfessions(): FormArray {
    return this.doctorEditForm.get('personProfessions') as FormArray;
  }

  constructor(
    private personService: PersonService,
    private formBuilder: FormBuilder,
    private departmentsService: DepartmentsService,
    private serviceService: ServiceService,
    private router: Router,
    private toastService: ToastService,
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    if (this.dialogConfig?.modal) {
      this.isModal = this.dialogConfig.modal;
    } else {
      this.pageTitle = this.id ? this.EDIT_FORM_TITLE : this.CREATE_FORM_TITLE;
    }

    if (!this.id && this.dialogConfig?.data?.id) {
      this.id = this.dialogConfig.data.id;
      this.modalInstance = this.dialogService.getInstance(this.ref);
    }

    this.getDepartments();
    this.getProviders();
    this.getServices();

    if (this.id) {
      this.personService.get(this.id).subscribe(
        {
          next: (response: IPerson) => {
            this.person = response;
            this.initEmptyForm();
            this.initBreadcrumbs();
          },
          error: err => {
            this.toastService.error(err.message);
          }
        }
      )
    } else {
      this.initEmptyForm();

    }
  }

  private getDepartments(): void {
    this.departmentsService.getDepartments().subscribe(
      {
        next: (response: IDepartment[]) => {
          this.departments = response;
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    );
  }

  private getProviders(): void {
    this.personService.getProvidersForSelect().subscribe(
      {
        next: (response: IPerson[]) => {
          this.relatedPersons = response;
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    );
  }

  private getServices(): void {
    this.serviceService.getServices(new HttpParams()
      .append("expandInfo", "PartData")
      .append("expandInfo", "ListData")
      .append("expandInfo", "EmployeeGroups")
      .append("isActive", true)
      .append("pageSize", 1000)
      .append("types", 0)
      .append("types", 3)
    ).subscribe(
      {
        next: (response: IService[]) => {
          this.services = response;
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    );
  }

  private initEmptyForm(): void {
    this.doctorEditForm = this.formBuilder.group({
      personId: [this.person?.personId || 0],
      firstName: [this.person?.firstName || null, Validators.required],
      lastName: [this.person?.lastName || null, Validators.required],
      code: [this.person?.code || null, [Validators.required, idCodeValidator()]],

      professionTypeId: [this.person?.professionTypeId || null],
      personProfessionCode: [this.person?.professionId || null],
      email: [this.person?.email || null],
      mobile: [this.person?.mobile || null],

      languages: [this.person?.languages || []],
      DepartmentIds: [this.person?.personDepartments.map(x => x.departmentId) || []],
      workaddressId: [this.person?.workaddressId || null],

      doctorCode: [this.person?.doctorCode || null],
      professionCode: [this.person?.professionCode || null],
      relatedPersonId: [this.person?.relatedPersonId || null],
      roomId: [this.person?.roomId || null],
      isProvider: [true],

      bookingWithoutPlanTime: [this.person?.bookingWithoutPlanTime || false],

      personProfessions:
        this.formBuilder.array(this.person?.personProfessions ? this.person?.personProfessions.map((service) => this.formBuilder.group({
          professionId: [service.professionId || null],
          personProfessionId: [service.personProfessionId || null],
          personId: [this.id ?? 0],
          professionCode: [service.professionCode || null],
          doctorCode: [service.doctorCode || null],
          tehikProfessionCode: [service.tehikProfessionCode || null],
        })) : []),
    });
  }

  getPersonProfession(idx: number): FormGroup {
    return this.personProfessions.at(idx) as FormGroup;
  }

  removePersonProfession(event: boolean, idx: number): void {
    if (event && idx >= 0) {
      this.personProfessions.removeAt(idx);
    }
  }

  addPersonProfession() {
    this.personProfessions.push(this.formBuilder.group({
      professionId: [null],
      personId: [this.id ?? 0],
      professionCode: [null],
      doctorCode: [null],
      tehikProfessionCode: [null]
    }));
  }

  submit() {
    if (this.doctorEditForm.invalid) {
      return;
    }
    this.personService.save(this.doctorEditForm.getRawValue()).subscribe(
      {
        next: (response: IPerson) => {
          if (this.isModal) {
            this.ref.close(response);
          } else {
            this.router.navigate([`doctors/${response.personId}/details`])
          }
        },
        error: err => {
          this.toastService.error(err.message);
        }
      })
  }

  cancel(): void {
    if (this.isModal) {
      this.ref.close();
    } else {
      this.router.navigate(this.id ? [`doctors/${this.id}/details`] : [`doctors`])
    }
  }

  serviceLanguages = [
    { key: 'et', value: 'Eesti' },
    { key: 'en', value: 'Inglise' },
    { key: 'fi', value: 'Soome' },
    { key: 'ru', value: 'Vene' },
    { key: 'by', value: 'Valgevene' },
    { key: 'de', value: 'Saksa' },
    { key: 'fr', value: 'Prantsusmaa' },
    { key: 'ua', value: 'Ukraina' },
  ];

  protected readonly OrderServiceStatus = OrderServiceStatus;
  protected readonly GeneralList = GeneralList;
  breadcrumbs: MenuItem[] | undefined;

  private initBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: "Osutajad",
        url: "doctors",
        target: HREFTarget.SELF
      },
      {
        label: `${this.person?.fullName}`,
        url: `doctors/${this.person?.personId}/details`,
        target: HREFTarget.SELF
      },
      {
        label: this.pageTitle,
        disabled: true
      },
    ]
  }
}
