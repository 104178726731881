import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: 'dayOfWeek'
})

export class DayOfWeekPipe implements PipeTransform {

  transform(dayNumber: number): string {
    switch(dayNumber) {
      case 1:
        return 'E';
      case 2:
        return 'T';
      case 3:
        return 'K';
      case 4:
        return 'N';
      case 5:
        return 'R';
      case 6:
        return 'L';
      case 7:
        return 'P';
      default:
        return '';
    }
  }
}
