import { Component, OnInit } from '@angular/core';
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthService } from "@core/services/auth.service";
import { Button } from "primeng/button";

@Component({
  selector: 'select-department-modal',
  standalone: true,
  templateUrl: './select-department.modal.component.html',
  imports: [
    Button
  ]
})
export class SelectDepartmentModalComponent implements OnInit {
  currentUser: ICurrentUser;
  departmentProfessionPairs: { department: any, profession: any }[] = [];

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private authService: AuthService
  ) {
    this.currentUser = this.config.data?.currentUser;
  }

  ngOnInit() {
    this.generateDepartmentProfessionPairs();
  }

  generateDepartmentProfessionPairs() {
    if (this.currentUser?.personDepartments && this.currentUser?.personProfessions) {
      this.currentUser.personDepartments.forEach(department => {
        let professions = this.currentUser.personProfessions;
        if(professions.length > 0) {
          this.currentUser.personProfessions.forEach(profession => {
            this.departmentProfessionPairs.push({
              department,
              profession
            });
          });
        } else {
          this.departmentProfessionPairs.push({
            department,
            profession: null
          });
        }
      });
    }
  }

  reload() {
    window.location.reload();
  }

  selectDepartmentProfession(departmentId: number, professionId: number) {
    this.authService.changeDepartment(departmentId, professionId).subscribe({
      next: () => {
        this.currentUser.selectedDepartmentId = departmentId;
        this.currentUser.selectedPersonProfessionId = professionId;
        this.ref.close();
        this.reload();
      },
      error: (error: any) => {
        console.error('Error changing department:', error);
      }
    });
  }

}
