import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { IDepartmentProfessionPair } from "@core/interfaces/department-profession-pair.interface";
import { IPersonProfession } from "@core/interfaces/person-profession.interface";
import { IDepartment } from "@core/interfaces/department.interface";

@Component({
  selector: 'app-dept-menu',
  templateUrl: './dept-menu.component.html',
  styleUrls: ['./dept-menu.component.scss'],
})
export class DeptMenuComponent {
  @Input() selectedProfession: IPersonProfession | null = null;
  @Input() selectedDepartment: IDepartment | null = null;
  @Input() departmentProfessionPairs: { department: IDepartment, profession: IPersonProfession }[] = [];
  @Output() departmentChanged = new EventEmitter<{
    department: IDepartment;
    profession: IPersonProfession;
  }>();
  @Output() menuToggled = new EventEmitter<any>();

  constructor(private authService: AuthService,) {
  }

  isMenuActive: boolean = false;

  toggleMenu(event: any, overlayPanel: any) {
    this.isMenuActive = !this.isMenuActive;
    this.menuToggled.emit(this.isMenuActive);
    overlayPanel.toggle(event);
  }

  changeDepartment( department: IDepartment, profession: IPersonProfession ) {
    this.departmentChanged.emit({ department, profession });
  }

  onMenuHide() {
    this.isMenuActive = false;
  }

  isSelected(dep: IDepartmentProfessionPair): boolean {
    return (
      dep.department.departmentId === this.selectedDepartment?.departmentId &&
      dep.profession.personProfessionId === this.selectedProfession?.personProfessionId
    );
  }

  logout() {
    this.authService.logout();
  }
}
